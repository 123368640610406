import {
  getColorNumberForUserAvatar,
  getInitials,
  UserEntity
} from "@/domain/user"
import dayjs from "dayjs"

import type { UsersResponse } from "@/api/useUsers.types"

export const getUsersData = (response: UsersResponse) => {
  const { data, metadata } = response || {}

  return {
    metadata,
    data: data.map<UserEntity>((user) => {
      return {
        initials: getInitials(user.email),
        key: user.email,
        colorNumber: getColorNumberForUserAvatar(user.email),
        ...user,
        lastLogin:
          user.lastLogin === undefined
            ? ""
            : dayjs(new Date(Number(`${user.lastLogin}000`))).format(
                "YYYY-MM-DD HH:mm"
              )
      }
    })
  }
}
