import { useDeleteKey, useGetAuthKeys } from "@/api/useAuthKeys"
import { AuthKeyType } from "@/api/useAuthKeys.types"
import { useState } from "react"
import { wrapFn } from "../../helpers/applications"
import { getAuthKeysData } from "./useAuthKeys.selector"

export const useAuthKeys = (
  needle: AuthKeyType,
  config?: { enabled: boolean }
) => {
  const [loading, setLoading] = useState(false)
  const context = useGetAuthKeys(needle, {
    select: getAuthKeysData,
    ...(config || {})
  })

  const { mutateAsync: mutateDeleteKey } = useDeleteKey(needle)

  const deleteKey = wrapFn(async (keyId: string) => {
    await mutateDeleteKey(keyId)
  }, setLoading)

  return [context, { loading, deleteKey }] as const
}

export type AuthKeysTabData = ReturnType<typeof getAuthKeysData>["data"]

export type AuthKey = AuthKeysTabData[number]
