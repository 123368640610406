import { useGetInventorySnapshot } from "@/api/useInventory"
import { useGetPosture } from "@/api/usePosture"
import { useParallelQueries } from "@/hooks/useHttp"
import { useFunnelTriage } from "@/pages/TriagePage/components/organisms/FunnelTriagePage"
import { snapshotInventoryPropsSelector } from "../selectors/inventory.selector"
import { posturePropsSelector } from "../selectors/posture.selector"
import { getQueriesSBOM } from "./helpers"

import type { DashboardProps, TotalResponse } from "./useDashboard.types"

const useDashboard = (): DashboardProps => {
  const postureResponse = useGetPosture(undefined, {
    select: posturePropsSelector
  })
  const inventorySnapshotResponse = useGetInventorySnapshot(undefined, {
    select: snapshotInventoryPropsSelector
  })

  const queries = getQueriesSBOM({ pageSize: 0 })
  const [context] = useParallelQueries<TotalResponse>(queries)
  const funnelContext = useFunnelTriage()
  const inventorySnapshotIsLoadingCompound =
    inventorySnapshotResponse.isLoading || context[0].isLoading
  const unavailable =
    !inventorySnapshotIsLoadingCompound &&
    !inventorySnapshotResponse.response?.containerImage.total
  const totalPackages = context[0].data?.metadata?.total || 0
  const inUseTotalPackages = context[1].data?.metadata?.total || 0

  return {
    postureProps: {
      ...postureResponse
    },
    inventorySnapshotProps: inventorySnapshotResponse,
    packagesInUseProps: {
      unavailable,
      response: {
        inUseTotal: inUseTotalPackages,
        notInUseTotal: totalPackages - inUseTotalPackages
      },
      isLoading:
        inventorySnapshotIsLoadingCompound ||
        context[0].isLoading ||
        context[1].isLoading
    },
    funnelProps: { context: funnelContext }
  }
}

export default useDashboard
