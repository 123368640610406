import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useHttp, useUpdate } from "@/hooks/useHttp"
import { useUser } from "@/hooks/useUser"

import type { SastResponse } from "./useSettings.types"

export const useGetSastSettings = <T = SastResponse, S = T>() => {
  const context = useHttp<T, S>(RESOURCES_NAMES.SETTINGS.SAST)

  return context
}

export const useUpdateSastSettings = <T = SastResponse>() => {
  const { updateFeatureFlags } = useUser()

  const context = useUpdate<T, unknown>(
    RESOURCES_NAMES.SETTINGS.SAST,
    undefined,
    undefined,
    () => ({
      onSuccess: (response) => {
        const { data } = response as SastResponse
        const { codeScanningEnabled } = data

        updateFeatureFlags({ sastScanFeature: codeScanningEnabled })
      }
    })
  )

  return context
}
