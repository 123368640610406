import { ISSUES_LIMIT } from "@/const/table-constants"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { NumberConvertor } from "@/hooks/useNumberConvertor"
import { ImpactCell } from "@/molecules/ImpactCell"
import { IssuesSummaryCell } from "@/molecules/IssuesSummaryCell"
import { MoreTableCell } from "@/molecules/MoreTableCell"
import { DigestImageCell } from "../../molecules/DigestImageCell"
import { ViewSbomCell } from "../../molecules/ViewSbomCell"

import type { SortOrder } from "@/atoms"
import type { RowClick } from "@/hooks/useLinkDrawerTooltip"
import type { ORDER_BY_PROPS } from "@/hooks/useOrderBy"
import type { TFunction } from "@/hooks/useTranslation"

export const getColumnsTablePreviewMode = (
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">
) => [
  {
    title: "Image Name",
    render: MoreTableCell,
    ...getDefaultColumnParams("imageName", sorter),
    sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
  },
  {
    title: "Digest",
    width: 130,
    render: DigestImageCell,
    ...getDefaultColumnParams("digestShort")
  }
]

export const getColumnsTable = ({
  sorter,
  t,
  convertors,
  previewMode,
  onRowClick
}: {
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">
  t: TFunction
  convertors: NumberConvertor
  previewMode?: boolean
  onRowClick?: RowClick
}) => {
  const columnsPreviewMode = getColumnsTablePreviewMode(sorter)

  if (previewMode) return columnsPreviewMode

  return [
    ...columnsPreviewMode,
    {
      title: "Issues Summary",
      width: 220,
      render: IssuesSummaryCell({ t, convertors }, ISSUES_LIMIT),
      ...getDefaultColumnParams("severity", sorter),
      defaultSortOrder: "descend" as SortOrder,
      sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
    },
    {
      title: "Apps",
      width: 70,
      className: "center-align-tab-template-component",
      sortDirections: ["descend", "ascend"] as SortOrder[],
      render: ImpactCell({ onRowClick }),
      ...getDefaultColumnParams("applicationsCount", sorter)
    },
    {
      title: "SBOM",
      width: 60,
      className: "center-align-tab-template-component",
      render: ViewSbomCell,
      ...getDefaultColumnParams("sbomQuery")
    },
    {
      title: "First Seen",
      width: 155,
      ...getDefaultColumnParams("firstSeen")
    }
  ]
}
