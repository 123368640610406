import { InfoCircleOutlined } from "@/icons"

import { Alert } from "@/atoms"
import cn from "classnames"
import styles from "./InformationAlert.module.scss"
import { Props } from "./InformationAlert.types"

export const InformationAlert = ({ message, className }: Props) => {
  return (
    <Alert
      className={cn(className, styles.infoIcon)}
      type="info"
      icon={<InfoCircleOutlined />}
      message={message}
      showIcon
    />
  )
}
