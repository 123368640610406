import { ERROR_EVENTS } from "@/const/event.constants"
import { getErrorType, isNetworkError } from "@/helpers/error.helper"
import { Component } from "react"
import type { ErrorBoundaryProps, ErrorBoundaryState } from "."
import { LayoutErrorResult } from "./LayoutErrorResult"

export class LayoutErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    const errorType = getErrorType(error)
    return {
      hasError: true,
      reason: errorType
    }
  }

  componentDidCatch(error: any) {
    if (isNetworkError(error)) return // http errors are logged in useHttp hook
    const custom_error = ERROR_EVENTS.LAYOUT
    this.props.logger.error(error as Error, { tags: custom_error })
  }

  componentDidUpdate(prevProps: Readonly<ErrorBoundaryProps>): void {
    if (
      this.state.reason === "Forbidden" &&
      this.props.user.currentScopeId !== prevProps.user.currentScopeId
    ) {
      this.setState({ hasError: false, reason: undefined })
    }
  }

  render() {
    return this.state.hasError && this.state.reason ? (
      <LayoutErrorResult errorType={this.state.reason} />
    ) : (
      this.props.children
    )
  }
}
