import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { useDocumentationToken } from "@/api/useAuth"
import { EXTERNAL_ROUTES } from "@/const/routes.constants"
import { Loader } from "@/pages/SignInPage/components/molecules/Loader"

export const ApiDocsAuth = () => {
  const { refetch } = useDocumentationToken({ enabled: false })
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setTimeout(() => {
      refetch().then((result) => {
        const token = result.data?.data.token
        const location = searchParams.get("location")
        const pathByLocation = !!location ? `${location}` : ""

        if (token)
          window.open(
            `${EXTERNAL_ROUTES.API_DOCS}${pathByLocation}?${EXTERNAL_ROUTES.PARAMS.JWT_TOKEN}=${token}`,
            "_self"
          )
        else navigate("/")
      })
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Loader />
    </div>
  )
}
