import { Form } from "@/atoms"
import { useServices } from "@/hooks/useServices"
import { useMultiFactorForm } from "@/pages/SignInPage/hooks/useMultiFactorForm"
import { MultiFactorFormTemplate } from "../../templates/MultiFactorFormTemplate"
import { ConfirmCodeInput } from "../ConfirmCodeInput"
import { PhoneStepsMultiFactorForm } from "../PhoneStepsMultiFactorForm"

import styles from "./ConfirmCodeMultiFactorForm.module.scss"

import type { ActionMultiFactorFormTemplateProps } from "../../templates/MultiFactorFormTemplate"

export const ConfirmCodeMultiFactorForm = ({
  values,
  onOk,
  onCancel
}: ActionMultiFactorFormTemplateProps) => {
  const codeFieldName = "code"
  const { step } = values
  const { auth } = useServices()
  const {
    inputRef,
    value,
    form,
    okDisabled,
    loading,
    setLoading,
    onSubmit,
    onKeyDown,
    t
  } = useMultiFactorForm(codeFieldName)

  return (
    <MultiFactorFormTemplate
      title={t("auth.mfa.confirmEnroll.title")}
      description={t("auth.mfa.confirmEnroll.title", {
        phoneNumber: values?.phoneNumber || ""
      })}
      okText={t("general.continue")}
      okDisabled={okDisabled}
      cancelText={t("general.back")}
      steps={<PhoneStepsMultiFactorForm loading={loading} currentStep={step} />}
      classes={{ title: styles.title }}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <Form
        name="codePhoneValidationForm"
        form={form}
        onFinish={async () => {
          try {
            setLoading(true)

            const { verificationId } = values

            await auth.verificationCode(verificationId, value, true)

            onOk()
          } catch (err) {
            const message = err instanceof Error ? err.message : ""

            if (
              message.includes("verificationCode-auth-adapter: INVALID-PARAMS")
            ) {
              return form.setFields([
                {
                  name: "code",
                  errors: [t("auth.mfa.errors.default")]
                }
              ])
            }

            form.setFields([
              { name: "code", errors: [t("auth.mfa.errors.invalid-code")] }
            ])
          } finally {
            setLoading(false)
          }
        }}
      >
        <ConfirmCodeInput
          inputRef={inputRef}
          name={codeFieldName}
          onKeyDown={onKeyDown}
        />
      </Form>
    </MultiFactorFormTemplate>
  )
}
