import { Form, InputNumber, Space, Text } from "@/atoms"
import { useFilters, useStateFilter } from "@/hooks/useFilters"
import { useEffect, useState } from "react"
import { DropdownFilter } from "../DropdownFilter"

import styles from "./KodemScoreFilter.module.scss"

export const KodemScoreFilter = ({
  isLoading,
  maxValue = 10,
  placeHolderDefaultValue = "Kodem Score",
  label = placeHolderDefaultValue,
  prefix = "KS ≥"
}: any) => {
  const [error, setError] = useState(false)
  const { searchParams, setSearchParams, setHash } = useFilters()
  const createInitialValues = () => ({
    kodemScore: searchParams.get("kodemScore") || 0
  })
  const { filterState, reset, onFilterStateChange, onOk } = useStateFilter(
    createInitialValues()
  )
  const value = Number(filterState.values.kodemScore)
  const validation = (value: number | string | null) => {
    const number = Number(value)

    if (number > maxValue || number < 0) {
      setError(true)
    } else {
      setError(false)
    }
  }

  const menuItems = [
    {
      key: "kodemScore",
      label: (
        <Form.Item
          className={styles.formItem}
          validateStatus={error ? "error" : undefined}
          extra={error ? `Insert a value between 0-${maxValue}` : undefined}
        >
          <Space>
            <Text>{label}</Text>
            <InputNumber
              placeholder={placeHolderDefaultValue}
              defaultValue={0}
              value={Number(filterState.values.kodemScore)}
              onInput={validation}
              onChange={(value) => {
                validation(value)
                onFilterStateChange({ kodemScore: value || 0 })
              }}
            />
          </Space>
        </Form.Item>
      )
    }
  ]

  useEffect(() => {
    const result = menuItems.some(({ key }: any) => {
      const checked = filterState.values[key]
      const checkedInUrl = searchParams.get(key)

      return checked !== checkedInUrl
    })

    if (result) {
      reset(createInitialValues())
    }

    onOk()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  return (
    <DropdownFilter
      disabled={isLoading}
      menuItems={menuItems}
      isPlaceholder={value === 0}
      clearDisabled={value === 0}
      okDisabled={!filterState.dirty || error}
      onOpenChange={(open: boolean) => {
        if (open) {
          reset(createInitialValues(), false)
        }
      }}
      onClear={() => onFilterStateChange({ kodemScore: 0 })}
      onOk={() => {
        setSearchParams((prev) => {
          const next = new URLSearchParams(prev)

          next.set("pageNumber", "0")

          if (value !== 0) {
            next.set("kodemScore", `${value}`)
          } else {
            next.delete("kodemScore")
          }

          return next
        })

        setHash(Date.now())
      }}
    >
      {`${prefix} ${createInitialValues().kodemScore}`}
    </DropdownFilter>
  )
}
