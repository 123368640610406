import { RESOURCES_NAMES } from "@/const/resource.constants"

export const AuthKeyStatusValues = {
  ACTIVE: "ACTIVE",
  EXPIRING_SOON: "EXPIRING_SOON",
  EXPIRED: "EXPIRED"
} as const

export const ExpirationOptionValues = {
  THREE_MONTHS: "THREE_MONTHS",
  ONE_YEAR: "ONE_YEAR",
  THREE_YEARS: "THREE_YEARS",
  NEVER: "NEVER"
} as const

export const AuthKeyTypeValues = {
  API_KEYS: RESOURCES_NAMES.AUTHENTICATION.API_KEYS,
  DEPLOYMENT_KEYS: RESOURCES_NAMES.AUTHENTICATION.DEPLOYMENT_KEYS,
  SERVICE_KEYS: RESOURCES_NAMES.AUTHENTICATION.SERVICE_KEYS
} as const

export const SourceIntegrationValues = {
  KODEM_CI: "kodem_ci",
  JENKINS: "jenkins",
  GITHUB_ACTIONS: "github_actions",
  CIRCLE_CI: "circle_ci"
} as const
