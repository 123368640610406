import { Card } from "@/atoms"
import { Heading } from "@/molecules/Heading"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"

import styles from "./OverviewCardTemplate.module.scss"

import type { OverviewCardTemplateComponent } from "."

export const EMPTY_VALUE = "-"

export const OverviewCardTemplate: OverviewCardTemplateComponent = ({
  items,
  title
}) => (
  <Card className={styles.card} title={<Heading level={6} title={title} />}>
    <OverviewDescriptions flex column={1} layout="horizontal" items={items} />
  </Card>
)
