import { useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"

import type {
  ContextFilters,
  Filters,
  Params,
  Props
} from "./useParamsFilter.types"

const FIELD_NAME_FILTER_ARR = [
  "severities",
  "languages",
  "severity",
  "exploitMaturity",
  "fixability"
]

export const getDefaultFilterSeverities = (fieldName = "severities") => ({
  [fieldName]: "Critical,High"
})

export const getDefaultFiltersImageDrawer = (severityFieldName?: string) => ({
  runtime: "Yes",
  ...getDefaultFilterSeverities(severityFieldName)
})

export const getDefaultParamsOpenSourceIssue = () => ({
  issueType: "Vulnerable_Package,Malicious_Package"
})

export const getDefaultParamsSastIssue = () => ({
  issueType: "Vulnerable_Code,Exposed_Secret"
})

export const getDefaultFiltersInventoryImageIssuesDrawer = () => ({
  ...getDefaultFiltersInventoryIssuesDrawer(),
  runtime: "Yes"
})

export const getDefaultFiltersInventoryIssuesDrawer = () => ({
  issueStatus: "open",
  ...getDefaultFilterSeverities("severity")
})

export const getDefaultFiltersInventorySastIssuesDrawer = () => ({
  issueStatus: "open"
})

export const geDefaultFiltersPackageDrawer = (props: {
  id: string
  projectId: string
}) => ({
  packageId: props.id,
  projectId: props.projectId,
  ...getDefaultParamsOpenSourceIssue(),
  ...getDefaultFiltersInventoryIssuesDrawer()
})

const getInitialState = (
  props: Props,
  attr: { searchParams: URLSearchParams }
) => {
  const {
    filterKey,
    initialParams = {},
    tabs = {},
    initialFilters = []
  } = props || {}
  const { defaultParams = {} } = props

  const initialFiltersAccValues = initialFilters.reduce(
    (acc, filter) => {
      const value = attr?.searchParams.get(filter)

      if (value) {
        acc[filter] = value
      }

      return acc
    },
    {} as Record<string, string>
  )

  const initialFiltersValues = Object.keys(initialFiltersAccValues).length
    ? initialFiltersAccValues
    : undefined

  const initialValue = tabs[filterKey] || initialFiltersValues || initialParams
  return { ...defaultParams, ...initialValue }
}

const getValueFromStrMultiFilter = (value: string) =>
  value ? value.split(",").filter(Boolean) : undefined

const getValidFiltersObj = (
  filters: Filters,
  settings = { arrays: FIELD_NAME_FILTER_ARR }
) =>
  Object.keys(filters).reduce((acc, key) => {
    if (filters[key] === undefined) return acc

    if (typeof filters[key] === "string" && settings.arrays.includes(key)) {
      acc[key] = getValueFromStrMultiFilter(filters[key] as string)
    } else {
      acc[key] = filters[key]
    }

    return acc
  }, {} as Filters)

const getEncodeStringFromObj = (filters: Filters) =>
  encodeURI(JSON.stringify(filters))

const getParamsWithFilters = (
  { pageNumber, orderBy, ascending, ...rest }: Params,
  fn = getValidFiltersObj
) => {
  const isFiltersEmpty = Object.keys(rest).length === 0

  return {
    ascending,
    orderBy,
    pageNumber: pageNumber || 0,
    filters: isFiltersEmpty ? undefined : getEncodeStringFromObj(fn(rest))
  }
}

const utils = {
  getValidFiltersObj,
  getEncodeStringFromObj,
  getParamsWithFilters,
  getValueFromStrMultiFilter
}

const useParamsState = (
  props: Props,
  attr: { searchParams: URLSearchParams }
) => {
  const [searchParams] = useSearchParams()
  const [params, setParams] = useState(
    getInitialState(props, {
      searchParams: attr?.searchParams || searchParams
    })
  )
  const patchParams = (newParams: Record<string, unknown> = {}) =>
    setParams((prevParams) => ({ ...prevParams, ...newParams }))

  return {
    params,
    utils,
    patchParams,
    updateParams: setParams,
    onChange: setParams,
    onPaginationChange: (pageNumber: string) => patchParams({ pageNumber })
  }
}

const useQueryStringState = (props: Props) => {
  const initialized = useRef(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const context = useParamsState(props, { searchParams })
  const { params } = context

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true

      return
    }
    const { drawerId } = props

    if (!drawerId) return

    const value = searchParams.get(drawerId)
    const decodeValue = value ? props.actions.decode(value) : {}
    const { pageNumber, ...rest } = params

    if (!decodeValue) return

    if (decodeValue.tabs) {
      decodeValue.tabs[props.filterKey] =
        `${pageNumber}` === "0" ? rest : params
    }

    searchParams.set(drawerId, props.actions.encode(decodeValue))
    setSearchParams(searchParams, { replace: true })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return context
}

export const useParamsFilter = (props: Props): ContextFilters => {
  const { drawerId } = props
  const hook = useRef(drawerId ? useQueryStringState : useParamsState).current
  //@ts-ignore
  return hook(props)
}
