import { useTranslation } from "@/hooks/useTranslation"
import { LinkButton } from "@/molecules/LinkButton"
import styles from "./IntegrationStatusButtonsContainer.module.scss"
import type { ButtonsContainerProps } from "./IntegrationStatusButtonsContainer.types"

export const IntegrationStatusButtonsContainer = ({
  integrationStatus,
  vendor
}: ButtonsContainerProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "integrations.callback"
  })
  return (
    <div className={styles.buttonsContainer}>
      {integrationStatus === "SUCCESS" && (
        <LinkButton
          to={`/integrations/${vendor}?tab=configuration`}
          type="primary"
        >
          {t("configureIntegration")}
        </LinkButton>
      )}

      {integrationStatus === "ERROR" && (
        <LinkButton to={`/integrations/${vendor}?tab=connect`}>
          {t("backToVendorIntegration", {
            name: t(`vendors.${vendor}`)
          })}
        </LinkButton>
      )}
      {integrationStatus === "REQUESTED" && (
        <>
          <LinkButton to="/">{t("linkToDashboard")}</LinkButton>
          <LinkButton to="/integrations" type="primary">
            {t("linkToIntegrations")}
          </LinkButton>
        </>
      )}
    </div>
  )
}
