import type { Token } from "./token/token-handler.types"

export const isEmptyString = (str: string) => !str || str.trim().length === 0

export const getTokenTemplate = (
  str = "",
  props: { offset?: number }
): Token => {
  const { offset = 0 } = props

  return {
    str,
    id: str,
    delimiter: undefined,
    value: undefined,
    startCaretPos: offset,
    endCaretPos: offset + str.length,
    isEmpty: isEmptyString(str)
  }
}
