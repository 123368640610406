import { AbstractHandler } from "./token-handler"

import type { PropsHandler, Token } from "./token-handler.types"

export class TrailingCommaHandler extends AbstractHandler {
  public handle(token: Token, props: PropsHandler): Token {
    const { str, value } = token

    if (value?.[value.length - 1] !== ",") {
      return super.handle(token, props)
    }

    const { endCaretPos } = token
    const { StringService } = props
    const caretPos = StringService.getCaretPos()

    if (endCaretPos > caretPos || StringService.isCaretPosInToken(token)) {
      return super.handle(token, props)
    }

    token.value = value.slice(0, -1)
    token.str = str.slice(0, -1)
    token.endCaretPos = endCaretPos - 1

    StringService.setCaretPos(caretPos - 1)

    return super.handle(token, props)
  }
}
