import cn from "classnames"

import { Table } from "@/atoms"
import { DataReadinessPlaceholder } from "@/molecules/DataReadinessPlaceholder"

import styles from "./DataReadiness.module.scss"

import type { DataReadinessProps } from "./DataReadiness.types"

export const DataReadiness: React.FC<DataReadinessProps> = (props) => {
  const { columns, type, locale } = props
  const { dataReadiness } = locale || {}
  const {
    classes = {},
    component = DataReadinessPlaceholder,
    ...attr
  } = dataReadiness || {}
  const { container, ...restClasses } = classes

  return (
    <Table
      key="DataReadinessTabTemplate"
      columns={columns}
      pagination={false}
      locale={{
        emptyText:
          typeof component === "function"
            ? component({
                type,
                classes: {
                  container: cn(styles.container, classes.container),
                  ...restClasses
                },
                ...attr
              })
            : component
      }}
    />
  )
}
