import type { ListScopesPermittedToUserResponse } from "@/api/useUsers.types"
import type { Option } from "."

export const getScopeOptions = (
  response: ListScopesPermittedToUserResponse
) => {
  const { data } = response || {}
  const { allUsersScopes, userSpecificScopes, optionalScopes } = data || {}
  return [
    ...allUsersScopes.map<Option>((scope) => ({
      label: scope.name,
      value: scope.id,
      disabled: true,
      selected: true
    })),
    ...userSpecificScopes.map<Option>((scope) => ({
      label: scope.name,
      value: scope.id,
      disabled: false,
      selected: true
    })),
    ...optionalScopes.map<Option>((scope) => ({
      label: scope.name,
      value: scope.id,
      disabled: false,
      selected: false
    }))
  ]
}
