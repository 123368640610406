import { HeaderPage } from "@/organisms/HeaderPage"
import { ApplicationsTab } from "@/pages/DiscoveryPage/components/organisms/ApplicationsTab"
import { useActiveKeyTabsInventory } from "@/pages/DiscoveryPage/hooks/useActiveKeyTabsInventory"
import { PageTemplate } from "@/templates/PageTemplate"

export const ApplicationsPage = () => (
  <PageTemplate
    full
    header={<HeaderPage titleTranslationKey="navigation.applications" />}
  >
    <ApplicationsTab useActiveKeyTabsInventory={useActiveKeyTabsInventory} />
  </PageTemplate>
)
