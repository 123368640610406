import { Switch } from "@/atoms"

import type { Policy } from "@/api/usePolicies.types"

export const StatusSwitcherCell =
  (isUpdating: boolean, onChange: (policy: Policy, checked: boolean) => void) =>
  (enabled: boolean, policy: Policy) => {
    return (
      <Switch
        disabled={isUpdating}
        checked={enabled}
        onChange={(checked: boolean, event) => {
          event.preventDefault()
          event.stopPropagation()
          onChange(policy, checked)
        }}
      />
    )
  }
