import cn from "classnames"
import List from "rc-virtual-list"

import { preventEventBubbling } from "@/helpers/utils"
import { ItemContentShowMore } from "./ItemContentShowMore"

import styles from "./ContentShowMore.module.scss"

import type { ContentShowMoreProps } from "."

export const ContentShowMore = ({
  items,
  copyable,
  openDrawer,
  height = 210,
  width = 340,
  itemKey = "key"
}: ContentShowMoreProps) => {
  const itemLength = items?.length
  const noBullets = itemLength < 2
  const itemHeight = (items[0] as any)?.description ? 42 : 22
  const listHeight =
    (itemLength || 0) * itemHeight > height ? height : undefined
  const commonProps = { copyable, width, noBullets, openDrawer }

  if (copyable && listHeight) {
    const lengthMax = items.reduce((max, item) => {
      const length = typeof item === "string" ? item.length : item.name.length

      return length > max ? length : max
    }, 0)
    const lengthMultiplier = 8
    const widthByLength = lengthMax * lengthMultiplier

    return (
      <List
        style={{
          minWidth: widthByLength > 360 ? 360 : widthByLength
        }}
        itemKey={itemKey}
        data={items}
        height={listHeight}
        itemHeight={itemHeight}
        onClick={preventEventBubbling}
      >
        {(item) => ItemContentShowMore(item, commonProps)}
      </List>
    )
  }

  return (
    <ul
      style={{ maxHeight: `${height}px` }}
      className={cn(styles.list, noBullets && styles.noBullets)}
      onClick={preventEventBubbling}
    >
      {(items || []).map((item, index) =>
        ItemContentShowMore(item, { index, ...commonProps })
      )}
    </ul>
  )
}
