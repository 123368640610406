import { IconTag } from "@/molecules/IconTag"
import { InsightsIcons, checkInsightValue } from "@/molecules/InsightsIcons"
import { ShowMore } from "@/molecules/ShowMore"

import styles from "./InsightsCell.module.scss"

import { InsightsCellComponent } from "."

export const InsightsCell: InsightsCellComponent = (props) => {
  const { id, items, values, t, tag, ...attr } = props
  const isArray = Array.isArray(values)

  if (tag) {
    return items.map((item, index) => {
      const value = checkInsightValue(
        isArray ? values[index] : values[item.key]
      )

      if (!value) return null

      return (
        <IconTag
          key={index}
          icon={<item.Icon />}
          text={t(`insights.${item.key}`)}
        />
      )
    })
  }

  const noTooltip = !Array.isArray(values) && values.allFalse

  if (noTooltip) {
    return <InsightsIcons noTooltip items={items} values={values} {...attr} />
  }

  return (
    <ShowMore
      id={id}
      label={
        <ShowMore.Label.Component>
          <InsightsIcons noTooltip items={items} values={values} {...attr} />
        </ShowMore.Label.Component>
      }
    >
      <ShowMore.Component
        title={t("general.insights")}
        classes={{ content: styles.contentPopover }}
      >
        {items.map((item, index) => {
          const value = checkInsightValue(
            isArray ? values[index] : values[item.key]
          )

          if (!value) return null

          return (
            <IconTag
              key={index}
              icon={<item.Icon />}
              text={t(`insights.${item.key}`)}
            />
          )
        })}
      </ShowMore.Component>
    </ShowMore>
  )
}
