import { SEVERITIES_FILTER_OPTIONS } from "@/const/options.constants"
import type { TFunction } from "@/hooks/useTranslation"
import { OptionFilter } from "@/organisms/SmartSearch/types/options.types"

export const InventoryFilters = {
  application: (t: TFunction) =>
    ({
      key: "applicationName",
      label: t("filters.application"),
      type: "input",
      helper: [
        {
          label: t("inventory.generalFilters.multipleValues", {
            label: "application"
          }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "applications"
          })
        }
      ]
    }) as OptionFilter,
  environment: (t: TFunction) =>
    ({
      key: "environment",
      label: t("filters.environment"),
      type: "input",
      helper: [
        {
          label: t("inventory.generalFilters.multipleValues", {
            label: "environment"
          }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "environments"
          })
        }
      ]
    }) as OptionFilter,
  namespace: (t: TFunction) =>
    ({
      key: "namespace",
      label: t("filters.namespace"),
      type: "input",
      helper: [
        {
          label: t("inventory.generalFilters.multipleValues", {
            label: "namespace"
          }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "namespaces"
          })
        }
      ]
    }) as OptionFilter,
  repositoryName: (t: TFunction) =>
    ({
      key: "repositoryName",
      label: t("filters.codeRepository"),
      type: "input",
      helper: [
        {
          label: t("inventory.generalFilters.multipleValues", {
            label: "code-repository"
          }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "code repositories"
          })
        }
      ]
    }) as OptionFilter,
  imageName: (t: TFunction) =>
    ({
      key: "imageName",
      label: t("filters.image"),
      type: "input",
      helper: [
        {
          label: t("inventory.generalFilters.multipleValues", {
            label: "image"
          }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "images"
          })
        }
      ]
    }) as OptionFilter,
  packageManagers: (t: TFunction) =>
    ({
      key: "packageManagers",
      label: t("filters.packageManager"),
      type: "multiple",
      helper: [
        {
          label: t("inventory.generalFilters.multipleValues", {
            label: "package-manager"
          }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "package managers"
          })
        }
      ],
      options: [
        {
          label: t("packageManagers.PACKAGE_MANAGER_UNSPECIFIED"),
          value: "unspecified"
        },
        t("packageManagers.PACKAGE_MANAGER_PIP"),
        t("packageManagers.PACKAGE_MANAGER_POETRY"),
        t("packageManagers.PACKAGE_MANAGER_GO_MODULES"),
        t("packageManagers.PACKAGE_MANAGER_RUBYGEMS"),
        t("packageManagers.PACKAGE_MANAGER_MAVEN"),
        t("packageManagers.PACKAGE_MANAGER_COMPOSER"),
        t("packageManagers.PACKAGE_MANAGER_YARN"),
        t("packageManagers.PACKAGE_MANAGER_PNPM"),
        t("packageManagers.PACKAGE_MANAGER_GRADLE"),
        t("packageManagers.PACKAGE_MANAGER_NPM"),
        t("packageManagers.PACKAGE_MANAGER_SBT"),
        t("packageManagers.PACKAGE_MANAGER_NUGET")
      ].map((value) => {
        if (typeof value === "string") {
          return { label: value, value }
        }

        return value
      })
    }) as OptionFilter
}

export const getCVEFilter = (key = "riskId") => {
  return {
    key: key,
    label: "cve",
    displayLabel: "CVE",
    type: "input",
    helper: [
      {
        label: "CVE:value1,value2",
        description:
          "Matches multiple cve or malicious identifiers as a substring (e.g. CVE-2022-3715, MAL-2022-4691)"
      }
    ]
  } as OptionFilter
}

export const getSeverityFilter = () => {
  return {
    key: "severity",
    label: "severity",
    type: "multiple",
    options: [...SEVERITIES_FILTER_OPTIONS]
  } as OptionFilter
}

export const getRuntimeFilter = () => {
  return {
    key: "runtime",
    order: 0,
    label: "runtime",
    type: "single",
    options: [
      { label: "Yes", value: "YES", disabled: false },
      { label: "No", value: "NO", disabled: true }
    ]
  } as OptionFilter
}
