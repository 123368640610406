export const findGetCursorPosition = (
  parent: any,
  node: any,
  offset: any,
  stat: any
) => {
  if (stat.done) return stat

  let currentNode = null

  if (parent.childNodes.length === 0) {
    stat.pos += parent.textContent.length
  } else {
    for (let i = 0; i < parent.childNodes.length && !stat.done; i++) {
      currentNode = parent.childNodes[i]
      if (currentNode === node) {
        stat.pos += offset
        stat.done = true
        return stat
      } else findGetCursorPosition(currentNode, node, offset, stat)
    }
  }

  return stat
}

export const findSetCursorPosition = (parent: any, range: any, stat: any) => {
  if (stat.done) return range

  let currentNode = null

  if (parent.childNodes.length === 0) {
    if (parent.textContent.length >= stat.pos) {
      range.setStart(parent, stat.pos)
      stat.done = true
    } else {
      stat.pos = stat.pos - parent.textContent.length
    }
  } else {
    for (let i = 0; i < parent.childNodes.length && !stat.done; i++) {
      currentNode = parent.childNodes[i]
      findSetCursorPosition(currentNode, range, stat)
    }
  }
  return range
}

export const getCursorPosition = (ref: any) => {
  const sel = window.getSelection()

  if (sel !== null && ref.current !== null) {
    const node = sel.focusNode
    const offset = sel.focusOffset
    const pos = findGetCursorPosition(ref.current, node, offset, {
      pos: 0,
      done: false
    })

    if (offset === 0) pos.pos += 0.5

    return pos.pos
  }
}

export const setCursorPosition = (ref: any, caretPos: number) => {
  const sel = window.getSelection()

  if (sel === null) return

  sel.removeAllRanges()

  const range = findSetCursorPosition(ref.current, document.createRange(), {
    pos: caretPos,
    done: false
  })

  range.collapse(true)
  sel.addRange(range)
}
