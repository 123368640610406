import { DownOutlined, RightOutlined } from "@/icons"

import { ExpandIconTableProps } from "."

export const ExpandIconTable = ({
  expanded,
  onExpand,
  record
}: ExpandIconTableProps) => {
  const { expandable } = record

  if (!expandable) return <RightOutlined style={{ opacity: 0.2 }} />

  const Icon = expanded && expandable ? DownOutlined : RightOutlined

  return <Icon onClick={(e) => onExpand(record, e)} />
}
