import { CodeHighlightNode, CodeNode } from "@lexical/code"
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { ListItemNode, ListNode } from "@lexical/list"
import { TRANSFORMERS } from "@lexical/markdown"
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin"
import { ListPlugin } from "@lexical/react/LexicalListPlugin"
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table"
import { $getRoot } from "lexical"
import ToolbarPlugin from "./plugins/ToolbarPlugin"
import ExampleTheme from "./themes/theme"

import AutoLinkPlugin from "./plugins/AutoLinkPlugin"
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin"
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin"

const editorConfig = {
  theme: ExampleTheme,
  namespace: "",
  onError(error: any) {
    throw error
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ]
}

function LoadHtmlPlugin({ html }: any) {
  const [editor] = useLexicalComposerContext()

  editor.update(() => {
    let nodes = []

    const parser = new DOMParser()
    const dom = parser.parseFromString(html, "text/html")

    nodes = $generateNodesFromDOM(editor, dom)

    const root = $getRoot()

    root.clear()
    root.append(...nodes)
  })

  return null
}

export default function Editor({ html, onChange }: any) {
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={null}
            ErrorBoundary={() => null}
          />
          <LoadHtmlPlugin html={html} />
          <HistoryPlugin />
          <OnChangePlugin
            onChange={(_, editor) => {
              editor.update(() => {
                const raw = $generateHtmlFromNodes(editor, null)

                const iterator = (currentElement: any) => {
                  const elements = currentElement.getElementsByClassName(
                    "editor-listitem editor-nested-listitem"
                  )

                  if (elements.length > 0) {
                    currentElement.childNodes.forEach((child: any) => {
                      iterator(child)

                      const classExist = child.classList.contains(
                        "editor-nested-listitem"
                      )

                      if (classExist) {
                        const childPrevious = child?.previousSibling

                        child.childNodes.forEach((childNode: any) => {
                          childPrevious?.appendChild(childNode.cloneNode(true))
                        })

                        child.remove()
                      }
                    })
                  }
                }

                const parser = new DOMParser()
                const dom = parser.parseFromString(raw, "text/html")

                iterator(dom.body)

                onChange(dom.body.innerHTML)
              })
            }}
          />
          <AutoFocusPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </div>
      </div>
    </LexicalComposer>
  )
}
