import { Text } from "@/atoms"
import {
  DeployCommand,
  type CommonDeployCommandProps
} from "@/pages/DeploymentsPage/components/molecules/DeployCommand"
import { DeploymentKeyButton } from "@/pages/DeploymentsPage/components/molecules/DeploymentKeyButton"
import { InlineInput } from "@/pages/DeploymentsPage/components/molecules/InlineInput"
import { getDefaultValues, getDeployCommand } from "./helpers"

export const CommonDeployCommand = (props: CommonDeployCommandProps) => {
  return (
    <DeployCommand
      getDefaultValues={getDefaultValues}
      getDeployCommand={getDeployCommand}
      {...props}
    >
      {({ values, onChange, onError }) => {
        return (
          <>
            <Text>{`helm upgrade --install kortex kortex-${props.version}.tgz \\`}</Text>
            <Text>
              --namespace
              <InlineInput
                key={`${values.key}_namespace`}
                name="namespace"
                value={values.namespace}
                onChange={onChange}
              />
              <Text>{" \\ "}</Text>
            </Text>
            <Text>
              --set kortex.environment=
              <InlineInput
                key={`${values.key}_environment`}
                name="environment"
                value={values.environment}
                onChange={onChange}
              />
              <Text>{", \\"}</Text>
            </Text>
            <Text>
              kortex.deploymentKey=
              <InlineInput
                key={`${values.key}_deploymentKey`}
                name="deploymentKey"
                value={values.deploymentKey}
                onChange={onChange}
                buttons={<DeploymentKeyButton onError={onError} />}
              />
              <Text>{", \\"}</Text>
            </Text>
            <Text>
              kortex.image.pullSecretName=
              <InlineInput
                key={`${values.key}_pullSecretName`}
                name="pullSecretName"
                value={values.pullSecretName}
                onChange={onChange}
              />
              <Text>{" \\ "}</Text>
            </Text>
            <Text>--create-namespace</Text>
          </>
        )
      }}
    </DeployCommand>
  )
}
