import { RESOURCES_NAMES } from "@/const/resource.constants"
import { Config, Params, useCreate, useHttp } from "@/hooks/useHttp"

import type {
  DeploymentKeysResponse,
  DeploymentsResponse,
  DeploymentVersionResponse,
  FiltersDeploymentResponse
} from "./useDeployments.types"

export const useGetDeploymentVersion = <
  T = DeploymentVersionResponse,
  S = T
>() => {
  const context = useHttp<T, S>(`${RESOURCES_NAMES.DEPLOYMENTS}/version`)

  return context
}

export const useGetDeploymentKey = <T = DeploymentKeysResponse, S = T>() => {
  const context = useCreate<T, S>(
    `${RESOURCES_NAMES.AUTHENTICATION.DEPLOYMENT_KEYS}`
  )

  return context
}

export const useGetFiltersDeployments = <T = FiltersDeploymentResponse, S = T>(
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.DEPLOYMENTS}/filters`,
    {},
    config
  )

  return context
}

export const useGetDeployments = <T = DeploymentsResponse, S = T>(
  params: Params<{}> = {},
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(RESOURCES_NAMES.DEPLOYMENTS, params, config)

  return context
}
