import { ActionsCell } from "@/molecules/ActionsCell"

import { isUserAdmin } from "@/domain/user"
import type { ScopeActionsCellComponent } from "."

export const ScopeActionsCell: ScopeActionsCellComponent =
  (t, { deleteScope, duplicateScope, user }) =>
  (_, record) => {
    const items = [
      {
        key: "duplicate",
        label: t("general.duplicate"),
        disabled: record.isAllResources
      },
      {
        key: "delete",
        label: `${t("general.delete")} ${t("general.scope")}`,
        disabled: record.isAllResources
      }
    ]
    const isAdmin = isUserAdmin(user)

    return (
      <ActionsCell
        items={items}
        disabled={!isAdmin}
        onClick={({ key }) => {
          if (key === items[1].key) return deleteScope(record.key)

          duplicateScope(record.key)
        }}
      />
    )
  }
