import { JiraSoftwareLogo } from "@/icons"
import { IconText } from "@/molecules/IconText"

import styles from "./Action.module.scss"

import { ActionsCountComponent } from "."

export const ActionsCount: ActionsCountComponent = ({ count }) => (
  //currently we only count jira tickets
  <IconText
    Icon={JiraSoftwareLogo}
    classes={{ container: styles.jiraInfo, icon: styles.icon }}
  >
    <span className={styles.count}>{count}</span>
  </IconText>
)
