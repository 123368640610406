import cn from "classnames"

import { Button, Text } from "@/atoms"
import { useToggle } from "@/hooks/useToggle"
import { useTranslation } from "@/hooks/useTranslation"

import styles from "./List.module.scss"

import type { ListProps } from "./List.types"

export const List = <I,>({
  items,
  render,
  limit,
  title,
  description,
  showMoreTitle,
  showLessTitle,
  showMoreIcon,
  Component = "ul",
  classes = {}
}: ListProps<I>) => {
  const { t } = useTranslation()
  const { value, toggle } = useToggle()
  const isLimit = limit !== undefined && items?.length > limit
  const currentList = value || !isLimit ? items : items?.slice(0, limit)
  const isBulletList = Component === "ul"

  return (
    <>
      {title && <Text className={styles.title}>{title}</Text>}
      {description && <Text className={styles.description}>{description}</Text>}
      <Component
        className={cn(
          styles.list,
          isBulletList && styles.desc,
          classes.list,
          !!limit && styles.withLimit
        )}
      >
        {(currentList || []).map((item, i) => render(item, i))}
      </Component>
      {isLimit && (
        <div className={cn(styles.buttonWrapper, classes.button)}>
          <Button
            icon={showMoreIcon}
            type="link"
            ghost
            onClick={() => toggle()}
          >
            {value
              ? showLessTitle || t("general.showLess")
              : showMoreTitle || t("general.showMore")}
          </Button>
        </div>
      )}
    </>
  )
}
