import { HeaderPage } from "@/organisms/HeaderPage"
import { BranchTab } from "@/pages/DiscoveryPage/components/organisms/BranchTab"
import { useActiveKeyTabsInventory } from "@/pages/DiscoveryPage/hooks/useActiveKeyTabsInventory"
import { PageTemplate } from "@/templates/PageTemplate"

export const BranchesPage = () => (
  <PageTemplate
    full
    header={<HeaderPage titleTranslationKey="navigation.branch" />}
  >
    <BranchTab useActiveKeyTabsInventory={useActiveKeyTabsInventory} />
  </PageTemplate>
)
