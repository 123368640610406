import {
  BaseImageUpdate,
  getIssuesCountMapOrderedSeverity,
  Severity,
  VulnerabilitiesBySeverity,
  Vulnerability
} from "@/domain/vulnerability"
import {
  DEFAULT_LONG_FORMAT,
  getDateFromSeconds,
  getDateInUserLocale
} from "@/helpers/date.helpers"
import { APPLICATION_DRAWER_ID } from "@/organisms/Drawers/const"

import type {
  BaseImageUpdateVulnerability,
  ContainerBaseImageDetailsResponse,
  ContainerBaseImageResponse
} from "@/api/useInventory.types"

export const getContainerBaseImagesTabData = (
  response: ContainerBaseImageResponse
) => {
  const { data, metadata } = response || {}

  return {
    metadata,
    data: data.map(({ data: dataRecord, id, displayName }) => {
      const { applications, issuesSummary } = dataRecord
      const imagesNames = dataRecord.imagesNames || []
      const [title = "", tag = ""] = imagesNames[0]?.split?.(":") || []
      const issuesCounts = getIssuesCountMapOrderedSeverity(issuesSummary)

      return {
        key: id,
        title,
        tag,
        displayName,
        sbomQuery: `baseImageName=${displayName}`,
        imageName: {
          copyable: true,
          count: imagesNames.length - 1,
          items: imagesNames,
          label: imagesNames[0] || "",
          width: "100%"
        },
        ...dataRecord,
        applicationsCount: {
          copyable: true,
          title: "Applications",
          items: (applications || []).map(
            ({ name, environment, namespace, applicationId }) => ({
              name,
              description: `${environment || "-"}/${namespace || "-"}`,
              drawerId: APPLICATION_DRAWER_ID,
              drawerEntityId: applicationId
            })
          ),
          label: applications?.length || ""
        },
        digestShort: dataRecord.digest?.substring(7, 19),
        firstSeen: getDateInUserLocale(
          getDateFromSeconds(Number(dataRecord.firstSeen)),
          DEFAULT_LONG_FORMAT
        ),
        severity: issuesCounts
      }
    })
  }
}

export const getInventoryContainerBaseImageDetails = (
  response: ContainerBaseImageDetailsResponse,
  t: (key: string) => string,
  { minorUpdate, majorUpdate }: { minorUpdate?: string; majorUpdate?: string }
) => {
  const { data } = response || {}
  const { minorUpdateVulnerabilities, majorUpdateVulnerabilities } = data || {}
  const minorUpdateVulns = convertBaseImageVulnsToVulnsBySeverity(
    minorUpdateVulnerabilities
  )

  const minorUpdateImage: BaseImageUpdate = {
    vulnerabilities: minorUpdateVulns,
    type: t("inventory.baseImageDrawer.minorUpgrade"),
    version: minorUpdate || ""
  }

  const majorUpdateVulns = convertBaseImageVulnsToVulnsBySeverity(
    majorUpdateVulnerabilities
  )

  const majorUpdateImage: BaseImageUpdate = {
    vulnerabilities: majorUpdateVulns,
    type: t("inventory.baseImageDrawer.majorUpgrade"),
    version: majorUpdate || ""
  }

  const updates = [minorUpdateImage, majorUpdateImage].filter(
    (image) => !!image.version
  )

  return {
    data: { updates }
  }
}

function convertBaseImageVulnsToVulnsBySeverity(
  response: BaseImageUpdateVulnerability[] | undefined
): VulnerabilitiesBySeverity | undefined {
  if (!response) {
    return undefined
  }

  const vulnerabilitiesBySeverity: VulnerabilitiesBySeverity = {}
  response?.forEach((vulnerability: BaseImageUpdateVulnerability) => {
    const severity = vulnerability.severity.toLowerCase() as Severity
    if (!vulnerabilitiesBySeverity[severity]) {
      vulnerabilitiesBySeverity[severity] = [] as Vulnerability[]
    }

    const vulnerabilityObj: Vulnerability = {
      cve: vulnerability.id,
      cvss: -1,
      severity: severity
    }

    ;(vulnerabilitiesBySeverity[severity] as Vulnerability[]).push(
      vulnerabilityObj
    )
  })

  return vulnerabilitiesBySeverity
}
