import cn from "classnames"
import { cloneElement, useEffect, useRef, useState } from "react"

import { Tooltip } from "@/atoms"
import { useClickOutside } from "@/hooks/useClickOutside"
import { useTranslation } from "@/hooks/useTranslation"
import { CloseOutlined, EditOutlined } from "@/icons"
import { InlineInputButton } from "../InlineInputButton"

import styles from "./InlineInput.module.scss"

import type { InlineInputProps } from "."

export const InlineInput = ({
  name,
  value,
  buttons,
  onChange
}: InlineInputProps) => {
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const initialValue = useRef(value).current

  useClickOutside(
    containerRef,
    () => {
      if (isEditing) {
        setIsEditing(false)
      }
    },
    { capture: true }
  )

  useEffect(() => {
    if (isEditing && inputRef.current) {
      const range = document.createRange()
      const sel = window.getSelection()

      range.selectNodeContents(inputRef.current)
      sel?.removeAllRanges()
      sel?.addRange(range)
    }
  }, [isEditing])

  return (
    <div className={cn(styles.container, isEditing && styles.edit)}>
      <div
        ref={containerRef}
        className={cn(styles.wrap, !isEditing && styles.view)}
      >
        <span
          contentEditable={isEditing}
          ref={inputRef}
          className={styles.input}
          onKeyDown={(event) => {
            const { code } = event

            if (code === "Enter") {
              event.preventDefault()
              event.stopPropagation()
              setIsEditing(false)
            }
          }}
          onInput={(event) => {
            onChange?.({
              name,
              value: (event.nativeEvent.target as HTMLElement)?.textContent
            })
          }}
        >
          {initialValue}
        </span>
        <InlineInputButton
          className={styles.button}
          type="text"
          icon={
            isEditing ? (
              <CloseOutlined />
            ) : (
              <Tooltip title={t("general.edit")}>
                <EditOutlined />
              </Tooltip>
            )
          }
          size="small"
          onClick={() => {
            if (isEditing) {
              if (inputRef.current) {
                inputRef.current.innerText = initialValue || ""
              }

              onChange?.({
                name,
                value: initialValue
              })
            }

            setIsEditing(!isEditing)
          }}
        />
        {!!buttons &&
          !isEditing &&
          cloneElement(buttons, { name, inputRef, onChange })}
      </div>
    </div>
  )
}
