import { useEffect, useRef } from "react"

import { INTERNAL_EVENTS } from "@/const/event.constants"
import {
  CONTAINER_SELECTOR,
  FILTER_ATTRIBUTE_NAME
} from "@/templates/TabTemplate"
import { DropdownSmartSearch } from "./components/organisms/DropdownSmartSearch"
import { InputSmartSearch } from "./components/organisms/InputSmartSearch"
import { useClickOutsideSmartSearch } from "./hooks/useClickOutsideSmartSearch"
import { useSearch } from "./hooks/useSearch"
import { useTooltip } from "./hooks/useTooltip"

import { createFilterOptions } from "./helpers/option.helpers"

export const SmartSearch = ({
  onlySearch,
  id = "smartSearch",
  placeholder = "Input Search Text",
  data = {},
  filters = []
}: any) => {
  const filterOptions = createFilterOptions(filters, data)
  const ref = useRef<HTMLDivElement>(null)
  const refRow = useRef<HTMLDivElement | undefined | null>()
  const props = useSearch({ id, data, filterOptions })
  const { open, handlers, services } = props

  useClickOutsideSmartSearch(ref, props)
  useTooltip()

  useEffect(() => {
    if (!refRow.current) {
      refRow.current = ref.current?.closest(CONTAINER_SELECTOR)
      services.StringService.setRefFilterCounter(
        refRow.current,
        FILTER_ATTRIBUTE_NAME
      )
    }

    refRow.current?.addEventListener(
      INTERNAL_EVENTS.CLEAR_FILTERS_TABLE_TAB_TEMPLATE,
      handlers.onClear
    )

    return () =>
      refRow.current?.removeEventListener(
        INTERNAL_EVENTS.CLEAR_FILTERS_TABLE_TAB_TEMPLATE,
        handlers.onClear
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id={id} ref={ref}>
      <DropdownSmartSearch
        r={ref}
        id={id}
        data={data}
        open={onlySearch ? false : open}
        onClick={handlers.onClick}
        Input={<InputSmartSearch placeholder={placeholder} {...props} />}
        services={services}
      />
    </div>
  )
}
