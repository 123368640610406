import type { PathScoreShieldProps } from "./PathScoreShield.types"

const PathScoreShield = ({ pathRef, ...props }: PathScoreShieldProps) => (
  <path
    ref={pathRef}
    d="M40 4 H70.32V40.78C70.39 51.93 67.23 62.85 61.24 72.18C55.48 81.21 47.03 88.12 37.14 91.9C27.26 88.12 18.83 81.21 13.07 72.20C7.09 62.86 3.93 51.93 4.00 40.78V4Z"
    strokeWidth="6.36923"
    strokeLinejoin="round"
    strokeLinecap="round"
    {...props}
  />
)

export default PathScoreShield
