import dayjs from "dayjs"
import { useMemo } from "react"
import { getDefaultLabelFormatterForXAxis } from "../../../const/graph"

type Region = {
  min: number
  max: number
  color: string
}

const getLine = (value: number) => ({
  type: "line",
  start: ["min", value] as [string, number],
  end: ["max", value] as [string, number],
  style: {
    stroke: getComputedStyle(document.documentElement).getPropertyValue(
      "--daybreak-blue-5-color"
    ),
    lineDash: [2, 2]
  }
})

const getRegions = (regions: Region[]) => {
  return regions.map(({ min, max, color }) => ({
    type: "regionFilter",
    start: ["min", max] as [string, number],
    end: ["max", min] as [string, number],
    color
  }))
}

const getConfig = (data: any[]) => {
  const thresholds = [70, 85]
  const documentStyle = getComputedStyle(document.documentElement)

  return {
    xField: "timestamp",
    yField: "value",
    annotations: [
      ...getRegions([
        {
          min: 0,
          max: thresholds[0] - 2,
          color: documentStyle.getPropertyValue("--dust-red-4-color")
        },
        {
          min: thresholds[0] - 2,
          max: thresholds[0],
          color: documentStyle.getPropertyValue("--volcano-4-color")
        },
        {
          min: thresholds[0],
          max: thresholds[1] - 2,
          color: documentStyle.getPropertyValue("--sunset-orange-4-color")
        },
        {
          min: thresholds[1] - 2,
          max: thresholds[1],
          color: documentStyle.getPropertyValue("--lime-4-color")
        },
        {
          min: thresholds[1],
          max: 100,
          color: documentStyle.getPropertyValue("--polar-green-4-color")
        }
      ]),
      getLine(76)
    ],
    xAxis: {
      ...getDefaultLabelFormatterForXAxis()
    },
    yAxis: { min: 0, max: 100 },
    lineStyle: {
      lineWidth: 4,
      stroke: documentStyle.getPropertyValue("--neutral-4-color")
    },
    data: data.reduceRight((acc, { timestamp, postureScore }) => {
      let valueColor = "var(--unsatisfactory-score)"

      if (postureScore > thresholds[0]) valueColor = "var(--solid-score)"
      if (postureScore > thresholds[1]) valueColor = "var(--exceptional-score)"

      acc.push({
        timestamp,
        valueColor,
        value: postureScore,
        fromNow: dayjs(new Date(timestamp * 1000)).fromNow()
      })

      return acc
    }, [] as any)
  }
}

const usePostureScoreGraph = ({ data = [] }) => {
  const props = useMemo(() => getConfig(data), [data])

  return props
}

export default usePostureScoreGraph
