import CopyToClipboard from "react-copy-to-clipboard"

import { IconButton } from "./IconButton"

import type { CopyButtonComponent } from "."

export const CopyButton: CopyButtonComponent = (props) => {
  const { text, ...buttonProps } = props

  if (!text) return <IconButton {...buttonProps} />

  // IconButton uses as render function for the component CopyToClipboard to work
  return (
    <CopyToClipboard text={text}>{IconButton(buttonProps)}</CopyToClipboard>
  )
}
