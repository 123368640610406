import cn from "classnames"

import { Row } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useTranslation } from "@/hooks/useTranslation"
import { FunnelCard } from "@/molecules/FunnelCard"
import { helpers } from "."

import styles from "./Funnel.module.scss"

import type { FunnelComponent } from "."

export const Funnel: FunnelComponent = (props) => {
  const { numbers, size } = props
  const allValuesIsZero = helpers.isAllZero(numbers)
  const middleValuePath = size === "sm" ? 25 : 50
  const paths = helpers
    .getOffsets(numbers, allValuesIsZero, middleValuePath)
    .map(helpers.getPath(middleValuePath))

  const maxValue = numbers[0]
  const { abbreviateNumber } = useNumberConvertor()
  const { logger } = useLogger()
  const { t } = useTranslation()
  const {
    span,
    cards,
    getCards,
    loading,
    getColorsByIndex = helpers.getColorsByIndex
  } = props

  return (
    <Row
      wrap={false}
      gutter={4}
      className={cn(styles.container, { [styles.small]: props.size === "sm" })}
    >
      {(cards || getCards(t)).map((card, index) => {
        const { key, link, ...rest } = card
        const flex = !span ? "auto" : "none"
        const value = numbers[index] || 0
        const colorIndex = allValuesIsZero ? -1 : index
        const color = getColorsByIndex(colorIndex)
        const valueToDisplay = allValuesIsZero ? "-" : abbreviateNumber(value)
        const extra = allValuesIsZero
          ? undefined
          : helpers.getPercentageDisplayValue(
              helpers.getPercentage(value, maxValue)
            )

        return (
          <FunnelCard
            key={key}
            id={key}
            loading={loading}
            span={span}
            flex={flex}
            size={size}
            to={link}
            d={paths[index]}
            maxViewBox={middleValuePath * 2}
            color={color}
            extra={extra}
            value={valueToDisplay}
            onClick={() => {
              const { MixPanelEvent } = props

              if (MixPanelEvent) logger.info(MixPanelEvent, { card: key })
            }}
            {...rest}
          />
        )
      })}
    </Row>
  )
}
