import { useState } from "react"

export const useModal = () => {
  const [visible, setVisible] = useState(false)

  return {
    visible,
    show: () => setVisible(true),
    hide: () => setVisible(false)
  }
}
