import { ICONS_INSIGHTS } from "@/const/insights-constants"
import { withInternetFacing } from "@/helpers/insights.helper"
import { InsightsCell } from "@/molecules/InsightsCell"

import type { InsightsSbomProps, InsightsSbomValues } from "."

export const InsightsSbomCell =
  (props: InsightsSbomProps) =>
  ({ id, tag, ...values }: InsightsSbomValues) => (
    <InsightsCell
      hide
      limit={4}
      id={id}
      tag={tag}
      items={withInternetFacing(
        [
          ICONS_INSIGHTS.runtime,
          ICONS_INSIGHTS.ingress,
          ICONS_INSIGHTS.isDirect,
          ICONS_INSIGHTS.isIndirect,
          ICONS_INSIGHTS.fromBaseImage,
          ICONS_INSIGHTS.exploitability.IN_THE_WILD,
          ICONS_INSIGHTS.exploitability.POC
        ],
        props.internetFacingFF
      )}
      values={values}
      {...props}
    />
  )
