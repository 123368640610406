import { ActionCard } from "."

import styles from "./Action.module.scss"

import type { ActionListCardComponent } from "."

export const ActionListCard: ActionListCardComponent = ({ tickets, type }) => {
  //currently we only support jira tickets actions
  if (!tickets?.length) return null

  return (
    <div className={styles.listCard}>
      {tickets.map((ticket, index) => (
        <ActionCard key={index} ticket={ticket} type={type} />
      ))}
    </div>
  )
}
