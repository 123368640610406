import { Breadcrumb, Link } from "@/atoms"
import { HeaderPage } from "@/organisms/HeaderPage"

import { HeaderBreadcrumbPageComponent } from "."

export const HeaderBreadcrumbPage: HeaderBreadcrumbPageComponent = (props) => {
  const { items, isLoading } = props

  return (
    <HeaderPage
      title={
        <Breadcrumb
          items={items.map((item) => {
            const { title, to } = item

            if (!to) return { title: isLoading ? "" : title }

            return {
              title: <Link to={to}>{title}</Link>
            }
          })}
        />
      }
    />
  )
}
