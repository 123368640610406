import cn from "classnames"
import { useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useGetDeploymentVersion } from "@/api/useDeployments"
import { Breadcrumb, Link, Skeleton, Title } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { HeaderPage } from "@/organisms/HeaderPage"
import { PageWithBreadcrumbTemplate } from "@/templates/PageWithBreadcrumbTemplate"
import { DeployTabs } from "../../organisms/DeployTabs"

import styles from "./NewDeploymentPage.module.scss"

export const NewDeploymentPage = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const { deployEnvFieldValue } = state || {}
  const { response, isLoading } = useGetDeploymentVersion()
  const { data } = response || {}
  const { version = "" } = data || {}
  const noError = !isLoading && !!version
  const environmentRef = useRef(deployEnvFieldValue).current
  const tabProps = { noError, version, environment: environmentRef }
  const navigate = useNavigate()

  useEffect(() => {
    navigate(".", { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageWithBreadcrumbTemplate
      full
      classes={{ main: styles.main }}
      header={
        <HeaderPage
          title={
            <Breadcrumb
              items={[
                {
                  title: (
                    <Link to="/deployments">{t("navigation.deployments")}</Link>
                  )
                },
                {
                  title: t("deployments.Deploy") || (
                    <Skeleton.Input active size="small" />
                  )
                }
              ]}
            />
          }
        />
      }
    >
      <Title
        className={cn(styles.title, isLoading && styles.loading)}
        level={5}
      >
        {`${t("deployments.type")}:`}
      </Title>
      <DeployTabs key={version} loading={isLoading} tabProps={tabProps} />
    </PageWithBreadcrumbTemplate>
  )
}
