import { Language } from "@/icons"
import { IconTextCell } from "@/molecules/IconTextCell"

import type { RowRecord, Text } from "."

export const LanguageIconCell = (textRecord: Text, { language }: RowRecord) => {
  const onlyText = typeof textRecord === "string"
  const text = onlyText ? textRecord : textRecord.text
  const description = onlyText ? "" : textRecord.description

  return (
    <IconTextCell
      icon={<Language language={language} />}
      text={text}
      description={description}
    />
  )
}
