import { useTranslation } from "@/hooks/useTranslation"
import { ToolOutlined } from "@/icons"
import { IconText } from "@/molecules/IconText"
import { ButtonModal } from "."

import styles from "./ButtonModal.module.scss"

import type { ButtonModalProps } from "."

export const FixExampleButtonModal = (
  props: Pick<ButtonModalProps, "children">
) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "buttonModal.fixExample"
  })

  return (
    <ButtonModal
      label={t("label")}
      Icon={ToolOutlined}
      buttonProps={{ size: "small" }}
      modalProps={{
        width: 1000,
        title: (
          <>
            <IconText level={5} Icon={ToolOutlined} text={t("text")} />
            <span className={styles.descriptionTitleModalFixExample}>
              {t("description")}
            </span>
          </>
        )
      }}
    >
      {props.children}
    </ButtonModal>
  )
}
