export const wrapFn =
  (fn: any, callback: (flag: boolean) => void) =>
  async (...args: any) => {
    try {
      callback(true)

      await fn(...args)

      return { err: null }
    } catch (err: any) {
      return { err: true, data: err?.data }
    } finally {
      callback(false)
    }
  }
