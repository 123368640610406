import { WIDTH_DEFAULT } from "@/organisms/Drawers/const"
import { SbomTab } from "../SbomTab"
import { TabsDrawerTemplate } from "../TabsDrawerTemplate"
import { IssueTabVirtualMachineDrawer } from "./components/IssueTabVirtualMachineDrawer"
import { TitleVirtualMachineDrawer } from "./components/TitleVirtualMachineDrawer"

import type { VirtualMachineDrawerComponent } from "."

export const VirtualMachineDrawer: VirtualMachineDrawerComponent = (props) => {
  const { widthAdditive, ...restProps } = props
  const tabs = ["sbom", "scaIssues"]
  const components = [SbomTab, IssueTabVirtualMachineDrawer]

  return (
    <TabsDrawerTemplate
      name="VirtualMachineDrawer"
      tabs={tabs}
      components={components}
      Title={TitleVirtualMachineDrawer}
      width={WIDTH_DEFAULT + widthAdditive}
      {...restProps}
    />
  )
}
