import cn from "classnames"

import { preventEventBubbling } from "@/helpers/utils"

import styles from "./InformationPopover.module.scss"

import type { CommonTemplateInformationPopoverProps } from "./CommonTemplateInformationPopover.types"

export const CommonTemplateInformationPopover = ({
  title,
  children,
  classes = {}
}: CommonTemplateInformationPopoverProps) => {
  return (
    <div className={styles.commonTemplate} onClick={preventEventBubbling}>
      {title && (
        <>
          <p className={cn(styles.title, classes.title)}>{title}</p>
          <div className={styles.hr} />
        </>
      )}
      <div className={cn(styles.container, classes.content)}>{children}</div>
    </div>
  )
}
