import { Text } from "@/atoms"
import { CountLabelShowMoreProps } from "."
import styles from "./CountLabelShowMore.module.scss"

export const CountLabelShowMore = ({
  count,
  children,
  copyable,
  ...props
}: CountLabelShowMoreProps) => {
  return (
    <span className={styles.container}>
      <Text data-tooltip-show-more={copyable ? undefined : "true"} {...props}>
        <Text className={styles.text} ellipsis>
          {children}
        </Text>
        {count > 0 && <span className={styles.count}>{`+${count}`}</span>}
      </Text>
    </span>
  )
}
