import { RESOURCES_NAMES } from "@/const/resource.constants"
import {
  useCreate,
  useDelete,
  useHttp,
  usePatch,
  useUpdate
} from "@/hooks/useHttp"

import type { Config, GetUseMutationOptions, Params } from "@/hooks/useHttp"
import type { ColorsResponse, LabelsResponse } from "./useLabels.types"

export const useGetLabels = <T = LabelsResponse, S = T>(
  params?: Params,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(RESOURCES_NAMES.LABELS.LIST, params, config)

  return context
}

export const useGetColorsLabels = () => {
  const context = useHttp<ColorsResponse>(RESOURCES_NAMES.LABELS.COLORS)

  return context
}

export const useCreateLabel = () => {
  const context = useCreate(RESOURCES_NAMES.LABELS.LIST)

  return context
}

export const useUpdateLabel = () => {
  const context = useUpdate(RESOURCES_NAMES.LABELS.LIST)

  return context
}

export const useDeleteLabel = () => {
  const context = useDelete(RESOURCES_NAMES.LABELS.LIST)

  return context
}

export const usePatchLabel = <T = LabelsResponse, S = T>(
  options: GetUseMutationOptions<T, S>
) => {
  const params = undefined
  const updater = undefined
  const context = usePatch(
    RESOURCES_NAMES.LABELS.LIST,
    params,
    updater,
    options
  )

  return context
}
