import { Statistic, Text } from "@/atoms"
import cn from "classnames"
import type { StatisticCardProps } from "."
import StatisticCardTemplate from "../../templates/StatisticCardTemplate"
import styles from "./StatisticCard.module.scss"

export const StatisticCard = (props: StatisticCardProps) => {
  const {
    to,
    extra,
    children,
    onClick,
    flex,
    selected,
    footer,
    classes = {},
    span = 12,
    size = "lg",
    ...statisticProps
  } = props
  const isSmallSize = size === "sm"
  const isExtra = extra !== undefined && extra !== null
  const displayExtra = isExtra && !isSmallSize

  return (
    <StatisticCardTemplate
      flex={flex}
      span={span}
      selected={selected}
      to={to}
      className={cn(styles.container, classes.container)}
      onClick={onClick}
      footer={footer}
    >
      <Statistic
        valueRender={(value) => {
          if (isSmallSize)
            return (
              <div className={styles.inlineExtraContainer}>
                {value}
                {isExtra && (
                  <Text type="secondary" className={styles.text}>
                    {`(${extra})`}
                  </Text>
                )}
              </div>
            )

          return value
        }}
        {...statisticProps}
      />
      {displayExtra && (
        <Text
          type="secondary"
          className={styles.text}
          data-type-component="extra-statistic-card"
        >
          {extra}
        </Text>
      )}
      {children && <div className={cn(classes.children)}>{children}</div>}
    </StatisticCardTemplate>
  )
}
