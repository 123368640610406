import dayjs from "dayjs"

import { useGetDeployments } from "@/api/useDeployments"
import { useQueryString } from "@/hooks/useQueryString"
import type { Filters } from "@/organisms/SmartSearch/types/options.types"

export const useDeployments = (filters: Filters) => {
  const paramsQueryString = useQueryString([
    "orderBy",
    "ascending",
    "descending",
    "daysAgo",
    ...filters.map(({ key }) => key)
  ])

  const context = useGetDeployments(paramsQueryString, {
    select: (response) => {
      const { data, metadata } = response
      return {
        metadata,
        data: data.assets?.map((item) => ({
          ...item,
          key: item.asset_id,
          status:
            item.status === "STATUS_CONNECTED" ? "Connected" : "Disconnected",

          deploymentType:
            item.deploymentType === "K8S" ? "Kubernetes" : item.deploymentType,
          firstInstallationTime: dayjs(
            item.firstInstallationTime * 1000
          ).format("YYYY-MM-DD HH:mm"),
          lastUpgradeTime: dayjs(item.lastUpgradeTime * 1000).format(
            "YYYY-MM-DD HH:mm"
          ),
          lastHeartbeatTime: dayjs(item.lastHeartbeatTime * 1000).format(
            "YYYY-MM-DD HH:mm"
          )
        }))
      }
    }
  })

  return context
}
