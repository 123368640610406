import { RESOURCES_NAMES } from "@/const/resource.constants"
import {
  Config,
  Params,
  useCreate,
  useDelete,
  useHttp,
  usePatch
} from "@/hooks/useHttp"
import type {
  ListScopesPermittedToUserResponse,
  UsersParams,
  UsersResponse
} from "./useUsers.types"

export type { Snapshot } from "./useInventory.types"

export const useGetUsers = <T = UsersResponse, S = T>(
  params: Params<UsersParams> = { pageNumber: "0" },
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(RESOURCES_NAMES.SETTINGS.USERS, params, config)

  return context
}

export const useInviteUsers = <T = unknown, S = T>() => {
  const context = useCreate<T, S>(`${RESOURCES_NAMES.SETTINGS.USERS}/invite`)

  return context
}

export const useGetUserScopes = <T = ListScopesPermittedToUserResponse, S = T>(
  params: any,
  config: Config<T, S>
) => {
  return useHttp<T, S>(
    `${RESOURCES_NAMES.SETTINGS.USERS}/scopes`,
    params,
    config
  )
}

export const useUpdateMembership = <T = unknown, S = T>(
  params: Params<{}> = {}
) => {
  const context = usePatch<T, S>(`${RESOURCES_NAMES.SETTINGS.USERS}`, params)

  return context
}

export const useResetPasswordUsers = <T = unknown, S = T>() => {
  const context = useCreate<T, S>(
    `${RESOURCES_NAMES.SETTINGS.USERS}/initiate-reset-password`
  )

  return context
}

export const useDeleteUsers = <T = unknown>() => {
  const context = useDelete<T>(RESOURCES_NAMES.SETTINGS.USERS)

  return context
}
