import { useCallback, useState } from "react"

export function useToggle(initialValue: unknown = false) {
  const [value, setValue] = useState(() =>
    typeof initialValue === "boolean" ? initialValue : !!initialValue
  )

  const toggle = useCallback((nextValue?: unknown) => {
    if (typeof nextValue === "boolean") {
      return setValue(nextValue)
    }

    return setValue((currentValue) => !currentValue)
  }, [])

  return { value, toggle, setValue }
}
