import { TFunction } from "@/hooks/useTranslation"

export const TEASERS = {
  KORTEX_INSTALLED: {
    noActiveSensorsDeployed: (t: TFunction) => ({
      title: t("No active sensors deployed"),
      link: {
        url: "/deployments",
        text: t("Deploy Kodem Sensors")
      }
    })
  },
  SCM_INSTALLED: {
    codeRepoNotConnected: (t: TFunction) => ({
      title: t("Code repositories not connected"),
      link: {
        url: "/integrations",
        text: t("Connect with SCM")
      }
    })
  }
}
