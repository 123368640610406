import { CellTable, SortOrder } from "@/atoms"
import { ISSUES_LIMIT } from "@/const/table-constants"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { NumberConvertor } from "@/hooks/useNumberConvertor"
import { ORDER_BY_PROPS } from "@/hooks/useOrderBy"
import { TFunction } from "@/hooks/useTranslation"
import { IssuesSummaryCell } from "@/molecules/IssuesSummaryCell"
import { MoreTableCell } from "@/molecules/MoreTableCell"
import { InstanceCell } from "../../molecules/InstanceCell"

import type { ColumnType } from "@/atoms"
import type { Application } from "@/pages/DiscoveryPage/application/application-inventory/useApplicationInventory.types"

export const getColumnsTable = ({
  convertors,
  sorter,
  t,
  previewMode
}: {
  convertors: NumberConvertor
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">
  t: TFunction
  previewMode?: boolean
}): ColumnType<Application>[] => {
  const columns = [
    {
      title: "Instance ID",
      render: InstanceCell,
      ...getDefaultColumnParams("instanceId", sorter),
      sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
    },
    {
      title: "Instance Type",
      width: 110,
      render: CellTable,
      ...getDefaultColumnParams("instanceType")
    },
    {
      title: "Environment",
      width: 100,
      render: CellTable,
      ...getDefaultColumnParams("environmentName")
    },
    {
      title: "Auto Scaling",
      width: 130,
      render: MoreTableCell,
      ...getDefaultColumnParams("autoScalingGroups")
    },
    {
      title: "OS",
      width: 60,
      render: CellTable,
      ...getDefaultColumnParams("os")
    },
    {
      title: "Machine Image",
      width: 200,
      render: CellTable,
      ...getDefaultColumnParams("imageId")
    },
    {
      title: "Issues Summary",
      width: 220,
      render: IssuesSummaryCell({ t, convertors }, ISSUES_LIMIT),
      ...getDefaultColumnParams("severity", sorter),
      defaultSortOrder: "descend" as SortOrder,
      sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
    },
    {
      title: "Last Seen",
      width: 150,
      ...getDefaultColumnParams("lastSeen")
    }
  ]

  if (!previewMode) return columns

  return columns.filter(({ key }) => {
    if (key === "lastSeen") return false
    if (key === "os") return false
    if (key === "autoScalingGroups") return false

    return true
  })
}
