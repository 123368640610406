import { preventEventBubbling } from "@/helpers/utils"
import { ActionsDropdown } from "../ActionsDropdown"

import type { ActionsCellComponent } from "."

export const ActionsCell: ActionsCellComponent = (menu) => (
  <div onClick={preventEventBubbling} data-testid="actions-cell">
    <ActionsDropdown menu={menu} />
  </div>
)
