import { ActionsCell } from "@/molecules/ActionsCell"

import type { Policy } from "@/api/usePolicies.types"
import type { TFunction } from "@/hooks/useTranslation"

export const ActionsCellScmTab =
  (
    t: TFunction,
    {
      onEdit,
      onDelete
    }: { onEdit: (policy: Policy) => void; onDelete: (policy: Policy) => void }
  ) =>
  (_: unknown, record: Policy) => {
    return (
      <ActionsCell
        items={[
          { key: "edit", label: <div>{t("general.edit")}</div> },
          { key: "delete", label: <div>{t("general.delete")}</div> }
        ]}
        onClick={({ key }) => {
          const action = key === "delete" ? onDelete : onEdit

          action(record)
        }}
      />
    )
  }
