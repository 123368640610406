import { useParams } from "react-router-dom"

import { Divider, Form, Space, Spin } from "@/atoms"
import { PROVIDER_AUTH_TYPES } from "@/const/provider-auth.constants"
import { useTranslation } from "@/hooks/useTranslation"
import { useAuthenticate } from "@/pages/SignInPage/applications/authenticate"
import PasswordFormSignIn from "@/pages/SignInPage/components/molecules/PasswordFormSignIn/PasswordFormSignIn"
import { SamlSignInForm } from "@/pages/SignInPage/components/molecules/SamlSignInForm/SamlSignInForm"
import { ThirdPartyProvidersSignInForm } from "@/pages/SignInPage/components/molecules/ThirdPartyProvidersSignInForm/ThirdPartyProvidersSignInForm"

import styles from "./SignInForm.module.scss"

import { useEffect } from "react"
import type { SignInFormProps } from "."
import { ConfirmCodeSignInForm } from "../../molecules/ConfirmCodeSignInForm"
import { MultiFactorSetUp } from "../MultiFactorSetUp"

export const SignInForm = (props: SignInFormProps) => {
  const { user, useCaseAuthenticate = useAuthenticate } = props
  const params = useParams()
  const [form] = Form.useForm()
  const {
    providers,
    signInWithSaml,
    signInWithGoogle,
    signInWithGithub,
    signInWithEmailPass
  } = useCaseAuthenticate(params, form)
  const { t } = useTranslation()
  const autoSaml =
    providers &&
    providers[PROVIDER_AUTH_TYPES.SAML] &&
    Object.keys(providers).length === 1

  useEffect(() => {
    if (autoSaml) {
      signInWithSaml()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers])

  if (!providers) {
    return (
      <div className={styles.wrapLoader}>
        <Space size="middle" className={styles.space}>
          <Spin size="large" />
        </Space>
      </div>
    )
  }

  if (user.userNeedVerifyPhoneNumberMultiFactor) {
    return <MultiFactorSetUp />
  }

  if (user.userNeedVerificationCodeMultiFactor) {
    return <ConfirmCodeSignInForm className={styles.form} />
  }

  if (autoSaml) {
    return (
      <div data-testid="divAutoSaml" className={styles.wrapLoader}>
        <Space size="middle" className={styles.space}>
          <Spin size="large" />
          {t("auth.samlAuthenticatingThroughYourSaml")}
        </Space>
      </div>
    )
  }

  return (
    <Form
      form={form}
      validateTrigger={["onBlur"]}
      className={styles.form}
      name="sigInForm"
      layout="vertical"
      requiredMark={false}
      onFinish={signInWithEmailPass}
    >
      {providers[PROVIDER_AUTH_TYPES.SAML] && (
        <>
          <Divider className={styles.divider}>{t("general.or")}</Divider>
          <SamlSignInForm onClick={signInWithSaml} />
        </>
      )}
      {providers[PROVIDER_AUTH_TYPES.GOOGLE] && (
        <>
          <Divider className={styles.divider}>{t("general.or")}</Divider>
          <ThirdPartyProvidersSignInForm
            signInWithGoogle={signInWithGoogle}
            signInWithGithub={signInWithGithub}
          />
        </>
      )}
      {providers[PROVIDER_AUTH_TYPES.EMAIL_PASSWORD] && (
        <>
          <Divider className={styles.divider}>{t("general.or")}</Divider>
          <PasswordFormSignIn />
        </>
      )}
    </Form>
  )
}
