import { isEmptyString } from "../helpers"
import { AbstractHandler } from "./token-handler"

import type { PropsHandler, Token } from "./token-handler.types"

export class ErrorHandler extends AbstractHandler {
  public handle(token: Token, props: PropsHandler): Token {
    const { str } = token

    if (isEmptyString(str)) {
      return super.handle(token, props)
    }

    const { delimiter, value } = token
    const { FilterService } = props
    const queryFilter = FilterService.getFilterByLabel("query")

    if (delimiter !== ":") {
      if (!queryFilter) {
        return super.handle(
          { error: { message: "free search is not allowed" }, ...token },
          props
        )
      }

      return super.handle(token, props)
    }

    const tokenWithCommonError = {
      error: { message: "Invalid operator" },
      ...token
    }

    const { StringService } = props

    if (!value) {
      if (queryFilter) {
        return super.handle(token, props)
      }

      if (StringService.isCaretPosInToken(token)) {
        return super.handle(token, props)
      }

      return super.handle(tokenWithCommonError, props)
    }

    const { id } = token

    if (!FilterService || !id) return super.handle(token, props)

    const filter = FilterService.getFilterByLabel(id)

    if (!filter) {
      const queryFilter = FilterService.getFilterByLabel("query")

      if (!queryFilter) {
        return super.handle(
          { error: { message: "free search is not allowed" }, ...token },
          props
        )
      }

      return super.handle(token, props)
    }

    const tokensById = props.StringService.getTokens().filter(
      (currentToken) => id === currentToken.id
    )

    if (
      tokensById.length > 1 &&
      token.endCaretPos !== tokensById[0].endCaretPos
    ) {
      return super.handle(
        { error: { message: "Duplicate key" }, ...token },
        props
      )
    }

    const { type, validation, options = [] } = filter

    if (type === "input") {
      return super.handle(token, props)
    }

    if (type === "inputNumber") {
      const { errorMessage = "Invalid range" } = filter

      if (validation && !validation(Number(value)))
        return super.handle(
          { error: { message: errorMessage }, ...token },
          props
        )

      return super.handle(token, props)
    }

    const values = value
      .split(",")
      .filter((v: string) =>
        validation
          ? !validation(v)
          : !!v && options.findIndex(({ label }: any) => v === label) === -1
      )

    if (values.length > 0) {
      return super.handle(
        { error: { message: "Invalid value" }, ...token },
        props
      )
    }

    return super.handle(token, props)
  }
}
