import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

export const useActiveKeySearchParams = <K extends string[]>(
  keys: K,
  fieldName = "tab",
  defaultValue: K[number] = keys[0]
) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const activeKey = searchParams.get(fieldName) || defaultValue
  const onChangeActiveKey = (nextActive: K[number]) => {
    searchParams.set(fieldName, nextActive)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    const index = keys.indexOf(activeKey)

    if (index === -1) {
      searchParams.set(fieldName, defaultValue)
      setSearchParams(searchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [activeKey, onChangeActiveKey] as const
}
