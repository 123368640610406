import { Divider, Space } from "@/atoms"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"

import styles from "./MetaRow.module.scss"

import type { MetaRowComponent } from "."

export const MetaRow: MetaRowComponent = ({ items }) => {
  return (
    <Space
      split={<Divider type="vertical" className="m-0-divider-ks" />}
      className={styles.container}
    >
      {items.map(({ label, children }, index) => (
        <div key={index} className={styles.item}>
          <OverviewDescriptions.Label>{label}</OverviewDescriptions.Label>
          <OverviewDescriptions.Label>{children}</OverviewDescriptions.Label>
        </div>
      ))}
    </Space>
  )
}
