import { useIssueFindingsDrawer } from "@/organisms/Drawers/applications/triage/useTriage"
import IssueDetailsSkeleton from "./IssueDetailsSkeleton"
import { IssueExpandableCellView } from "./IssueExpandableCellView"

import type { IssueExpandableCellProps } from "./IssueExpandableCell.types"

export const IssueExpandableCell = (props: IssueExpandableCellProps) => {
  const {
    issueType,
    fixability,
    id,
    drawer,
    noLimits,
    riskInsights,
    copyable
  } = props
  const context = useIssueFindingsDrawer(id)
  const { response, isLoading } = context

  if (isLoading || !response) {
    return <IssueDetailsSkeleton />
  }

  const {
    metadata,
    fixVersions,
    codeRepoFindings,
    baseImageFindings = [],
    imageFindings = [],
    vmFindings = []
  } = response

  return (
    <IssueExpandableCellView
      noLimits={noLimits}
      drawer={drawer}
      metadata={metadata}
      issueType={issueType}
      fixability={fixability}
      fixVersions={fixVersions}
      codeRepoFindings={codeRepoFindings}
      baseImageFindings={baseImageFindings}
      imageFindings={imageFindings}
      vmFindings={vmFindings}
      insightIssue={riskInsights}
      copyable={copyable}
    />
  )
}
