import dayjs from "dayjs"

import type { Posture, PostureResponse } from "@/api/usePosture.types"

export const getTimestampPosture = (data: Posture) => data.timestamp * 1000

export const posturePropsSelector = (response: PostureResponse) => {
  const postureData = response?.data || []
  const day = dayjs()

  const {
    postureScore = 0,
    counts = { highRisk: 0, criticalRisk: 0, lowRisk: 0, noRisk: 0 }
  } = postureData[0] || {}
  const weekAgoIndex = postureData
    .slice(0, 8)
    .findIndex(
      (posture) => day.diff(getTimestampPosture(posture), "days") === 7
    )

  return {
    weekAgoIndex,
    data: postureData,
    score: postureScore || 0,
    ...counts
  }
}
