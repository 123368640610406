import { Navigate, Route, Routes } from "react-router-dom"

import { NAMES_ROUTES } from "@/const/routes.constants"
import { ComingSoonPage } from "@/pages/ComingSoonPage"
import { ApplicationsPage } from "./components/pages/ApplicationsPage"
import { BaseImagesPage } from "./components/pages/BaseImagesPage"
import { BranchesPage } from "./components/pages/BranchesPage"
import { ImagesPage } from "./components/pages/ImagesPage"
import { SbomPage } from "./components/pages/SbomPage"
import { VirtualMachinesPage } from "./components/pages/VirtualMachinesPage"

export const DiscoveryPage = () => {
  const { DISCOVERY } = NAMES_ROUTES

  return (
    <Routes>
      <Route path={`/${DISCOVERY.BRANCH}`} element={<BranchesPage />} />
      <Route
        path={`/${DISCOVERY.APPLICATIONS}`}
        element={<ApplicationsPage />}
      />
      <Route path={`/${DISCOVERY.IMAGES}`} element={<ImagesPage />} />
      <Route path={`/${DISCOVERY.BASE_IMAGES}`} element={<BaseImagesPage />} />
      <Route path={`/${DISCOVERY.SBOM}`} element={<SbomPage />} />
      <Route path={`/${DISCOVERY.VM}`} element={<VirtualMachinesPage />} />
      <Route
        path={`/${DISCOVERY.API}`}
        element={<ComingSoonPage keyPage="api" />}
      />
      <Route
        path={`/${DISCOVERY.AI}`}
        element={<ComingSoonPage keyPage="ai" />}
      />
      <Route
        path="/*"
        element={
          <Navigate to={`/${DISCOVERY.ROOT}/${DISCOVERY.BRANCH}`} replace />
        }
      />
    </Routes>
  )
}
