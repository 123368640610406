export const PROVIDER_AUTH_TYPES = {
  EMAIL_PASSWORD: "PROVIDER_TYPE_EMAIL_PASSWORD",
  SAML: "PROVIDER_TYPE_SAML",
  GOOGLE: "PROVIDER_TYPE_GOOGLE",
  GITHUB: "PROVIDER_TYPE_GITHUB"
}

export const REDIRECT_URL_PARAM_AUTH = "redirect_url"

export const STEPS_MFA = {
  CHOOSE_MFA_METHOD: 0,
  INPUT_PHONE_NUMBER: 1,
  CONFIRM_CODE: 2,
  SUCCESS: 3
}
