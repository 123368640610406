import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useCreate, useDelete, useHttp, useUpdate } from "@/hooks/useHttp"

import type { Config, Params, QueryClient, Updater } from "@/hooks/useHttp"
import type { ScopesResponse } from "./useScopes.types"

export const useGetScopes = <T = ScopesResponse, S = T>(
  params?: Params<{ needle?: string; pageSize?: number; query?: string }>,
  config?: Config<T, S>
) => {
  const { needle, ...restParams } = params || {}
  const url = needle
    ? `${RESOURCES_NAMES.SETTINGS.SCOPES}/${needle}`
    : RESOURCES_NAMES.SETTINGS.SCOPES
  const context = useHttp<T, S>(url, restParams, config)

  return context
}

const getMutationOptions = (queryClient: QueryClient) => ({
  onSuccess: () => {
    queryClient.refetchQueries({ queryKey: [RESOURCES_NAMES.SETTINGS.SCOPES] })
  }
})

export const usePostScope = () => {
  const creator = useCreate(
    RESOURCES_NAMES.SETTINGS.SCOPES,
    undefined,
    undefined,
    getMutationOptions
  )

  return creator
}

export const usePutScope = (params?: Params) => {
  const updater = useUpdate(
    RESOURCES_NAMES.SETTINGS.SCOPES,
    params,
    undefined,
    getMutationOptions
  )

  return updater
}

export const useDeleteScope = (
  params: Params | undefined = undefined,
  updater?: Updater<ScopesResponse, string>
) => {
  const remover = useDelete(RESOURCES_NAMES.SETTINGS.SCOPES, params, updater)

  return remover
}

export const useGetFiltersScopes = <T = ScopesResponse, S = T>(
  params?: Params<{}>,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.SETTINGS.SCOPES}/filters/options`,
    params,
    config
  )

  return context
}
