import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import { useAcceptInvite, useGetTenant } from "@/api/useAuth"
import { PROVIDER_AUTH_TYPES } from "@/const/provider-auth.constants"
import { useLogger } from "@/hooks/useLogger"

import type { FlowState } from "."

export const useInvite = () => {
  const [slug, setSlug] = useState<string | undefined>()
  const [state, setState] = useState<FlowState>("loading")
  const [searchParams] = useSearchParams()
  const { response, isError } = useGetTenant(slug)
  const navigate = useNavigate()
  const { mutateAsync: mutateAcceptInvite } = useAcceptInvite()
  const { logger, EVENTS } = useLogger()
  const params = useParams()
  const dataFromUrl = { email: searchParams.get("email") }

  const acceptInvite = async (values: any) => {
    const navProps = { replace: true }
    const { user_email, ...restValues } = values
    const token = searchParams.get("token")

    try {
      const tenantId = response?.data.id

      if (!tenantId) throw new Error(`Invalid params: tenantId:${tenantId}`)

      await mutateAcceptInvite({
        slug,
        callback_token: token,
        status: "ACCEPTED",
        user_email: user_email || dataFromUrl.email,
        ...restValues
      })

      const providers = response?.data?.identityProviders

      if (!providers) {
        return navigate("/", navProps)
      }

      const isSAMLEnabled = providers.find(
        (provider) => provider.type === PROVIDER_AUTH_TYPES.SAML
      )

      const isEmailPassword = providers.find(
        (provider) => provider.type === PROVIDER_AUTH_TYPES.EMAIL_PASSWORD
      )

      if (isSAMLEnabled && !isEmailPassword) {
        return navigate(`/${slug}/signin`, navProps)
      }

      if (isEmailPassword && slug && token) {
        searchParams.set("token", token)

        return navigate(
          `/${slug}/reset-password?${searchParams.toString()}`,
          navProps
        )
      }
    } catch (err) {
      logger.error(err as Error, {
        custom_error: EVENTS.ERROR_EVENTS.AUTH.ACCEPT_INVITE
      })

      setState("error")
    }
  }

  useEffect(() => {
    if (!response) return

    if (isError || !response.data?.identityProviders?.length) {
      return setState("error")
    }

    setState("success")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  useEffect(() => {
    const slug = params?.slug

    if (!slug) {
      return setState("error")
    }

    setSlug(slug)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [state, dataFromUrl, acceptInvite] as const
}
