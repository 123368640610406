import { useGetInventoryApplication } from "@/api/useInventory"
import { useQueryString } from "@/hooks/useQueryString"
import { getApplicationTabData } from "./useApplicationInventory.selector"

export const useApplicationInventory = (
  paramKeys?: { key: string }[],
  config?: { enabled: boolean; headers?: Record<string, string> }
) => {
  const params = useQueryString([
    "daysAgo",
    "orderBy",
    "ascending",
    ...(paramKeys || [])
  ])
  const context = useGetInventoryApplication(params, {
    select: getApplicationTabData,
    ...(config || {})
  })
  return context
}
