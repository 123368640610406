import { Text } from "@/atoms"
import type { TextLabelShowMoreProps } from "."

export const TextLabelShowMore = ({
  children,
  copyable,
  ...props
}: TextLabelShowMoreProps) => (
  <Text
    underline
    data-tooltip-show-more={copyable ? undefined : "true"}
    {...props}
  >
    {children}
  </Text>
)
