import { BetaLabel, Skeleton, Tag, Title } from "@/atoms"
import { LogoIntegration } from "../../molecules/LogoIntegration"

import styles from "./HeaderItem.module.scss"

import type { HeaderItemProps } from "."

export const HeaderItem = ({
  name,
  displayName,
  isEnabled,
  isInstalled,
  tags,
  isLoading,
  beta
}: HeaderItemProps) => (
  <div className={styles.container}>
    {isLoading ? (
      <Skeleton.Node>
        <div />
      </Skeleton.Node>
    ) : (
      <LogoIntegration
        className={styles.logo}
        name={name}
        isEnabled={isEnabled}
        isInstalled={isInstalled}
      />
    )}
    {isLoading ? (
      <Skeleton active paragraph={{ rows: 1 }} />
    ) : (
      <div className={styles.text}>
        <Title className={styles.title} level={2}>
          {displayName}
          {beta && <BetaLabel />}
        </Title>
        <div>
          {tags.map((tag) => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </div>
      </div>
    )}
  </div>
)
