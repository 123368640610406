import { Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import {
  DeployTab,
  TitleStepDeployTab,
  type CommonDeployTabProps
} from "@/pages/DeploymentsPage/components/molecules/DeployTab"
import { DownloadStep } from "@/pages/DeploymentsPage/components/organisms/DownloadStep"
import { EC2DeployCommand } from "@/pages/DeploymentsPage/components/organisms/EC2DeployCommand"

import type { Query } from "@/pages/DeploymentsPage/components/molecules/DownloadFileButton"

export const EC2Tab = (props: CommonDeployTabProps) => {
  const { noError, version } = props
  const packageSpecType = "ec2"
  const files = [
    {
      name: `kortex-${version}-linux-arm64.tgz`,
      query: { packageSpecType, packageSpecArch: "arm64" } as Query
    },
    {
      name: `kortex-${version}-linux-amd64.tgz`,
      query: { packageSpecType, packageSpecArch: "amd64" } as Query
    }
  ]
  const { t } = useTranslation()

  return (
    <DeployTab
      steps={[
        {
          title: (
            <DownloadStep
              noError={noError}
              files={files}
              title={t("deployments.ec2.deploymentStep")}
            />
          ),
          status: "process"
        },
        {
          title: (
            <TitleStepDeployTab>
              <span>{`${t("general.deploy")} `}</span>
              {` - ${t("deployments.ec2.commandStep")}`}
            </TitleStepDeployTab>
          ),
          status: "process"
        }
      ]}
      message={
        <span>
          <Text>
            {t("deployments.ec2.alert.text1")}
            <br />
            {t("deployments.ec2.alert.text2")}
          </Text>
          <br />
          <b>{t("deployments.ec2.alert.note")}</b>
        </span>
      }
    >
      <EC2DeployCommand {...props} />
    </DeployTab>
  )
}
