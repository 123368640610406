import { useNavigate } from "react-router-dom"

import { Button, Modal, Text, Tooltip } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { EyeOutlined, SaveOutlined } from "@/icons"

import { isUserAdmin } from "@/domain/user"
import { useUser } from "@/hooks/useUser"
import styles from "./FooterScopePage.module.scss"

export const FooterScopePage = ({
  submitting,
  form,
  currentScopeId,
  statePreview,
  setPreviewState
}: any) => {
  const navigate = useNavigate()
  const [modal, modalContextHolder] = Modal.useModal()
  const { t } = useTranslation()
  const { user } = useUser()
  const isNotAdmin = !isUserAdmin(user)
  const disabledPreview = isNotAdmin
    ? false
    : statePreview.dirty || !currentScopeId

  return (
    <div className="container-buttons-form-footer-page-with-breadcrumb-template-settings">
      <Button
        onClick={() => {
          const translationKeys = currentScopeId
            ? { msg: "editMsg", btn: "editBtn" }
            : { msg: "createMsg", btn: "createBtn" }

          modal.confirm({
            className: styles.modal,
            content: (
              <Text className={styles.text}>
                {t(`settings.scopes.cancel.${translationKeys.msg}`)}
              </Text>
            ),
            okText: t(`settings.scopes.cancel.${translationKeys.btn}`),
            onOk: () => navigate(-1),
            cancelText: t("general.stay")
          })
        }}
      >
        {t("general.cancel")}
      </Button>
      <Tooltip
        title={
          disabledPreview
            ? t("settings.scopes.messages.previewTooltip")
            : undefined
        }
      >
        <Button
          disabled={disabledPreview}
          icon={<EyeOutlined />}
          onClick={() =>
            setPreviewState((prevState: object) => ({
              ...prevState,
              open: true
            }))
          }
        >
          {t("general.preview")}
        </Button>
      </Tooltip>

      <Button
        disabled={!statePreview.dirty || isNotAdmin}
        type="primary"
        icon={<SaveOutlined />}
        loading={submitting}
        onClick={() => {
          form.submit()
        }}
      >
        {t("settings.scopes.saveScope")}
      </Button>
      {modalContextHolder}
    </div>
  )
}
