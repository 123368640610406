import { useRef } from "react"

import { Collapse, Form, message, Paragraph } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { RadioLabel } from "../../molecules/RadioLabel"
import { SamlAuthenticationMethodForm } from "../SamlAuthenticationMethodForm"

import styles from "./AuthenticationMethodCollapse.module.scss"

import type {
  AuthenticationMethodCollapseProps,
  AuthenticationMethods
} from "."

export const AuthenticationMethodCollapse: React.FC<
  AuthenticationMethodCollapseProps
> = ({ data, form, updateAuthMethod }) => {
  const [messageApi, messageContextHolder] = message.useMessage()
  const KEYS: Record<string, AuthenticationMethods> = {
    emailAndPassword: "emailAndPassword",
    saml: "saml"
  }
  const { t } = useTranslation("translation", {
    keyPrefix: "settings.auth.methods"
  })
  const activeKeyInitialValue = useRef<AuthenticationMethods>(
    (() =>
      ((Object.keys(data) as Array<AuthenticationMethods>).find(
        (key) => data[key].enabled
      ) as AuthenticationMethods) || KEYS.emailAndPassword)()
  ).current
  const activeKey = Form.useWatch("activeKey", form) || activeKeyInitialValue

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={{ activeKey: activeKeyInitialValue, ...data }}
        onValuesChange={() => {
          form.setFieldValue("dirty", true)
        }}
        onFinish={async (values) => {
          try {
            const activeKey = values?.activeKey || ""
            let disabledKey = (
              Object.keys(data) as Array<AuthenticationMethods>
            ).filter((key) => KEYS[key] !== activeKey)[0]

            if (!activeKey || !disabledKey) return

            const res = await updateAuthMethod({
              [activeKey]: {
                enabled: true,
                ...values[activeKey]
              },
              [disabledKey]: { enabled: false }
            })

            if (res?.data) {
              const { data = {} } = res
              const currentValues = form.getFieldsValue()

              form.setFieldsValue({ ...currentValues, ...data })
            }

            messageApi.success(
              t("messages.setUp", {
                method: t(`${KEYS[activeKey]}.title`)
              })
            )
          } catch (err) {
            const message = (err as Error)?.message || "Internal error"
            let notificationMessage = message

            Object.keys(values[activeKey]).forEach((key) => {
              if (message.includes(key)) {
                const errMsg = message.includes("Invalid argument")
                  ? t("errors.invalidArg")
                  : message

                notificationMessage = t("errors.invalidArg")
                form.setFields([{ name: [activeKey, key], errors: [errMsg] }])
              }
            })

            messageApi.error(notificationMessage)
          }
        }}
      >
        <Paragraph className={styles.title}>{t("title")}</Paragraph>
        <Collapse
          accordion
          bordered={false}
          className={styles.collapse}
          expandIcon={() => null}
          activeKey={activeKey}
          items={[
            {
              key: `${KEYS.emailAndPassword}_TEMPORARY_DISABLE`,
              label: (
                <Form.Item name="activeKey" noStyle>
                  <RadioLabel
                    label={t(`${KEYS.emailAndPassword}.title`)}
                    description={t(`${KEYS.emailAndPassword}.description`)}
                    checked={activeKey === KEYS.emailAndPassword}
                    onChange={() => {
                      form.setFieldValue("activeKey", KEYS.emailAndPassword)
                    }}
                  />
                </Form.Item>
              ),
              className: styles.panel,
              children: null
            },
            {
              key: KEYS.saml,
              label: (
                <Form.Item name="activeKey" noStyle>
                  <RadioLabel
                    label={t("saml.title")}
                    description={t("saml.description")}
                    checked={activeKey === KEYS.saml}
                    onChange={() => {
                      form.setFieldValue("activeKey", KEYS.saml)
                    }}
                  />
                </Form.Item>
              ),
              className: styles.panel,
              children: (
                <SamlAuthenticationMethodForm
                  activeKey={activeKey}
                  dataKey={KEYS.saml}
                  form={form}
                />
              )
            }
          ]}
        />
        <Form.Item noStyle name="dirty" required={false} />
      </Form>
      {messageContextHolder}
    </>
  )
}
