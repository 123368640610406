import type { Values } from "../common"

export const encodeParams = (values: Values) => {
  try {
    const { record, tabs = {}, initialFilters } = values
    const { id, meta } = record

    return btoa(JSON.stringify({ id, initialFilters, meta, ...tabs }))
  } catch {
    return ""
  }
}

export const decodeParams = (encodedParams: string) => {
  try {
    const decodedParams = atob(encodedParams)
    const { id, initialFilters, meta, ...tabs } = JSON.parse(decodedParams)

    return { record: { id, meta }, initialFilters, tabs }
  } catch {
    return null
  }
}

export const getInitialDataFromQueryClient = <TData>(
  data: [unknown, KosmosApi.Response<TData[]> | undefined][],
  fn: (value: TData) => boolean
) =>
  data.reduce(
    (acc, value) => {
      if (acc) return acc

      const arr = value?.[1]

      if (arr?.data?.length) {
        const index = arr.data.findIndex(fn)

        if (index === -1) return acc

        return { ...arr, data: [arr.data[index]] }
      }

      return acc
    },
    undefined as KosmosApi.Response<TData[]> | undefined
  )
