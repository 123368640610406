import { useGetFiltersDeployments } from "@/api/useDeployments"
import { FiltersDeployment } from "@/api/useDeployments.types"
import { getOptionsForFilters } from "./useFiltersDeployments.selector"

export const checkNoDeployments = (
  filters:
    | Record<keyof FiltersDeployment, { label: string; value: string }[]>
    | undefined
) => {
  return filters?.environments.length === 0
}

export const useFiltersDeployments = () => {
  const context = useGetFiltersDeployments({ select: getOptionsForFilters })

  return context
}
