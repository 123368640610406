import { WIDTH_DEFAULT } from "@/organisms/Drawers/const"
import { TabsDrawerTemplate } from "../TabsDrawerTemplate"
import { IntroducedThroughTab } from "./components/IntroducedThroughTab"
import { IssueTabPackageDrawer } from "./components/IssueTabPackageDrawer"
import { OverviewTabPackageDrawer } from "./components/OverviewTabPackageDrawer"
import { TitlePackageDrawer } from "./components/TitlePackageDrawer"

export const PackageDrawer = (props: any) => {
  const { widthAdditive } = props
  const tabs = ["overview", "introducedThrough", "scaIssues"]
  const components = [
    OverviewTabPackageDrawer,
    IntroducedThroughTab,
    IssueTabPackageDrawer
  ]

  return (
    <TabsDrawerTemplate
      fillingBackGround
      name="PackageDrawer"
      tabs={tabs}
      components={components}
      Title={TitlePackageDrawer}
      width={WIDTH_DEFAULT + widthAdditive}
      {...props}
    />
  )
}
