import cn from "classnames"

import { Select } from "@/atoms"
import { trim } from "../../helpers"

import styles from "./TagInput.module.scss"

import type { TagInputComponent } from "."

export const TagInput: TagInputComponent = ({ onChange, ...props }) => {
  return (
    <Select<string | string[]>
      open
      mode="tags"
      tokenSeparators={[","]}
      className={cn(
        "tag-ant-select-selection-item-ant-select-selector",
        styles.container
      )}
      popupClassName={styles.popupClassName}
      onInputKeyDown={(e) => {
        const dom = e.target as HTMLInputElement

        if (e.key === "Enter" && !dom?.value) e.stopPropagation()
      }}
      onChange={(value) => onChange?.(trim(value))}
      {...props}
    />
  )
}
