import cn from "classnames"
import { Fragment } from "react"

import { Link, Text } from "@/atoms"
import { IconResource } from "."

import styles from "./Resources.module.scss"

import type { ResourceComponent } from "."

export const Resource: ResourceComponent = (props) => {
  const { type, name, sourceControl, tag, to, onClick, classes = {} } = props
  const isLinkView = !!to || !!onClick
  const Component = isLinkView ? Link : "div"
  const Wrapper = tag ? "div" : Fragment
  const wrapperProps = tag ? { className: styles.wrapper } : {}
  const rootClassName = cn(
    styles.section,
    styles.resource,
    classes.container,
    tag && styles.tagResource
  )

  return (
    <Component className={rootClassName} to={to} type="link" onClick={onClick}>
      <IconResource type={type} sourceControl={sourceControl} />
      <Wrapper {...wrapperProps}>
        <Text ellipsis className={cn(styles.nameResource, classes.name)}>
          {name}
        </Text>
        {tag && (
          <Text type="secondary" className={styles.tag}>
            {tag}
          </Text>
        )}
      </Wrapper>
    </Component>
  )
}
