import { Creator } from "@/api/useAuthKeys.types"
import { getColorNumberForUserAvatar, getInitials } from "@/domain/user"
import { UserItem } from "@/molecules/UserItem"

export const CreatorCell = (creator: Creator) => {
  const displayName = creator.name ?? creator.email
  return (
    <UserItem
      avatarSize="small"
      tooltip={displayName}
      email={creator.email}
      initials={getInitials(displayName)}
      colorNumber={getColorNumberForUserAvatar(creator.email)}
      displayOnlyAvatar
    />
  )
}
