import { Text } from "@/atoms"
import { TooltipShowMoreProps } from "."
import { ContentShowMore } from "../ContentShowMore"
import styles from "./TooltipShowMore.module.scss"

export const TooltipShowMore = (props: TooltipShowMoreProps) => (
  <>
    {props.title && <Text className={styles.title}>{props.title}</Text>}
    <ContentShowMore {...props} />
  </>
)
