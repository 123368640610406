import { useSearchParams } from "react-router-dom"

export const useQueryString = (
  additionParamKeys?: (string | { key: string })[]
) => {
  const [searchParams] = useSearchParams()

  const result = {
    pageNumber: searchParams.get("pageNumber") || "0",
    query: searchParams.get("query") || undefined,
    ...additionParamKeys?.reduce(
      (acc, item) => {
        const key = typeof item === "string" ? item : item.key
        const value = searchParams.get(key)

        if (value) {
          acc[key] = value
        }

        return acc
      },
      {} as Record<string, string>
    )
  }

  if (!result.query) {
    delete result.query
  }

  return result
}
