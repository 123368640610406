import { Paragraph, Spin, Title } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { LoadingOutlined } from "@/icons"

import styles from "./IntegrationLoader.module.scss"

export const IntegrationLoader = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "integrations.callback.loading"
  })

  return (
    <>
      <div className={styles.spinContainer}>
        <Spin indicator={<LoadingOutlined />} />
      </div>
      <Title level={4} className={styles.status}>
        {t("status")}
      </Title>
      <Paragraph type="secondary" className={styles.text}>
        {t("text")}
      </Paragraph>
    </>
  )
}
