import cn from "classnames"

import { Paragraph } from "@/atoms"

import styles from "./PackageVersion.module.scss"

import type { PackageVersionsComponent } from "."

export const PackageVersions: PackageVersionsComponent = (props) => {
  const { name, version } = props

  if (!name && !version) return <span>-</span>

  return (
    <Paragraph
      className={styles.container}
      ellipsis={{
        rows: 2,
        tooltip: {
          title: version,
          rootClassName: cn("root-class-tooltip-antd-ks", styles.tooltip)
        }
      }}
    >{`${name} ${version}`}</Paragraph>
  )
}
