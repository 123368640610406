import { Avatar, Text } from "@/atoms"
import cn from "classnames"

import styles from "./UserItem.module.scss"

import type { UserItemProps } from "."

export const UserItem = (props: UserItemProps) => {
  const {
    initials,
    company,
    email,
    colorNumber,
    tooltip,
    avatarSize,
    description = company,
    classes = {}
  } = props
  const { displayOnlyAvatar, darkBackground, testId = "UserItem" } = props
  const isTableView = !company
  const containerClass = cn(
    styles.containerUserItem,
    darkBackground && styles.darkBackground,
    displayOnlyAvatar && styles.onlyAvatar
  )
  const isSmallAvatar = avatarSize === "small"
  const fontSizeByAvatarSize = isSmallAvatar ? 9 : 12
  const style = {
    backgroundColor: `var(--color-${colorNumber}-avatar-palette)`,
    color: "var(--neutral-10-color)",
    fontSize: `${isTableView ? fontSizeByAvatarSize : 14}px`
  }

  return (
    <div
      className={containerClass}
      data-testid={testId}
      data-tooltip-content={tooltip}
    >
      {initials && (
        <Avatar size={avatarSize} style={style}>
          {initials}
        </Avatar>
      )}
      {!displayOnlyAvatar && (
        <div
          className={cn(styles.textSection, isTableView && styles.tableView)}
        >
          <Text className={styles.emailUserItem}>{email}</Text>
          <Text className={cn(styles.description, classes.description)}>
            {description}
          </Text>
        </div>
      )}
    </div>
  )
}
