export * from "./TabTemplate"
export type {
  RecordProp,
  RecordState,
  TabTemplateProps
} from "./TabTemplate.types"
export { getLinkToDrawer } from "./useDrawerTabTemplate"

export const CONTAINER_SELECTOR = '[data-id-tab-template="row"]'
export const FILTER_ATTRIBUTE_NAME = "data-filter-counts"
