import { BaseImage, Image, SourceControlIcon, VM } from "@/icons"

import type { IconResourceComponent } from "."

export const IconResource: IconResourceComponent = (props) => {
  const { type, sourceControl } = props

  if (!type) return null
  if (type === "ImageRepository") return <Image />
  if (type === "Image") return <BaseImage />
  if (type === "Vm") return <VM />

  if (!sourceControl) return null
  return <SourceControlIcon sourceControl={sourceControl} />
}
