import { capitalize } from "@/helpers/string.helpers"
import { INEQUALITY_SYMBOL } from "@/organisms/SmartSearch/const/symbol.constants"
import { getTokenTemplate } from "../helpers"
import { AbstractHandler } from "./token-handler"

import type { PropsHandler, Token, TokenTemplate } from "./token-handler.types"

export class TokenHandler extends AbstractHandler {
  public handle(token: TokenTemplate, props: PropsHandler): Token {
    const { str } = token
    let [_id, delimiter, value, ...needle] = str.split(/(:)/g)
    let id = delimiter ? _id.toLowerCase() : _id

    value = value ? value + (needle || []).join("") : value

    const currentToken = getTokenTemplate(str, props)
    currentToken.id = id
    currentToken.delimiter = delimiter
    currentToken.value = value
    currentToken.inequality = id?.[0] === INEQUALITY_SYMBOL

    const { FilterService } = props

    if (delimiter) {
      const displayLabel = FilterService.getFilterByLabel(id)?.["displayLabel"]
      currentToken.label = displayLabel ?? capitalize(id)
    }

    if (id === "query") {
      currentToken.id = currentToken.value
      currentToken.str = currentToken.value
      currentToken.label = currentToken.value
      currentToken.delimiter = undefined
      currentToken.value = undefined
    } else if (
      id &&
      delimiter &&
      value &&
      !FilterService.getFilterByLabel(id)
    ) {
      const nextValue = `${id}${delimiter}${value}`
      currentToken.id = nextValue
      currentToken.str = nextValue
      currentToken.delimiter = undefined
      currentToken.value = undefined
    }

    return super.handle(currentToken, props)
  }
}
