import type { Snapshot } from "@/api/useInventory"

export const EMPTY_VALUE_STATISTIC_CARD = "-"

export const getStatisticCardProps = (
  values: undefined | Snapshot,
  {
    abbreviateNumber,
    showPlaceholder,
    t
  }: {
    abbreviateNumber: (value: number | string) => number | string
    showPlaceholder?: Features.Placeholder
    t: (key: string) => string
  }
) => {
  const { new: value = 0, total = 0 } = values || {}

  if (showPlaceholder || total < 1) {
    return { value: EMPTY_VALUE_STATISTIC_CARD }
  }

  return {
    value: abbreviateNumber(total),
    extra: `${t("general.new")} ${abbreviateNumber(value)}`
  }
}
