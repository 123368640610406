import { Button } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { ConnectPlug } from "@/icons"

import styles from "./ConnectIntegrationPage.module.scss"

interface ConnectIntegrationFooterProps {
  onSubmit: (values: any) => void
  onCancel: () => void
  isEnabled?: boolean
  isLoading?: boolean
}

export const ConnectIntegrationFooter: React.FC<
  ConnectIntegrationFooterProps
> = ({ onSubmit, onCancel, isEnabled = false, isLoading = false }) => {
  const { t } = useTranslation()
  const { t: tConnect } = useTranslation("translation", {
    keyPrefix: `integrations.connect`
  })

  return (
    <div className={styles.footerContainer}>
      <Button onClick={onCancel} disabled={isLoading}>
        {t("general.cancel")}
      </Button>

      <Button
        type="primary"
        icon={<ConnectPlug />}
        loading={isLoading}
        disabled={isEnabled}
        onClick={onSubmit}
      >
        {tConnect("title")}
      </Button>
    </div>
  )
}
