import { useEffect, useState } from "react"

import { EnterKey, SpaceKey } from "@/icons"

import styles from "./FooterItem.module.scss"

export const FooterItem = ({ refContainer, services, t }: any) => {
  const { StringService, FilterService } = services
  const [text, setIsShow] = useState(t("filter.footer.toStartSearch"))
  const isEmpty = StringService.getTokenByCaretPos()?.isEmpty

  useEffect(() => {
    const noFoundEl = refContainer.current?.querySelector(
      '[data-type="no-item-found-smart-search"]'
    )
    const textForNotFoundCase = FilterService.freeSearchAvailable()
      ? t("filter.footer.forFreeSearch")
      : ""
    const nextText = noFoundEl
      ? textForNotFoundCase
      : t("filter.footer.toStartSearch")

    if (nextText !== text) {
      setIsShow(nextText)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [{}])

  return (
    <div className="title-item-smart-search-ks">
      {!!text && (
        <>
          <EnterKey />
          <span className={styles.text}>
            Click <span className={styles.keyText}>ENTER</span>
            {text}
          </span>
        </>
      )}

      {!isEmpty && (
        <>
          {!!text && <span className={styles.divider} />}
          <SpaceKey />
          <span className={styles.text}>
            Click <span className={styles.keyText}>SPACE</span>
            for new key
          </span>
        </>
      )}
    </div>
  )
}
