import styles from "./FooterModal.module.scss"

import type { FooterModalComponent } from "./FooterModal.types"

export const FooterModal: FooterModalComponent = (
  originalNode,
  { Aside } = {}
) => (
  <div className={styles.container}>
    {Aside && <span className={styles.aside}>{Aside}</span>}
    {originalNode}
  </div>
)
