import { useState } from "react"

import { useGetSastSettings, useUpdateSastSettings } from "@/api/useSettings"
import { Checkbox, Form, Text, message } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { Heading } from "@/molecules/Heading"
import { VerticalTabTemplate } from "@/pages/SettingsPage/components/templates/VerticalTabTemplate"
import { ActionButtonsFooter } from "../../molecules/ActionButtonsFooter"
import { Loader } from "../../molecules/Loader"

import styles from "./CodeScanSastTab.module.scss"

export const CodeScanSastTab = () => {
  const context = useGetSastSettings()
  const updater = useUpdateSastSettings()
  const isLoading = context.isLoading
  const { response } = context
  const { data } = response || {}
  const { t: tSast } = useTranslation("translation", {
    keyPrefix: "settings.codeScanSast"
  })
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isDirty, setIsDirty] = useState(false)
  const disabled = !isDirty
  const cancelDisabled = disabled || updater.isPending
  const [messageApi, contextHolder] = message.useMessage()
  const codeScanningEnabledValue = Form.useWatch("codeScanningEnabled", form)

  return (
    <VerticalTabTemplate
      title={<Heading level={4} title={tSast("title")} />}
      footer={
        <ActionButtonsFooter
          okDisabled={disabled}
          onOk={form.submit}
          okLoading={updater.isPending}
          onCancel={() => {
            form.resetFields()
            setIsDirty(false)
          }}
          cancelDisabled={cancelDisabled}
          okConfirmModal={
            !codeScanningEnabledValue && {
              okText: t("general.confirm"),
              cancelText: t("general.back"),
              content: tSast("confirmContent")
            }
          }
        />
      }
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Text type="secondary">{tSast("description")}</Text>
          <Form
            disabled={updater.isPending}
            name="codeScanForm"
            form={form}
            className={styles.form}
            onFieldsChange={() => setIsDirty(true)}
            initialValues={data}
            onFinish={async (values) => {
              try {
                const result = await updater.mutateAsync({
                  enable_code_scanning: !!values.codeScanningEnabled
                })
                const { vendorExistence, vendorMessage } =
                  result?.metadata || {}

                if (!vendorExistence && vendorMessage)
                  return messageApi.info(
                    "No connected source code repositories - integrate source control to utilize"
                  )

                messageApi.success("Applied successfully")

                setIsDirty(false)
              } catch {}
            }}
          >
            <Form.Item
              name="codeScanningEnabled"
              valuePropName="checked"
              noStyle
            >
              <Checkbox>{tSast("checkbox")}</Checkbox>
            </Form.Item>
          </Form>
        </>
      )}
      {contextHolder}
    </VerticalTabTemplate>
  )
}
