import {
  BaseImage,
  DirectDependency,
  ExploitIconsGroup,
  IndirectDependency,
  Ingress,
  InternetFacing,
  PublicImage,
  Runtime
} from "@/icons"

import { ExploitMaturity } from "@/domain/issue"

export const EXPLOITABILITY_KEY = "exploitability"

export const ICONS_INSIGHTS = {
  runtime: {
    key: "runtime",
    Icon: Runtime
  },
  ingress: {
    key: "ingress",
    Icon: Ingress
  },
  isDirect: {
    key: "isDirect",
    Icon: DirectDependency
  },
  isIndirect: {
    key: "isIndirect",
    Icon: IndirectDependency
  },
  fromBaseImage: {
    key: "fromBaseImage",
    Icon: BaseImage
  },
  [EXPLOITABILITY_KEY]: {
    POC: {
      key: `${EXPLOITABILITY_KEY}.POC`,
      Icon: ExploitIconsGroup.POC
    },
    IN_THE_WILD: {
      key: `${EXPLOITABILITY_KEY}.IN_THE_WILD`,
      Icon: ExploitIconsGroup.IN_THE_WILD
    }
  },
  internetFacing: {
    key: "internetFacing",
    Icon: InternetFacing
  },
  publicImage: {
    key: "publicImage",
    Icon: PublicImage
  }
}

export const getExploitabilityByKeys = (
  value: ExploitMaturity | ExploitMaturity[]
) => {
  if (Array.isArray(value)) {
    return value.reduce(
      (acc, item) => {
        acc[`${EXPLOITABILITY_KEY}.${item}`] = true

        return acc
      },
      {} as Record<string, boolean>
    )
  }

  return { [`${EXPLOITABILITY_KEY}.${value}`]: true }
}
