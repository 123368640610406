import { Text } from "@/atoms"
import { Item, List } from "@/pages/DiscoveryPage/components/molecules/List"
import styles from "./ImageIntroduceThrough.module.scss"

export type PackageLocationsProps = {
  packageLocations: string[]
}
export const PackageLocations: React.FC<PackageLocationsProps> = ({
  packageLocations
}) => {
  return (
    <div className={styles.skeletonContainer}>
      {packageLocations.length === 0 && "-"}
      <List
        limit={3}
        items={packageLocations}
        render={(packageLocation) => {
          const textComponent = (
            <Text copyable={{ text: packageLocation }}>{packageLocation}</Text>
          )
          return (
            <Item
              key={packageLocation}
              value={textComponent}
              title={""}
              classes={{ value: styles.itemValue }}
            />
          )
        }}
      />
    </div>
  )
}
