import { useQueryClient } from "@/hooks/useHttp"
import { useEffect, useMemo, useState } from "react"

import { SLUG_LS } from "@/const/local-storage.constants"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { getInitials, User } from "@/domain/user"
import { useLogger } from "@/hooks/useLogger"
import useServices from "@/hooks/useServices"
import { UserContext } from "@/hooks/useUser"

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const client = useQueryClient()
  const { localStorage, auth, http, intercom } = useServices()
  const { logger, EVENTS } = useLogger()
  const [user, setUser] = useState<User>(() => ({
    currentScopeId: http.getCurrentScopeId(),
    ...auth.getCurrentUser()
  }))
  const value = useMemo(
    () => ({
      user,
      updateFeatureFlags: (featureFlags: Partial<Features.Flags>) => {
        setUser((state: User) => ({
          ...state,
          featureFlags: {
            ...state.featureFlags,
            ...featureFlags
          }
        }))
      },
      setFeatureFlags: (
        featureFlags: Features.Response,
        role: User["role"]
      ) => {
        const { dataReadiness, ...restFeatureFlags } = featureFlags
        const dataReadinessValue =
          dataReadiness === "digesting" ? dataReadiness : false

        setUser((state: User) => ({
          ...state,
          role,
          featureFlags: {
            ...state.featureFlags,
            ...restFeatureFlags,
            dataReadiness: dataReadinessValue
          }
        }))
      },
      signOut: async () => {
        try {
          await auth.signOut()
          http.reset()
          logger.reset()
          client.clear()
          intercom.shutdown()
          localStorage.removeItem(SLUG_LS)
          setUser(auth.getCurrentUser())
        } catch (err) {
          const message =
            err instanceof Error ? err.message : EVENTS.ERROR_EVENTS.SIGN_OUT

          logger.error(message)
          window.location.reload()
        }
      },
      setCurrentScope: (nextScope: string | null) => {
        setUser((state: User) => ({
          ...state,
          currentScopeId: nextScope
        }))
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, auth, localStorage, logger, http]
  )

  useEffect(() => {
    auth.subscribe(
      auth.AUTH_STATE_CHANGED_EVENT,
      (currentUser: Partial<User>) => {
        if (currentUser.authorized) {
          logger.info(EVENTS.ANALYTIC_EVENTS.LOGIN, {
            email: currentUser.email,
            slug: currentUser.company
          })
        }

        if (!!http.isAuth && !currentUser.authorized) {
          http.isAuth = false
          http.post(RESOURCES_NAMES.AUTHENTICATION.LOGOUT, {}).catch((err) => {
            // "401 Unauthorized Error" is expected here if token is already expired otherwise log error
            if (err?.status !== 401)
              logger.error(err as Error, {
                custom_error: EVENTS.ERROR_EVENTS.SIGN_OUT
              })
          })
        }

        http.setSignOut(value.signOut)
        http.setFeatureFlags(value.setFeatureFlags)

        setUser((state: User) => ({
          ...state,
          ...currentUser,
          initials: getInitials(currentUser?.email || state?.email || "")
        }))
      }
    )

    auth.subscribe(
      auth.AUTH_MULTI_FACTOR_EVENT,
      (currentUser: Partial<User>) => {
        setUser((state: User) => ({
          ...state,
          ...currentUser
        }))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserProvider
