import type { DataTask, PropsTask, TaskResponse } from "./tasks.types"

const refetch = (url: string, taskId: string, props: PropsTask) => {
  const { timeoutIdByURL, http, onError, onSuccess } = props

  timeoutIdByURL.current[url] = setTimeout(async () => {
    try {
      const {
        data: { payload, status }
      } = await http.get<TaskResponse>(`${url}/${taskId}`, {})

      if (!timeoutIdByURL.current[url]) {
        return
      }

      if ((status === "PENDING" || status === "STARTED") && !payload) {
        return refetch(url, taskId, props)
      }

      if (status === "SUCCESS" && payload) {
        return onSuccess(payload.downloadUrl, url)
      }

      onError(url)
    } catch {
      onError(url)
    }
  }, 2000)
}

export const createTask = async (
  { url, params }: DataTask,
  props: PropsTask
) => {
  try {
    const { http, logger, analytic } = props
    const {
      data: { taskId, willBeSentByEmail, filters, totalRows, noData }
    } = await http.get<{
      data: {
        taskId: string
        willBeSentByEmail: boolean
        filters: Record<string, any>
        totalRows: number
        noData: boolean
      }
    }>(url, {
      params
    })
    if (analytic) {
      const { event, type } = analytic
      logger.info(event, {
        type,
        totalRows,
        filters,
        byEmail: willBeSentByEmail,
        noData: noData
      })
    }

    if (noData) return

    if (willBeSentByEmail) {
      if (props?.onEmail) props?.onEmail(url)
      return
    }

    refetch(url, taskId, props)
  } catch {
    props?.onError(url)
  }
}
