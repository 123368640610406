import { useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import { Button, Form, Title } from "@/atoms"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useCreate } from "@/hooks/useHttp"
import { useServices } from "@/hooks/useServices"
import { useTranslation } from "@/hooks/useTranslation"
import { ExpiredMessage } from "../../molecules/ExpiredMessage"
import { PasswordInput } from "../../molecules/PasswordInput"

import styles from "./ResetPasswordForm.module.scss"

export const ResetPasswordForm = () => {
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [hasFeedback, setHasFeedback] = useState({
    password: false,
    confirmPassword: false
  })
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const password = Form.useWatch("password", form)
  const services = useServices()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")
  const { slug } = useParams()
  const context = useCreate(RESOURCES_NAMES.AUTHENTICATION.RESET_PASSWORD)

  if (context.isError) {
    return <ExpiredMessage />
  }

  const onFinish = async (value: { confirmPassword: string }) => {
    try {
      if (!token || !slug) {
        return navigate("/")
      }

      await context.mutateAsync({
        token,
        password,
        passwordConfirm: value.confirmPassword
      })

      navigate(`/${slug}/signin`, { replace: true })
    } catch (err: any) {
      services.logger.error(err)
    }
  }

  const loading = context.isPending

  return (
    <Form className={styles.wrap} form={form} onFinish={onFinish}>
      <Title className={styles.title} level={4}>
        {t("auth.setYourPassword")}
      </Title>
      <PasswordInput
        hasFeedback={hasFeedback.password}
        validateTrigger={["onBlur"]}
        className={styles.itemForm}
        name="password"
        rules={[
          {
            required: true,
            message: t("error.passwordRequired") || "Required"
          },
          () => ({
            validator(_, value) {
              const regex = new RegExp(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
              )

              if (value?.length >= 8 && regex.test(value)) {
                setShowErrorMessage(false)

                return Promise.resolve()
              }

              setShowErrorMessage(true)

              return Promise.reject(
                new Error(
                  t("error.passwordComplexity") ||
                    "Password does not meet complexity requirements"
                )
              )
            }
          })
        ]}
        placeholder={t("auth.newPassword") || ""}
        onBlur={() => setHasFeedback((prev) => ({ ...prev, password: true }))}
      />
      <PasswordInput
        hasFeedback={hasFeedback.confirmPassword}
        validateTrigger={["onBlur"]}
        className={styles.itemForm}
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: t("error.passwordRequired") || "Required"
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(
                  t("error.passwordNotMatch") || "Passwords do not match"
                )
              )
            }
          })
        ]}
        placeholder={t("auth.confirmNewPassword") || ""}
        onBlur={() =>
          setHasFeedback((prev) => ({ ...prev, confirmPassword: true }))
        }
      />

      {showErrorMessage && (
        <div className={styles.explanationMessage}>
          <ul>
            <li>{t("auth.complexPassword")}</li>
            <li>{t("auth.symbolsPassword")}</li>
          </ul>
        </div>
      )}

      <Button
        block
        disabled={loading}
        loading={loading}
        htmlType="submit"
        size="large"
        type="ks"
      >
        {t("general.save")}
      </Button>
    </Form>
  )
}
