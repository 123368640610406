import type { Scope } from "@/api/usePolicies.types"
import type { ResponseFromSelector } from "."

type SpecificScope = {
  name: keyof Scope | undefined
  value: string[] | string | null | undefined
}[]

export const trimString = <V>(value: V) =>
  typeof value === "string" ? value.trim() : value

export const trim = <V>(value: V) => {
  if (Array.isArray(value)) return value.map(trimString)

  return trimString(value)
}

export const getEmptyScope = () => ({ name: undefined, value: undefined })

export const getInitialValues = (
  response: ResponseFromSelector | undefined,
  isNewPolicy: boolean
) => {
  const { specificScopes, ...rest } =
    isNewPolicy || !response?.data?.[0]
      ? {
          scope: {} as Scope,
          specificScopes: [getEmptyScope()],
          currentConditions: [[], []],
          currentScope: "all"
        }
      : response.data[0]

  return {
    specificScopes: !specificScopes.length ? [getEmptyScope()] : specificScopes,
    ...rest
  }
}

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const getScopeForPayload = (values: {
  currentScope: "all" | unknown
  specificScopes: SpecificScope
}) => {
  const { currentScope, specificScopes } = values
  return currentScope === "all"
    ? {
        repository_name: null,
        organization_name: null,
        github_topics: null
      }
    : specificScopes?.reduce(
        (acc, { name, value }) => {
          if (!name || !value) return acc

          const nextValue = trim(value)

          acc[camelToSnakeCase(name)] = Array.isArray(nextValue)
            ? nextValue.join(",")
            : nextValue

          return acc
        },
        {} as Record<string, string>
      )
}

export const getConditionsForPayload = (values: {
  currentConditions: { key: string; selectedValue: unknown }[][]
}) =>
  (values.currentConditions || [])
    .filter((value) => !!value?.length)
    .map((value) =>
      value.reduce(
        (acc, condition) => {
          const { selectedValue } = condition

          acc[condition.key] = trim(selectedValue)

          return acc
        },
        {} as Record<string, unknown>
      )
    )

export const testIsCVI = (value: string) => /^CVE-\d{4}-\d{4,}$/i.test(value)

export const cviValidator = (_: unknown, value: string[]) => {
  if (!value?.every(testIsCVI)) return Promise.reject()

  return Promise.resolve()
}
