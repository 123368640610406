import { useGetIntegrationsList } from "@/api/useIntegrations"
import { Title } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { HeaderPage } from "@/organisms/HeaderPage"
import { ListIntegrations } from "@/pages/IntegrationsPage/components/organisms/ListIntegrations"
import { ListIntegrationsSkeleton } from "@/pages/IntegrationsPage/components/organisms/ListIntegrationsSkeleton"
import { PageTemplate } from "@/templates/PageTemplate"

import styles from "./ListIntegrationsPage.module.scss"

export const ListIntegrationsPage = () => {
  const { t } = useTranslation()
  const { response, isLoading } = useGetIntegrationsList()
  const { data = [] } = response || {}

  return (
    <PageTemplate header={<HeaderPage titleTranslationKey="Integrations" />}>
      <div className={styles.title}>
        <Title level={2}>{t("integrations.title_list")}</Title>
      </div>
      <div className={styles.list}>
        {isLoading && <ListIntegrationsSkeleton />}
        {!isLoading && <ListIntegrations list={data} />}
      </div>
    </PageTemplate>
  )
}
