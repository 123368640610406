import {
  Button,
  Empty,
  Link,
  message,
  Modal,
  type ModalFuncProps
} from "@/atoms"
import { isUserAdmin } from "@/domain/user"
import { useLogger } from "@/hooks/useLogger"
import { useTooltip } from "@/hooks/useTooltip"
import { useUser } from "@/hooks/useUser"
import { ExclamationCircleOutlined, KeyIcon } from "@/icons"
import { Heading } from "@/molecules/Heading"
import { useActiveKeyTabsUsers } from "@/pages/SettingsPage/hooks/useActiveKeyTabsUsers"
import { TabTemplate } from "@/templates/TabTemplate"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { VerticalTabTemplate } from "../../templates/VerticalTabTemplate"
import styles from "./AuthKeysTab.module.scss"
import { KeysTabProps } from "./AuthKeysTab.types"
import { CreateKeyModal } from "./CreateKeyModal"
import { getColumnsTable } from "./getColumnsTable"

export const AuthKeysTab = (props: KeysTabProps) => {
  const { t } = useTranslation()
  const { t: tAuthKeys } = useTranslation("translation", {
    keyPrefix: "settings.authkeys"
  })

  const { activeKey } = useActiveKeyTabsUsers()
  const [{ response, isLoading }, { loading, deleteKey }] = props.useAuthKeys(
    props.keyType,
    {
      enabled: activeKey === props.tabName
    }
  )
  const { data, metadata }: any = response || ({} as any)
  const currentDialog = useRef<any>(null)
  const [modal, modalContextHolder] = Modal.useModal()
  const [showModal, setShowModal] = useState<boolean | number>(false)
  const [messageApi, messageContextHolder] = message.useMessage()
  const { user } = useUser()
  const { logger } = useLogger()

  const isKeyCreationEnabled =
    props.keyType === "API_KEYS" ||
    (isUserAdmin(user) && props.keyType !== "SERVICE_KEYS")

  const { COMMAND_GENERATE_KEY } = props.analyticsEvents

  useEffect(() => {
    currentDialog.current?.update({ okButtonProps: { loading } })
  }, [loading])

  const showConfirmDialog = ({
    message,
    title,
    okText,
    onOk
  }: ModalFuncProps & { message: { success: string; error: string } }) => {
    currentDialog.current = modal.confirm({
      className: styles.confirmDialog,
      icon: <ExclamationCircleOutlined />,
      cancelText: t("general.cancel"),
      title,
      okText,
      okButtonProps: { loading },
      onOk: async (close) => {
        try {
          const { err, data } = await onOk?.()
          const type = !err ? "success" : "error"
          const content = !err || !data?.detail ? message[type] : data.detail

          messageApi.open({
            type,
            content
          })
        } catch {
          messageApi.open({
            type: "error",
            content: "Error"
          })
        } finally {
          close()
        }
      }
    })
  }

  const columns = useRef(
    getColumnsTable({
      keyType: props.keyType,
      tAuthKeys,
      deleteColumnKey: (record: { key: string; name: string }) => {
        const { key, name } = record
        showConfirmDialog({
          title: tAuthKeys("confirm.delete.title", { name }),
          okText: t("general.delete"),
          message: {
            error: tAuthKeys("confirm.delete.error", {
              name
            }),
            success: tAuthKeys("confirm.delete.success", {
              name
            })
          },
          onOk: () => deleteKey(key)
        })
      }
    })
  ).current

  useTooltip(data)

  return (
    <VerticalTabTemplate
      title={
        <Heading
          level={4}
          title={props.title}
          popover={{
            className: styles.popover,
            content: (
              <div className={styles.popoverContent}>
                <span>{props.description}</span>
              </div>
            )
          }}
        />
      }
      button={
        isKeyCreationEnabled && (
          <Button
            icon={<KeyIcon />}
            type="primary"
            className={styles.button}
            onClick={() => setShowModal(Date.now())}
          >
            {tAuthKeys("actions.create")}
          </Button>
        )
      }
    >
      <TabTemplate
        dataSource={data}
        metadata={metadata}
        columns={columns}
        loading={isLoading}
        tabPosition="left"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <div className={styles.empty}>
                  <span>{tAuthKeys("noKeys")}</span>
                  {isKeyCreationEnabled && (
                    <Link>
                      <Button
                        type="link"
                        onClick={() => {
                          setShowModal(true)
                        }}
                      >
                        {tAuthKeys("createFirstNewKey")}
                      </Button>
                    </Link>
                  )}
                </div>
              }
            />
          )
        }}
      />
      <CreateKeyModal
        key={`${showModal}`}
        open={!!showModal}
        useCreateKey={props.useCreateKey}
        logCreateKey={(keyName) => {
          logger.info(COMMAND_GENERATE_KEY, { keyName })
        }}
        keyType={props.keyType}
        onClose={() => {
          setShowModal(false)
        }}
      />
      {modalContextHolder}
      {messageContextHolder}
    </VerticalTabTemplate>
  )
}
