import { Text, Tooltip } from "@/atoms"

import styles from "./CellTableWithCopyableTooltip.module.scss"

import type { CellTableWithCopyableTooltipProps } from "."

export const CellTableWithCopyableTooltip = ({
  title,
  copyableText,
  value
}: CellTableWithCopyableTooltipProps) => (
  <Tooltip
    className={styles.tooltip}
    overlayClassName={styles.overlayClassName}
    destroyTooltipOnHide
    title={
      <Text className={styles.copyableText} copyable={!!copyableText}>
        {title || copyableText || value}
      </Text>
    }
  >
    {value}
  </Tooltip>
)
