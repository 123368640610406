import { useTranslation } from "@/hooks/useTranslation"
import {
  DeployTab,
  TitleStepDeployTab,
  type CommonDeployTabProps
} from "@/pages/DeploymentsPage/components/molecules/DeployTab"
import { CommonDeployCommand } from "@/pages/DeploymentsPage/components/organisms/CommonDeployCommand"
import { DownloadStep } from "@/pages/DeploymentsPage/components/organisms/DownloadStep"

export const CommonDeployTab = (props: CommonDeployTabProps) => {
  const { noError, version } = props
  const files = [
    { name: `kortex-${version}.tgz`, url: "/api/v1/deployments/download" },
    {
      name: "kortex-regcred.yaml",
      url: "/api/v1/deployments/registry-credentials"
    }
  ]
  const { t } = useTranslation()

  return (
    <DeployTab
      steps={[
        {
          title: (
            <DownloadStep
              noError={noError}
              files={files}
              title={t("deployments.kubernetes.deploymentStep")}
            />
          ),
          status: "process"
        },
        {
          title: (
            <TitleStepDeployTab>
              <span>{`${t("general.deploy")} `}</span>
              {` - ${t("deployments.kubernetes.commandStep")}`}
            </TitleStepDeployTab>
          ),
          status: "process"
        }
      ]}
      message={
        <span>
          {t("deployments.kubernetes.alert.text")}
          <br />
          <b>{t("deployments.kubernetes.alert.note")}</b>
        </span>
      }
    >
      <CommonDeployCommand {...props} />
    </DeployTab>
  )
}
