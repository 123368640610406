import { InformationPopover } from "@/molecules/InformationPopover"
import styles from "./BaseReposLabel.module.scss"
import { BaseReposLabelProps } from "./BaseReposLabel.types"

export const BaseReposLabel = ({ t }: BaseReposLabelProps) => {
  return (
    <>
      {t("baseReposInput.title")}
      <div className={styles.tooltip}>
        <InformationPopover.Popover
          content={
            <InformationPopover.CommonTemplate>
              {t("baseReposInput.tooltip")}
            </InformationPopover.CommonTemplate>
          }
        />
      </div>
    </>
  )
}
