import { Button } from "@/atoms"
import { useHttp } from "@/hooks/useHttp"
import { DownloadOutlined } from "@/icons"
import { downloadFileFromUrl } from "@/services/download"

import styles from "./DownloadFileButton.module.scss"

import type { DownloadFileButtonComponent } from "."

export const DownloadFileButton: DownloadFileButtonComponent = (props) => {
  const context = useHttp("/v1/deployments/download-url", props.query, {
    enabled: false
  })

  return (
    <Button
      loading={context.isLoading}
      className={styles.button}
      icon={<DownloadOutlined />}
      onClick={async () => {
        let url = props.url

        if (!url && props.query) {
          const { data } = (await context.refetch()) as {
            data?: { data: { downloadUrl: string } }
          }

          url = data?.data?.downloadUrl
        }

        if (url) downloadFileFromUrl({ filename: props.name, url })
      }}
    >
      {props.name}
    </Button>
  )
}
