import { useState } from "react"

import { Button } from "@/atoms"
import { CheckOutlined, CopyOutlined } from "@/icons"

import { ButtonCopyButtonProps } from "."

export const IconButton = (props: ButtonCopyButtonProps) => {
  const [copied, setCopied] = useState(false)
  const {
    children,
    onCopy,
    onClick,
    resetStateTimeout = 2000,
    size = "small",
    type = children ? "default" : "text",
    ...restProps
  } = props

  return (
    <Button
      size={size}
      type={type}
      icon={copied ? <CheckOutlined /> : <CopyOutlined />}
      onClick={() => {
        setCopied(true)

        if (!!resetStateTimeout)
          setTimeout(() => setCopied(false), resetStateTimeout)

        if (onCopy) onCopy()
      }}
      {...restProps}
    >
      {children}
    </Button>
  )
}
