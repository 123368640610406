import locales from "@/assets/locales/en.json"
import * as Config from "@/config"
import { SLUG_LS } from "@/const/local-storage.constants"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { createDefaultAdapter, ServicesContext } from "@/hooks/useServices"
import { AuthAdapter } from "@/services/auth-adapter"
import * as download from "@/services/download"
import { HttpAdapter } from "@/services/http-adapter"
import { IntercomAdapter } from "@/services/intercom-adapter"
import Logger, { createTransportsByEnv } from "@/services/logger-adapter"
import i18n from "i18next"
import { useRef } from "react"
import { initReactI18next } from "react-i18next"

const ServicesProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const auth = useRef(
    createDefaultAdapter(AuthAdapter, {
      apiKey: Config.FIREBASE_API_KEY,
      authDomain: Config.FIREBASE_AUTH_DOMAIN,
      slug: localStorage.getItem(SLUG_LS) || ""
    })
  ).current
  const logger = useRef(
    new Logger({
      transports: createTransportsByEnv(Config)
    })
  ).current

  const services = useRef({
    auth,
    logger,
    download,
    http: createDefaultAdapter(HttpAdapter, {
      logger,
      apiUrl: "/api",
      exchangeTokenUrl: RESOURCES_NAMES.AUTHENTICATION.AUTH,
      auth: auth
    }),
    t: i18n.use(initReactI18next).init({
      resources: locales,
      lng: "en",
      fallbackLng: "en",
      interpolation: {
        escapeValue: false
      }
    }),
    localStorage,
    intercom: createDefaultAdapter(IntercomAdapter, {
      logger,
      appId: Config.INTERCOM_APP_ID,
      isDev: Config.isDev
    })
  }).current

  return (
    <ServicesContext.Provider value={services}>
      {children}
    </ServicesContext.Provider>
  )
}

export default ServicesProvider
