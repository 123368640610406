import { Result } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { NoScope } from "@/icons"
import { HeaderPage } from "@/organisms/HeaderPage"
import { PageTemplate } from "@/templates/PageTemplate"

import styles from "./NoScopeResult.module.scss"

export const NoScopeResult = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "unauthorized"
  })

  return (
    <PageTemplate header={<HeaderPage titleTranslationKey="" />}>
      <Result
        className={styles.container}
        icon={<NoScope className={styles.noScopeIcon} />}
        title={<div className={styles.title}>{t("title")}</div>}
        subTitle={<div className={styles.text}>{t("text")}</div>}
      />
    </PageTemplate>
  )
}
