import cn from "classnames"
import { Fragment } from "react"

import { Button, Popover } from "@/atoms"
import { JiraSoftwareLogo, JiraSoftwareLogoOutlined, TagFilled } from "@/icons"
import { ExternalLinkIcon } from "@/molecules/ExternalLinkIcon"

import styles from "./CreateJiraTicketButton.module.scss"

export const CreateJiraTicketButton = ({
  isLoading,
  hasIntegration,
  t,
  onClick,
  outlined,
  id,
  disabled: disabledProps,
  typeBtn,
  sizeBtn = "xs"
}: any) => {
  const disabled = disabledProps || !hasIntegration
  const Wrap = isLoading || hasIntegration ? Fragment : Popover
  const propsWrap =
    Wrap === Popover
      ? {
          content: (
            <span className={styles.contentTooltip}>
              <ExternalLinkIcon
                text={t("integrations.noIntegrationTicketSystem")}
                to="/integrations"
              />
            </span>
          )
        }
      : ({} as object)
  const JiraIcon = outlined ? JiraSoftwareLogoOutlined : JiraSoftwareLogo
  const Icon = !hasIntegration ? TagFilled : JiraIcon
  const buttonClassName = cn(
    disabled && styles.disabled,
    !hasIntegration && styles.noIntegrationTicketSystem
  )
  const buttonText = hasIntegration
    ? t("jiraIssueModal.createButton")
    : t("jiraIssueModal.noIntegrationCreateButton")
  return (
    <Wrap {...propsWrap}>
      <Button
        id={id}
        className={buttonClassName}
        disabled={disabled}
        loading={isLoading}
        size={sizeBtn}
        type={typeBtn}
        icon={<Icon />}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Wrap>
  )
}
