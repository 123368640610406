import { Form, Segmented } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { MinusCircleFilled, PlusCircleFilled } from "@/icons"

import styles from "./TitleCard.module.scss"

import type { TitleCardProps } from "."

export const TitleCard: React.FC<TitleCardProps> = ({
  name,
  showIncludeExclude,
  disable
}) => {
  const { t } = useTranslation()
  const isDisabled = disable ?? false

  return (
    <div className={styles.container}>
      <span className={styles.title}>{`Condition ${name + 1}`}</span>
      {showIncludeExclude && (
        <Form.Item name={[name, "includeExclude"]}>
          <Segmented
            disabled={isDisabled}
            className={styles.segmented}
            options={[
              {
                label: (
                  <span className={styles.option} data-type="include">
                    <PlusCircleFilled />
                    <span>{t("general.include")}</span>
                  </span>
                ),
                value: "Include",
                disabled: isDisabled
              },
              {
                label: (
                  <span className={styles.option} data-type="exclude">
                    <MinusCircleFilled />
                    <span>{t("general.exclude")}</span>
                  </span>
                ),
                value: "Exclude",
                disabled: isDisabled
              }
            ]}
          />
        </Form.Item>
      )}
    </div>
  )
}
