import { ScoreExplanationProps } from "@/api/useTriage.types"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { TitleScoreCard } from "@/molecules/ScoreExplanation"
import { getItems } from "@/molecules/ScoreExplanation/getItems"
import { OverviewCardTemplate } from "@/organisms/Drawers/components/PackageDrawer/components/OverviewCardTemplate"

export const ScoreCardExplanation = ({
  record
}: {
  record: ScoreExplanationProps
}) => {
  const { t } = useTranslation()
  const internetFacingFF = useFeatureFlags("internetFacingIntegration")

  if (!record) return null

  const { kodemScore } = record

  return (
    <OverviewCardTemplate
      title={<TitleScoreCard t={t} score={kodemScore} />}
      items={getItems(record, { t, internetFacingFF })}
    />
  )
}
