import { AuthKeyStatusValues } from "@/api/useAuthKeys.selector"
import { AuthKeysResponse, AuthKeyStatus } from "@/api/useAuthKeys.types"
import { MONTH_IN_SECONDS } from "@/const/default-values"
import { getDateFromSeconds, getDateInUserLocale } from "@/helpers/date.helpers"
import dayjs from "dayjs"

export const getAuthKeysData = (response: AuthKeysResponse) => {
  const { data, metadata } = response || {}

  const getDateOrDefault = (date: number) =>
    getDateInUserLocale(getDateFromSeconds(date), "lll") || "-"

  return {
    metadata,
    data: data?.map((key) => {
      return {
        id: key.id,
        key: key.id,
        name: key.name,
        description: key.description,
        creator: key.createdBy ?? { name: "Unknown", email: "Unknown" },
        createdAt: getDateOrDefault(key.createdAt),
        expiry: getDateOrDefault(key.expiry),
        status: getExpiryValue(key.expiry),
        lastUsedAt: getDateOrDefault(key.lastUsedAt)
      }
    })
  }
}

function getExpiryValue(expiry: number): AuthKeyStatus {
  if (expiry === 0) {
    return AuthKeyStatusValues.ACTIVE
  }

  const now = dayjs().unix()
  const untilExpiry = expiry - now

  if (untilExpiry <= 0) {
    return AuthKeyStatusValues.EXPIRED
  }
  if (untilExpiry < MONTH_IN_SECONDS) {
    return AuthKeyStatusValues.EXPIRING_SOON
  }
  return AuthKeyStatusValues.ACTIVE
}
