import { Image } from "@/icons"
import { CopyLinkButton } from "@/molecules/CopyLinkButton"
import { TitleDrawer } from "@/molecules/TitleDrawer"
import { useImageEntity } from "@/organisms/Drawers/applications/inventory/useInventory"
import { SkeletonTitle } from "@/organisms/Drawers/components/SkeletonTitle"
import { useLink } from "@/organisms/Drawers/hooks/useLink"

import type { EntityRecord } from "@/organisms/Drawers/applications/common"

export const TitleImageDrawer = (
  props: EntityRecord & { drawerId: string }
) => {
  const [context] = useImageEntity(props.record?.id)
  const { response, isLoading } = context || {}
  const { data } = response || {}
  const link = useLink(props.drawerId)

  if (isLoading || !data?.length) return <SkeletonTitle />

  const { displayName, tag } = data[0]

  return (
    <TitleDrawer
      icon={<Image />}
      title={displayName}
      subTitle={`Tag: ${tag}`}
      aside={<CopyLinkButton link={link} />}
    />
  )
}
