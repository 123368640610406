import { Card, Paragraph } from "@/atoms"
import { InfoCircleOutlined } from "@/icons"

import styles from "./InformationCard.module.scss"

import type { Props } from "."

export const InformationCard = ({ infoMessage, children }: Props) => {
  return (
    <Card className={styles.card}>
      <div className={styles.titlePrerequisites}>
        <InfoCircleOutlined />
        <Paragraph>{infoMessage}</Paragraph>
      </div>
      {children}
    </Card>
  )
}
