import { Button, Link } from "@/atoms"
import styles from "./DeployButton.module.scss"

import type { DeployTypeButtonProps } from "./DeployButton.types"

export const DeployButton = ({ name, Icon }: DeployTypeButtonProps) => {
  return (
    <div className={styles.aside}>
      <Link to="/deployments/new-deployment">
        <Button type="primary" className={styles.button} icon={Icon}>
          {name}{" "}
        </Button>
      </Link>
    </div>
  )
}
