import type {
  FiltersDeployment,
  FiltersDeploymentResponse
} from "@/api/useDeployments.types"

type Key = keyof FiltersDeployment

export const getOptionsForFilters = (response: FiltersDeploymentResponse) => {
  const { data } = response || {}
  const keys = Object.keys(data) as Key[]

  return keys.reduce(
    (acc, key) => {
      acc[key] = data[key as Key].map((value) => ({
        label: value.indexOf(" ") !== -1 ? `"${value}"` : value,
        value
      }))

      return acc
    },
    {} as Record<Key, { label: string; value: string }[]>
  )
}
