export type Nullish = null | undefined

export const toDictionary = <T extends Record<any, any>>(
  arr: T[],
  key: string | number
): Record<string, T> =>
  isArray(arr) ? Object.fromEntries(arr.map((v, i) => [v[key] || i, v])) : {}

export const isArray = Array.isArray

export const isEmpty = (v: unknown) => {
  if (isNullish(v)) return true
  if (isString(v)) return !/([^\s])/.test(v)
  if (isArray(v)) return v.length === 0
  if (isObject(v)) return isObjectEmpty(v)
  return false
}

export const isNullish = (v: unknown): v is Nullish =>
  v === undefined || v === null

export const isObject = (v: unknown): v is object =>
  !!v && typeof v === "object" && v.constructor === Object

const isTypeof =
  <T>(type: string) =>
  (v: unknown): v is T =>
    typeof v === type

export const isString = isTypeof<string>("string")

const isObjectEmpty = (obj: Object) => {
  for (let _i in obj) return false
  return true
}

export const toArray = <T>(v: T): T extends any[] ? T : T[] =>
  // @ts-ignore
  isArray(v) ? v : [v]

export const range = (length?: number) => Array.from(Array(length || 0).keys())

export const isBoolean = isTypeof<boolean>("boolean")

type ValueUnion<T> = T[keyof T]
export const match = <
  TValue extends string | number | symbol | undefined | null,
  TMatchObject extends
    | {
        [key in Exclude<TValue, undefined | null>]: any
      }
    | ({
        [key in Exclude<TValue, undefined | null>]?: any
      } & { default: Exclude<any, undefined> })
>(
  valueToMatch: TValue,
  matchObject: TMatchObject
): TMatchObject extends { default: Exclude<any, undefined> }
  ? ValueUnion<TMatchObject>
  : undefined | ValueUnion<TMatchObject> => {
  if (valueToMatch && matchObject.hasOwnProperty(valueToMatch)) {
    return matchObject[valueToMatch as Exclude<TValue, undefined | null>]
  }
  // @ts-ignore
  return matchObject["default"]
}

export const preventEventBubbling = (event: React.SyntheticEvent | Event) => {
  event.stopPropagation()
}

export const preventDefaultWrapper =
  (fn?: any) => (event: React.SyntheticEvent | Event) => {
    event.preventDefault()
    preventEventBubbling(event)
    fn?.()
  }

type ParamShallowEqualObjects<T> = T | null | undefined
type TShallowEqualObjects = <T extends Record<string, unknown>>(
  a: ParamShallowEqualObjects<T>,
  b: ParamShallowEqualObjects<T>
) => boolean

export const shallowEqualObjects: TShallowEqualObjects = (a, b) => {
  if (a === b) return true
  if (!a || !b) return false

  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)
  const lenKeys = aKeys.length

  if (lenKeys !== bKeys.length) return false

  for (let i = 0; i < lenKeys; i++) {
    const key = aKeys[i]

    if (a[key] !== b[key]) {
      return false
    }
  }

  return true
}

export const generateExternalID = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  let result = ""

  for (let i = 0; i < 12; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return { key: Date.now(), value: result.toUpperCase() }
}
