import { TABS_PAGE_CONTAINER_ID } from "@/const/tab.constants"
import type {
  Severity,
  VulnerabilitiesBySeverity,
  Vulnerability
} from "@/domain/vulnerability"
import { getLinkByVulnerability } from "@/domain/vulnerability"
import { isArray } from "@/helpers/utils"
import { useTranslation } from "@/hooks/useTranslation"
import { useEffect } from "react"
import tippy, { hideAll } from "tippy.js"

export const getTitle = (title: string) =>
  `<div class="title-tippy"><span>${title}</span></div>`

export const getIcon = (id: string) =>
  `<svg width="1em" height="1em" viewBox="0 0 36 36" fill="none"><use href="#${id}"></use></svg>`

export const getSubTitle = (subTitle: string, iconId: string) => {
  return `<div class="sub-title-tippy"><span class="icon-sub-title-tippy">${getIcon(
    iconId
  )}</span><span class="text-sub-title-tippy">${subTitle}:</span></div>`
}

export const getLinkVulnerability = (data: Vulnerability) =>
  `<a class="link-tippy" href=${getLinkByVulnerability(
    data
  )} target="_blank" rel="noreferrer">
    ${data.cve}
  </a>`

export const getBodyVulnerability = (
  vulnerabilities: undefined | Vulnerability[]
) =>
  `<div class="">
    ${
      !vulnerabilities
        ? "<span>No CVEs</span>"
        : vulnerabilities.length === 1
          ? getLinkVulnerability(vulnerabilities[0])
          : `<ul class="vulnerabilities-list">${vulnerabilities
              .map(
                (vulnerability) =>
                  `<li class="cve-bullet">${getLinkVulnerability(
                    vulnerability
                  )}</li>`
              )
              .join("")}</ul>`
    }
  </div>`

export const renderExploitVulnerabilities = (
  cveInTheWild: Vulnerability[],
  cvePoc: Vulnerability[],
  t: any
) => {
  const hasCveInTheWild = cveInTheWild.length > 0
  const hasCvePoc = cvePoc.length > 0

  if (hasCveInTheWild && hasCvePoc) {
    return wrapWithBody(
      `${addInTheWildCves(
        t,
        cveInTheWild
      )} <div class="tooltip-section-seperator"></div> ${addPocCves(t, cvePoc)}`
    )
  } else if (hasCveInTheWild) {
    return wrapWithBody(`${addInTheWildCves(t, cveInTheWild)}`)
  } else if (hasCvePoc) {
    return wrapWithBody(`${addPocCves(t, cvePoc)}`)
  }
  return wrapWithBody("")
}

export const wrapWithBody = (html: string) => {
  return `<div class="body-tippy"> ${html} </div>`
}

type Data =
  | {
      vulnerabilities: VulnerabilitiesBySeverity
    }[]
  | {}

type Setting = {
  preventRemoveContent?: boolean
}

export const useTooltip = (data?: Data, settings?: Setting) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (!!data) {
      const commonProps = {
        maxWidth: "none",
        theme: "light",
        appendTo: document.body
      }
      const tippyContent = tippy("[data-tooltip-content]", {
        content: (reference) => {
          const translationKey = reference.getAttribute(
            "data-tooltip-translation"
          )
          const content = reference.getAttribute("data-tooltip-content") || ""

          if (!settings?.preventRemoveContent) {
            reference.removeAttribute("data-tooltip-content")
          }

          if (translationKey) {
            return t(content) || content
          }

          return content
        },
        ...commonProps
      })

      const tippyExploitMaturity = tippy("[data-tooltip-exploit-maturity]", {
        allowHTML: true,
        interactive: true,
        content: (reference) => {
          try {
            const rowIndex = Number(
              reference.getAttribute("data-tooltip-exploit-maturity")
            )
            reference.removeAttribute("data-tooltip-exploit-maturity")

            if (!isArray(data)) return t(`sbom.exploitPopover.notExploitable`)

            const vulnerabilities = data[rowIndex]?.vulnerabilities || {}
            const cveInTheWild: Vulnerability[] = []
            const cvePoc: Vulnerability[] = []

            Object.keys(vulnerabilities).forEach((severity) => {
              const severityVulnerabilities = vulnerabilities[severity]

              if (Array.isArray(severityVulnerabilities)) {
                severityVulnerabilities.forEach((vulnerability) => {
                  if (vulnerability.exploitMaturity === "IN_THE_WILD") {
                    cveInTheWild.push(vulnerability)
                  } else if (vulnerability.exploitMaturity === "POC") {
                    cvePoc.push(vulnerability)
                  }
                })
              }
            })

            if (!cveInTheWild.length)
              return t(`sbom.exploitPopover.notExploitable`)

            return `<div>
            ${getTitle(t(`sbom.exploitPopover.title`))}
            ${renderExploitVulnerabilities(cveInTheWild, cvePoc, t)}
            </div>`
          } catch {
            return ""
          }
        },
        ...commonProps
      })

      const tippyVulnerabilities = tippy("[data-vulnerabilities]", {
        allowHTML: true,
        interactive: true,
        content: (reference) => {
          const tooltipValues = reference
            .getAttribute("data-vulnerabilities")
            ?.split(",")

          reference.removeAttribute("data-vulnerabilities")

          if (!tooltipValues) {
            return "No data"
          }

          const rowIndex = Number(tooltipValues[0])
          const typeVulnerability = tooltipValues[1] as Severity
          if (!isArray(data)) return "No data"

          const vulnerabilities =
            data[rowIndex]?.vulnerabilities?.[typeVulnerability]

          return `<div>
            ${getTitle(t(`vulnerabilities.${typeVulnerability}`))}
            <div class="body-tippy">
              ${getBodyVulnerability(vulnerabilities)}
            </div>
            </div>`
        },
        ...commonProps
      })

      let tippyShowMore: any[] = []
      let tippyShowMoreTimeout: NodeJS.Timeout | null = null

      tippyShowMoreTimeout = setTimeout(() => {
        tippyShowMore = tippy("[data-tooltip-show-more]", {
          content(reference) {
            const id = reference
              .closest("[data-template-id-show-more]")
              ?.getAttribute("data-template-id-show-more")
            const template = id && document.getElementById(id)

            reference.removeAttribute("data-tooltip-show-more")

            if (!template) {
              return ""
            }

            return template.innerHTML
          },
          allowHTML: true,
          interactive: true,
          ...commonProps
        })
        // timeout is needed to the component Text to apply the styles
      }, 100)

      const elPage = document.getElementById(TABS_PAGE_CONTAINER_ID)
      let timeout: NodeJS.Timeout | null = null

      const onScroll = () => {
        if (!timeout)
          window.requestAnimationFrame(() => {
            hideAll({ duration: 0 })
          })

        if (timeout) clearTimeout(timeout)

        timeout = setTimeout(() => {
          timeout = null
        }, 100)
      }

      elPage?.addEventListener("scroll", onScroll)

      return () => {
        if (timeout) clearTimeout(timeout)
        if (tippyShowMoreTimeout) clearTimeout(tippyShowMoreTimeout)
        elPage?.removeEventListener("scroll", onScroll)
        tippyVulnerabilities.forEach((tippyInstance) => tippyInstance.destroy())
        tippyShowMore.forEach((tippyInstance) => tippyInstance.destroy())
        tippyContent.forEach((tippyInstance) => tippyInstance.destroy())
        tippyExploitMaturity.forEach((tippyInstance) => tippyInstance.destroy())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
}
function addInTheWildCves(t: any, cveInTheWild: Vulnerability[]) {
  return `${getSubTitle(
    t(`general.exploitability.IN_THE_WILD`),
    "exploite-svg"
  )}${getBodyVulnerability(cveInTheWild)}`
}

function addPocCves(t: any, cvePoc: Vulnerability[]) {
  return `${getSubTitle(
    t(`general.exploitability.POC`),
    "exploite-poc-svg"
  )}${getBodyVulnerability(cvePoc)}`
}
