import { RefObject, useEffect } from "react"

export const useClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent) => void,
  params?: AddEventListenerOptions
): void => {
  useEffect(() => {
    if (!ref?.current) {
      return
    }

    const fn = (event: MouseEvent) => {
      const el = ref?.current

      if (!el || el.contains(event.target as Node)) {
        return
      }

      handler(event)
    }

    const EVENT = "click"

    window.addEventListener(EVENT, fn, params)

    return () => {
      window.removeEventListener(EVENT, fn, params)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler, ref])
}
