import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled
} from "@/icons"
import cn from "classnames"
import styles from "./IntegrationStatusIcon.module.scss"
import type { IntegrationStatusIconProps } from "./IntegrationStatusIcon.types"

export const IntegrationStatusIcon = ({
  integrationStatus
}: IntegrationStatusIconProps) => {
  if (integrationStatus === "REQUESTED")
    return (
      <ClockCircleFilled className={cn(styles.statusIcon, styles.requested)} />
    )
  if (integrationStatus === "ERROR")
    return <CloseCircleFilled className={cn(styles.statusIcon, styles.error)} />

  return <CheckCircleFilled className={cn(styles.statusIcon, styles.success)} />
}
