import cn from "classnames"

import { Heading } from "@/molecules/Heading"

import styles from "./IconText.module.scss"

import type { IconTextComponent } from "."

export const IconText: IconTextComponent = (props) => {
  const { Icon, text, level, classes = {} } = props
  const useHeading = !!level && !!text

  if (useHeading) {
    return (
      <Heading
        level={level}
        title={text}
        icon={<Icon className={classes.icon} />}
      />
    )
  }

  const { children } = props

  return (
    <span className={cn(styles.container, classes.container)}>
      <Icon className={classes.icon} />
      {!!text && <span>{text}</span>}
      {!!children && children}
    </span>
  )
}
