import { Button, Space } from "@/atoms"
import { PlusOutlined } from "@/icons"

import type { ButtonListFormComponent } from "./ButtonListForm.types"

export const ButtonListForm: ButtonListFormComponent = (props) => {
  const { disabled, children, onClick } = props

  return (
    <Space>
      <Button
        block
        disabled={disabled}
        type="dashed"
        size="small"
        className="action-button-list-form-settings"
        onClick={onClick}
        icon={<PlusOutlined />}
      >
        {children}
      </Button>
    </Space>
  )
}
