import { Button, FormInstance } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { CheckCircleOutlined } from "@/icons"

import styles from "./IntegrationDetailsFooter.module.scss"

interface IntegrationDetailsFooterProps {
  form: FormInstance
  submitting?: boolean
  isFormDirty?: boolean
  setFormDirty: (value: boolean) => void
  resetConfiguration: () => void
}

export const IntegrationDetailsFooter: React.FC<
  IntegrationDetailsFooterProps
> = ({
  submitting = false,
  isFormDirty,
  form,
  setFormDirty,
  resetConfiguration
}) => {
  const { t } = useTranslation()

  const handleReset = () => {
    resetConfiguration()
    setFormDirty(false)
  }

  const handleSubmit = () => {
    form.submit()
    setFormDirty(false)
  }

  return (
    <div className={styles.container}>
      <Button onClick={handleReset} disabled={!isFormDirty}>
        {t("general.cancel")}
      </Button>

      <Button
        type="primary"
        icon={<CheckCircleOutlined />}
        loading={submitting}
        disabled={!isFormDirty}
        onClick={handleSubmit}
      >
        {t("general.apply")}
      </Button>
    </div>
  )
}
