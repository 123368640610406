import { ANALYTIC_EVENTS } from "@/const/event.constants"
import { SearchOutlined } from "@/icons"
import { INEQUALITY_SYMBOL } from "@/organisms/SmartSearch/const/symbol.constants"
import { ItemListTemplate } from "../../templates/ItemListTemplate"
import { DescriptionItem } from "../DescriptionItem"

export const KeyList = (props: any) => {
  const { logger, services, createHandler } = props
  const { FilterService, StringService } = services

  const currentValue = StringService.getTextByCaretPos()
  const items = FilterService.filterFilters(
    currentValue,
    StringService.getIds()
  )
  const className = "item-smart-search-ks key-item-smart-search-ks"

  return (
    <ItemListTemplate title="keys" Icon={SearchOutlined}>
      {items.map(({ key, label, displayLabel, inequality }: any) => {
        const handler = (event: MouseEvent | KeyboardEvent) => {
          createHandler(() => {
            logger?.info(ANALYTIC_EVENTS.TAB.FILTER.ADVANCE_SEARCH.CLICK.KEY, {
              value: key
            })

            const symbol =
              currentValue === INEQUALITY_SYMBOL ? INEQUALITY_SYMBOL : ""

            return `${symbol}${displayLabel ?? label}`
          })(event)
        }

        if (inequality) return null

        return (
          <DescriptionItem className={className} key={key} handler={handler}>
            {displayLabel ?? label}
          </DescriptionItem>
        )
      })}
    </ItemListTemplate>
  )
}
