import { useEffect, useRef } from "react"

import { Form, Steps } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { PlayCircleOutlined } from "@/icons"
import { cviValidator, testIsCVI } from "../../helpers"
import { InputConditionSection } from "../InputConditionSection"

import styles from "./ConditionSection.module.scss"

import type { ConditionSectionComponent } from "."

export const ConditionSection: ConditionSectionComponent = (props) => {
  const { form, conditionsOptions, rules, setFormIsDirty } = props
  const conditionsFieldName = "currentConditions"
  const conditions = form.getFieldValue(conditionsFieldName)
  const cviRules = [
    { message: "Invalid value", validator: cviValidator },
    ...rules
  ]
  const focusLabelRef = useRef<number>(Date.now())
  const { t: tConditionSection } = useTranslation("translation", {
    keyPrefix: "settings.policies.form.conditions"
  })

  Form.useWatch(conditionsFieldName, form)

  useEffect(() => {
    document.getElementById(`label-${focusLabelRef.current}`)?.focus()
    focusLabelRef.current = Date.now()
    document
      .querySelectorAll('[data-input-condition-key="cveIds"]')
      .forEach((el) => {
        const items = el.querySelectorAll(".ant-select-selection-item")

        items.forEach((item) => {
          const value = item.getAttribute("title")

          if (value && !testIsCVI(value)) {
            item.classList.add("error-tag-input")
          }
        })
      })
  }, [conditions])

  const validateForm = () => {
    form.validateFields()
    setFormIsDirty()
  }

  return (
    <Form.List name={conditionsFieldName}>
      {(steps, stepsActions) => {
        return (
          <Steps
            className={styles.container}
            rootClassName="rcn"
            direction="vertical"
            size="small"
            current={conditions.length - 2}
            items={steps.map((step, stepIndex) => {
              const selectedValues = conditions?.[stepIndex] || []
              const selectedIds = selectedValues.reduce(
                (
                  acc: Record<string, boolean>,
                  { value }: { value: string }
                ) => {
                  acc[value] = true
                  return acc
                },
                {}
              )

              const currentConditionsOptions = conditionsOptions.map(
                ({
                  options,
                  ...rest
                }: {
                  options: { id: string; opts: [] }[]
                }) => ({
                  options: options.map((option) => {
                    if (selectedIds[option.id]) {
                      selectedIds[option.id] = option

                      return { disabled: true, ...option }
                    }

                    return option
                  }),
                  ...rest
                })
              )
              const fieldNamePrefix = [conditionsFieldName, stepIndex]

              return {
                key: step.key,
                title:
                  stepIndex === 0
                    ? tConditionSection("when")
                    : tConditionSection("or"),
                icon: <PlayCircleOutlined className={styles.icon} />,
                description: (
                  <div className={styles.step}>
                    <Form.List
                      name={step.name}
                      rules={[
                        {
                          message: tConditionSection("rules.more"),
                          validator: async (_, values) => {
                            if (values?.length === 1) {
                              const option = conditionsOptions
                                .map(
                                  ({ options }: { options: unknown[] }) =>
                                    options
                                )
                                .flat()
                                .find(
                                  ({ id }: { id: string }) =>
                                    id === values[0].key
                                )

                              if (option.canBeUsedAlone === false)
                                return Promise.reject()
                            }

                            return Promise.resolve()
                          }
                        }
                      ]}
                    >
                      {(fields, actions, { errors }) => {
                        return (
                          <>
                            {fields.map((condition, index) => {
                              const { key, value, selectedValue, focusId } =
                                selectedValues[index]
                              const {
                                valueType,
                                opts,
                                displayName,
                                description,
                                placeholder
                              } = selectedIds[value]
                              const currentRules =
                                key === "cveIds" ? cviRules : rules

                              return (
                                <InputConditionSection
                                  id={focusId}
                                  label={displayName}
                                  description={description}
                                  rules={currentRules}
                                  key={condition.key}
                                  placeholder={placeholder}
                                  name={[index, "selectedValue"]}
                                  type={valueType}
                                  data-input-condition-key={key}
                                  options={opts}
                                  fieldNames={{ label: "displayName" }}
                                  onChange={validateForm}
                                  onReady={() => {
                                    if (opts?.length === 1 && !selectedValue) {
                                      const fieldName = [
                                        ...fieldNamePrefix,
                                        index,
                                        "selectedValue"
                                      ]
                                      form.setFieldValue(
                                        fieldName,
                                        opts[0].value
                                      )
                                    }
                                  }}
                                  onRemove={() => {
                                    if (fields.length === 1 && steps.length > 2)
                                      stepsActions.remove(stepIndex)
                                    else actions.remove(index)
                                    setFormIsDirty()
                                  }}
                                />
                              )
                            })}
                            <Form.ErrorList errors={errors} />
                            <InputConditionSection
                              labelInValue
                              fieldNames={{
                                label: "displayName",
                                value: "id"
                              }}
                              key={fields.length}
                              options={currentConditionsOptions}
                              onChange={(value) => {
                                form.setFieldValue(
                                  [...fieldNamePrefix, fields.length],
                                  typeof value === "string"
                                    ? value
                                    : {
                                        focusId: `label-${focusLabelRef.current}`,
                                        ...value
                                      }
                                )
                                validateForm()

                                if (stepIndex === conditions.length - 1)
                                  stepsActions.add()
                              }}
                            />
                          </>
                        )
                      }}
                    </Form.List>
                  </div>
                )
              }
            })}
          />
        )
      }}
    </Form.List>
  )
}
