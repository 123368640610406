import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

type Params = {
  keys: string[]
}

export const getQueryStringByKey = (key: string | undefined) => {
  if (!key) return ""

  const queryString = sessionStorage?.getItem(key)

  return !!queryString ? `?${queryString}` : ""
}

export const useQueryStringStorage = (
  key: string | undefined,
  params: Params
) => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (!key) return

    sessionStorage?.setItem(key, searchParams.toString())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  useEffect(() => {
    return () => {
      const { keys = [] } = params

      if (!sessionStorage?.removeItem) return

      keys.forEach((key) => {
        sessionStorage.removeItem(key)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
