import { Skeleton, Tabs } from "@/atoms"
import { AKS, EC2, ECS, EKS, Fargate, GKE, Kubernetes } from "@/icons"
import { DeployTypeButton } from "../../molecules/DeployTypeButton"
import { CommonDeployTab } from "../../organisms/CommonDeployTab"

import { EC2Tab } from "../../organisms/EC2Tab"

import styles from "./DeployTabs.module.scss"

import type { DeployTabsProps } from "."

export const DeployTabs = ({ loading, tabProps }: DeployTabsProps) => {
  return (
    <Tabs
      className={styles.container}
      items={
        loading
          ? [
              {
                label: (
                  <Skeleton.Node active className={styles.nodeSkeleton}>
                    <div />
                  </Skeleton.Node>
                ),
                key: "loading",
                children: (
                  <>
                    <Skeleton active paragraph={{ rows: 3 }} />
                    <Skeleton active paragraph={{ rows: 3 }} />
                  </>
                )
              }
            ]
          : [
              {
                label: <DeployTypeButton name="Kubernetes" Icon={Kubernetes} />,
                key: "kubernetes",
                children: <CommonDeployTab {...tabProps} />
              },
              {
                label: <DeployTypeButton name="GKE" Icon={GKE} />,
                key: "gke",
                children: <CommonDeployTab {...tabProps} />
              },
              {
                label: <DeployTypeButton name="AKS" Icon={AKS} />,
                key: "aks",
                children: <CommonDeployTab {...tabProps} />
              },
              {
                label: <DeployTypeButton name="EKS" Icon={EKS} />,
                key: "eks",
                children: <CommonDeployTab {...tabProps} />
              },
              {
                label: <DeployTypeButton name="EC2" Icon={EC2} />,
                key: "ec2",
                children: <EC2Tab {...tabProps} />
              },
              {
                label: (
                  <DeployTypeButton
                    name="EKS Fargate"
                    Icon={Fargate}
                    disabled
                  />
                ),
                key: "fargate",
                children: null,
                disabled: true
              },
              {
                label: <DeployTypeButton name="ECS" Icon={ECS} disabled />,
                key: "ecs",
                children: null,
                disabled: true
              }
            ]
      }
    />
  )
}
