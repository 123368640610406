import cn from "classnames"

import styles from "./TabsTemplate.module.scss"

import type { TabsTemplateProps } from "."

export const TabsTemplate = ({
  children,
  className,
  placement,
  ...props
}: TabsTemplateProps) => (
  <div
    className={cn(
      styles.container,
      { [styles.drawer]: placement === "drawer" },
      className
    )}
    {...props}
  >
    {children}
  </div>
)
