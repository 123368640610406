import { Button, Popover, Text } from "@/atoms"
import { preventEventBubbling } from "@/helpers/utils"
import { useLinkDrawerTooltip } from "@/hooks/useLinkDrawerTooltip"
import { CopyOutlined } from "@/icons"
import { ContentShowMore } from "../ContentShowMore"

import styles from "./PopoverShowMore.module.scss"

import type { PopoverShowMoreProps } from "."

export const PopoverShowMore = ({
  children,
  copyableTitle,
  onRowClick,
  ...props
}: PopoverShowMoreProps) => {
  const { open, openDrawer } = useLinkDrawerTooltip({ onRowClick })

  return (
    <Popover
      destroyTooltipOnHide
      open={open}
      rootClassName={styles.rootClassName}
      title={
        <div className={styles.title} onClick={preventEventBubbling}>
          {props.title}
          {copyableTitle && (
            <span className={styles.copyButton}>
              <Text
                copyable={{
                  icon: <Button size="small" icon={<CopyOutlined />} />,
                  text: copyableTitle,
                  format: "text/plain"
                }}
              />
            </span>
          )}
        </div>
      }
      content={<ContentShowMore openDrawer={openDrawer} {...props} />}
    >
      {children}
    </Popover>
  )
}
