import { Flex, Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { usePackageDescribe } from "@/organisms/Drawers/applications/inventory/useInventory"
import { splitPackageIdFromDrawerRecord } from "@/organisms/Drawers/applications/inventory/useInventory.selectors"
import { useParamsFilter } from "@/organisms/Drawers/hooks/useParamsFilter"
import { IntroduceThroughModal } from "../../../IssuesTab/IssueExpandableCell/components/IntroduceThrough/IntroduceThroughModal"
import { SearchFilter } from "../SearchFilter"

export const IntroducedThroughTab = (props: any) => {
  const values = splitPackageIdFromDrawerRecord(props)
  const { t } = useTranslation()
  const filtersContext = useParamsFilter({
    filterKey: "introducedThrough",
    ...props
  })
  const { response, isLoading } = usePackageDescribe({
    ...values,
    ...filtersContext.params
  })
  const { data } = response || {}
  const {
    codeRepoFindings = [],
    baseImageFindings = [],
    imageFindings = [],
    vmFindings = []
  } = data || ({} as any)

  return (
    <Flex vertical className="body-tab-drawer-ks">
      <Flex vertical>
        <Text type="secondary">
          Explore the resources below to see how the package is introduced to
          your environment.
        </Text>
        <Text type="secondary">
          All listed resources have the same issue in the same project.
        </Text>
      </Flex>
      <Flex>
        <IntroduceThroughModal
          drawer
          t={t}
          tKey="inventory.images.drawer.issue."
          title=""
          description=""
          buttonText="buttonText"
          codeRepoFindings={codeRepoFindings}
          baseImageFindings={baseImageFindings}
          imageFindings={imageFindings}
          vmFindings={vmFindings}
          isLoading={isLoading}
          SearchComponent={<SearchFilter {...filtersContext} />}
        />
      </Flex>
    </Flex>
  )
}
