import cn from "classnames"

import styles from "./ComponentShowMore.module.scss"

import type { ComponentShowMoreComponentType } from "."

export const ComponentShowMore: ComponentShowMoreComponentType = ({
  children,
  title,
  classes = {}
}) => (
  <div className={styles.container}>
    {title && <span className={styles.title}>{title}</span>}
    <span className={styles.divider} />
    <div className={cn(styles.content, classes.content)}>{children}</div>
  </div>
)
