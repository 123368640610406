import cn from "classnames"

import styles from "./VerticalTabTemplate.module.scss"

import type { VerticalTabTemplateProps } from "./VerticalTabTemplate.types"

export const VerticalTabTemplate: React.FC<VerticalTabTemplateProps> = (
  props
) => {
  const { title, button, children, footer } = props

  return (
    <div className={cn(styles.container, footer && styles.fullHeight)}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          {title}
          {button}
        </div>
        <div className={styles.content}>{children}</div>
      </div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  )
}
