import { Popover } from "@/atoms"
import { useLinkDrawerTooltip } from "@/hooks/useLinkDrawerTooltip"
import { ContentResourcesPopover } from "./ContentResourcesPopover"

import styles from "./Resources.module.scss"

import type { ResourcesPopoverComponent } from "."

export const ResourcesPopover: ResourcesPopoverComponent = (props) => {
  const { children, resources } = props
  const { open, openDrawer } = useLinkDrawerTooltip(props)

  return (
    <Popover
      destroyTooltipOnHide
      open={open}
      rootClassName={styles.rootTooltip}
      content={
        <ContentResourcesPopover
          resources={resources}
          openDrawer={openDrawer}
        />
      }
    >
      {children}
    </Popover>
  )
}
