import cn from "classnames"

import { Form, Password } from "@/atoms"
import { EyeInvisibleOutlined, LockOutlined } from "@/icons"

import type { PasswordInputProps } from "."

import styles from "./PasswordInput.module.scss"

export const PasswordInput = ({
  placeholder,
  className,
  allowClear = false,
  testId = "passwordInputSignIn",
  onBlur,
  ...props
}: PasswordInputProps) => (
  <Form.Item className={cn(styles.formItem, className)} {...props}>
    <Password
      allowClear={allowClear}
      className={styles.passwordInput}
      size="large"
      placeholder={placeholder}
      prefix={<LockOutlined />}
      suffix={<EyeInvisibleOutlined />}
      data-testid={testId}
      onBlur={onBlur}
    />
  </Form.Item>
)
