import { useIssueFindings } from "@/api/useTriage"
import { FindingsTriageResponse } from "@/api/useTriage.types"
import { getFindingsInfoForExpandRow } from "./useTriage.selectors"

export const useIssueFindingsDrawer = (issueId: string | undefined) => {
  const params = { needle: `${issueId}` }
  const context = useIssueFindings<
    FindingsTriageResponse,
    ReturnType<typeof getFindingsInfoForExpandRow>
  >(params, {
    enabled: !!issueId,
    select: getFindingsInfoForExpandRow
  })
  return context
}
