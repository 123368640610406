import { useTranslation } from "@/hooks/useTranslation"
import { TITLE_ITEM_LIST_CLASS } from "../../../const/classes.constants"
import { DescriptionItem } from "../../molecules/DescriptionItem"
import { TitleItem } from "../../molecules/TitleItem"

export const ItemListTemplate = ({ children, Icon, title }: any) => {
  const { t } = useTranslation()

  return (
    <>
      <TitleItem
        className={TITLE_ITEM_LIST_CLASS}
        title={t(title)}
        Icon={Icon}
      />
      {!children ||
        (children.length === 0 && (
          <DescriptionItem disabled className="item-smart-search-ks">
            <span data-type="no-item-found-smart-search">
              {t("No items found")}
            </span>
          </DescriptionItem>
        ))}
      {children}
    </>
  )
}
