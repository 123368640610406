import { Gradient } from "./Funnel.types"

export const isAllZero = (numbers: number[]) => numbers.every((v) => v === 0)

export const getOffsets = (
  numbers: number[],
  allValuesIsZero = isAllZero(numbers),
  middle = 50
): [number, number][] => {
  const maxValueViewBox = middle * 2
  const diff = numbers.map((v, i) => {
    if (i === 0) return middle

    const r = Math.round((v / numbers[0]) * maxValueViewBox) / 2

    return r < 0.5 ? 0.5 : r
  })

  return diff.map((v, i) => {
    if (allValuesIsZero) return [0, 0]
    if (i === diff.length - 1) return [v, v]

    return [v, diff[i + 1]]
  })
}

export const getPath =
  (middleValuePath: number) => (offset: [number, number]) => {
    const middle = middleValuePath
    const leftSizeOffset = offset[0]
    const rightSizeOffset = offset[1]
    const y1Top = middle - leftSizeOffset
    const y1Bottom = middle + leftSizeOffset
    const y2Top = middle - rightSizeOffset
    const y2Bottom = middle + rightSizeOffset
    const max = middle * 2
    const x1 = 0
    const x2 = max
    const K = 0.37
    const dx = x2 - x1
    const dy2 = y2Bottom - y2Top

    if (dy2 <= 1 && y1Bottom - y1Top - (y2Bottom - y2Top) <= 10)
      return `M 0 ${y1Top} L ${max} ${y2Top} L ${max} ${y2Bottom} L 0 ${y1Bottom} Z`

    return (
      "M" +
      x1 +
      "," +
      y1Top +
      "C" +
      (x1 + dx * K) +
      "," +
      y1Top +
      " " +
      (x1 + dx * (1 - K)) +
      "," +
      y2Top +
      " " +
      x2 +
      "," +
      y2Top +
      "L" +
      x2 +
      "," +
      y2Bottom +
      "C" +
      (x2 - dx * K) +
      "," +
      y2Bottom +
      " " +
      (x2 - dx * (1 - K)) +
      "," +
      y1Bottom +
      " " +
      x1 +
      "," +
      y1Bottom +
      "Z"
    )
  }

export const getPercentage = (value: number, max: number) => (value / max) * 100

export const getPercentageDisplayValue = (value: number) => {
  if (value < 10) {
    const divider = value <= 1 ? 100 : 10

    const nextValue = Math.trunc(value * divider) / divider

    if (nextValue < 0.01) {
      return "< 0.01%"
    }

    return `${nextValue}%`
  }

  return `${Math.round(value)}%`
}

export const getColorsByIndex = (index: number) => {
  switch (index) {
    case 0:
      return [
        { offset: "10%", stopColor: "#FAF1E3" },
        { offset: "100%", stopColor: "#FF9C6E" }
      ] as Gradient
    case 1:
      return [
        { offset: "69%", stopColor: "#FF9C6E" },
        { offset: "99%", stopColor: "#FF7875" }
      ] as Gradient
    case 2:
      return [
        { offset: "29%", stopColor: "#FF7875" },
        { offset: "100%", stopColor: "#FF4D4F" }
      ] as Gradient
    case 3:
      return [
        { offset: "25%", stopColor: "#FF4D4F" },
        { offset: "75%", stopColor: "#A8071A" }
      ] as Gradient
    case 4:
      return "#A8071A"

    default:
      return "#E8E8E8"
  }
}
