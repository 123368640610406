import { Card, Link } from "@/atoms"
import cn from "classnames"

import styles from "./IntegrationCardTemplate.module.scss"

import type { IntegrationCardTemplateProps } from "."

export const IntegrationCardTemplate = ({
  isEnabled,
  to,
  header,
  title,
  description,
  tags,
  onClick
}: IntegrationCardTemplateProps) => (
  <Link to={isEnabled ? to : undefined}>
    <Card
      className={cn(styles.container, isEnabled && styles.enabled)}
      onClick={onClick}
    >
      <div className={styles.body}>
        <div className={styles.header}>{header}</div>
        {title}
        {description}
      </div>
      <div className={styles.tagsContainer}>{tags}</div>
    </Card>
  </Link>
)
