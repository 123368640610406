export const PackageTooltip = () => (
  <div>
    <span>
      Each row represents a package version, showing all associated resources
      and insights.
    </span>
    <br />
    <span>
      Clicking a row expands details, displaying every project containing the
      package and the relevant insights.
    </span>
  </div>
)
