import { CellTable } from "@/atoms"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { ActionsLabelCell } from "../../molecules/ActionsLabelCell"
import { LabelCell } from "../../molecules/LabelCell"

import type { TFunction } from "@/hooks/useTranslation"
import type { Services } from "@/interfaces/services"
import type { ActionsLabel } from "../../molecules/ActionsLabelCell"

export const getColumnsTable = (props: {
  t: TFunction
  http: Services.IHttpAdapter
  actions: ActionsLabel
}) => {
  const { t } = props
  const keyPrefix = "settings.labels.table."

  return [
    {
      title: t(`${keyPrefix}name`),
      render: LabelCell,
      ...getDefaultColumnParams("name")
    },
    {
      title: t(`${keyPrefix}description`),
      render: CellTable,
      width: "65%",
      ...getDefaultColumnParams("description")
    },
    {
      width: 35,
      render: ActionsLabelCell(props),
      ...getDefaultColumnParams("actions")
    }
  ]
}
