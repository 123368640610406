import { useReducer } from "react"

import type { Issue } from "@/domain/issue"
import type { Label } from "@/domain/label"
import type { KeyRow } from "@/pages/TriagePage/application/useSelection.types"

type SelectedIssue = Pick<Issue, "id" | "labels">

export type Items = Record<
  string,
  { nestedItems?: Issue[]; selectedNestedIds?: KeyRow[] } | undefined
>

type Checked = boolean | "indeterminate"

interface Option extends Label {
  checked: Checked
  initialState: Checked
  hide?: boolean
  timestamp?: number
}

interface LabelState {
  openCreateModal: boolean
  options: Option[]
  issues: string[]
  searchValue?: string
  noSearchResults?: boolean
  isNewValue?: boolean
}

interface Props {
  reset?: boolean
}

type Action =
  | ReturnType<typeof switchOpenCreateModal>
  | ReturnType<typeof onSearch>
  | ReturnType<typeof updateOptions>
  | ReturnType<typeof switchCheckedState>
  | ReturnType<typeof addNewLabel>

const SWITCH_OPEN_CREATE_MODAL = "SWITCH_OPEN_CREATE_MODAL" as const

export const switchOpenCreateModal = (payload?: boolean) => ({
  payload,
  type: SWITCH_OPEN_CREATE_MODAL
})

const SEARCH_VALUE = "SEARCH_VALUE" as const

export const onSearch = (payload: string) => ({
  payload,
  type: SEARCH_VALUE
})

const UPDATE_OPTIONS = "UPDATE_OPTIONS" as const

export const updateOptions = (
  options: Label[],
  items: Items,
  props?: Props
) => ({
  items,
  options,
  props,
  type: UPDATE_OPTIONS
})

const SWITCH_CHECKED_STATE = "SWITCH_CHECKED_STATE" as const

export const switchCheckedState = (payload: number | string) => ({
  payload,
  type: SWITCH_CHECKED_STATE
})

const ADD_NEW_LABEL = "ADD_NEW_LABEL" as const

export const addNewLabel = (payload: Label) => ({
  payload,
  type: ADD_NEW_LABEL
})

const filterOptionsByValue = (options: Option[], payload?: string) => {
  if (!payload)
    return {
      isNewValue: false,
      noSearchResults: false,
      options: options.map(({ hide, ...option }) => ({
        hide: false,
        ...option
      }))
    }

  let isNewValue = true
  let noSearchResults = true
  const value = payload.trim().toLocaleLowerCase()
  const nextOptions = options.map(({ hide, ...option }) => {
    const name = option.name.toLowerCase()
    const shouldHide = !name.includes(value)

    if (isNewValue && value === name) isNewValue = false
    if (noSearchResults && !shouldHide) noSearchResults = false

    return {
      hide: shouldHide,
      ...option
    }
  })

  return { isNewValue, noSearchResults, options: nextOptions }
}

export const getInitialCheckedState = (count: number, total: number) => {
  if (count === 0) return false
  if (count === total) return true

  return "indeterminate"
}

export const getNextCheckedState = (option: Option) => {
  if (option.initialState !== "indeterminate") return !option.checked

  if (option.checked === "indeterminate") return true
  if (option.checked) return false

  return "indeterminate" as const
}

export const sortFn = (a: Option, b: Option) => {
  if (a.timestamp || b.timestamp)
    return (a.timestamp ?? 0) > (b.timestamp ?? 0) ? -1 : 1
  if (a.checked && b.checked === "indeterminate") return -1
  if (!a.checked && b.checked) return 1
  if (a.checked && !b.checked) return -1

  return 0
}

const reducer = (state: LabelState, action: Action) => {
  const { type } = action

  switch (type) {
    case UPDATE_OPTIONS: {
      const { items, options, props = {} } = action
      const issuesByLabelId: Record<string, number> = {}
      const issues = Object.values(items)
        .reduce((acc, item) => {
          const { nestedItems, selectedNestedIds } = item ?? {}

          acc.push(
            (nestedItems || []).filter(({ id }) =>
              (selectedNestedIds || []).includes(id)
            )
          )

          return acc
        }, [] as SelectedIssue[][])
        .flat()
        .map(({ id, labels }) => {
          labels.forEach(({ id }) => {
            issuesByLabelId[id] = (issuesByLabelId[id] ?? 0) + 1
          })

          return id
        })
      const totalIssues = issues.length
      const { reset } = props
      const checkedInState = reset
        ? []
        : state.options.filter(({ checked }) => checked)
      const nextOptions = options
        .map<Option>((option) => {
          const count = issuesByLabelId[option.id] ?? 0
          const initialState = getInitialCheckedState(count, totalIssues)
          const isCheckedInState = checkedInState.find(
            ({ id }) => id === option.id
          )

          return {
            checked: isCheckedInState?.checked || initialState,
            initialState,
            ...option
          }
        })
        .sort(sortFn)

      const nextSearchValue = reset ? "" : state.searchValue

      return {
        ...state,
        issues,
        searchValue: nextSearchValue,
        ...filterOptionsByValue(nextOptions, nextSearchValue)
      }
    }

    case SWITCH_OPEN_CREATE_MODAL:
      return {
        ...state,
        openCreateModal: action.payload ?? !state.openCreateModal
      }

    case SEARCH_VALUE: {
      const { payload } = action
      const { options } = state

      return {
        ...state,
        searchValue: payload,
        ...filterOptionsByValue(options, payload)
      }
    }

    case SWITCH_CHECKED_STATE: {
      const { payload } = action
      const nextOptions = state.options.map((option, index) => {
        const shouldUpdate =
          typeof payload === "string"
            ? option.id === payload
            : index === payload

        if (shouldUpdate) {
          const checked = getNextCheckedState(option)

          return { ...option, checked }
        }

        return option
      })

      return {
        ...state,
        options: nextOptions
      }
    }

    case ADD_NEW_LABEL: {
      const { payload } = action

      return {
        ...state,
        options: state.options
          .map((option) => {
            if (option.id === payload.id)
              return {
                ...option,
                checked: true,
                hide: false,
                timestamp: Date.now()
              }

            return { ...option, hide: false }
          })
          .sort(sortFn),
        searchValue: "",
        isNewValue: false,
        openCreateModal: false
      }
    }

    default:
      return state
  }
}

const getInitialState = () => ({
  openCreateModal: false,
  isNewValue: false,
  options: [],
  issues: []
})

const useLabelState = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState())

  return {
    state,
    dispatch
  }
}

export default useLabelState
