import { useServices } from "@/hooks/useServices"
import { useUser } from "@/hooks/useUser"
import { useEffect } from "react"

export const useServicesWithUser = () => {
  const { user } = useUser()
  const { intercom, logger } = useServices()

  useEffect(() => {
    if (user.authorized && user.email) {
      logger.identify(user)
      intercom.update(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intercom, logger, user.authorized, user.email])
}
