import { Badge, Checkbox } from "@/atoms"
import { useFilters, useStateFilter } from "@/hooks/useFilters"
import cn from "classnames"
import { useEffect, useState } from "react"
import { DropdownFilter } from "../DropdownFilter"

import styles from "./CheckboxFilter.module.scss"

import type { MenuProps } from "@/atoms"
import type { CheckboxFilterProps } from "."

export const CheckboxFilter = ({
  keyFilter,
  isLoading,
  items = [],
  placeHolderDefaultValue,
  order
}: CheckboxFilterProps) => {
  const { searchParams, setSearchParams, setHash } = useFilters()
  const createInitialFilterState = () =>
    (searchParams.get(keyFilter) || "")
      .split(",")
      .filter((v) => !!v)
      .reduce(
        (acc, key) => {
          acc[key] = { checked: true, timestamp: Date.now() }

          return acc
        },
        {} as Record<string, any>
      )
  const { filterState, reset, onFilterStateChange, onClear, onOk } =
    useStateFilter(createInitialFilterState())
  const [placeHolder, setPlaceHolder] = useState({
    label: placeHolderDefaultValue,
    count: 0
  })

  const menuItems: MenuProps["items"] = items
    .sort((a, b) => {
      if (order === "none") {
        return 0
      }

      return a.label > b.label ? -1 : 1
    })
    .map(({ label, value, displayLabel = label }) => {
      const checked = !!filterState.values[value]?.checked

      return {
        className: cn(styles.checkboxMenuItem, checked && styles.checked),
        label: (
          <Checkbox
            className={styles.checkbox}
            onChange={(e) => {
              onFilterStateChange({
                [value]: { checked: !!e.target.checked, timestamp: Date.now() }
              })
            }}
            checked={checked}
          >
            {displayLabel}
          </Checkbox>
        ),
        sort: label,
        key: value,
        onKeyPress: () => {
          onFilterStateChange({
            [value]: { checked: !checked, timestamp: Date.now() }
          })
        }
      }
    })

  useEffect(() => {
    const initialState = createInitialFilterState()
    const itemsByValue = items.reduce(
      (acc, { label, value, displayLabel = label }) => {
        acc[value] = displayLabel

        return acc
      },
      {} as Record<string, string>
    )
    const keys = Object.keys(initialState)
      .filter((key) => !!initialState[key]?.checked)
      .sort((a, b) => initialState[a].timestamp - initialState[b].timestamp)
      .map((key) => itemsByValue[key])

    setPlaceHolder({
      label: keys?.[0] || placeHolderDefaultValue,
      count: keys.length
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isLoading])

  useEffect(() => {
    const result = menuItems.some(({ key }: any) => {
      const checked = !!filterState.values[key]?.checked
      const checkedInUrl = !!searchParams
        .get(keyFilter)
        ?.split(",")
        .includes(key)

      return checked !== checkedInUrl
    })

    if (result) {
      reset(createInitialFilterState())
    }

    onOk()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  return (
    <DropdownFilter
      allowSearch
      disabled={isLoading}
      isPlaceholder={placeHolder.label === placeHolderDefaultValue}
      clearDisabled={!filterState.allowClear}
      okDisabled={!filterState.dirty}
      keyFilter={keyFilter}
      menuItems={menuItems}
      onClear={onClear}
      onOpenChange={(open: boolean) => {
        if (open) {
          reset(createInitialFilterState(), false)
        }
      }}
      onOk={() => {
        setSearchParams((prev) => {
          const next = new URLSearchParams(prev)

          next.set("pageNumber", "0")

          const nextValue = Object.keys(filterState.values)
            .filter((key) => !!filterState.values[key].checked)
            .join(",")

          if (!nextValue) {
            next.delete(keyFilter)

            return next
          }

          next.set(keyFilter, nextValue)

          return next
        })

        setHash(Date.now())
      }}
    >
      {placeHolder.label}
      {placeHolder.count > 1 && (
        <Badge
          className={cn(styles.badge, isLoading && styles.disabled)}
          count={`+ ${placeHolder.count - 1}`}
        />
      )}
    </DropdownFilter>
  )
}
