import { useEffect, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { DRAWERS_PARAM_NAME } from "../const"
import { getActiveTabFieldName, getIdsDrawer } from "./useDrawer"

export const useLink = (drawerId: string) => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const [link, setLink] = useState<string>()

  useEffect(() => {
    const ids = getIdsDrawer(searchParams)

    if (!ids.includes(drawerId)) return
    if (!searchParams.has(drawerId)) return

    const nextSearchParams = new URLSearchParams(searchParams)

    ids.forEach((id) => {
      nextSearchParams.delete(id)
      nextSearchParams.delete(getActiveTabFieldName(id))
    })

    const value = searchParams.get(drawerId)

    if (!value) return

    nextSearchParams.set(DRAWERS_PARAM_NAME, drawerId)
    nextSearchParams.set(drawerId, value)

    const activeTabFieldName = getActiveTabFieldName(drawerId)
    const activeTabValue = searchParams.get(activeTabFieldName)

    if (activeTabValue) nextSearchParams.set(activeTabFieldName, activeTabValue)

    const nextLink = `${
      window.location.href.split("?")[0]
    }?${nextSearchParams.toString()}`

    if (link === nextLink) return

    setLink(
      `${window.location.href.split("?")[0]}?${nextSearchParams.toString()}`
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams])

  return link
}
