import { Button, Text, Tooltip } from "@/atoms"
import { LinkOutlined } from "@/icons"

import { useTranslation } from "@/hooks/useTranslation"

import styles from "./CopyLinkButton.module.scss"

import type { CopyLinkButtonComponent } from "."

export const CopyLinkButton: CopyLinkButtonComponent = ({
  disabled,
  link = window.location.href
}) => {
  const { t } = useTranslation()

  return (
    <Text
      className={styles.text}
      copyable={{
        tooltips: false,
        icon: (
          <Tooltip title={t("general.copyLink")}>
            <Button
              disabled={disabled}
              size="small"
              type="secondary"
              icon={<LinkOutlined />}
            />
          </Tooltip>
        ),
        text: link
      }}
    />
  )
}
