import { Skeleton } from "@/atoms"

export const IntegrationDetailsSkeleton = () => {
  return (
    <>
      <Skeleton active paragraph={{ rows: 3 }} title={false} />
      <Skeleton active paragraph={{ rows: 3 }} title={false} />
    </>
  )
}
