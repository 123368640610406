import { lazy, Suspense } from "react"

import { Fallback } from "./Fallback"

import type { CodeHighlightComponent } from "."

const Code = lazy(() => import("./Code"))

export const CodeHighlight: CodeHighlightComponent = ({ loader, ...props }) => (
  <Suspense fallback={loader || <Fallback />}>
    <Code {...props} />
  </Suspense>
)
