import cn from "classnames"

import { Popover } from "@/atoms"
import { InfoCircleOutlined } from "@/icons"
import { CommonTemplateInformationPopover } from "./CommonTemplateInformationPopover"

import styles from "./InformationPopover.module.scss"

import type { InformationPopoverProps } from "."

const PopoverComponent = ({
  content,
  classNamePopover,
  classNameIcon,
  children,
  iconSize,
  open,
  placement = "right"
}: InformationPopoverProps) => (
  <Popover
    rootClassName={cn(styles.popover, classNamePopover)}
    placement={placement}
    content={content}
    open={open}
  >
    {children || (
      <InfoCircleOutlined
        className={cn(
          styles.icon,
          {
            [styles.smallIcon]: iconSize === "small",
            [styles.largeIcon]: iconSize === "large"
          },
          classNameIcon
        )}
      />
    )}
  </Popover>
)

const InformationPopover = {
  Popover: PopoverComponent,
  CommonTemplate: CommonTemplateInformationPopover
}

export { InformationPopover }
