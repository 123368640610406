import type { PropsTextHandler, TextHandler, Token } from "./text-handler.types"

export abstract class AbstractTextHandler implements TextHandler {
  private nextHandler?: TextHandler

  public getIndexByCaretPos(props: PropsTextHandler): number {
    const { StringService } = props
    const [tokenIndex] = StringService.getDataByCaretPos()

    return tokenIndex
  }

  public getTokenByIndex(index: number, props: PropsTextHandler) {
    const { StringService } = props

    return StringService.getTokens()[index]
  }

  public getPreviousToken(props: PropsTextHandler) {
    const index = this.getIndexByCaretPos(props) - 1

    return this.getTokenByIndex(index, props)
  }

  public getNextToken(props: PropsTextHandler) {
    const index = this.getIndexByCaretPos(props) + 1

    return this.getTokenByIndex(index, props)
  }

  public needAddValueToToken(props: PropsTextHandler): boolean {
    const { addString, tokenInCaretPos } = props

    return !!addString && tokenInCaretPos
  }

  public setNext(handler: TextHandler): TextHandler {
    this.nextHandler = handler

    return handler
  }

  public handle(token: Token, props: PropsTextHandler): string {
    if (this.nextHandler) {
      return this.nextHandler.handle(token, props)
    }

    return token.str
  }
}
