import { useEffect } from "react"

import { Button, Divider } from "@/atoms"
import {
  CHECKBOX_ISSUE_DATA_ID,
  DATA_OPENED_IN_DRAWER
} from "@/const/table-constants"
import { checkboxOnClick } from "@/helpers/table.helpers"
import { useQueryClient } from "@/hooks/useHttp"
import { useLogger } from "@/hooks/useLogger"
import { useFeatureFlags } from "@/hooks/useUser"
import { ExpandedRowTable } from "@/molecules/ExpandedRowTable"
import { TRIAGE_DRAWER_ID } from "@/organisms/Drawers/const"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import {
  actions,
  useSelection
} from "@/pages/TriagePage/application/useSelection"
import { useIssuesProjectTriage } from "@/pages/TriagePage/application/useTriage"
import { projectUpdater } from "@/pages/TriagePage/application/useTriage.selector"
import { getColumnsExpandedRow } from "./getColumnsExpandedRow"

import styles from "./ExpandedRowTriageTab.module.scss"

import { type Severity } from "@/domain/vulnerability"

export const ExpandedRowTriageTab = ({
  expanded,
  projectId,
  filterKeys,
  t,
  onCollapsed,
  setRecord
}: any) => {
  const response = useIssuesProjectTriage(
    { needle: `/${projectId}/issues` },
    filterKeys,
    { enabled: !!expanded }
  )
  const [state, dispatch] = useSelection()
  const selectedState = state.items[projectId] || {}
  const internetFacingFF = useFeatureFlags("internetFacingIntegration")

  const {
    isLoading,
    isRefetching,
    isFetchingNextPage,
    isStale,
    fetchNextPage,
    hasNextPage,
    data
  } = response
  const { pages = [], metadata } = data || {}
  const { updating, selectedNestedIds = [] } = selectedState
  const loading = isLoading || (!isStale && isRefetching) || updating
  const queryClient = useQueryClient()

  useEffect(() => {
    if (loading) return

    dispatch(
      actions.setIdsNestedTable(
        pages.map(({ id, status, labels }) => ({ id, status, labels })),
        projectId,
        { issuesTotal: metadata?.total }
      )
    )

    if (pages.length === 0) {
      projectUpdater(projectId, queryClient, (project) => ({
        ...project,
        kodemScore: 0,
        issuesSummary: {} as Record<Severity, number>
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onChangeRow = (selectedRowKeys: (string | number | bigint)[]) => {
    dispatch(actions.onSelectRowsInNestedTable(projectId, selectedRowKeys))
  }
  const { onRowClick, idsInDrawers } = useDrawer(TRIAGE_DRAWER_ID)
  const { logger } = useLogger()

  return (
    <ExpandedRowTable
      initialMinHeight
      rootClassName={styles.container}
      columns={getColumnsExpandedRow({
        internetFacingFF: !!internetFacingFF,
        t,
        setRecord,
        onRowClick
      })}
      dataSource={pages}
      loading={loading}
      onRow={(record) => ({
        [DATA_OPENED_IN_DRAWER]: !!idsInDrawers[record.id],
        onClick: (event: React.MouseEvent<HTMLElement>) => {
          const value = { key: record.id }
          checkboxOnClick(event, {
            record: value,
            cb: () => onRowClick(value, TRIAGE_DRAWER_ID),
            logger,
            analyticEvent: {
              name: "IssueDrawer",
              placement: "ExpandedRowTriageTab"
            }
          })
        }
      })}
      rowSelection={{
        type: "checkbox",
        preserveSelectedRowKeys: false,
        selectedRowKeys: selectedNestedIds,
        getCheckboxProps: (record) => ({
          disabled: updating,
          [`${CHECKBOX_ISSUE_DATA_ID}`]: record.id
        }),
        onChange: onChangeRow
      }}
      footer={() => (
        <>
          <Button
            disabled={!hasNextPage}
            loading={isFetchingNextPage}
            size="small"
            type="link"
            onClick={() => fetchNextPage()}
          >
            Show more
          </Button>
          <Divider type="vertical" />
          <Button size="small" type="link" onClick={onCollapsed}>
            Collapse all
          </Button>
        </>
      )}
    />
  )
}
