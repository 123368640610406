import {
  addIntegration,
  captureException,
  getClient,
  init,
  setUser
} from "@sentry/react"

import { EVENTS } from "@/const/event.constants"
import { AbstractTransport } from "./Transport"

import type { Services } from "@/interfaces/services"
import type { BrowserOptions, replayIntegration } from "@sentry/react"

type Replay = ReturnType<typeof replayIntegration>

interface InstanceSentryTransport {
  init: typeof init
  getClient: typeof getClient
  setUser: typeof setUser
  captureException: typeof captureException
  addIntegration: typeof addIntegration
}

class SentryTransport extends AbstractTransport {
  protected instance: undefined | InstanceSentryTransport
  private AUTOMATION_USER_EMAIL = "automation@kodemsecurity.com"

  constructor(
    options: BrowserOptions,
    params: { level: Services.LoggerAdapter.LevelKeys },
    instance = { init, setUser, captureException, addIntegration, getClient }
  ) {
    super({ level: params.level || "error" })

    if (options.dsn) {
      this.instance = instance
      this.instance.init(options)
    }
  }

  private getIntegrationByName(name: string) {
    return this.instance?.getClient()?.getIntegrationByName?.(name)
  }

  private getReplayIntegration() {
    return this.getIntegrationByName("Replay") as Replay
  }

  public addIntegration(integration: any) {
    this.instance?.addIntegration?.(integration)
  }

  public restartReplayIntegration() {
    try {
      const replay = this.getReplayIntegration()

      if (replay) {
        replay.stop()
        replay.startBuffering()
      }
    } catch (error: any) {
      this.log({
        message: error,
        level: "error",
        meta: { custom_error: "Failed to restart replay integration" }
      })
    }
  }

  public stopReplayIntegration() {
    try {
      const replay = this.getReplayIntegration()

      if (replay) {
        replay.stop()
      }
    } catch (error: any) {
      this.log({
        message: error,
        level: "error",
        meta: { custom_error: "Failed to stop replay integration" }
      })
    }
  }

  public async addReplayIntegration() {
    try {
      const replay = getClient()?.getIntegrationByName?.("Replay")

      if (replay) {
        return this.restartReplayIntegration()
      }

      const { replayIntegration } = await import("@sentry/react")

      this.addIntegration(
        replayIntegration({
          blockAllMedia: true,
          maskAllText: false,
          networkDetailAllowUrls: [/\/api\/(v\d+)\//, "/version"],
          beforeErrorSampling: (error) => {
            const { tags, user } = error || {}
            const { custom_error } = tags || {}

            // Do not record the session in case of authorization error
            if (custom_error === EVENTS.ERROR_EVENTS.SIGN_IN) return false

            // Do not record a session for an unauthorized user
            if (!user) return false

            // Do not record a session for the automation user
            if (user.email === this.AUTOMATION_USER_EMAIL) return false

            return true
          }
        })
      )
    } catch (error: any) {
      this.log({
        message: error,
        level: "error",
        meta: { custom_error: "Failed to add replay integration" }
      })
    }
  }

  public identify(data: Services.LoggerAdapter.IdentificationData) {
    this.instance?.setUser(data)

    if (data?.email && data.email !== this.AUTOMATION_USER_EMAIL)
      this.addReplayIntegration()
  }

  public reset() {
    this.stopReplayIntegration()
    this.instance?.setUser(null)
  }

  public log(info: Services.LoggerAdapter.Info) {
    const { message, meta } = info

    this.instance?.captureException(message, meta)
  }
}

export { SentryTransport }
