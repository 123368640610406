import { Button, Divider, Empty, Link } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"

import styles from "./EmptyMenu.module.scss"

import { isUserAdmin } from "@/domain/user"
import { useUser } from "@/hooks/useUser"
import type { EmptyMenuProps } from "."
import { FooterMenu } from "../FooterMenu"

export const EmptyMenu = (props: EmptyMenuProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "settings.scopes" })
  const onClick = () => props.actions.setOpenKeys([])
  const { user } = useUser()
  const isAdmin = isUserAdmin(user)

  return (
    <>
      {isAdmin && (
        <Empty
          className={styles.container}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <>
              <p className={styles.text}>{t("noScopes")}</p>
              <p className={styles.text}>{t("descriptions")}</p>
              <Link to="/settings/scopes/new">
                <Button type="link" className={styles.button} onClick={onClick}>
                  {t("createFirstNewScope")}
                </Button>
              </Link>
            </>
          }
        />
      )}
      {!isAdmin && (
        <Empty
          className={styles.container}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <>
              <p className={styles.text}>{t("noScopesUser")}</p>
              <p className={styles.text}>{t("noScopesUserMsg")}</p>
              <Divider className={styles.divider} />
              <div className={styles.footer}>
                <FooterMenu
                  applyDisabled={true}
                  clearDisabled={true}
                  manageScopesDisabled={true}
                  actions={props.actions}
                />
              </div>
            </>
          }
        />
      )}
    </>
  )
}
