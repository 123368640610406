import {
  JiraCreateIssueMetadataResponse,
  JiraFieldOptionsParams,
  JiraFieldOptionsResponse,
  JiraMetadataFieldParams
} from "@/api/useJira.types"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { Config, Params, useCreate, useHttp } from "@/hooks/useHttp"

export const useGetJiraFieldOptions = <T = JiraFieldOptionsResponse, S = T>(
  params: Params<JiraFieldOptionsParams>,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.TICKETING.JIRA}/fields`,
    params,
    config
  )

  return context
}

export const useGetJiraCreateIssueMetadata = <
  T = JiraCreateIssueMetadataResponse,
  S = T
>(
  params: Params<JiraMetadataFieldParams> = {},
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.TICKETING.JIRA}/metadata`,
    params,
    config
  )

  return context
}
export const useCreateJiraIssue = (params: Params<{}> = {}) => {
  const context = useCreate<
    { data: Jira.CreateIssuePayload },
    Jira.CreateIssuePayload // should be Jira.CreateIssueResponse and if error Jira.CreateIssueError
  >(`${RESOURCES_NAMES.ALERTS}/issues`, params, (_, newData) => ({
    data: newData
  }))

  return context
}
