import { Pie } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"

import type { PackagesInUseProps } from "@/pages/DashboardPage/hooks/useDashboard.types"

export const PiePackagesUseWidget = ({ response }: PackagesInUseProps) => {
  const { inUseTotal = 0, notInUseTotal = 0 } = response || {}
  const { t } = useTranslation("translation", {
    keyPrefix: "dashboard.packagesUseWidget"
  })
  const documentStyle = getComputedStyle(document.documentElement)
  const contentValue =
    notInUseTotal + inUseTotal > 0
      ? `${((100 * inUseTotal) / (notInUseTotal + inUseTotal)).toFixed(0)}%`
      : "0%"

  const configs = {
    appendPadding: 2,
    angleField: "value",
    colorField: "type",
    color: [
      documentStyle.getPropertyValue("--daybreak-blue-5-color"),
      documentStyle.getPropertyValue("--neutral-5-color")
    ],
    radius: 1,
    innerRadius: 0.82,
    legend: false as false,
    label: {
      type: "inner",
      offset: "-50%",
      content: ""
    },
    width: 150,
    height: 114,
    data: [
      {
        type: t("inUse"),
        value: inUseTotal
      },
      {
        type: t("notInUse"),
        value: notInUseTotal
      }
    ],
    statistic: {
      title: false as false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: documentStyle.getPropertyValue("--daybreak-blue-5-color"),
          fontSize: "24px",
          fontWeight: 500
        },
        content: contentValue
      }
    },
    tooltip: false as false
  }

  return <Pie {...configs} />
}
