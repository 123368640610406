import cn from "classnames"

import { Tag } from "@/atoms"

import styles from "./StatusTag.module.scss"

import type { Status } from "@/domain/user"

export const StatusTag = (t: (v: string) => string) => (status: Status) => (
  <Tag
    data-tooltip-content={
      status === "INVITED" ? "Pending user activation" : undefined
    }
    className={cn(
      styles.container,
      { [styles.accepted]: status === "ACCEPTED" },
      { [styles.declined]: status === "DECLINED" },
      { [styles.invited]: status === "INVITED" }
    )}
  >
    {t(`settings.users.${status}`)}
  </Tag>
)
