import {
  CONTAINER_LIST_CLASS,
  LIST_MENU_DROPDOWN_SMART_SEARCH_ID
} from "../../../const/classes.constants"
import { useMenuDropdownSmartSearch } from "../../../hooks/useMenuDropdownSmartSearch"
import { FooterItem } from "../../molecules/FooterItem"
import { HelperList } from "../../molecules/HelperItemList"
import { KeyList } from "../../molecules/KeyItemList"
import { ValueList } from "../../molecules/ValueItemList"

import styles from "./MenuDropdown.module.scss"

export const MenuDropdown = (props: any) => {
  const {
    ref,
    displayValues,
    valueClickedLastTime,
    createHandler,
    onFocus,
    onKeyDown
  } = useMenuDropdownSmartSearch(props)

  return (
    <div id="MenuDropdownSmartSearch" className={styles.container}>
      <ul
        id={LIST_MENU_DROPDOWN_SMART_SEARCH_ID}
        data-id-smart-search={LIST_MENU_DROPDOWN_SMART_SEARCH_ID}
        ref={ref}
        className={CONTAINER_LIST_CLASS}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        tabIndex={0}
      >
        {!displayValues && <KeyList createHandler={createHandler} {...props} />}
        {displayValues && (
          <HelperList createHandler={createHandler} {...props} />
        )}
        {displayValues && (
          <ValueList
            valueClickedLastTime={valueClickedLastTime}
            createHandler={createHandler}
            {...props}
          />
        )}
      </ul>
      <FooterItem refContainer={ref} {...props} />
    </div>
  )
}
