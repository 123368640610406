import { useRef } from "react"

import { SbomFilter as SbomFilterDiscoveryPage } from "@/pages/DiscoveryPage/components/organisms/SbomFilter"

import type { SbomFilterComponent } from "."

export const SbomFilter: SbomFilterComponent = (props) => {
  const initialState = useRef(props.params).current

  return <SbomFilterDiscoveryPage initialState={initialState} {...props} />
}
