import { Tooltip } from "@/atoms"

import type { WrapperLabelPrimaryProps } from "."

export const WrapperLabelPrimary = (props: WrapperLabelPrimaryProps) => {
  const { children, title } = props

  if (!title) return children

  return (
    <Tooltip placement="right" title={title}>
      {children}
    </Tooltip>
  )
}
