import { Skeleton } from "@/atoms"
import styles from "./IssueExpandableCell.module.scss"

export default function IssueDetailsSkeleton() {
  return (
    <div className={styles.skeletonContainer}>
      <Skeleton.Input size="large" block active />
      <Skeleton.Input size="large" block active />
      <Skeleton.Input size="large" block active />
    </div>
  )
}
