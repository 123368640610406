import { Text, Title } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { LinkExpired } from "@/icons"

import styles from "./ExpiredMessage.module.scss"

export const ExpiredMessage = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "auth.linkExpired"
  })

  return (
    <div className={styles.container}>
      <LinkExpired className={styles.icon} />
      <Title level={4} className={styles.title}>
        {t("title")}
      </Title>
      <Text>{t("text")}</Text>
    </div>
  )
}
