import { Navigate, Route, Routes } from "react-router-dom"

import { ANALYTIC_EVENTS } from "@/const/event.constants"
import { NAMES_ROUTES, PARAMS } from "@/const/routes.constants"
import { ComingSoonPage } from "@/pages/ComingSoonPage"
import { CIPage } from "./components/pages/CIPage"
import { ScmPage } from "./components/pages/ScmPage"
import { ScmPoliciesPage } from "./components/pages/ScmPoliciesPage"

export const GovernancePage = () => {
  const {
    SCM,
    ROOT,
    CI,
    SCAN_HISTORY,
    THREAT_DETECTION_POLICIES,
    AUTOMATION_HISTORY,
    AUTOMATION_POLICIES
  } = NAMES_ROUTES.GOVERNANCE

  return (
    <Routes>
      <Route
        path={`/${SCAN_HISTORY}`}
        element={<ComingSoonPage keyPage="scanHistory" />}
      />
      <Route
        path={`/${AUTOMATION_HISTORY}`}
        element={<ComingSoonPage keyPage="automationHistory" />}
      />
      <Route
        path={`/${AUTOMATION_POLICIES}`}
        element={<ComingSoonPage keyPage="automationPolicies" />}
      />
      <Route
        path={`/${THREAT_DETECTION_POLICIES}`}
        element={<ComingSoonPage keyPage="threatDetectionPolicies" />}
      />
      <Route path={`/${SCM}/:needle`} element={<ScmPage />} />
      <Route
        path={`/${SCM}/:${PARAMS.SUB_TYPE}/:${PARAMS.ID}`}
        element={
          <ScmPoliciesPage
            policyType="SCM"
            settingsRoute={NAMES_ROUTES.GOVERNANCE.SCM}
            analyticsEvents={ANALYTIC_EVENTS.SETTINGS.POLICIES.SCM}
          />
        }
      />
      <Route
        path={`/${SCM}/*`}
        element={<Navigate to={`/${ROOT}/${SCM}/protect`} />}
      />

      <Route path={`/${CI}/:needle`} element={<CIPage />} />
      <Route
        path={`/${CI}/:${PARAMS.SUB_TYPE}/:${PARAMS.ID}`}
        element={
          <ScmPoliciesPage
            policyType="CI"
            settingsRoute={NAMES_ROUTES.GOVERNANCE.CI}
            analyticsEvents={ANALYTIC_EVENTS.SETTINGS.POLICIES.CI}
          />
        }
      />
      <Route
        path={`/${CI}/*`}
        element={<Navigate to={`/${ROOT}/${CI}/protect`} />}
      />

      <Route path="/history" element={<Navigate to="/" />} />
      <Route path="/policies" element={<Navigate to="/" />} />
      <Route path="/*" element={<Navigate to={`/${ROOT}/${SCM}`} />} />
    </Routes>
  )
}
