import { Col, Divider, Title } from "@/atoms"
import { TEASERS } from "@/const/teaser.constants"
import { getPostureScoreSeverity } from "@/domain/score"
import { useTranslation } from "@/hooks/useTranslation"
import { StatisticDifference } from "@/molecules/StatisticDifference"
import { ScoreShield } from "../../atoms/ScoreShield"
import PostureScoreGraph from "../../molecules/PostureScoreGraph"
import CardDashboardTemplate from "../../templates/CardDashboardTemplate"

import styles from "./PostureScoreWidget.module.css"

import type { PostureScoreWidgetProps } from "./PostureScoreWidget.types"

const PostureScoreWidget = (props: PostureScoreWidgetProps) => {
  const { response, isLoading } = props
  const { data = [], score = 0, weekAgoIndex = -1 } = response || {}
  const { result } = getPostureScoreSeverity(score)
  const color = `var(--${result}-score)`
  const keyPrefix = "dashboard.postureScoreWidget"
  const { t } = useTranslation()
  const lastScore = data[0]?.postureScore
  const lastWeekScore = data[weekAgoIndex]?.postureScore
  const diffFromLastWeek = lastWeekScore && lastScore - lastWeekScore
  const isNaN = Number.isNaN(Number(diffFromLastWeek))

  return (
    <CardDashboardTemplate
      unavailable={!isLoading && data.length === 0}
      className={{ row: styles.container }}
      loading={isLoading}
      noData={data.length === 0}
      title={t(`${keyPrefix}.title`)}
      contentPopover={t(`${keyPrefix}.description`)}
      wrap={false}
      teasers={{
        dataReadiness: true,
        kortexInstalled: TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(t)
      }}
    >
      <Col flex="0 0 auto">
        <section className={styles.sectionScorePostureScoreWidget}>
          <ScoreShield color={color} score={score} />
          <Title level={3} className={styles.title} style={{ color }}>
            {t(result)}
          </Title>
          <StatisticDifference
            noTrend={isNaN}
            isNeutral={isNaN || diffFromLastWeek === 0}
            isPositive={diffFromLastWeek >= 0}
            title={t(`${keyPrefix}.differenceStatistic`)}
            value={isNaN ? "N/A" : Math.abs(diffFromLastWeek)}
          />
        </section>
      </Col>
      <Col flex="none">
        <Divider type="vertical" className={styles.dividerPostureScoreWidget} />
      </Col>
      <Col flex="auto" className={styles.sectionGraph}>
        <PostureScoreGraph data={data} />
      </Col>
    </CardDashboardTemplate>
  )
}

export default PostureScoreWidget
