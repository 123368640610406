import type { Handler, PropsHandler, Token } from "./token-handler.types"

export abstract class AbstractHandler implements Handler {
  private nextHandler?: Handler

  public setNext(handler: Handler): Handler {
    this.nextHandler = handler

    return handler
  }

  public handle(token: Token, props: PropsHandler): Token {
    if (this.nextHandler) {
      return this.nextHandler.handle(token, props)
    }

    return token
  }
}
