import cn from "classnames"

import { Collapse } from "@/atoms"

import styles from "./CollapseDrawer.module.scss"

import type { CollapseDrawerProps } from "."

export const CollapseDrawer = ({
  children,
  header,
  extra,
  classes = {},
  ...props
}: CollapseDrawerProps) => (
  <Collapse
    className={cn(styles.container, classes.container)}
    items={[
      {
        extra,
        key: props.defaultActiveKey || "defaultKey",
        label: header,
        children: children
      }
    ]}
    {...props}
  />
)
