import { BranchScanInfo, BranchScanStatus } from "@/api/useInventory.types"
import { Tag } from "@/atoms"
import { ScanStatusIcon } from "./ScanStatusIcon"

const STATUS_COLOR: Record<BranchScanStatus, string> = {
  Incomplete: "warning",
  Processing: "processing",
  Info: "processing",
  Done: "success"
}

const STATUS_DEFAULT_MESSAGE: Record<BranchScanStatus, string> = {
  Incomplete: "Incomplete",
  Processing: "Processing",
  Info: "Info",
  Done: "Complete"
}

export const CodeRepoScanStatusTag = ({ status, message }: BranchScanInfo) => {
  const color = STATUS_COLOR[status] ?? "default"
  const displayStatus = message ?? STATUS_DEFAULT_MESSAGE[status] ?? null
  return (
    <Tag color={color} icon={<ScanStatusIcon status={status} colored />}>
      {displayStatus}
    </Tag>
  )
}
