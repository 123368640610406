import {
  SelectionContext,
  useSelectionState
} from "@/pages/TriagePage/application/useSelection"

import type { SelectionProviderComponent } from "./SelectionProvider.types"

export const SelectionProvider: SelectionProviderComponent = ({ children }) => {
  const value = useSelectionState()

  return (
    <SelectionContext.Provider value={value}>
      {children}
    </SelectionContext.Provider>
  )
}
