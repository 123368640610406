import { useDeleteIntegration } from "@/api/useIntegrations"
import { Button, Collapse, Flex, Modal, Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import {
  DisconnectOutlined,
  ExclamationCircleOutlined,
  FolderOpenOutlined
} from "@/icons"

import styles from "./ConfigureTabGitLabIntegration.module.scss"

import type { ConfigureTabGitLabIntegrationComponent } from "."

export const ConfigureTabGitLabIntegration: ConfigureTabGitLabIntegrationComponent =
  ({ configuration, messageApi, refetchIntegration }) => {
    const { installations = [] } = configuration || {}
    const noGroups = !installations.length
    const remover = useDeleteIntegration({
      vendor: "gitlab"
    })
    const [modal, modalContextHolder] = Modal.useModal()
    const onDelete = async (needle: string) => {
      try {
        const result = (await remover.mutateAsync(needle)) as {
          data: boolean
          error: string
        }

        if (!result.data) throw new Error(result.error)

        return refetchIntegration()
      } catch (err: Error | any) {
        const { data } = err || {}
        const message = data?.error || data?.detail || "Error"

        messageApi.error(message)
      }
    }
    const { t } = useTranslation()
    const { t: tGitLab } = useTranslation("translation", {
      keyPrefix: "integrations.connect.gitlab"
    })

    return (
      <Flex vertical gap={16} className={styles.container}>
        <Text strong>GitLabs Groups</Text>
        {noGroups && <Text>{tGitLab("noGroup")}</Text>}
        {!noGroups && (
          <Collapse
            items={installations.map(({ groupName, projectsCount }: any) => ({
              label: (
                <Flex vertical>
                  <Text strong>{groupName}</Text>
                  <Text type="secondary">
                    <Flex gap={4} className={styles.description}>
                      <FolderOpenOutlined />
                      {`${projectsCount} ${tGitLab("project_connected", { count: projectsCount })}`}
                    </Flex>
                  </Text>
                </Flex>
              ),
              children: (
                <Button
                  danger
                  size="small"
                  icon={<DisconnectOutlined />}
                  onClick={() =>
                    modal.confirm({
                      icon: <ExclamationCircleOutlined />,
                      cancelText: t("general.cancel"),
                      content: tGitLab("deleteGuard"),
                      okText: t("general.disconnect"),
                      onOk: () => onDelete(groupName)
                    })
                  }
                >
                  {t("general.disconnect")}
                </Button>
              )
            }))}
          />
        )}
        {modalContextHolder}
      </Flex>
    )
  }
