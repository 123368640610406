import { useSearchParams } from "react-router-dom"
import { Error, type Params } from "./useIntegrationCallbackParams.types"

export const useIntegrationCallbackParams = () => {
  const [searchParams] = useSearchParams()

  const values: Record<Params, string> = {}

  searchParams.forEach((value, key) => {
    values[key] = value
  })

  let error: Error = { valid: true, invalidReason: undefined }
  if (!values.vendor) {
    error = { valid: false, invalidReason: "INVALID_PARAMS" }
  } else if (values.setup_action === "request") {
    error = { valid: false, invalidReason: "SETUP_ACTION_REQUEST" }
  }
  return { values, error }
}
