import type { TFunction } from "@/hooks/useTranslation"
import { Filters } from "@/organisms/SmartSearch/types/options.types"
import {
  getCVEFilter,
  getRuntimeFilter,
  getSeverityFilter,
  InventoryFilters
} from "@/pages/DiscoveryPage/utils/filters.utils"

export const getItemsFilter = (t: TFunction) => {
  return [
    {
      label: t("filters.firstSeen"),
      key: "daysAgo",
      type: "radio" as const,
      orientation: "vertical" as const,
      options: [
        {
          label: "Last 24 hours",
          value: "1",
          displayName: "First Seen (24 Hours)"
        },
        {
          label: "Last 7 days",
          value: "7",
          displayName: "First Seen (7 Days)"
        },
        {
          label: "Last 30 days",
          value: "30",
          displayName: "First Seen (30 Days)"
        },
        {
          label: "Last 90 days",
          value: "90",
          displayName: "First Seen (90 Days)"
        }
      ]
    }
  ]
}

export const getFilters = (t: TFunction): Filters => [
  InventoryFilters.application(t),
  InventoryFilters.repositoryName(t),
  InventoryFilters.imageName(t),
  InventoryFilters.environment(t),
  InventoryFilters.namespace(t),
  getRuntimeFilter(),
  getSeverityFilter(),
  getCVEFilter(),
  {
    key: "tag",
    label: t("filters.tag"),
    type: "input",
    helper: [
      {
        label: t("inventory.generalFilters.multipleValues", {
          label: "tag"
        }),
        description: t("inventory.generalFilters.multipleSubstring", {
          name: "tags"
        })
      }
    ]
  },
  {
    key: "os",
    label: t("filters.os"),
    type: "input",
    helper: [
      {
        label: t("inventory.generalFilters.multipleValues", {
          label: "os"
        }),
        description: t("inventory.generalFilters.multipleSubstring", {
          name: "operating systems"
        })
      }
    ]
  },
  {
    key: "publicImage",
    label: t("filters.publicImage"),
    type: "single",
    options: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "NO" }
    ]
  }
]
