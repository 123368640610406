import { Divider, Space } from "@/atoms"
import { LabelExpandedRow } from "@/molecules/LabelExpandedRow"
import { LinkRisk } from "@/molecules/LinkRisk"

import styles from "../IssueExpandableCell.module.scss"

import type { TFunction } from "@/hooks/useTranslation"
import type { IFindingsMetadata } from "../IssueExpandableCell.types"

export const FindingsMetadata: React.FC<
  IFindingsMetadata & { t: TFunction; tKey: string }
> = ({ issueType, cvss, epss, packageVersions, packageName, cwe, t, tKey }) => {
  return (
    <Space split={<Divider type="vertical" />}>
      <LabelExpandedRow
        label={`${t("general.type")}:`}
        value={`${t(`issueTypes.${issueType}`)}`}
      />
      <LabelExpandedRow label="CVSS:" value={`${cvss}`} />
      <LabelExpandedRow
        label="EPSS:"
        value={!isNaN(epss) ? `${(Number(epss) * 100).toFixed(2)}%` : "-"}
      />
      <LabelExpandedRow
        label="CWE:"
        value={
          <LinkRisk
            cve={cwe.description ? `${cwe.id}` : "-"}
            classes={{ text: styles.value }}
          />
        }
      />
      <LabelExpandedRow
        label={`${t(`${tKey}currentVersions`)}:`}
        value={
          packageVersions?.length
            ? `${packageName} ${packageVersions.join(", ")}`
            : "-"
        }
        classes={{ value: styles.currentVersions }}
      />
    </Space>
  )
}
