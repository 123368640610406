import { useParams } from "react-router-dom"

import { Button, Form, Input, Link } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { EyeInvisibleOutlined, UserOutlined } from "@/icons"
import { PasswordInput } from "../PasswordInput"

import styles from "./PasswordFormSignIn.module.scss"

export const PasswordFormSignIn = () => {
  const params = useParams()
  const { t } = useTranslation()

  return (
    <div>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: t("error.emailRequired") || ""
          },
          {
            type: "email",
            message: t("error.emailInvalid") || ""
          }
        ]}
      >
        <Input
          type="email"
          size="large"
          placeholder={t("general.email") || ""}
          placeholderAlign="center"
          prefix={<UserOutlined />}
          suffix={<EyeInvisibleOutlined className={styles.hide} />}
          data-testid="emailInputSignIn"
        />
      </Form.Item>

      <PasswordInput
        name="password"
        rules={[
          {
            required: true,
            message: t("error.passwordRequired") || "Required"
          }
        ]}
        placeholder={t("general.password") || ""}
      />

      <Form.Item className={styles.signInButtonFormItem}>
        <Button
          block
          type="ks"
          htmlType="submit"
          size="large"
          data-testid="buttonEmailPasswordSignIn"
        >
          {t("auth.signIn")}
        </Button>
      </Form.Item>
      <Link
        className={styles.forgotPassword}
        to={`/${params.slug}/forgot-password`}
      >
        Forgot password?
      </Link>
    </div>
  )
}

export default PasswordFormSignIn
