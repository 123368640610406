import mixpanelBrowser, { Config, Mixpanel } from "mixpanel-browser"
import { AbstractTransport } from "./Transport"

import type { Services } from "@/interfaces/services"

const kodemInternalDomain = "kodemsecurity.com"

class MixpanelTransport extends AbstractTransport {
  protected instance: undefined | Mixpanel
  protected requestIP: null | Promise<string> = null
  protected identified: boolean = false
  protected defaultMetaAttributes: Record<string, unknown> = {}

  constructor(
    token: string,
    mixpanelConfig: Partial<Config>,
    params: { level: Services.LoggerAdapter.LevelKeys },
    instance: Mixpanel = mixpanelBrowser
  ) {
    super(params)

    if (token) {
      mixpanelBrowser.init(token, mixpanelConfig)
      this.instance = instance
    }

    this.requestIP = this.getUserIP()
  }

  private async getUserIP() {
    try {
      const response = await fetch("https://api.ipify.org?format=json")
      const { ip } = await response.json()

      return ip
    } catch (error) {
      return null
    }
  }

  public setDefaultMetaAttributes(attributes: Record<string, string>) {
    this.defaultMetaAttributes = {
      ...this.defaultMetaAttributes,
      ...attributes
    }
  }

  public identify(data: Services.LoggerAdapter.IdentificationData) {
    if (this.instance && data.email && this.shouldTrack(data)) {
      this.instance.identify(data.email)
      this.instance.people.set({
        $email: data.email,
        $name: data.email
      })
      this.setDefaultMetaAttributes({ slug: data.company })

      this.identified = true
      this.requestIP?.then((ip: string | null) => {
        if (ip && this.instance) this.instance.people.set({ $ip: ip })
      })
    }
  }

  public reset() {
    if (this.instance) {
      this.identified = false
      this.defaultMetaAttributes = {}
      this.instance.reset()
    }
  }

  public log(info: Services.LoggerAdapter.Info) {
    const { message, meta } = info
    if (this.identified && typeof message === "string") {
      this.instance?.track(message, { ...this.defaultMetaAttributes, ...meta })
    }
  }

  private shouldTrack(data: Services.LoggerAdapter.IdentificationData) {
    return !data.email.endsWith(kodemInternalDomain)
  }
}

export { MixpanelTransport }
