import { Item, List } from "@/pages/DiscoveryPage/components/molecules/List"

import styles from "./SourceList.module.scss"

import type { IntroducedThrough } from "."

export const IntroducedTroughItem = ({
  introducedThrough
}: IntroducedThrough) => {
  return (
    <List
      classes={{ list: styles.itemList, button: styles.button }}
      items={introducedThrough}
      limit={2}
      render={(item, index) => (
        <Item
          key={index}
          title={(item || [])
            .map(({ name, version }) => (version ? `${name}:${version}` : name))
            .join(" > ")}
        />
      )}
    />
  )
}
