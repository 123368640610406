import { EXPLOITABILITY_KEY, ICONS_INSIGHTS } from "@/const/insights-constants"
import { InsightsIcons } from "@/molecules/InsightsIcons"
import { LinkRisk } from "@/molecules/LinkRisk"

import styles from "./LinkCVECell.module.scss"

import type { ExploitMaturity } from "@/domain/issue"

export const LinkCVECell = (
  cve: string,
  { exploitMaturity }: { exploitMaturity: ExploitMaturity }
) => (
  <div className={styles.container}>
    <LinkRisk cve={cve} classes={{ text: styles.text }} />
    <InsightsIcons
      hide
      isKeyTranslation
      items={[
        ICONS_INSIGHTS[EXPLOITABILITY_KEY].POC,
        ICONS_INSIGHTS[EXPLOITABILITY_KEY].IN_THE_WILD
      ]}
      values={[exploitMaturity === "POC", exploitMaturity === "IN_THE_WILD"]}
    />
  </div>
)
