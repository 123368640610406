import cn from "classnames"

import { Divider, Space, Text } from "@/atoms"
import { Heading } from "@/molecules/Heading"

import styles from "./HeadingCard.module.scss"

import type { HeadingCardProps } from "."

export const HeadingCard: React.FC<HeadingCardProps> = (props) => {
  const { title, isBeta, ...popover } = props
  const popoverProps =
    popover?.titlePopover || popover?.contentPopover
      ? {
          title: popover.titlePopover,
          content: popover.contentPopover,
          className: popover.classNamePopover,
          placement: popover.placementHeaderInfoPopover
        }
      : undefined

  return (
    <Space
      className={cn(styles.space, isBeta && styles.beta)}
      split={<Divider className={styles.divider} type="vertical" />}
    >
      <Heading level={5} title={title} popover={popoverProps} />
      {isBeta && <Text className={styles.tag}>Beta</Text>}
    </Space>
  )
}
