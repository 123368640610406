import { ConsoleTransport } from "./transports/Console.transport"
import { MixpanelTransport } from "./transports/Mixpanel.transport"
import { SentryTransport } from "./transports/Sentry.transport"

const transports = {
  Console: ConsoleTransport,
  Sentry: SentryTransport,
  Mixpanel: MixpanelTransport
}

export { Logger as default } from "./Logger"
export * from "./utils"
export { transports }
