import type { Filters, Option, OptionFilter } from "../types/options.types"

export const getFilterValueByLabel = (label: string, filter: OptionFilter) =>
  filter.options?.find((option) => option.label === label)?.value

export const createFilterOptions = (
  options: Filters,
  data: Record<string, (string | Option)[]> = {}
) => {
  try {
    return options
      .sort((a, b) => (a.label > b.label ? 1 : -1))
      .reduce((acc, option) => {
        const { key, type, options, order } = option

        if (type === "input") {
          acc.push(option)

          return acc
        }

        const nextOptions = (data[key] || options || []).map((option) => {
          if (typeof option === "string") return option

          const { label, ...rest } = option

          return {
            label:
              /\s/g.test(label) && !/^".*"$/im.test(label)
                ? `"${label}"`
                : label,
            ...rest
          }
        })

        acc.push(
          Object.assign({}, option, {
            options: order !== "none" ? nextOptions.sort() : nextOptions
          })
        )

        return acc
      }, [] as Filters)
  } catch (e) {
    return []
  }
}
