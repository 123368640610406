import { Result } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"

import { FrownOutlined, MehOutlined } from "@/icons"
import styles from "./LayoutErrorResult.module.scss"
import { LayoutErrorResultProps } from "./LayoutErrorResult.types"

export const LayoutErrorResult = (props: LayoutErrorResultProps) => {
  const { errorType } = props
  const { t } = useTranslation("translation", {
    keyPrefix: "layoutError"
  })
  const icon =
    errorType === "Forbidden" ? (
      <FrownOutlined className={styles.icon} />
    ) : (
      <MehOutlined className={styles.icon} />
    )
  return (
    <Result
      className={styles.container}
      icon={icon}
      title={<div className={styles.title}>{t(errorType.toLowerCase())}</div>}
    />
  )
}
