import { Popover, Space, Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { Descriptions } from "@/molecules/Descriptions"

import styles from "./BaseImageDetailsCell.module.scss"

import type { BaseImageDetailsProps } from "."
import { BulletedList } from "../BulletedList"

export const BaseImageDetailsCell: React.FC<BaseImageDetailsProps> = (
  props
) => {
  const { digest, items } = props
  const { t } = useTranslation()

  if (!items?.length) {
    return null
  }

  const [firstName, ...alternativeImagesNames] = items
  const isAlternativeImagesNames = !!alternativeImagesNames?.length

  return (
    <div className={styles.container}>
      <div className={styles.customDescription}>
        <Descriptions.Item copyable w={500} label={`${t("general.name")}:`}>
          {firstName}
        </Descriptions.Item>
        <Descriptions.Item
          copyable
          copyableText={digest}
          w={500}
          label={`${t("general.digest")}:`}
        >
          {digest?.substring(7, 19)}
        </Descriptions.Item>
      </div>
      {isAlternativeImagesNames && (
        <div>
          <Popover
            rootClassName={styles.popover}
            content={
              <div>
                <Descriptions.Item
                  w={500}
                  label={`${t(
                    "sbom.drawer.baseImageSection.alternativeNamesTitlePopover"
                  )}:`}
                />
                <BulletedList unstyled>
                  {(alternativeImagesNames || []).map((value, i) => (
                    <Text key={i} className={styles.text} copyable>
                      {value}
                    </Text>
                  ))}
                </BulletedList>
              </div>
            }
          >
            <Space className={styles.space}>
              <Text>{t("sbom.drawer.baseImageSection.alternativeNames")}</Text>
            </Space>
          </Popover>
        </div>
      )}
    </div>
  )
}
