import { Card, Link } from "@/atoms"
import { JiraSoftwareLogo } from "@/icons"
import { IconText } from "@/molecules/IconText"
import { ActionCollapse } from "./ActionCollapse"
import { ActionListCard } from "./ActionListCard"
import { ActionsCount } from "./ActionsCount"

import styles from "./Action.module.scss"

import type { ActionCardComponent } from "."

export const ActionCard: ActionCardComponent = ({ ticket, type }) => (
  <Link href={ticket.url} target="_blank" className={styles.link}>
    <Card
      className={
        type === "secondary"
          ? styles.actionCardSecondary
          : styles.actionCardPrimary
      }
    >
      <IconText
        Icon={JiraSoftwareLogo}
        children={<span className={styles.text}> {ticket.name} </span>}
        classes={{ container: styles.jiraInfo, icon: styles.icon }}
      />
    </Card>
  </Link>
)

ActionCard.List = ActionListCard
ActionCard.Collapse = ActionCollapse
ActionCard.Count = ActionsCount
