import { useEffect } from "react"
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom"

import { REDIRECT_URL_PARAM_AUTH } from "@/const/provider-auth.constants"
import { EXTERNAL_ROUTES, NAMES_ROUTES } from "@/const/routes.constants"
import { isAuthorizedUser } from "@/domain/user"
import { setPluginsDateHelpers } from "@/helpers/date.helpers"
import { useCheckApplicationUpdates } from "@/hooks/useCheckUpdates"
import { useLoggerOnNavigation } from "@/hooks/useLogger"
import { useServicesWithUser } from "@/hooks/useServicesWithUser"
import { useUser } from "@/hooks/useUser"
import { Drawers } from "@/organisms/Drawers"
import { SplashScreen } from "@/organisms/SplashScreen"
import { DashboardPage } from "@/pages/DashboardPage"
import { DeploymentRouts } from "@/pages/DeploymentsPage"
import { DiscoveryPage } from "@/pages/DiscoveryPage"
import { GovernancePage } from "@/pages/GovernancePage"
import { IntegrationsPage } from "@/pages/IntegrationsPage"
import { SettingsRouter } from "@/pages/SettingsPage"
import { AcsAuth } from "@/pages/SignInPage/components/organisms/AcsAuth"
import { ApiDocsAuth } from "@/pages/SignInPage/components/organisms/ApiDocsAuth"
import { ForgotPasswordForm } from "@/pages/SignInPage/components/organisms/ForgotPasswordForm"
import { OrganizationSlugForm } from "@/pages/SignInPage/components/organisms/OrganizationSlugForm"
import { ResetPasswordForm } from "@/pages/SignInPage/components/organisms/ResetPasswordForm"
import { SignInForm } from "@/pages/SignInPage/components/organisms/SignInForm"
import { WelcomeCard } from "@/pages/SignInPage/components/organisms/WelcomeCard"
import { SignInPageTemplate } from "@/pages/SignInPage/components/templates/SignInPageTemplate"
import { TenantSignupPage } from "@/pages/TenantSignupPage/TenantSignupPage"
import { TriagePage } from "@/pages/TriagePage"
import { LayoutTemplate } from "@/templates/LayoutTemplate"
import { useServices } from "./hooks/useServices"

const App = () => {
  const { user } = useUser()
  const { auth } = useServices()
  const [searchParams] = useSearchParams()
  const redirectToUrl = searchParams.get(REDIRECT_URL_PARAM_AUTH) || "/"

  useEffect(() => {
    setPluginsDateHelpers()
  }, [])

  useServicesWithUser()
  useLoggerOnNavigation()
  useCheckApplicationUpdates()

  const authorizedUser = isAuthorizedUser(user)

  const {
    userNeedVerifyPhoneNumberMultiFactor,
    userNeedVerificationCodeMultiFactor
  } = user
  const userNeedUseMultiFactor =
    userNeedVerifyPhoneNumberMultiFactor || userNeedVerificationCodeMultiFactor
  const showApplication = authorizedUser && !userNeedUseMultiFactor

  const { INTEGRATIONS, DISCOVERY, TRIAGE } = NAMES_ROUTES

  if (showApplication && redirectToUrl === EXTERNAL_ROUTES.API_DOCS)
    return (
      <Routes>
        <Route element={<SignInPageTemplate />}>
          <Route path="*" element={<ApiDocsAuth />} />
        </Route>
      </Routes>
    )

  return (
    <>
      <Routes>
        {!showApplication && (
          <Route element={<SignInPageTemplate />}>
            <Route
              path=":slug/forgot-password"
              element={<ForgotPasswordForm />}
            />
            <Route path=":slug/signin" element={<SignInForm user={user} />} />
            <Route path=":slug/acs" element={<AcsAuth />} />
            <Route path=":slug/invite" element={<WelcomeCard />} />
            <Route
              path=":slug/reset-password"
              element={<ResetPasswordForm />}
            />
            <Route path="/signup" element={<TenantSignupPage />} />
            <Route
              path={`/${INTEGRATIONS.ROOT}/${INTEGRATIONS.CALLBACK}`}
              element={<IntegrationsPage authorizedUser={authorizedUser} />}
            />
            <Route path="/*" element={<OrganizationSlugForm />} />
          </Route>
        )}
        {showApplication && (
          <Route element={<LayoutTemplate />}>
            <Route path="/" element={<DashboardPage />} />
            <Route path={`${DISCOVERY.ROOT}/*`} element={<DiscoveryPage />} />
            <Route
              path={`${TRIAGE.ROOT}/*`}
              element={<TriagePage isDemo={auth.isDemo()} />}
            />
            <Route path="governance/*" element={<GovernancePage />} />
            <Route path="deployments/*" element={<DeploymentRouts />} />
            <Route
              path={`${INTEGRATIONS.ROOT}/*`}
              element={<IntegrationsPage authorizedUser={authorizedUser} />}
            />
            <Route path="settings/*" element={<SettingsRouter />} />
            <Route
              path="/*"
              element={<Navigate to={redirectToUrl} replace />}
            />
          </Route>
        )}
      </Routes>
      {showApplication && <Drawers />}
      {showApplication && <SplashScreen />}
    </>
  )
}

export default App
