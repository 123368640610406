import { Divider } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { PackageManagerIcon } from "@/icons"
import React from "react"
import styles from "./PackageManagersList.module.scss"
import { PackageManagersProps } from "./PackageManagersProps.types"

export const PackageManagersList = ({
  packageManagers = []
}: PackageManagersProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "packageManagers" })

  return (
    <div className={styles.wrapper}>
      {!packageManagers?.length && "-"}
      {packageManagers.map((manager, index) => (
        <React.Fragment key={index}>
          <div className={styles.packageManagerPill}>
            <PackageManagerIcon packageManager={manager} />
            {t(manager)}
          </div>
          {index < packageManagers.length - 1 && <Divider type="vertical" />}
        </React.Fragment>
      ))}
    </div>
  )
}
