import cn from "classnames"

import { ItemProps } from "."
import styles from "./List.module.scss"

export const Item: React.FC<ItemProps> = ({
  title,
  value,
  suffix,
  classes = {},
  Component = "li"
}) => (
  <Component className={cn(styles.item, classes.item)}>
    <span className={classes.title}>{title}</span>
    {value && <span className={cn(styles.value, classes.value)}>{value}</span>}
    {suffix && <span>{suffix}</span>}
  </Component>
)
