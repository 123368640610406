import { getIssuesCountMapOrderedSeverity } from "@/domain/vulnerability"
import {
  DEFAULT_LONG_FORMAT,
  getDateInUserLocale,
  getTimestampInMillisecondsFromSecond
} from "@/helpers/date.helpers"

import type { VMResponse } from "@/api/useInventory.types"

export const getVirtualMachineTabData = (response: VMResponse) => {
  const { data = [], metadata } = response || {}
  return {
    data: data.map(({ id, data: entity }, index) => {
      const { autoScalingGroups, lastSeen, ...item } = entity

      return {
        id,
        key: id,
        autoScalingGroups: {
          id: `autoScalingGroups_${id}_${index}`,
          items: autoScalingGroups,
          label: autoScalingGroups[0] || "",
          count: autoScalingGroups.length - 1
        },
        lastSeen: getDateInUserLocale(
          new Date(getTimestampInMillisecondsFromSecond(lastSeen)),
          DEFAULT_LONG_FORMAT
        ),
        severity: getIssuesCountMapOrderedSeverity(item.issuesSummary as any),
        ...item
      }
    }),
    metadata
  }
}
