import type {
  Condition,
  ConditionsOptionsPoliciesResponse,
  PoliciesResponse,
  ScopeResponse
} from "@/api/usePolicies.types"

export const ALL_SCOPE = "all"

export const SPECIFIC_SCOPE = "specific"

export const getScopeOptionsScm = (response: ScopeResponse) => {
  const { data } = response || {}

  return (data || []).map(({ displayName, id, ...restScope }) => ({
    label: displayName,
    value: id,
    ...restScope
  }))
}

export const getPoliciesScm = (
  response: PoliciesResponse,
  scopeOptions: ReturnType<typeof getScopeOptionsScm> | undefined
) => {
  const { data, ...rest } = response

  return {
    data: (data || []).map((policy) => {
      const specificScopes = (scopeOptions || [])
        .filter(({ value }) => !!policy.scope[value])
        .map(({ value, label }) => ({
          name: value,
          value: policy.scope[value]?.split(","),
          label
        }))
      const currentScope = !specificScopes.length ? ALL_SCOPE : SPECIFIC_SCOPE
      const currentConditions = policy.conditions.map((condition) =>
        Object.keys(condition).map((key) => ({
          key,
          value: key,
          selectedValue: condition[key]
        }))
      )
      currentConditions.push([])

      if (currentConditions.length === 1) currentConditions.push([])

      return {
        key: policy.id,
        currentScope,
        specificScopes,
        currentConditions: currentConditions,
        ...policy
      }
    }),
    ...rest
  }
}

export const getConditionsOptions = (
  response: ConditionsOptionsPoliciesResponse
) => {
  const { data } = response
  const dataByFilterType = (data || []).reduce(
    (acc, { opts, ...condition }) => {
      if (!acc[condition.filterType]) acc[condition.filterType] = []
      acc[condition.filterType].push({
        opts: opts?.map((option) =>
          typeof option === "string"
            ? { displayName: option, value: option }
            : { displayName: option.displayName, value: option.id }
        ),
        ...condition
      })

      return acc
    },
    {} as Record<string, Condition[]>
  )

  return Object.keys(dataByFilterType).map((filterType) => ({
    displayName: filterType,
    options: dataByFilterType[filterType]
  }))
}
