import { SelectionProvider } from "@/pages/TriagePage/components/organisms/SelectionProvider"
import { Tab } from "./Tab"

import type { IssuesTabProps } from "./IssuesTab.types"

export const IssuesTab = (props: IssuesTabProps) => {
  return (
    <SelectionProvider>
      <Tab {...props} />
    </SelectionProvider>
  )
}
