import type { GitHubConfiguration } from "@/api/useIntegrations.types"
import { Checkbox, Collapse, Form, FormInstance, Title } from "@/atoms"
import { Typography } from "antd"
import { useEffect, useState } from "react"
import { IntegrationDetailsSkeleton } from "../../IntegrationDetailsSkeleton"

import styles from "./GitHubConfigurationForm.module.scss"

import { useTranslation } from "@/hooks/useTranslation"
import { CodeRepo } from "@/icons"
import type { Dispatch } from "react"

type Props = {
  isDebug?: boolean
  // installation is null when the component is loading
  configuration: GitHubConfiguration | undefined
  onApply: (configuration: GitHubConfiguration) => void
  form: FormInstance
  setFormDirty: Dispatch<boolean>
}

export const GitHubConfigurationForm: React.FC<Props> = ({
  isDebug = false,
  configuration = null,
  form,
  onApply,
  setFormDirty
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "integrations.configuration.GitHub"
  })
  const [currentConfigurations, setCurrentConfigurations] =
    useState<GitHubConfiguration | null>(configuration)
  const values = Form.useWatch(["installations"], form)

  useEffect(() => {
    setCurrentConfigurations(configuration)
  }, [configuration])

  if (currentConfigurations === null) {
    return <IntegrationDetailsSkeleton />
  }

  const { installations = [] } = currentConfigurations

  return (
    <>
      <Title className={styles.title} level={5}>
        {t("configurationTitle")}
      </Title>
      <Form
        form={form}
        initialValues={{
          installations
        }}
        onChange={() => {
          setFormDirty(true)
        }}
        onFinish={(values) => {
          onApply(values as GitHubConfiguration)
        }}
      >
        {installations.length > 0 ? (
          <Form.List name="installations">
            {(fields) => (
              <div>
                {fields.map((field, index) => {
                  const installation = installations[field.name]
                  const vulnerabilityReportsEnabled =
                    values?.[field.name].vulnerabilityReportsEnabled
                  const fieldKey = [
                    "installations",
                    field.name,
                    "vulnerabilityReportsEnabled"
                  ]
                  return (
                    <Form.Item key={field.key} className={styles.formItem}>
                      <Collapse
                        activeKey="disabled" // remove this line for enabling collapse
                        className={styles.collapse}
                        defaultActiveKey={fields.length === 1 ? [index] : []} // Add this line
                        items={[
                          {
                            label: (
                              <div className={styles.labelContainer}>
                                <div className={styles.titleWrap}>
                                  <span>{installation.organizationName}</span>
                                  <span className={styles.description}>
                                    <span className={styles.codeRepo}>
                                      <CodeRepo />
                                    </span>
                                    {t("reposConnected", {
                                      count: installation.repoCount
                                    })}
                                  </span>
                                </div>
                                <a
                                  href={`https://github.com/apps/kodem-security/installations/${installation.installationId}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={styles.link}
                                >
                                  {t("configureInGithub")}
                                </a>
                              </div>
                            ),
                            children: (
                              <div className={styles.dropDown}>
                                <div>
                                  <Checkbox
                                    className={styles.dropDown}
                                    checked={vulnerabilityReportsEnabled}
                                    onChange={() =>
                                      form.setFieldValue(
                                        fieldKey,
                                        !vulnerabilityReportsEnabled
                                      )
                                    }
                                  >
                                    {t("enablePullRequestReport")}
                                  </Checkbox>
                                </div>
                                <div className={styles.description}>
                                  {t("enablePullRequestReportDescription")}
                                </div>
                              </div>
                            )
                          }
                        ]}
                      />
                    </Form.Item>
                  )
                })}
              </div>
            )}
          </Form.List>
        ) : (
          <div>{t("noInstallations")}</div>
        )}
        {isDebug && (
          <Form.Item noStyle shouldUpdate>
            {() => (
              <Typography>
                <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
              </Typography>
            )}
          </Form.Item>
        )}
      </Form>
    </>
  )
}
