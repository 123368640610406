export const getScriptById = (id: string) => document.querySelector(`#${id}`)

export const removeScript = (id: string) => {
  const script = getScriptById(id)

  if (script) {
    script.remove()
  }
}

export const isScriptExist = (id: string) => !!getScriptById(id)

export const addScript = (
  id: string,
  { src, defer, async }: { src: string; defer?: boolean; async?: boolean },
  onLoad: () => void
) => {
  if (isScriptExist(id)) {
    return onLoad()
  }

  const scriptElement = document.createElement("script")

  scriptElement.id = id
  scriptElement.src = src
  scriptElement.defer = !!defer
  scriptElement.async = !!async
  scriptElement.onload = onLoad

  const containerElement =
    document.getElementsByTagName("head")[0] || document.body

  containerElement.appendChild(scriptElement)
}
