import { message } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import useServices from "@/hooks/useServices"
import { Loader } from "@/pages/SignInPage/components/molecules/Loader/Loader"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

export const AcsAuth = () => {
  const { slug } = useParams()
  const [searchParams] = useSearchParams()
  const { auth } = useServices()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { logger, EVENTS } = useLogger()

  useEffect(() => {
    const popupErrorValue = "popupError"
    const onError = () => {
      logger.error("SAML ACS Auth Error", {
        tags: {
          custom_error: EVENTS.ERROR_EVENTS.SIGN_IN,
          provider: "saml"
        }
      })
      message.error(t("auth.error.signin"))
      navigate(`/${slug}/signin`, { replace: true })
    }

    const isModal = window.opener && window.opener !== window
    const fn = async () => {
      try {
        const token = searchParams.get("token")
        const firebaseTenantId = searchParams.get("firebaseTenantId")
        const isPopupError = searchParams.get("error") === popupErrorValue
        const isParamsValid = slug && token && firebaseTenantId

        if (!isParamsValid || isPopupError) {
          return onError()
        }

        auth.setCompany(slug)
        auth.setTenantId(firebaseTenantId)
        await auth.signInWithToken(token)

        if (isModal) window.opener.location.replace(window.location.href)
      } catch (err) {
        if (isModal)
          return window.opener.location.replace(
            `${window.location.href}&error=${popupErrorValue}`
          )
        onError()
      } finally {
        if (isModal) window.close()
      }
    }
    fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, slug])

  return <Loader />
}
