import { Tag } from "@/atoms"
import { getLabelStyle } from "@/domain/label"

import styles from "./LabelTag.module.scss"

import type { ClosableTagComponent } from "./LabelTag.types"

export const ClosableTag: ClosableTagComponent = (props) => {
  const { id, name, color } = props

  return (
    <Tag key={id} style={getLabelStyle(color)} className={styles.tagComponent}>
      {name}
    </Tag>
  )
}
