import { TEASERS } from "@/const/teaser.constants"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import HighRiskPackagesGraph from "../../molecules/HighRiskPackagesGraph"
import CardDashboardTemplate from "../../templates/CardDashboardTemplate"

import styles from "./PackagesOverTime.module.scss"

import type { PackagesOverTimeProps } from "."

const PackagesOverTime = ({ isLoading, ...props }: PackagesOverTimeProps) => {
  const keyPrefix = "dashboard.packagesOverTimeWidget"
  const { response } = props
  const { data = [] } = response || {}
  const { t } = useTranslation()
  const dataReadinessFF = useFeatureFlags("dataReadiness")

  return (
    <CardDashboardTemplate
      unavailable={!isLoading && data.length === 0}
      loading={isLoading}
      noData={data.length === 0}
      title={t(`${keyPrefix}.title`)}
      wrap={false}
      teasers={{
        dataReadiness: true,
        kortexInstalled: TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(t)
      }}
    >
      <div className={styles.container}>
        {!dataReadinessFF && <HighRiskPackagesGraph {...props} />}
      </div>
    </CardDashboardTemplate>
  )
}

export default PackagesOverTime
