export const capitalizeFirstLetter = (str: string) => {
  try {
    return str.charAt(0).toUpperCase() + str.slice(1)
  } catch {
    return str
  }
}

export const capitalize = (str: string) => {
  try {
    return str.replace(/(^|[^a-zA-Z])([a-z])/g, (match) => match.toUpperCase())
  } catch {
    return str
  }
}

export const replaceLinkInString = (str: string) =>
  str.replace(
    /(\b(https):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim,
    '<a href="$1" target="_blank">$1</a>'
  )

export const sanitizeString = (str: string) => {
  const SYMBOLS = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;"
  } as Record<string, string>

  const reg = /&(?!(amp|lt|gt|quot|#x27|#x2F);)|[<>"'/]/gi

  return str.replace(reg, (match) => SYMBOLS[match])
}

export const sliceConcat = (str: string | undefined, length: number) => {
  if (!str) {
    return ""
  }
  if (str.length > length) {
    return str.slice(0, length) + "..."
  }
  return str
}
