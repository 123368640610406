import { useState } from "react"

import {
  useDeleteUsers,
  useGetUsers,
  useInviteUsers,
  useResetPasswordUsers,
  useUpdateMembership
} from "@/api/useUsers"
import { useQueryString } from "@/hooks/useQueryString"
import { getUsersData } from "./useUsers.selector"
import {
  EditUserRequestPayload,
  InviteUserRequestPayload
} from "./useUsersSettings.types"

export const useUsers = (
  paramKeys?: { key: string }[],
  config?: { enabled: boolean }
) => {
  const [loading, setLoading] = useState(false)
  const params = useQueryString(paramKeys)
  const paramsOrder = useQueryString(["orderBy", "ascending"])
  const context = useGetUsers(
    { ...params, ...paramsOrder },
    {
      select: getUsersData,
      ...(config || {})
    }
  )

  const { mutateAsync: mutateSendInviteByEmail } = useInviteUsers()
  const { mutateAsync: mutateResetPassword } = useResetPasswordUsers()
  const { mutateAsync: mutateDeleteUser } = useDeleteUsers()
  const { mutateAsync: mutateUpdateMembership } = useUpdateMembership()

  const wrapFn =
    (fn: any) =>
    async (...args: any) => {
      try {
        setLoading(true)

        await fn(...args)

        return { err: null }
      } catch (err: any) {
        return { err: true, data: err?.data }
      } finally {
        setLoading(false)
      }
    }

  const sendInviteByEmail = wrapFn(
    async (payload: InviteUserRequestPayload) => {
      await mutateSendInviteByEmail(payload)
      await context.refetch()
    }
  )

  const resetPassword = wrapFn(async (email: string) => {
    await mutateResetPassword({ user_email: email })
  })

  const updateMembership = wrapFn(
    async (id: string, payload: EditUserRequestPayload) => {
      await mutateUpdateMembership({ needle: `/${id}`, data: payload })
      await context.refetch()
    }
  )

  const deleteUser = wrapFn(async (userId: string) => {
    await mutateDeleteUser(userId)
    await context.refetch()
  })

  return [
    context,
    { loading, deleteUser, resetPassword, sendInviteByEmail, updateMembership }
  ] as const
}
