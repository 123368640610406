import { isE2E } from "../../config"
import { AuthAdapter as Adapter } from "./auth-adapter"

let AuthAdapter = Adapter

if (isE2E) {
  AuthAdapter = require("./auth-adapter.mock").AuthAdapter
}

export type { InitialParamsAuthAdapter } from "./auth-adapter"
export { AuthAdapter }
