import {
  useGetArchivedRepositoriesCount,
  useGetInventoryBranch
} from "@/api/useInventory"
import { useQueryString } from "@/hooks/useQueryString"
import { getBranchTabData } from "./useBranchInventory.selector"

export const useBranch = (
  paramKeys?: { key: string }[],
  config?: Record<string, unknown>
) => {
  const params = useQueryString(["orderBy", "ascending", ...(paramKeys || [])])
  const context = useGetInventoryBranch(params, {
    select: getBranchTabData,
    ...config
  })

  return context
}

export const useBranchArchivedRepos = () => {
  const context = useGetArchivedRepositoriesCount()
  return context
}
