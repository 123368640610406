import { EXPLOITABILITY_KEY, ICONS_INSIGHTS } from "@/const/insights-constants"

import type { Item } from "@/molecules/InsightsIcons"

export const getExploitMaturitiesValues = (values: string[]) =>
  (values || []).reduce(
    (acc, curr) => {
      acc[`${EXPLOITABILITY_KEY}.${curr}`] = true

      return acc
    },
    {} as Record<string, boolean>
  )

export const getBooleanValuesFromInsights = ({
  internetFacing,
  imageAccessPermission,
  ...values
}: {
  internetFacing?: "Yes" | "YES" | "No" | "NO" | null
  imageAccessPermission?: "public" | "private"
}): Record<string, boolean> => ({
  internetFacing: internetFacing?.toLowerCase() === "yes",
  publicImage: imageAccessPermission === "public",
  ...values
})

export const withInternetFacing = (
  insights: Item[],
  internetFacing?: boolean
) => {
  if (internetFacing) insights.splice(1, 0, ICONS_INSIGHTS.internetFacing)

  return insights
}
