import { Button, Radio, Text } from "@/atoms"
import cn from "classnames"

import styles from "./InsightRadio.module.scss"

import type { InsightRadioProps } from "."

export const InsightRadio = ({
  name,
  title,
  Icon,
  value,
  options,
  onChange
}: InsightRadioProps) => {
  const currentOptions = options.map(({ label, displayLabel, ...attr }) => ({
    label: displayLabel || label,
    ...attr
  }))

  return (
    <div className={styles.container}>
      <Text className={styles.text}>
        <Icon />
        {title}
      </Text>
      <div className={styles.buttonContainer}>
        <Button
          className={cn(styles.clearButton, !value && styles.hide)}
          type="text"
          onClick={() => onChange({ [name]: null })}
        >
          Clear
        </Button>
        <Radio.Group
          className={styles.radioGroup}
          name={name}
          size="small"
          options={currentOptions}
          onChange={({ target }) => {
            onChange({ [name]: target.value })
          }}
          optionType="button"
          buttonStyle="solid"
          value={value}
        />
      </div>
    </div>
  )
}
