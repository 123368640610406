import { useEffect, useState } from "react"

import { usePrevious } from "@/hooks/usePrevious"
import { CollapseDrawer } from "@/organisms/Drawers/components/CollapseDrawer"
import { ActionListCard } from "./ActionListCard"
import { ActionsCount } from "./ActionsCount"

import { Heading } from "@/molecules/Heading"
import type { ActionCollapseComponent } from "."

export const ActionCollapse: ActionCollapseComponent = ({
  activeKey,
  tickets
}) => {
  //currently we only support jira tickets actions
  const count = tickets?.length
  const [currentActiveKey, setCurrentActiveKey] = useState(activeKey)
  const prevCount = usePrevious(count)

  useEffect(() => {
    if (!count || !prevCount) return

    if (count > prevCount) setCurrentActiveKey(`${count}`)
  }, [prevCount, count])

  if (!count) return null

  return (
    <CollapseDrawer
      key={currentActiveKey}
      defaultActiveKey={currentActiveKey}
      header={<Heading level={6} title="Actions" />}
      extra={<ActionsCount count={count} />}
    >
      <ActionListCard tickets={tickets} />
    </CollapseDrawer>
  )
}
