import cn from "classnames"

import { useDocumentationToken } from "@/api/useAuth"
import { Button, Dropdown, Tooltip } from "@/atoms"
import { EXTERNAL_ROUTES } from "@/const/routes.constants"
import { getColorNumberForUserAvatar } from "@/domain/user"
import { useLogger } from "@/hooks/useLogger"
import { useUser } from "@/hooks/useUser"
import { ReadOutlined, SettingOutlined } from "@/icons"
import { useKeyByParams } from "../SideNavigation/hooks/useKeyByParams"
import { getNavMenu } from "./NavMenu"
import { getUserMenu } from "./UserMenu"

import styles from "./MenuHeader.module.scss"

export const MenuHeader = () => {
  const { user, signOut } = useUser()
  const currentKey = useKeyByParams()
  const { isActive, ...navMenu } = getNavMenu(currentKey)
  const { refetch, isRefetching } = useDocumentationToken({ enabled: false })
  const { logger, EVENTS } = useLogger()

  if (!user.email) {
    return <div className={cn(styles.container, styles.noUser)} />
  }

  const style = {
    backgroundColor: `var(--color-${getColorNumberForUserAvatar(user.email)}-avatar-palette)`,
    color: "var(--neutral-10-color)",
    fontSize: "14px"
  }

  return (
    <div className={styles.container}>
      <Tooltip title="API Documentation">
        <Button
          loading={isRefetching}
          className={styles.buttonMenu}
          icon={<ReadOutlined />}
          type="text"
          onClick={async () => {
            try {
              logger.info(EVENTS.ANALYTIC_EVENTS.OPEN_API_DOCUMENTATION)

              const { data: response } = await refetch()
              const token = response?.data?.token

              if (token)
                window.open(
                  `${EXTERNAL_ROUTES.API_DOCS}?${EXTERNAL_ROUTES.PARAMS.JWT_TOKEN}=${token}`,
                  "_blank"
                )

              if (!token)
                logger.error("API Documentation - token is not available")
            } catch (err: any) {
              logger.error(err)
            }
          }}
        />
      </Tooltip>
      <Dropdown rootClassName={cn(styles.root, styles.navMenu)} menu={navMenu}>
        <Button
          className={cn(styles.buttonMenu, isActive && styles.activeButtonMenu)}
          icon={<SettingOutlined />}
          type="text"
        />
      </Dropdown>
      <div className={styles.userItemContainer}>
        <Dropdown rootClassName={styles.root} menu={getUserMenu(user, signOut)}>
          <span style={style} className={styles.userItem}>
            {user.initials}
          </span>
        </Dropdown>
      </div>
    </div>
  )
}
