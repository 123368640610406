import cn from "classnames"
import { Fragment } from "react"

import { Popover } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { IconTag } from "@/molecules/IconTag"
import { ContentDismissedTag, IconDismissedTag } from "."

import styles from "./DismissedTag.module.scss"

import type { DismissedTagComponent } from "."

export const DismissedTag: DismissedTagComponent = (props) => {
  const { classes = {}, ...contentProps } = props
  const { email } = contentProps
  const WrapComponent = email ? Popover : Fragment
  const propsWrapComponent = email
    ? {
        destroyTooltipOnHide: true,
        rootClassName: styles.rootClassName,
        content: <ContentDismissedTag placement="popover" {...contentProps} />
      }
    : {}
  const { t } = useTranslation()

  return (
    <WrapComponent {...propsWrapComponent}>
      <span>
        <IconTag
          iconSize="sm"
          classes={{ tag: cn(styles.tag, classes.tag) }}
          icon={<IconDismissedTag />}
          text={t("general.dismissed")}
        />
      </span>
    </WrapComponent>
  )
}
