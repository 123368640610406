import cn from "classnames"
import { useEffect, useState } from "react"

import { Button } from "@/atoms"
import { useResizeObserver } from "@/hooks/useResizeObserver"
import { CloseCircleFilled, SearchOutlined } from "@/icons"
import {
  resetStyleForMinimizeSpanList,
  setStyleForMinimizeSpanList
} from "./helpers"

import styles from "./InputSmartSearch.module.scss"

export const InputSmartSearch = ({
  refInput,
  open,
  html,
  placeholder,
  showPlaceholder,
  handlers,
  disabled
}: any) => {
  const [state, setState] = useState({
    countsHiddenElements: 0,
    resize: 0
  })
  const { countsHiddenElements, resize } = state
  const editableDivID = "input-smart-search"
  const rerender = () =>
    setState((prevState) => ({ ...prevState, resize: Date.now() }))

  useEffect(() => {
    if (open || !!resize) resetStyleForMinimizeSpanList(refInput)
  }, [resize, open, refInput])

  useResizeObserver(refInput, rerender)

  useEffect(() => {
    if (open) return

    const { countsHiddenElements } = setStyleForMinimizeSpanList(refInput)
    const { clientWidth, clientHeight } = refInput?.current || {}
    const isDisplayNone = !clientWidth && !clientHeight

    if (isDisplayNone) return

    setState((prevState) => ({ ...prevState, countsHiddenElements }))
  }, [open, html, countsHiddenElements, resize, refInput])

  useEffect(() => {
    const event = "paste"
    const listener = (event: ClipboardEvent) => {
      const text = event.clipboardData?.getData("text/plain")

      if (!text) event.preventDefault()
    }

    document.getElementById(editableDivID)?.addEventListener(event, listener)

    return () => {
      document
        .getElementById(editableDivID)
        ?.removeEventListener(event, listener)
    }
  }, [])

  return (
    <div
      className={cn(
        styles.container,
        open && styles.focus,
        disabled && styles.disabled
      )}
    >
      <div
        id={editableDivID}
        data-id-smart-search={editableDivID}
        data-placeholder={showPlaceholder ? placeholder : undefined}
        ref={refInput}
        contentEditable={!disabled}
        suppressContentEditableWarning
        spellCheck={false}
        className={cn(
          styles.input,
          showPlaceholder && styles.placeholder,
          open && styles.open
        )}
        dangerouslySetInnerHTML={{
          __html: html
        }}
        onKeyUp={handlers.onKeyUp}
        onKeyDown={handlers.onKeyDown}
        onInput={handlers.onInput}
        onFocus={handlers.onFocus}
        onClick={handlers.onClick}
        onDoubleClick={(event) => {
          const target = event.target as HTMLDivElement

          if (target) window.getSelection()?.selectAllChildren(target)
        }}
      />
      {!!countsHiddenElements && !open && (
        <div className={styles.count}>
          <span>{`+${countsHiddenElements}`}</span>
        </div>
      )}
      {!showPlaceholder && (
        <div className={cn(styles.iconClear, "clear-button-smart-search")}>
          <Button
            type="text"
            icon={<CloseCircleFilled />}
            onClick={handlers.onClear}
          />
        </div>
      )}
      <div className={cn(styles.iconSearch, "search-button-smart-search")}>
        <Button
          disabled={disabled}
          type="text"
          icon={<SearchOutlined />}
          onClick={() => {
            const kbEvent = new KeyboardEvent("keydown", {
              bubbles: true,
              cancelable: true,
              key: "Enter"
            })

            refInput.current?.dispatchEvent(kbEvent)
          }}
        />
      </div>
    </div>
  )
}

export const InputSmartSearchLoading = ({
  placeholder
}: {
  placeholder?: string
}) => (
  <InputSmartSearch
    disabled
    showPlaceholder
    placeholder={placeholder}
    handlers={{}}
  />
)
