import { AttackChain } from "./useTriage.types"

export const mockData = {
  cbec51a0d2680b4acbb20a41e2c35bd2: {
    riskId: "CVE-2023-44487",
    package_name: "stdlib",
    issueId: "cbec51a0d2680b4acbb20a41e2c35bd2",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains initial access via a vulnerable SUID binary.",
            technique: "T1078",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites: "Presence of a vulnerable SUID binary.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes the SUID binary to set dumpable flag to 1.",
            technique: "T1059",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites: "Initial access via vulnerable SUID binary.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker crashes the descendant process to create a core dump in a root-owned directory.",
            technique: "T1068",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites:
              "Execution of SUID binary and setting dumpable flag to 1.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Impact",
            details:
              "Attacker uses core dump to escalate privileges and gain root access.",
            technique: "T1499",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites: "Core dump created in root-owned directory.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Impact",
            details:
              "Attacker exploits HTTP/2 protocol to cause denial of service.",
            technique: "T1499",
            riskId: "CVE-2023-44487",
            package_name: "stdlib",
            prerequisites: "Root access to the system.",
            metadata: {
              kodemScore: 853,
              riskId: "CVE-2023-44487",
              severity: "high",
              isRuntime: true,
              issueId: "cbec51a0d2680b4acbb20a41e2c35bd2",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "IN_THE_WILD"
            }
          }
        ],
        details:
          "Attacker gains root access and causes DoS via HTTP/2 vulnerability.",
        title: "DoS via Root Access",
        is_severe: false,
        kodemScore: 2537,
        scenario_id: 3
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits CVE-2021-36368 to gain unauthorized access via OpenSSH client.",
            technique: "T1078",
            riskId: "CVE-2021-36368",
            package_name: "openssh-client",
            prerequisites: "Attacker has network access to the target system.",
            metadata: {
              kodemScore: 247,
              riskId: "CVE-2021-36368",
              severity: "low",
              isRuntime: false,
              issueId: "0c37e77ac2ba5368c7639017aa4a395b",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes commands to interact with the system and gather information.",
            technique: "T1059",
            prerequisites: "Initial access gained via CVE-2021-36368.",
            metadata: null
          },
          {
            title: "Credential Access",
            details:
              "Attacker exploits CVE-2023-4641 to retrieve passwords from memory buffer.",
            technique: "T1003",
            riskId: "CVE-2023-4641",
            package_name: "passwd",
            prerequisites:
              "Attacker has access to the system and can execute commands.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-4641",
              severity: "medium",
              isRuntime: false,
              issueId: "8efbf06750f04a3f42de8a00430df5d3",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Impact",
            details:
              "Attacker exploits CVE-2023-44487 to cause a denial of service via HTTP/2.",
            technique: "T1499",
            riskId: "CVE-2023-44487",
            package_name: "stdlib",
            prerequisites:
              "Attacker has access to the system and can send HTTP/2 requests.",
            metadata: {
              kodemScore: 853,
              riskId: "CVE-2023-44487",
              severity: "high",
              isRuntime: true,
              issueId: "cbec51a0d2680b4acbb20a41e2c35bd2",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "IN_THE_WILD"
            }
          }
        ],
        details:
          "Attacker gains access, retrieves credentials, and causes DoS.",
        is_severe: false,
        title: "Credential Breach",
        kodemScore: 1442,
        scenario_id: 4
      }
    ]
  },
  "8c5628d382b46f3515b812594f39808e": {
    riskId: "CVE-2023-29404",
    package_name: "stdlib",
    issueId: "8c5628d382b46f3515b812594f39808e",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains initial access by exploiting CVE-2023-51385 in OpenSSH client.",
            technique: "T1078",
            riskId: "CVE-2023-51385",
            package_name: "openssh-client",
            prerequisites:
              "User connects to a malicious Git repository with a submodule containing shell metacharacters.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2023-51385",
              severity: "medium",
              isRuntime: false,
              issueId: "e27b5b6f261713eba12cedb8962b56ff",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes arbitrary code at build time using CVE-2023-29404 in stdlib.",
            technique: "T1059",
            riskId: "CVE-2023-29404",
            package_name: "stdlib",
            prerequisites:
              "Initial access gained and ability to run 'go get' on malicious module.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-29404",
              severity: "critical",
              isRuntime: true,
              issueId: "8c5628d382b46f3515b812594f39808e",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details: "Attacker installs a backdoor to maintain access.",
            technique: "T1547",
            prerequisites: "Arbitrary code execution achieved.",
            metadata: null
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker escalates privileges by exploiting CVE-2023-34152 in ImageMagick.",
            technique: "T1068",
            riskId: "CVE-2023-34152",
            package_name: "imagemagick",
            prerequisites: "Backdoor installed and access to ImageMagick.",
            metadata: {
              kodemScore: 568,
              riskId: "CVE-2023-34152",
              severity: "critical",
              isRuntime: false,
              issueId: "3b8156b664855394d558be3522abc2dd",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "T1070",
            prerequisites: "Privileges escalated.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data from the compromised system.",
            technique: "T1041",
            prerequisites: "Logs cleared to avoid detection.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes code, escalates privileges, and exfiltrates data.",
        title: "Privilege Escalation",
        is_severe: false,
        kodemScore: 1793,
        scenario_id: 3
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains initial access by exploiting CVE-2021-36368 in OpenSSH client.",
            technique: "T1078",
            riskId: "CVE-2021-36368",
            package_name: "openssh-client",
            prerequisites:
              "Attacker has access to a vulnerable OpenSSH client.",
            metadata: {
              kodemScore: 247,
              riskId: "CVE-2021-36368",
              severity: "low",
              isRuntime: false,
              issueId: "0c37e77ac2ba5368c7639017aa4a395b",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes arbitrary code by exploiting CVE-2023-29404 in stdlib during build time.",
            technique: "T1059",
            riskId: "CVE-2023-29404",
            package_name: "stdlib",
            prerequisites:
              "Attacker has initial access and can run 'go get' on malicious module.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-29404",
              severity: "critical",
              isRuntime: true,
              issueId: "8c5628d382b46f3515b812594f39808e",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker retrieves password from memory by exploiting CVE-2023-4641 in shadow-utils.",
            technique: "T1003",
            riskId: "CVE-2023-4641",
            package_name: "passwd",
            prerequisites:
              "Attacker has executed arbitrary code and has access to memory.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-4641",
              severity: "medium",
              isRuntime: false,
              issueId: "8efbf06750f04a3f42de8a00430df5d3",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker installs a backdoor to maintain access to the compromised system.",
            technique: "T1547",
            prerequisites:
              "Attacker has retrieved credentials and has access to the system.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "T1070",
            prerequisites:
              "Attacker has installed a backdoor and has access to the system.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes code, retrieves credentials, installs backdoor, and evades detection.",
        title: "Credential Theft",
        is_severe: false,
        kodemScore: 1420,
        scenario_id: 5
      }
    ]
  },
  adc5d8a2673bd682e20d9cd6c3e7cb46: {
    riskId: "CVE-2023-29405",
    package_name: "stdlib",
    issueId: "adc5d8a2673bd682e20d9cd6c3e7cb46",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains access by exploiting CVE-2023-51385 in OpenSSH client.",
            technique: "T1078",
            riskId: "CVE-2023-51385",
            package_name: "openssh-client",
            prerequisites:
              "User connects to a malicious Git repository with a submodule containing shell metacharacters.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2023-51385",
              severity: "medium",
              isRuntime: false,
              issueId: "e27b5b6f261713eba12cedb8962b56ff",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes arbitrary code by exploiting CVE-2023-29405 in stdlib during build time.",
            technique: "T1059",
            riskId: "CVE-2023-29405",
            package_name: "stdlib",
            prerequisites:
              "Attacker has initial access and can run 'go get' on a malicious module.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-29405",
              severity: "critical",
              isRuntime: true,
              issueId: "adc5d8a2673bd682e20d9cd6c3e7cb46",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details: "Attacker installs a backdoor to maintain access.",
            technique: "T1547",
            prerequisites:
              "Attacker has executed arbitrary code on the system.",
            metadata: null
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits CVE-2023-34152 in ImageMagick to gain criticaler privileges.",
            technique: "T1068",
            riskId: "CVE-2023-34152",
            package_name: "imagemagick",
            prerequisites:
              "Attacker has persistence and can execute code on the system.",
            metadata: {
              kodemScore: 568,
              riskId: "CVE-2023-34152",
              severity: "critical",
              isRuntime: false,
              issueId: "3b8156b664855394d558be3522abc2dd",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "T1070",
            prerequisites: "Attacker has escalated privileges.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data from the compromised system.",
            technique: "T1041",
            prerequisites:
              "Attacker has escalated privileges and can access sensitive data.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes code, escalates privileges, and exfiltrates data.",
        is_severe: false,
        title: "Privilege Escalation",
        kodemScore: 1793,
        scenario_id: 4
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains access by exploiting OpenSSH None authentication option.",
            technique: "T1078",
            riskId: "CVE-2021-36368",
            package_name: "openssh-client",
            prerequisites:
              "OpenSSH client configured with agent forwarding and without -oLogLevel=verbose.",
            metadata: {
              kodemScore: 247,
              riskId: "CVE-2021-36368",
              severity: "low",
              isRuntime: false,
              issueId: "0c37e77ac2ba5368c7639017aa4a395b",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes arbitrary code during build time using malicious module with cgo.",
            technique: "T1059",
            riskId: "CVE-2023-29405",
            package_name: "stdlib",
            prerequisites: "Initial access to the system.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-29405",
              severity: "critical",
              isRuntime: true,
              issueId: "adc5d8a2673bd682e20d9cd6c3e7cb46",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker retrieves password from memory buffer after failed password attempt.",
            technique: "T1003",
            riskId: "CVE-2023-4641",
            package_name: "passwd",
            prerequisites:
              "Access to the system and ability to trigger password prompt.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-4641",
              severity: "medium",
              isRuntime: false,
              issueId: "8efbf06750f04a3f42de8a00430df5d3",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker installs a backdoor to maintain access to the compromised system.",
            technique: "T1547",
            prerequisites: "Credential access to the system.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker clears logs to cover tracks and avoid detection.",
            technique: "T1070",
            prerequisites: "Persistence on the system.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes code, retrieves credentials, and maintains access.",
        title: "Credential Theft",
        is_severe: false,
        kodemScore: 1420,
        scenario_id: 5
      }
    ]
  },
  d98f4179104b2df24bf217133664e2a9: {
    riskId: "CVE-2021-38297",
    package_name: "stdlib",
    issueId: "d98f4179104b2df24bf217133664e2a9",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a specially crafted request to exploit buffer overfmedium in WASM module.",
            technique: "Exploit Public-Facing Application",
            riskId: "CVE-2021-38297",
            package_name: "stdlib",
            prerequisites:
              "The target system must be running a vulnerable version of Go with GOARCH=wasm GOOS=js.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2021-38297",
              severity: "critical",
              isRuntime: true,
              issueId: "d98f4179104b2df24bf217133664e2a9",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Execute arbitrary code by leveraging the buffer overfmedium vulnerability.",
            technique: "Exploitation for Client Execution",
            riskId: "CVE-2021-38297",
            package_name: "stdlib",
            prerequisites:
              "Successful exploitation of the buffer overfmedium vulnerability.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2021-38297",
              severity: "critical",
              isRuntime: true,
              issueId: "d98f4179104b2df24bf217133664e2a9",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Exploit SSL_select_next_proto to leak up to 255 bytes of memory.",
            technique: "Credentials in Files",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites: "Attacker has code execution on the target system.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Discovery",
            details: "Enumerate users on the system using OpenSSH GSS2.",
            technique: "Account Discovery",
            riskId: "CVE-2018-15919",
            package_name: "openssh-client",
            prerequisites: "Attacker has code execution on the target system.",
            metadata: {
              kodemScore: 331,
              riskId: "CVE-2018-15919",
              severity: "medium",
              isRuntime: false,
              issueId: "40678305709fd4d074d1841d435dbb85",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Collection",
            details:
              "Collect sensitive information from memory and user enumeration results.",
            technique: "Data from Local System",
            prerequisites: "Successful credential access and discovery steps.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details: "Exfiltrate collected data to an external server.",
            technique: "Exfiltration Over C2 Channel",
            prerequisites:
              "Collected sensitive information from the target system.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes code, leaks credentials, and exfiltrates data.",
        is_severe: true,
        kodemScore: 25240,
        scenario_id: 1,
        title: "Credential Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a malicious WASM module to the target service.",
            technique: "T1190",
            riskId: "CVE-2021-38297",
            package_name: "stdlib",
            prerequisites: "Target service uses GOARCH=wasm GOOS=js.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2021-38297",
              severity: "critical",
              isRuntime: true,
              issueId: "d98f4179104b2df24bf217133664e2a9",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "The malicious WASM module is executed, triggering a buffer overfmedium.",
            technique: "T1059",
            riskId: "CVE-2021-38297",
            package_name: "stdlib",
            prerequisites: "Initial access with malicious WASM module.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2021-38297",
              severity: "critical",
              isRuntime: true,
              issueId: "d98f4179104b2df24bf217133664e2a9",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Exploit integer overfmedium in libaom0 to gain criticaler privileges.",
            technique: "T1068",
            riskId: "CVE-2024-5171",
            package_name: "libaom0",
            prerequisites: "Execution of malicious WASM module.",
            metadata: {
              kodemScore: 568,
              riskId: "CVE-2024-5171",
              severity: "critical",
              isRuntime: false,
              issueId: "c9bca8917024af04c7ad682a448e3a05",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details:
              "Modify logs to hide malicious activities using elevated privileges.",
            technique: "T1070",
            prerequisites: "Privilege escalation to criticaler privileges.",
            metadata: null
          },
          {
            title: "Credential Access",
            details: "Dump credentials from memory using elevated privileges.",
            technique: "T1003",
            prerequisites:
              "Elevated privileges from integer overfmedium exploit.",
            metadata: null
          },
          {
            title: "Impact",
            details: "Exfiltrate sensitive data from the compromised system.",
            technique: "T1020",
            prerequisites: "Access to credentials and elevated privileges.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, and exfiltrates data.",
        is_severe: true,
        title: "Privilege Escalation",
        kodemScore: 22300,
        scenario_id: 8
      }
    ]
  },
  f6870bb6e9a916744bdd805d87501180: {
    riskId: "CVE-2023-24538",
    package_name: "stdlib",
    issueId: "f6870bb6e9a916744bdd805d87501180",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted request to the vulnerable API endpoint to inject arbitrary JavaScript.",
            technique: "T1190",
            riskId: "CVE-2023-24538",
            package_name: "stdlib",
            prerequisites:
              "The API endpoint is exposed and accessible to the attacker.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-24538",
              severity: "critical",
              isRuntime: true,
              issueId: "f6870bb6e9a916744bdd805d87501180",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Injected JavaScript code is executed within the context of the vulnerable application.",
            technique: "T1059.007",
            prerequisites: "Successful injection of arbitrary JavaScript code.",
            metadata: null
          },
          {
            title: "Credential Access",
            details:
              "Attacker exploits SSL_select_next_proto to leak up to 255 bytes of memory.",
            technique: "T1552",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites:
              "Injected JavaScript code can interact with the SSL/TLS layer.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Exfiltration",
            details:
              "Leaked memory contents, including sensitive data, are sent to the attacker's server.",
            technique: "T1041",
            prerequisites:
              "Memory contents are successfully leaked and accessible to the attacker.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker uses the leaked credentials to gain unauthorized access to sensitive resources.",
            technique: "T1486",
            prerequisites:
              "Leaked credentials are valid and provide access to sensitive resources.",
            metadata: null
          }
        ],
        details:
          "Attacker injects JS, leaks memory, and gains unauthorized access.",
        title: "Unauthorized Access",
        is_severe: true,
        kodemScore: 13620,
        scenario_id: 3
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits CVE-2023-24538 to inject arbitrary JavaScript code into the Go template.",
            technique: "T1071.001",
            riskId: "CVE-2023-24538",
            package_name: "stdlib",
            prerequisites:
              "The attacker has access to a vulnerable endpoint that processes user-supplied templates.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-24538",
              severity: "critical",
              isRuntime: true,
              issueId: "f6870bb6e9a916744bdd805d87501180",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Injected JavaScript code is executed in the context of the web application.",
            technique: "T1059.007",
            prerequisites: "Successful exploitation of CVE-2023-24538.",
            metadata: null
          },
          {
            title: "Credential Access",
            details:
              "Attacker exploits CVE-2023-4641 to retrieve passwords from memory buffer.",
            technique: "T1003.001",
            riskId: "CVE-2023-4641",
            package_name: "passwd",
            prerequisites:
              "Attacker has access to the system memory where shadow-utils stores passwords.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-4641",
              severity: "medium",
              isRuntime: false,
              issueId: "8efbf06750f04a3f42de8a00430df5d3",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker uses retrieved credentials to create a new user account with elevated privileges.",
            technique: "T1136.001",
            prerequisites:
              "Attacker has valid credentials retrieved from memory.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to remove traces of the attack.",
            technique: "T1070.004",
            prerequisites: "Attacker has administrative access to the system.",
            metadata: null
          }
        ],
        details:
          "Attacker injects JS, retrieves passwords, creates user, and clears logs.",
        is_severe: true,
        kodemScore: 11730,
        scenario_id: 4,
        title: "JS Injection Breach"
      }
    ]
  },
  "063a3661bd71510cf844edd0f30aadb7": {
    riskId: "CVE-2023-24540",
    package_name: "stdlib",
    issueId: "063a3661bd71510cf844edd0f30aadb7",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted template containing special whitespace characters to the vulnerable service.",
            technique: "T1190",
            riskId: "CVE-2023-24540",
            package_name: "stdlib",
            prerequisites:
              "The service is exposed to the attacker and accepts user input.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-24540",
              severity: "critical",
              isRuntime: true,
              issueId: "063a3661bd71510cf844edd0f30aadb7",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "The crafted template is processed, leading to improper sanitization and execution of malicious JavaScript.",
            technique: "T1059.007",
            riskId: "CVE-2023-24540",
            package_name: "stdlib",
            prerequisites: "Initial access step has been completed.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-24540",
              severity: "critical",
              isRuntime: true,
              issueId: "063a3661bd71510cf844edd0f30aadb7",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details:
              "The attacker uses HTML-like comment tokens to bypass security filters.",
            technique: "T1140",
            riskId: "CVE-2023-39318",
            package_name: "stdlib",
            prerequisites:
              "The crafted template is being processed by the service.",
            metadata: {
              kodemScore: 636,
              riskId: "CVE-2023-39318",
              severity: "medium",
              isRuntime: true,
              issueId: "c57ff3ecfba8b1fe3eb5affba866b530",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Exfiltration",
            details:
              "The attacker exfiltrates sensitive data by sending it to a remote server via JavaScript.",
            technique: "T1041",
            prerequisites:
              "Malicious JavaScript is executed in the victim's browser.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "The attacker performs an XSS attack, compromising the integrity of the web application.",
            technique: "T1499",
            riskId: "CVE-2023-39319",
            package_name: "stdlib",
            prerequisites:
              "Malicious JavaScript is executed in the victim's browser.",
            metadata: {
              kodemScore: 636,
              riskId: "CVE-2023-39319",
              severity: "medium",
              isRuntime: true,
              issueId: "b29dddf26c81d03bb92b6055520ee014",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker exploits XSS vulnerabilities to execute malicious JavaScript and exfiltrate data.",
        is_severe: true,
        title: "Data Exfiltration",
        kodemScore: 29340,
        scenario_id: 3
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a specially crafted JavaScript template to the service.",
            technique: "T1190",
            riskId: "CVE-2023-24540",
            package_name: "stdlib",
            prerequisites: "Service is exposed to the attacker.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-24540",
              severity: "critical",
              isRuntime: true,
              issueId: "063a3661bd71510cf844edd0f30aadb7",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "The crafted JavaScript template is executed, bypassing the sanitization process.",
            technique: "T1059",
            riskId: "CVE-2023-24540",
            package_name: "stdlib",
            prerequisites: "Initial access with crafted JavaScript template.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-24540",
              severity: "critical",
              isRuntime: true,
              issueId: "063a3661bd71510cf844edd0f30aadb7",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker exploits SSL_select_next_proto to leak memory contents.",
            technique: "T1552",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites: "Execution of crafted JavaScript template.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data obtained from memory leak.",
            technique: "T1041",
            prerequisites: "Memory contents leaked via SSL_select_next_proto.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes code, leaks and exfiltrates sensitive data.",
        is_severe: true,
        title: "Code Execution",
        kodemScore: 21930,
        scenario_id: 2
      }
    ]
  },
  "87f1d6e56de81e80c6edcc4da8ae19fc": {
    riskId: "CVE-2023-29402",
    package_name: "stdlib",
    issueId: "87f1d6e56de81e80c6edcc4da8ae19fc",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a malicious Go module with newline characters in directory names.",
            technique: "T1078",
            riskId: "CVE-2023-29402",
            package_name: "stdlib",
            prerequisites:
              "Assume the attacker can upload Go modules to the build system.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-29402",
              severity: "critical",
              isRuntime: true,
              issueId: "87f1d6e56de81e80c6edcc4da8ae19fc",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "The Go build process executes the malicious module, causing unexpected behavior.",
            technique: "T1204",
            riskId: "CVE-2023-29402",
            package_name: "stdlib",
            prerequisites: "Initial access via malicious Go module.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-29402",
              severity: "critical",
              isRuntime: true,
              issueId: "87f1d6e56de81e80c6edcc4da8ae19fc",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker exploits SSL_select_next_proto to leak memory contents.",
            technique: "T1552",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites: "Execution of malicious Go module.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data obtained from memory leak.",
            technique: "T1041",
            prerequisites: "Credential access via memory leak.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to cover tracks.",
            technique: "T1070",
            prerequisites: "Exfiltration of sensitive data.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes malicious code, leaks and exfiltrates sensitive data.",
        is_severe: true,
        kodemScore: 21930,
        title: "Data Exfiltration",
        scenario_id: 1
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits CVE-2021-36368 to gain unauthorized access via modified server supporting None authentication.",
            technique: "T1078",
            riskId: "CVE-2021-36368",
            package_name: "openssh-client",
            prerequisites:
              "Attacker has access to a server that can be modified to support None authentication.",
            metadata: {
              kodemScore: 247,
              riskId: "CVE-2021-36368",
              severity: "low",
              isRuntime: false,
              issueId: "0c37e77ac2ba5368c7639017aa4a395b",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker runs a malicious Go program using cgo with directories containing newline characters.",
            technique: "T1059",
            riskId: "CVE-2023-29402",
            package_name: "stdlib",
            prerequisites:
              "Attacker has access to the system and can run Go programs.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-29402",
              severity: "critical",
              isRuntime: true,
              issueId: "87f1d6e56de81e80c6edcc4da8ae19fc",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker retrieves password from memory buffer due to improper cleanup after failed password attempt.",
            technique: "T1003",
            riskId: "CVE-2023-4641",
            package_name: "passwd",
            prerequisites:
              "Attacker has access to the system and can trigger a password change process.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-4641",
              severity: "medium",
              isRuntime: false,
              issueId: "8efbf06750f04a3f42de8a00430df5d3",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker uses retrieved credentials to create a persistent backdoor for future access.",
            technique: "T1098",
            prerequisites:
              "Attacker has valid credentials retrieved from memory buffer.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker clears logs to remove traces of malicious activities.",
            technique: "T1070",
            prerequisites:
              "Attacker has sufficient privileges to access and modify system logs.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes malicious code, retrieves credentials, and establishes persistence.",
        is_severe: false,
        kodemScore: 1420,
        title: "Code Execution",
        scenario_id: 4
      }
    ]
  },
  f2f6509cd9e56484e1525c0f02e572f5: {
    riskId: "CVE-2024-27304",
    package_name: "github.com/jackc/pgx/v4",
    issueId: "f2f6509cd9e56484e1525c0f02e572f5",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted SQL query to exploit SQL injection vulnerability.",
            technique: "T1190",
            riskId: "CVE-2024-27304",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "The service is using a vulnerable version of pgx and accepts user input for SQL queries.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2024-27304",
              severity: "critical",
              isRuntime: true,
              issueId: "f2f6509cd9e56484e1525c0f02e572f5",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Execute arbitrary SQL commands to gain control over the database.",
            technique: "T1059.007",
            prerequisites:
              "Successful exploitation of SQL injection vulnerability.",
            metadata: null
          },
          {
            title: "Credential Access",
            details: "Extract user credentials stored in the database.",
            technique: "T1555.003",
            prerequisites: "Access to the database through SQL injection.",
            metadata: null
          },
          {
            title: "Persistence",
            details:
              "Create a new database user with administrative privileges.",
            technique: "T1078",
            prerequisites: "Control over the database through SQL injection.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Delete logs to cover tracks of the SQL injection attack.",
            technique: "T1070.004",
            prerequisites: "Administrative access to the database.",
            metadata: null
          },
          {
            title: "Impact",
            details: "Exfiltrate sensitive data from the database.",
            technique: "T1537",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "Control over the database and ability to execute arbitrary SQL commands.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker gains control over the database and exfiltrates sensitive data.",
        is_severe: true,
        kodemScore: 15730,
        title: "Data Exfiltration",
        scenario_id: 1
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains access to the system via a vulnerable web application.",
            technique: "T1078",
            prerequisites: "Assume initial access to the system.",
            metadata: null
          },
          {
            title: "Execution",
            details:
              "Attacker exploits CVE-2024-27304 to perform SQL injection and execute arbitrary SQL commands.",
            technique: "T1190",
            riskId: "CVE-2024-27304",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites: "Initial access to the system.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2024-27304",
              severity: "critical",
              isRuntime: true,
              issueId: "f2f6509cd9e56484e1525c0f02e572f5",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker uses CVE-2021-3864 to escalate privileges by exploiting the dumpable flag setting.",
            technique: "T1068",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites: "Execution of arbitrary SQL commands.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Dumping",
            details:
              "Attacker dumps credentials from the database using escalated privileges.",
            technique: "T1003",
            prerequisites: "Privilege escalation.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates the dumped credentials to an external server.",
            technique: "T1041",
            prerequisites: "Credential dumping.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, and exfiltrates credentials.",
        is_severe: false,
        kodemScore: 1252,
        title: "Credential Theft",
        scenario_id: 5
      }
    ]
  },
  f369abc0940d994822770dbcbebdd56c: {
    riskId: "CVE-2019-1010022",
    package_name: "libc6",
    issueId: "f369abc0940d994822770dbcbebdd56c",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted file to exploit heap-based buffer overfmedium in SQLite3.",
            technique: "T1190",
            riskId: "CVE-2023-7104",
            package_name: "libsqlite3-dev",
            prerequisites:
              "Target system must have SQLite3 up to version 3.43.0 installed.",
            metadata: {
              kodemScore: 436,
              riskId: "CVE-2023-7104",
              severity: "high",
              isRuntime: false,
              issueId: "5d3c110306d8ad02b1fb1fbe07fec3f8",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes arbitrary code by exploiting the buffer overfmedium vulnerability.",
            technique: "T1059",
            riskId: "CVE-2023-7104",
            package_name: "libsqlite3-dev",
            prerequisites:
              "Initial access achieved by exploiting CVE-2023-7104.",
            metadata: {
              kodemScore: 436,
              riskId: "CVE-2023-7104",
              severity: "high",
              isRuntime: false,
              issueId: "5d3c110306d8ad02b1fb1fbe07fec3f8",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker uses CVE-2019-1010022 to bypass stack guard protection and escalate privileges.",
            technique: "T1068",
            riskId: "CVE-2019-1010022",
            package_name: "libc6",
            prerequisites:
              "Arbitrary code execution achieved by exploiting CVE-2023-7104.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2019-1010022",
              severity: "critical",
              isRuntime: true,
              issueId: "f369abc0940d994822770dbcbebdd56c",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details: "Attacker disables security tools to avoid detection.",
            technique: "T1562",
            prerequisites:
              "Privilege escalation achieved by exploiting CVE-2019-1010022.",
            metadata: null
          },
          {
            title: "Credential Access",
            details:
              "Attacker dumps credentials from memory to gain further access.",
            technique: "T1003",
            prerequisites: "Security tools disabled to avoid detection.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker exfiltrates sensitive data from the compromised system.",
            technique: "T1537",
            prerequisites: "Credentials accessed from memory.",
            metadata: null
          }
        ],
        details:
          "Attacker gains initial access, escalates privileges, and exfiltrates sensitive data.",
        is_severe: false,
        title: "Data Exfiltration",
        kodemScore: 1703,
        scenario_id: 5
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted payload to exploit heap buffer overfmedium in display444as420 function.",
            technique: "T1190",
            riskId: "CVE-2024-38949",
            package_name: "libde265-0",
            prerequisites:
              "Service is exposed to the attacker and accepts payloads.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2024-38949",
              severity: "medium",
              isRuntime: false,
              issueId: "3dfab8524699b2ede5c19e0ea17d9aa2",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes arbitrary code by exploiting the heap buffer overfmedium vulnerability.",
            technique: "T1059",
            riskId: "CVE-2024-38949",
            package_name: "libde265-0",
            prerequisites:
              "Initial access achieved by exploiting CVE-2024-38949.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2024-38949",
              severity: "medium",
              isRuntime: false,
              issueId: "3dfab8524699b2ede5c19e0ea17d9aa2",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker uses the bypass stack guard protection to escalate privileges.",
            technique: "T1068",
            riskId: "CVE-2019-1010022",
            package_name: "libc6",
            prerequisites: "Arbitrary code execution achieved.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2019-1010022",
              severity: "critical",
              isRuntime: true,
              issueId: "f369abc0940d994822770dbcbebdd56c",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Dumping",
            details:
              "Attacker dumps credentials from memory to gain further access.",
            technique: "T1003",
            prerequisites: "Privileges escalated.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "T1070",
            prerequisites: "Credentials dumped and further access gained.",
            metadata: null
          }
        ],
        details:
          "Attacker gains initial access, executes code, escalates privileges, dumps credentials, and evades detection.",
        title: "Credential Dumping",
        is_severe: false,
        kodemScore: 1619,
        scenario_id: 6
      }
    ]
  },
  "94796fc77e37b2c690b56a935b68500a": {
    riskId: "CVE-2024-24790",
    package_name: "stdlib",
    issueId: "94796fc77e37b2c690b56a935b68500a",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted request to exploit CVE-2023-39319 in the html/template package.",
            technique: "T1190",
            riskId: "CVE-2023-39319",
            package_name: "stdlib",
            prerequisites: "Vulnerable html/template package in use.",
            metadata: {
              kodemScore: 636,
              riskId: "CVE-2023-39319",
              severity: "medium",
              isRuntime: true,
              issueId: "b29dddf26c81d03bb92b6055520ee014",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Malicious script executes in the victim's browser due to improper escaping in templates.",
            technique: "T1059.007",
            prerequisites: "Successful exploitation of CVE-2023-39319.",
            metadata: null
          },
          {
            title: "Credential Access",
            details:
              "Malicious script captures user credentials and sends them to the attacker's server.",
            technique: "T1110",
            prerequisites: "Execution of malicious script in victim's browser.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses CVE-2024-24790 to bypass IP-based access controls.",
            technique: "T1070.004",
            riskId: "CVE-2024-24790",
            package_name: "stdlib",
            prerequisites: "Captured user credentials.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2024-24790",
              severity: "critical",
              isRuntime: true,
              issueId: "94796fc77e37b2c690b56a935b68500a",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data from the compromised system.",
            technique: "T1041",
            prerequisites: "Bypassed IP-based access controls.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes script, captures credentials, bypasses controls, and exfiltrates data.",
        is_severe: true,
        title: "Credential Capture",
        kodemScore: 14670,
        scenario_id: 7
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted GVariant to the target service, exploiting deserialization flaw.",
            technique: "Exploit Public-Facing Application",
            riskId: "CVE-2023-32665",
            package_name: "libglib2.0-0",
            prerequisites:
              "Service is exposed to the attacker and accepts GVariant data.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-32665",
              severity: "medium",
              isRuntime: false,
              issueId: "55d450ed19183c6dc1cde5c85c93219b",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "The crafted GVariant causes excessive processing, leading to denial of service.",
            technique: "Denial of Service",
            riskId: "CVE-2023-32665",
            package_name: "libglib2.0-0",
            prerequisites:
              "Initial access achieved by sending crafted GVariant.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-32665",
              severity: "medium",
              isRuntime: false,
              issueId: "55d450ed19183c6dc1cde5c85c93219b",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses IPv4-mapped IPv6 addresses to bypass IP-based access controls.",
            technique: "Use Alternate Authentication Material",
            riskId: "CVE-2024-24790",
            package_name: "stdlib",
            prerequisites: "Service uses IP-based access controls.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2024-24790",
              severity: "critical",
              isRuntime: true,
              issueId: "94796fc77e37b2c690b56a935b68500a",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker maintains access by exploiting the same deserialization flaw periodically.",
            technique: "Implant Container Image",
            riskId: "CVE-2023-32665",
            package_name: "libglib2.0-0",
            prerequisites: "Initial access and execution steps completed.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-32665",
              severity: "medium",
              isRuntime: false,
              issueId: "55d450ed19183c6dc1cde5c85c93219b",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Impact",
            details:
              "Attacker causes repeated denial of service, disrupting the target service.",
            technique: "Resource Hijacking",
            riskId: "CVE-2023-32665",
            package_name: "libglib2.0-0",
            prerequisites:
              "Persistence achieved by exploiting deserialization flaw periodically.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-32665",
              severity: "medium",
              isRuntime: false,
              issueId: "55d450ed19183c6dc1cde5c85c93219b",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker exploits deserialization flaw and IP bypass to cause repeated DoS.",
        is_severe: false,
        kodemScore: 2199,
        title: "IP Bypass DoS",
        scenario_id: 8
      }
    ]
  },
  b2d62d17a6548b93208486f24142c3a9: {
    riskId: "CVE-2024-27304",
    package_name: "github.com/jackc/pgproto3/v2",
    issueId: "b2d62d17a6548b93208486f24142c3a9",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted SQL query to exploit integer overfmedium vulnerability.",
            technique: "Exploit Public-Facing Application",
            riskId: "CVE-2024-27304",
            package_name: "github.com/jackc/pgproto3/v2",
            prerequisites:
              "Public-facing application with vulnerable pgproto3 package.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2024-27304",
              severity: "critical",
              isRuntime: true,
              issueId: "b2d62d17a6548b93208486f24142c3a9",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uses the SQL injection to execute arbitrary commands on the database server.",
            technique: "Command and Scripting Interpreter",
            prerequisites: "Successful SQL injection from previous step.",
            metadata: null
          },
          {
            title: "Execution",
            details:
              "Attacker exploits command injection vulnerability in OpenSSH to execute arbitrary commands.",
            technique: "Command and Scripting Interpreter",
            riskId: "CVE-2023-51385",
            package_name: "openssh-client",
            prerequisites: "Access to the system via SQL injection.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2023-51385",
              severity: "medium",
              isRuntime: false,
              issueId: "e27b5b6f261713eba12cedb8962b56ff",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker installs a backdoor on the compromised system for persistent access.",
            technique: "Implant Container Image",
            prerequisites: "Execution of arbitrary commands on the system.",
            metadata: null
          },
          {
            title: "Credential Access",
            details:
              "Attacker extracts sensitive information, including credentials, from the compromised system.",
            technique: "Credentials from Password Stores",
            prerequisites: "Persistent access to the system.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker exfiltrates data and disrupts services on the compromised system.",
            technique: "Data Destruction",
            prerequisites:
              "Access to sensitive information and system control.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes commands, installs backdoor, extracts credentials, and disrupts services.",
        is_severe: true,
        kodemScore: 12250,
        title: "Credential Extraction",
        scenario_id: 7
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a specially crafted email to bypass domain verification.",
            technique: "T1078",
            riskId: "CVE-2023-27043",
            package_name: "python3.9",
            prerequisites: "None",
            metadata: {
              kodemScore: 331,
              riskId: "CVE-2023-27043",
              severity: "medium",
              isRuntime: false,
              issueId: "3b1101195928e4bfdd2a17e9d981bec2",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker exploits SQL injection vulnerability to execute arbitrary SQL commands.",
            technique: "T1190",
            riskId: "CVE-2024-27304",
            package_name: "github.com/jackc/pgproto3/v2",
            prerequisites: "Initial Access",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2024-27304",
              severity: "critical",
              isRuntime: true,
              issueId: "b2d62d17a6548b93208486f24142c3a9",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker creates a new user with administrative privileges in the database.",
            technique: "T1078.003",
            prerequisites: "Execution",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker deletes logs to cover tracks of the SQL injection and user creation.",
            technique: "T1070.004",
            prerequisites: "Persistence",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data from the database using SQL commands.",
            technique: "T1041",
            prerequisites: "Persistence",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes SQL injection, creates admin user, evades detection, and exfiltrates data.",
        is_severe: false,
        title: "Admin User Creation",
        kodemScore: 1162,
        scenario_id: 3
      }
    ]
  },
  e08eb6357efa62cd67bc80210742335f: {
    riskId: "CVE-2022-23806",
    package_name: "stdlib",
    issueId: "e08eb6357efa62cd67bc80210742335f",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits SQL injection vulnerability to gain unauthorized access to the database.",
            technique: "T1190",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "The non-default simple protocol is used, and placeholders are user-controlled.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes arbitrary SQL commands to manipulate database data.",
            technique: "T1059.008",
            prerequisites: "Initial access to the database via SQL injection.",
            metadata: null
          },
          {
            title: "Credential Dumping",
            details:
              "Attacker extracts credentials stored in the database for further exploitation.",
            technique: "T1003",
            prerequisites: "Execution of arbitrary SQL commands.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses elliptic curve point validation bypass to avoid detection.",
            technique: "T1070.004",
            riskId: "CVE-2022-23806",
            package_name: "stdlib",
            prerequisites:
              "Access to cryptographic operations in the application.",
            metadata: {
              kodemScore: 794,
              riskId: "CVE-2022-23806",
              severity: "critical",
              isRuntime: true,
              issueId: "e08eb6357efa62cd67bc80210742335f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data from the database to an external server.",
            technique: "T1041",
            prerequisites: "Extracted credentials and access to the database.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker modifies or deletes critical data in the database, causing disruption.",
            technique: "T1485",
            prerequisites: "Execution of arbitrary SQL commands.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, manipulates data, and exfiltrates sensitive information.",
        is_severe: true,
        kodemScore: 15360,
        titile: "Sensitive Data Exfiltration",
        scenario_id: 2,
        title: "Data Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a maliciously crafted HTML template to the web application.",
            technique: "T1190",
            riskId: "CVE-2023-39319",
            package_name: "stdlib",
            prerequisites:
              "The web application uses the vulnerable html/template package.",
            metadata: {
              kodemScore: 636,
              riskId: "CVE-2023-39319",
              severity: "medium",
              isRuntime: true,
              issueId: "b29dddf26c81d03bb92b6055520ee014",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "The malicious script within the HTML template is executed in the user's browser.",
            technique: "T1059.007",
            prerequisites: "Initial access step successfully executed.",
            metadata: null
          },
          {
            title: "Credential Access",
            details:
              "The malicious script captures user credentials and sends them to the attacker's server.",
            technique: "T1110",
            prerequisites: "Execution step successfully executed.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "The attacker uses obfuscation techniques to hide the malicious script in the HTML template.",
            technique: "T1027",
            prerequisites: "Initial access step successfully executed.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "The attacker uses the captured credentials to access sensitive information.",
            technique: "T1486",
            riskId: "CVE-2022-23806",
            package_name: "stdlib",
            prerequisites: "Credential access step successfully executed.",
            metadata: {
              kodemScore: 794,
              riskId: "CVE-2022-23806",
              severity: "critical",
              isRuntime: true,
              issueId: "e08eb6357efa62cd67bc80210742335f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker gains access to sensitive information via XSS and cryptographic flaw.",
        is_severe: true,
        titile: "Sensitive Data Access",
        kodemScore: 14300,
        scenario_id: 6,
        title: "XSS Data Exposure"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains access to the system using a vulnerable SUID binary.",
            technique: "T1078",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites: "Presence of a vulnerable SUID binary.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes the vulnerable SUID binary to set dumpable flag.",
            technique: "T1059",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites: "Initial access using the vulnerable SUID binary.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker crashes the descendant process to create a core dump in a root-owned directory.",
            technique: "T1068",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites: "Execution of the vulnerable SUID binary.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses CVE-2022-23806 to bypass elliptic curve validation checks.",
            technique: "T1070",
            riskId: "CVE-2022-23806",
            package_name: "stdlib",
            prerequisites:
              "Privilege escalation to gain criticaler privileges.",
            metadata: {
              kodemScore: 794,
              riskId: "CVE-2022-23806",
              severity: "critical",
              isRuntime: true,
              issueId: "e08eb6357efa62cd67bc80210742335f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker extracts sensitive information from the core dump file.",
            technique: "T1003",
            prerequisites: "Core dump created in a root-owned directory.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker uses extracted credentials to perform malicious activities.",
            technique: "T1486",
            prerequisites:
              "Access to sensitive information from the core dump.",
            metadata: null
          }
        ],
        details:
          "Attacker escalates privileges and extracts sensitive information using core dumps.",
        is_severe: false,
        titile: "Sensitive Data Access",
        kodemScore: 2057,
        scenario_id: 5,
        title: "Core Dump Exploitation"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a specially crafted request to exploit SSL_select_next_proto vulnerability.",
            technique: "Exploit Public-Facing Application",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites:
              "The target application uses OpenSSL and calls SSL_select_next_proto with a 0 length list.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes code to read arbitrary memory contents due to buffer overread.",
            technique: "Exploitation for Client Execution",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites:
              "Initial access achieved by exploiting CVE-2024-5535.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker retrieves sensitive information from memory, including potential credentials.",
            technique: "Credentials from Password Stores",
            prerequisites: "Memory contents read due to buffer overread.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses elliptic curve point validation flaw to bypass cryptographic checks.",
            technique: "Subvert Trust Controls",
            riskId: "CVE-2022-23806",
            package_name: "stdlib",
            prerequisites:
              "Access to cryptographic operations in the application.",
            metadata: {
              kodemScore: 794,
              riskId: "CVE-2022-23806",
              severity: "critical",
              isRuntime: true,
              issueId: "e08eb6357efa62cd67bc80210742335f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Impact",
            details:
              "Attacker uses obtained credentials to perform unauthorized actions.",
            technique: "Data Destruction",
            prerequisites: "Credentials obtained from memory.",
            metadata: null
          }
        ],
        details:
          "Attacker exploits SSL vulnerability to read memory, bypasses crypto checks, and uses credentials.",
        is_severe: false,
        kodemScore: 1856,
        titile: "Memory Reading",
        scenario_id: 1,
        title: "SSL Exploit"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted request to the vulnerable service to exploit CVE-2023-45857.",
            technique: "Exploit Public-Facing Application",
            riskId: "CVE-2023-45857",
            package_name: "axios",
            prerequisites: "Service is accessible over the network.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2023-45857",
              severity: "medium",
              isRuntime: false,
              issueId: "4323c34c2a3bd739cd2731201d1d5a77",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "javascript",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker retrieves the XSRF-TOKEN from the HTTP header in the response.",
            technique: "Steal Application Access Token",
            riskId: "CVE-2023-45857",
            package_name: "axios",
            prerequisites: "Successful exploitation of CVE-2023-45857.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2023-45857",
              severity: "medium",
              isRuntime: false,
              issueId: "4323c34c2a3bd739cd2731201d1d5a77",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "javascript",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uses the stolen XSRF-TOKEN to perform authenticated actions.",
            technique: "Exploitation for Client Execution",
            prerequisites: "Possession of a valid XSRF-TOKEN.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses the elliptic curve point validation flaw to bypass cryptographic checks.",
            technique: "Subvert Trust Controls",
            riskId: "CVE-2022-23806",
            package_name: "stdlib",
            prerequisites:
              "Access to cryptographic operations using elliptic curves.",
            metadata: {
              kodemScore: 794,
              riskId: "CVE-2022-23806",
              severity: "critical",
              isRuntime: true,
              issueId: "e08eb6357efa62cd67bc80210742335f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Impact",
            details:
              "Attacker performs unauthorized actions, potentially leading to data manipulation or exfiltration.",
            technique: "Data Manipulation",
            prerequisites: "Authenticated access to the application.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access and manipulates data using stolen tokens and cryptographic flaws.",
        is_severe: false,
        kodemScore: 1582,
        titile: "Data Manipulation",
        scenario_id: 7,
        title: "Token Manipulation"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a specially crafted email to bypass domain verification.",
            technique: "T1078.003",
            riskId: "CVE-2023-27043",
            package_name: "libpython3.9-stdlib",
            prerequisites: "None",
            metadata: {
              kodemScore: 331,
              riskId: "CVE-2023-27043",
              severity: "medium",
              isRuntime: false,
              issueId: "56e1632f57c4438d3f5ac6081b1b6668",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uploads a malicious SVG file to trigger a segmentation fault.",
            technique: "T1203",
            riskId: "CVE-2023-1289",
            package_name: "imagemagick",
            prerequisites: "Initial access through CVE-2023-27043",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-1289",
              severity: "medium",
              isRuntime: false,
              issueId: "672c56e24db98fc6dffef742b1fbbfec",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses the elliptic curve validation flaw to avoid detection.",
            technique: "T1070.004",
            riskId: "CVE-2022-23806",
            package_name: "stdlib",
            prerequisites:
              "Execution of malicious SVG file through CVE-2023-1289",
            metadata: {
              kodemScore: 794,
              riskId: "CVE-2022-23806",
              severity: "critical",
              isRuntime: true,
              issueId: "e08eb6357efa62cd67bc80210742335f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker gains access, executes malicious code, and evades detection.",
        titile: "Code Execution",
        is_severe: false,
        kodemScore: 1467,
        scenario_id: 4,
        title: "Stealthy Code Execution"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits CVE-2021-36368 to gain access via modified OpenSSH server.",
            technique: "T1078",
            riskId: "CVE-2021-36368",
            package_name: "openssh-client",
            prerequisites: "Attacker has access to a modified OpenSSH server.",
            metadata: {
              kodemScore: 247,
              riskId: "CVE-2021-36368",
              severity: "low",
              isRuntime: false,
              issueId: "0c37e77ac2ba5368c7639017aa4a395b",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes code to interact with the elliptic curve cryptography functions.",
            technique: "T1059",
            prerequisites: "Initial access to the system.",
            metadata: null
          },
          {
            title: "Exploitation for Client Execution",
            details:
              "Attacker exploits CVE-2022-23806 to bypass elliptic curve cryptography validation.",
            technique: "T1203",
            riskId: "CVE-2022-23806",
            package_name: "stdlib",
            prerequisites:
              "Execution of code interacting with elliptic curve cryptography functions.",
            metadata: {
              kodemScore: 794,
              riskId: "CVE-2022-23806",
              severity: "critical",
              isRuntime: true,
              issueId: "e08eb6357efa62cd67bc80210742335f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker exploits CVE-2023-4641 to retrieve passwords from memory buffer.",
            technique: "T1003",
            riskId: "CVE-2023-4641",
            package_name: "passwd",
            prerequisites: "Access to the system and ability to execute code.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-4641",
              severity: "medium",
              isRuntime: false,
              issueId: "8efbf06750f04a3f42de8a00430df5d3",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "T1070",
            prerequisites: "Access to the system and ability to execute code.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates retrieved credentials and sensitive data.",
            technique: "T1041",
            prerequisites: "Access to the system and retrieved credentials.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, bypasses cryptography, retrieves credentials, and exfiltrates data.",
        is_severe: false,
        titile: "Credential Theft",
        kodemScore: 1383,
        scenario_id: 3,
        title: "Credential Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits CVE-2023-34152 in ImageMagick to execute arbitrary code remotely.",
            technique: "T1190",
            riskId: "CVE-2023-34152",
            package_name: "imagemagick",
            prerequisites: "ImageMagick with --enable-pipes configured.",
            metadata: {
              kodemScore: 568,
              riskId: "CVE-2023-34152",
              severity: "critical",
              isRuntime: false,
              issueId: "3b8156b664855394d558be3522abc2dd",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker runs a script to search for sensitive files on the system.",
            technique: "T1059.004",
            prerequisites: "Initial access achieved through CVE-2023-34152.",
            metadata: null
          },
          {
            title: "Credential Access",
            details:
              "Attacker extracts credentials from configuration files found on the system.",
            technique: "T1552.001",
            prerequisites: "Sensitive files identified in the previous step.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "T1070.004",
            prerequisites:
              "Credentials accessed and sensitive operations performed.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker uses CVE-2022-23806 to manipulate cryptographic operations, causing data integrity issues.",
            technique: "T1496",
            riskId: "CVE-2022-23806",
            package_name: "stdlib",
            prerequisites:
              "Access to the system and ability to run arbitrary code.",
            metadata: {
              kodemScore: 794,
              riskId: "CVE-2022-23806",
              severity: "critical",
              isRuntime: true,
              issueId: "e08eb6357efa62cd67bc80210742335f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker gains access, extracts credentials, evades detection, and manipulates cryptographic operations.",
        is_severe: false,
        titile: "Sensitive Data Manipulation",
        kodemScore: 1362,
        scenario_id: 8,
        title: "Credential Extraction"
      }
    ]
  },
  "3e111587e3dcd5ad97d71853f2df9725": {
    riskId: "CVE-2023-48795",
    package_name: "golang.org/x/crypto",
    issueId: "3e111587e3dcd5ad97d71853f2df9725",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted YAML input to exploit the Unmarshal function vulnerability.",
            technique: "Exploit Public-Facing Application",
            riskId: "CVE-2022-28948",
            package_name: "gopkg.in/yaml.v3",
            prerequisites:
              "Public-facing application endpoint that processes YAML input.",
            metadata: {
              kodemScore: 710,
              riskId: "CVE-2022-28948",
              severity: "high",
              isRuntime: true,
              issueId: "efa2da4e9cf01346cee0d16f9efffd59",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "The crafted YAML input causes the application to crash, leading to potential code execution.",
            technique: "Exploitation for Client Execution",
            riskId: "CVE-2022-28948",
            package_name: "gopkg.in/yaml.v3",
            prerequisites:
              "Successful exploitation of the Unmarshal function vulnerability.",
            metadata: {
              kodemScore: 710,
              riskId: "CVE-2022-28948",
              severity: "high",
              isRuntime: true,
              issueId: "efa2da4e9cf01346cee0d16f9efffd59",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses the SSH transport protocol vulnerability to bypass integrity checks.",
            technique: "Obfuscated Files or Information",
            riskId: "CVE-2023-48795",
            package_name: "golang.org/x/crypto",
            prerequisites: "Established SSH connection with the target system.",
            metadata: {
              kodemScore: 782,
              riskId: "CVE-2023-48795",
              severity: "medium",
              isRuntime: true,
              issueId: "3e111587e3dcd5ad97d71853f2df9725",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "IN_THE_WILD"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker installs a backdoor to maintain access to the compromised system.",
            technique: "Implant Internal Image",
            prerequisites:
              "Successful code execution and evasion of integrity checks.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker causes a denial of service by exploiting GVariant deserialization vulnerability.",
            technique: "Endpoint Denial of Service",
            riskId: "CVE-2023-32665",
            package_name: "libglib2.0-0",
            prerequisites:
              "Access to the system and ability to send crafted GVariant data.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-32665",
              severity: "medium",
              isRuntime: false,
              issueId: "55d450ed19183c6dc1cde5c85c93219b",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker gains access, evades defenses, and causes DoS using multiple CVEs.",
        is_severe: true,
        title: "Denial of Service",
        kodemScore: 25440,
        scenario_id: 8
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits CVE-2023-48795 to bypass SSH integrity checks and gain access.",
            technique: "T1078",
            riskId: "CVE-2023-48795",
            package_name: "golang.org/x/crypto",
            prerequisites: "SSH service is exposed to the attacker.",
            metadata: {
              kodemScore: 782,
              riskId: "CVE-2023-48795",
              severity: "medium",
              isRuntime: true,
              issueId: "3e111587e3dcd5ad97d71853f2df9725",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "IN_THE_WILD"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes malicious commands on the compromised system.",
            technique: "T1059",
            prerequisites:
              "Initial access gained through SSH integrity check bypass.",
            metadata: null
          },
          {
            title: "Credential Access",
            details:
              "Attacker exploits CVE-2024-5535 to read sensitive memory contents.",
            technique: "T1552",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites: "Attacker has execution capabilities on the system.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "T1070",
            prerequisites: "Attacker has execution capabilities on the system.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data obtained from memory.",
            technique: "T1041",
            prerequisites: "Sensitive data obtained from memory.",
            metadata: null
          },
          {
            title: "Impact",
            details: "Attacker disrupts service by consuming resources.",
            technique: "T1499",
            riskId: "CVE-2024-36910",
            package_name: "linux-libc-dev",
            prerequisites: "Attacker has execution capabilities on the system.",
            metadata: {
              kodemScore: 378,
              riskId: "CVE-2024-36910",
              severity: "medium",
              isRuntime: false,
              issueId: "407cf3f1faa3f537fc6c7197ba921a97",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker gains access, reads sensitive data, evades detection, and disrupts service.",
        title: "Data Exfiltration",
        is_severe: true,
        kodemScore: 16910,
        scenario_id: 2
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted HTML template to the vulnerable service to exploit XSS.",
            technique: "T1071.001",
            riskId: "CVE-2023-39319",
            package_name: "stdlib",
            prerequisites: "Service is accessible to the attacker.",
            metadata: {
              kodemScore: 636,
              riskId: "CVE-2023-39319",
              severity: "medium",
              isRuntime: true,
              issueId: "b29dddf26c81d03bb92b6055520ee014",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Malicious script executes in the context of the vulnerable web application.",
            technique: "T1059.007",
            prerequisites: "Successful exploitation of CVE-2023-39319.",
            metadata: null
          },
          {
            title: "Credential Access",
            details:
              "Attacker captures user credentials via the injected script.",
            technique: "T1056.001",
            prerequisites: "Malicious script execution.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses the Terrapin attack to bypass SSH integrity checks.",
            technique: "T1562.001",
            riskId: "CVE-2023-48795",
            package_name: "golang.org/x/crypto",
            prerequisites: "Access to SSH connection.",
            metadata: {
              kodemScore: 782,
              riskId: "CVE-2023-48795",
              severity: "medium",
              isRuntime: true,
              issueId: "3e111587e3dcd5ad97d71853f2df9725",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "IN_THE_WILD"
            }
          },
          {
            title: "Persistence",
            details: "Attacker installs a persistent backdoor via SSH access.",
            technique: "T1547.001",
            prerequisites: "Bypassed SSH integrity checks.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data over the compromised SSH connection.",
            technique: "T1041",
            prerequisites: "Persistent SSH access.",
            metadata: null
          }
        ],
        details:
          "Attacker exploits XSS and SSH vulnerabilities to gain access and exfiltrate data.",
        is_severe: true,
        title: "Data Exfiltration",
        kodemScore: 14180,
        scenario_id: 7
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains access to the system via a vulnerable SSH connection.",
            technique: "T1078",
            riskId: "CVE-2023-48795",
            package_name: "golang.org/x/crypto",
            prerequisites:
              "SSH service is running and accessible from the network.",
            metadata: {
              kodemScore: 782,
              riskId: "CVE-2023-48795",
              severity: "medium",
              isRuntime: true,
              issueId: "3e111587e3dcd5ad97d71853f2df9725",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "IN_THE_WILD"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits the dumpable flag flaw to escalate privileges using a SUID binary.",
            technique: "T1068",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites:
              "Attacker has access to a SUID binary that sets real UID and GID equal to effective UID and GID.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "T1070.004",
            prerequisites: "Attacker has escalated privileges.",
            metadata: null
          },
          {
            title: "Credential Access",
            details: "Attacker dumps credentials from memory or files.",
            technique: "T1003",
            prerequisites: "Attacker has escalated privileges.",
            metadata: null
          },
          {
            title: "Persistence",
            details: "Attacker installs a backdoor to maintain access.",
            technique: "T1547",
            prerequisites: "Attacker has escalated privileges.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, evades detection, and maintains persistence.",
        is_severe: true,
        title: "Privilege Escalation",
        kodemScore: 12030,
        scenario_id: 3
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits SQL injection vulnerability to gain unauthorized access to the database.",
            technique: "T1190",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "The application uses the non-default simple protocol and user-controlled inputs.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes malicious SQL commands to retrieve sensitive data.",
            technique: "T1059.003",
            prerequisites: "Initial access to the database via SQL injection.",
            metadata: null
          },
          {
            title: "Credential Access",
            details:
              "Attacker extracts user credentials stored in the database.",
            technique: "T1212",
            prerequisites:
              "Execution of SQL commands to access sensitive data.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Attacker disables logging to avoid detection.",
            technique: "T1562.002",
            prerequisites:
              "Access to the database and ability to execute SQL commands.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker modifies or deletes critical data in the database.",
            technique: "T1485",
            prerequisites:
              "Access to the database and ability to execute SQL commands.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, retrieves credentials, evades detection, and modifies data.",
        is_severe: true,
        title: "Data Manipulation",
        kodemScore: 7420,
        scenario_id: 4
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a specially crafted email to bypass domain verification.",
            technique: "T1078",
            riskId: "CVE-2023-27043",
            package_name: "python3.9",
            prerequisites:
              "Target application uses email module for domain verification.",
            metadata: {
              kodemScore: 331,
              riskId: "CVE-2023-27043",
              severity: "medium",
              isRuntime: false,
              issueId: "3b1101195928e4bfdd2a17e9d981bec2",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details: "Attacker uploads a malicious SVG file to the server.",
            technique: "T1203",
            riskId: "CVE-2023-1289",
            package_name: "imagemagick",
            prerequisites: "Attacker has access to upload files to the server.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-1289",
              severity: "medium",
              isRuntime: false,
              issueId: "672c56e24db98fc6dffef742b1fbbfec",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker maintains access by exploiting the SSH integrity check bypass.",
            technique: "T1098",
            riskId: "CVE-2023-48795",
            package_name: "golang.org/x/crypto",
            prerequisites: "Attacker has SSH access to the server.",
            metadata: {
              kodemScore: 782,
              riskId: "CVE-2023-48795",
              severity: "medium",
              isRuntime: true,
              issueId: "3e111587e3dcd5ad97d71853f2df9725",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "IN_THE_WILD"
            }
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses the SSH integrity check bypass to avoid detection.",
            technique: "T1562",
            riskId: "CVE-2023-48795",
            package_name: "golang.org/x/crypto",
            prerequisites: "Attacker maintains SSH access to the server.",
            metadata: {
              kodemScore: 782,
              riskId: "CVE-2023-48795",
              severity: "medium",
              isRuntime: true,
              issueId: "3e111587e3dcd5ad97d71853f2df9725",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "IN_THE_WILD"
            }
          },
          {
            title: "Impact",
            details:
              "Attacker causes a denial of service by generating large trash files.",
            technique: "T1499",
            riskId: "CVE-2023-1289",
            package_name: "imagemagick",
            prerequisites:
              "Attacker can upload and execute SVG files on the server.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-1289",
              severity: "medium",
              isRuntime: false,
              issueId: "672c56e24db98fc6dffef742b1fbbfec",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details: "Attacker gains access, evades detection, and causes DoS.",
        title: "Denial of Service",
        is_severe: false,
        kodemScore: 2579,
        scenario_id: 5
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits CVE-2023-51385 to inject OS commands via crafted SSH user/host names.",
            technique: "T1078",
            riskId: "CVE-2023-51385",
            package_name: "openssh-client",
            prerequisites: "SSH access to the target system.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2023-51385",
              severity: "medium",
              isRuntime: false,
              issueId: "e27b5b6f261713eba12cedb8962b56ff",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Execute arbitrary commands on the target system using the injected OS commands.",
            technique: "T1059",
            prerequisites:
              "Successful OS command injection via CVE-2023-51385.",
            metadata: null
          },
          {
            title: "Privilege Escalation",
            details:
              "Exploit CVE-2023-34152 to execute code with elevated privileges via ImageMagick.",
            technique: "T1068",
            riskId: "CVE-2023-34152",
            package_name: "imagemagick",
            prerequisites: "Ability to execute commands on the target system.",
            metadata: {
              kodemScore: 568,
              riskId: "CVE-2023-34152",
              severity: "critical",
              isRuntime: false,
              issueId: "3b8156b664855394d558be3522abc2dd",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details:
              "Use CVE-2023-48795 to bypass SSH integrity checks and disable security features.",
            technique: "T1562",
            riskId: "CVE-2023-48795",
            package_name: "golang.org/x/crypto",
            prerequisites: "Established SSH connection to the target system.",
            metadata: {
              kodemScore: 782,
              riskId: "CVE-2023-48795",
              severity: "medium",
              isRuntime: true,
              issueId: "3e111587e3dcd5ad97d71853f2df9725",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "IN_THE_WILD"
            }
          },
          {
            title: "Credential Access",
            details:
              "Dump credentials from the target system using elevated privileges.",
            technique: "T1003",
            prerequisites: "Elevated privileges on the target system.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Exfiltrate sensitive data from the target system over the compromised SSH connection.",
            technique: "T1041",
            prerequisites:
              "Access to sensitive data and established SSH connection.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, and exfiltrates data.",
        is_severe: false,
        title: "Data Exfiltration",
        kodemScore: 1744,
        scenario_id: 6
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits CVE-2021-36368 to gain unauthorized access via modified server supporting None authentication.",
            technique: "T1078",
            riskId: "CVE-2021-36368",
            package_name: "openssh-client",
            prerequisites:
              "Attacker has access to a server that supports None authentication.",
            metadata: {
              kodemScore: 247,
              riskId: "CVE-2021-36368",
              severity: "low",
              isRuntime: false,
              issueId: "0c37e77ac2ba5368c7639017aa4a395b",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uses the compromised SSH connection to execute commands on the target system.",
            technique: "T1059",
            prerequisites: "Initial access gained via CVE-2021-36368.",
            metadata: null
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits CVE-2023-4641 to retrieve passwords from memory and escalate privileges.",
            technique: "T1068",
            riskId: "CVE-2023-4641",
            package_name: "passwd",
            prerequisites:
              "Attacker has access to execute commands on the target system.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-4641",
              severity: "medium",
              isRuntime: false,
              issueId: "8efbf06750f04a3f42de8a00430df5d3",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses CVE-2023-48795 to bypass SSH integrity checks and disable security features.",
            technique: "T1562",
            riskId: "CVE-2023-48795",
            package_name: "golang.org/x/crypto",
            prerequisites:
              "Attacker has escalated privileges and can modify SSH configurations.",
            metadata: {
              kodemScore: 782,
              riskId: "CVE-2023-48795",
              severity: "medium",
              isRuntime: true,
              issueId: "3e111587e3dcd5ad97d71853f2df9725",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "IN_THE_WILD"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker captures additional credentials using the compromised SSH connection.",
            technique: "T1552",
            prerequisites: "SSH integrity checks bypassed via CVE-2023-48795.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker exfiltrates sensitive data from the compromised system.",
            technique: "T1537",
            prerequisites:
              "Additional credentials captured and SSH connection maintained.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, bypasses defenses, and exfiltrates data.",
        title: "Data Exfiltration",
        is_severe: false,
        kodemScore: 1371,
        scenario_id: 1
      }
    ]
  },
  a0e9dc4ba5e8fdb52b88172d14a187d5: {
    riskId: "CVE-2024-33602",
    package_name: "libc6",
    issueId: "a0e9dc4ba5e8fdb52b88172d14a187d5",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted SQL query to exploit SQL injection vulnerability in pgx.",
            technique: "T1190",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "The application must use the non-default simple protocol and have user-controlled inputs.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes arbitrary SQL commands to gain control over the database.",
            technique: "T1059.003",
            prerequisites:
              "Successful exploitation of SQL injection vulnerability.",
            metadata: null
          },
          {
            title: "Persistence",
            details:
              "Attacker creates a new database user with administrative privileges.",
            technique: "T1078",
            prerequisites: "Control over the database through SQL injection.",
            metadata: null
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker leverages the new administrative user to escalate privileges within the application.",
            technique: "T1068",
            prerequisites:
              "Creation of a new database user with administrative privileges.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker clears database logs to remove traces of malicious activity.",
            technique: "T1070.002",
            prerequisites: "Administrative access to the database.",
            metadata: null
          },
          {
            title: "Impact",
            details: "Attacker exfiltrates sensitive data from the database.",
            technique: "T1537",
            riskId: "CVE-2024-33602",
            package_name: "libc6",
            prerequisites:
              "Control over the database and ability to execute arbitrary SQL commands.",
            metadata: {
              kodemScore: 768,
              riskId: "CVE-2024-33602",
              severity: "high",
              isRuntime: true,
              issueId: "a0e9dc4ba5e8fdb52b88172d14a187d5",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker gains initial access via SQL injection, escalates privileges, and exfiltrates data.",
        title: "Data Exfiltration",
        is_severe: true,
        kodemScore: 15100,
        scenario_id: 7
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains access to the system using a vulnerable SUID binary.",
            technique: "T1078",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites: "Presence of a vulnerable SUID binary.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes the vulnerable SUID binary to set dumpable flag to 1.",
            technique: "T1059",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites: "Initial access to the system.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker crashes the descendant process to create a core dump in a root-owned directory.",
            technique: "T1068",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites: "Execution of the vulnerable SUID binary.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker uses the core dump to analyze and find sensitive information for persistence.",
            technique: "T1505",
            prerequisites: "Core dump created in a root-owned directory.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker clears logs to hide traces of the exploit and maintain access.",
            technique: "T1070",
            prerequisites: "Access to the system and root privileges.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker uses the information from the core dump to escalate privileges and control the system.",
            technique: "T1486",
            riskId: "CVE-2024-33602",
            package_name: "libc6",
            prerequisites:
              "Core dump analysis and access to sensitive information.",
            metadata: {
              kodemScore: 768,
              riskId: "CVE-2024-33602",
              severity: "high",
              isRuntime: true,
              issueId: "a0e9dc4ba5e8fdb52b88172d14a187d5",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker escalates privileges and controls the system using core dump analysis.",
        title: "Data Exfiltration",
        is_severe: false,
        kodemScore: 2031,
        scenario_id: 2
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a specially crafted email to bypass domain verification.",
            technique: "T1078",
            riskId: "CVE-2023-27043",
            package_name: "libpython3.9-minimal",
            prerequisites:
              "The target system uses email-based domain verification for access control.",
            metadata: {
              kodemScore: 331,
              riskId: "CVE-2023-27043",
              severity: "medium",
              isRuntime: false,
              issueId: "fc9ac3669b54fe4c155d1b60ff3c6cd6",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uploads a malicious SVG file to trigger a segmentation fault.",
            technique: "T1203",
            riskId: "CVE-2023-1289",
            package_name: "imagemagick",
            prerequisites:
              "Attacker has access to the system via email bypass.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-1289",
              severity: "medium",
              isRuntime: false,
              issueId: "672c56e24db98fc6dffef742b1fbbfec",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker exploits nscd netgroup cache to corrupt memory and maintain access.",
            technique: "T1505",
            riskId: "CVE-2024-33602",
            package_name: "libc6",
            prerequisites: "Attacker has executed code on the system.",
            metadata: {
              kodemScore: 768,
              riskId: "CVE-2024-33602",
              severity: "high",
              isRuntime: true,
              issueId: "a0e9dc4ba5e8fdb52b88172d14a187d5",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker leverages corrupted memory to escalate privileges on the system.",
            technique: "T1068",
            prerequisites: "Memory corruption from nscd netgroup cache.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "T1070",
            prerequisites: "Attacker has escalated privileges.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker causes a denial of service by generating large trash files.",
            technique: "T1499",
            riskId: "CVE-2023-1289",
            package_name: "imagemagick",
            prerequisites:
              "Attacker has escalated privileges and can execute code.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-1289",
              severity: "medium",
              isRuntime: false,
              issueId: "672c56e24db98fc6dffef742b1fbbfec",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details: "Attacker gains access, escalates privileges, and causes DoS.",
        is_severe: false,
        title: "Denial of Service",
        kodemScore: 1783,
        scenario_id: 4
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits CVE-2023-51385 in OpenSSH to gain initial access to the system.",
            technique: "T1078",
            riskId: "CVE-2023-51385",
            package_name: "openssh-client",
            prerequisites: "None",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2023-51385",
              severity: "medium",
              isRuntime: false,
              issueId: "e27b5b6f261713eba12cedb8962b56ff",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uses CVE-2023-34152 in ImageMagick to execute arbitrary code on the system.",
            technique: "T1059",
            riskId: "CVE-2023-34152",
            package_name: "imagemagick",
            prerequisites: "Initial access gained through CVE-2023-51385",
            metadata: {
              kodemScore: 568,
              riskId: "CVE-2023-34152",
              severity: "critical",
              isRuntime: false,
              issueId: "3b8156b664855394d558be3522abc2dd",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits CVE-2024-33602 in nscd to corrupt memory and escalate privileges.",
            technique: "T1068",
            riskId: "CVE-2024-33602",
            package_name: "libc6",
            prerequisites: "Arbitrary code execution through CVE-2023-34152",
            metadata: {
              kodemScore: 768,
              riskId: "CVE-2024-33602",
              severity: "high",
              isRuntime: true,
              issueId: "a0e9dc4ba5e8fdb52b88172d14a187d5",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker gains access, executes code, and escalates privileges.",
        is_severe: false,
        kodemScore: 1730,
        title: "Privilege Escalation",
        scenario_id: 5
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits OpenSSH None authentication option to gain access to the server.",
            technique: "T1078",
            riskId: "CVE-2021-36368",
            package_name: "openssh-client",
            prerequisites: "Attacker has network access to the target server.",
            metadata: {
              kodemScore: 247,
              riskId: "CVE-2021-36368",
              severity: "low",
              isRuntime: false,
              issueId: "0c37e77ac2ba5368c7639017aa4a395b",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes a script to interact with the nscd service.",
            technique: "T1059",
            prerequisites: "Initial access to the server.",
            metadata: null
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits nscd netgroup cache vulnerability to corrupt memory and escalate privileges.",
            technique: "T1068",
            riskId: "CVE-2024-33602",
            package_name: "libc6",
            prerequisites: "Execution of script interacting with nscd service.",
            metadata: {
              kodemScore: 768,
              riskId: "CVE-2024-33602",
              severity: "high",
              isRuntime: true,
              issueId: "a0e9dc4ba5e8fdb52b88172d14a187d5",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker retrieves password from memory due to improper buffer cleaning in shadow-utils.",
            technique: "T1003",
            riskId: "CVE-2023-4641",
            package_name: "passwd",
            prerequisites: "Privilege escalation to access memory.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-4641",
              severity: "medium",
              isRuntime: false,
              issueId: "8efbf06750f04a3f42de8a00430df5d3",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "T1070",
            prerequisites: "Credential access to gain necessary permissions.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data from the compromised server.",
            technique: "T1041",
            prerequisites:
              "Defense evasion to avoid detection during data exfiltration.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, retrieves credentials, and exfiltrates data.",
        is_severe: false,
        kodemScore: 1357,
        title: "Data Exfiltration",
        scenario_id: 1
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits CVE-2024-5535 to cause a buffer overread and leak memory contents.",
            technique: "T1078",
            riskId: "CVE-2024-5535",
            package_name: "libssl-dev",
            prerequisites:
              "Application calls SSL_select_next_proto with a 0 length list of supported client protocols.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "400d868dc48cdc26a729775131ebb17b",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uses leaked memory contents to craft a payload for remote code execution.",
            technique: "T1059",
            prerequisites: "Memory contents leaked from initial access step.",
            metadata: null
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits CVE-2024-33602 to corrupt memory and escalate privileges.",
            technique: "T1068",
            riskId: "CVE-2024-33602",
            package_name: "libc6",
            prerequisites: "Attacker has remote code execution on the system.",
            metadata: {
              kodemScore: 768,
              riskId: "CVE-2024-33602",
              severity: "high",
              isRuntime: true,
              issueId: "a0e9dc4ba5e8fdb52b88172d14a187d5",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker dumps credentials from memory using elevated privileges.",
            technique: "T1003",
            prerequisites:
              "Elevated privileges from privilege escalation step.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to cover tracks.",
            technique: "T1070",
            prerequisites: "Access to system logs with elevated privileges.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data including credentials and memory dumps.",
            technique: "T1041",
            prerequisites:
              "Access to sensitive data from credential access step.",
            metadata: null
          }
        ],
        details:
          "Attacker gains initial access, escalates privileges, and exfiltrates sensitive data.",
        is_severe: false,
        kodemScore: 1299,
        title: "Data Exfiltration",
        scenario_id: 3
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains access to the system via a vulnerable network service.",
            technique: "T1078",
            prerequisites: "Assume initial access to the system.",
            metadata: null
          },
          {
            title: "Execution",
            details:
              "Attacker executes a malicious payload to exploit the nscd netgroup cache vulnerability.",
            technique: "T1059",
            riskId: "CVE-2024-33602",
            package_name: "libc6",
            prerequisites: "Initial access to the system.",
            metadata: {
              kodemScore: 768,
              riskId: "CVE-2024-33602",
              severity: "high",
              isRuntime: true,
              issueId: "a0e9dc4ba5e8fdb52b88172d14a187d5",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits the use-after-free vulnerability in the Linux kernel to gain criticaler privileges.",
            technique: "T1068",
            riskId: "CVE-2024-39496",
            package_name: "linux-libc-dev",
            prerequisites: "Execution of malicious payload.",
            metadata: {
              kodemScore: 463,
              riskId: "CVE-2024-39496",
              severity: "high",
              isRuntime: false,
              issueId: "58a312b523ac67c9b65958cb6f2edcbc",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "T1070",
            prerequisites: "Privilege escalation.",
            metadata: null
          },
          {
            title: "Credential Access",
            details:
              "Attacker dumps credentials from memory to gain further access.",
            technique: "T1003",
            prerequisites: "Defense evasion.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker exfiltrates sensitive data from the compromised system.",
            technique: "T1020",
            prerequisites: "Credential access.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, and exfiltrates data.",
        is_severe: false,
        title: "Data Exfiltration",
        kodemScore: 1231,
        scenario_id: 6
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted image file to the target application using ImageMagick.",
            technique: "T1190",
            riskId: "CVE-2021-20312",
            package_name: "imagemagick",
            prerequisites:
              "Target application processes image files using ImageMagick.",
            metadata: {
              kodemScore: 447,
              riskId: "CVE-2021-20312",
              severity: "high",
              isRuntime: false,
              issueId: "3cb2d80b3ac45929cd0da132429164c8",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "The crafted image file triggers an integer overfmedium in WriteTHUMBNAILImage, causing undefined behavior.",
            technique: "T1203",
            riskId: "CVE-2021-20312",
            package_name: "imagemagick",
            prerequisites: "Initial access via crafted image file (Step 1).",
            metadata: {
              kodemScore: 447,
              riskId: "CVE-2021-20312",
              severity: "high",
              isRuntime: false,
              issueId: "3cb2d80b3ac45929cd0da132429164c8",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Exploit the memory corruption to execute arbitrary code with elevated privileges.",
            technique: "T1068",
            prerequisites: "Execution of arbitrary code (Step 2).",
            metadata: null
          },
          {
            title: "Persistence",
            details:
              "Install a backdoor to maintain access to the compromised system.",
            technique: "T1547",
            prerequisites: "Privilege escalation (Step 3).",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Disable security software to avoid detection.",
            technique: "T1562",
            prerequisites: "Persistence (Step 4).",
            metadata: null
          },
          {
            title: "Impact",
            details: "Exfiltrate sensitive data from the compromised system.",
            technique: "T1005",
            prerequisites: "Defense evasion (Step 5).",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, and exfiltrates data.",
        is_severe: false,
        kodemScore: 894,
        title: "Data Exfiltration",
        scenario_id: 8
      }
    ]
  },
  "0d72a06a01aa77486c8cca95d8df91aa": {
    riskId: "CVE-2023-39323",
    package_name: "stdlib",
    issueId: "0d72a06a01aa77486c8cca95d8df91aa",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker uploads a malicious Go module containing a cgo directive with disalmediumed linker flags.",
            technique: "T1190",
            riskId: "CVE-2023-29404",
            package_name: "stdlib",
            prerequisites:
              "Attacker has access to upload or modify Go modules.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-29404",
              severity: "critical",
              isRuntime: true,
              issueId: "8c5628d382b46f3515b812594f39808e",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "The go command executes arbitrary code at build time due to the malicious cgo directive.",
            technique: "T1059.004",
            riskId: "CVE-2023-29404",
            package_name: "stdlib",
            prerequisites:
              "Initial access step completed, malicious Go module is built.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-29404",
              severity: "critical",
              isRuntime: true,
              issueId: "8c5628d382b46f3515b812594f39808e",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Exploiting the arbitrary code execution to gain criticaler privileges on the system.",
            technique: "T1068",
            prerequisites: "Execution step completed, arbitrary code running.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses the //line directive to bypass restrictions and avoid detection.",
            technique: "T1562.001",
            riskId: "CVE-2023-39323",
            package_name: "stdlib",
            prerequisites:
              "Privilege escalation step completed, attacker has criticaler privileges.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2023-39323",
              severity: "high",
              isRuntime: true,
              issueId: "0d72a06a01aa77486c8cca95d8df91aa",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker accesses sensitive files to extract credentials.",
            technique: "T1003",
            prerequisites:
              "Defense evasion step completed, attacker has access to sensitive files.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker uses the obtained credentials to disrupt services or exfiltrate data.",
            technique: "T1486",
            prerequisites:
              "Credential access step completed, attacker has valid credentials.",
            metadata: null
          }
        ],
        details:
          "Attacker gains initial access via malicious Go module, escalates privileges, evades defenses, and disrupts services.",
        is_severe: true,
        title: "Arbitrary Code Execution",
        kodemScore: 24040,
        scenario_id: 3
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted SQL query to exploit SQL injection vulnerability in pgx package.",
            technique: "T1190",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "The target application must use the non-default simple protocol and have user-controlled input.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uses the SQL injection to execute arbitrary SQL commands on the database.",
            technique: "T1059.007",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "Successful exploitation of SQL injection vulnerability.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker creates a new database user with critical privileges for persistent access.",
            technique: "T1078",
            prerequisites:
              "Ability to execute arbitrary SQL commands on the database.",
            metadata: null
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker leverages the new user account to gain administrative privileges on the database.",
            technique: "T1068",
            prerequisites:
              "Creation of a new critical-privilege database user.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Attacker modifies or deletes logs to cover their tracks.",
            technique: "T1070",
            prerequisites: "Administrative privileges on the database.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker injects malicious Go build directives to execute arbitrary code during compilation.",
            technique: "T1496",
            riskId: "CVE-2023-39323",
            package_name: "stdlib",
            prerequisites:
              "Ability to modify source code files and trigger a Go build process.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2023-39323",
              severity: "high",
              isRuntime: true,
              issueId: "0d72a06a01aa77486c8cca95d8df91aa",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker gains access, escalates privileges, and executes arbitrary code.",
        is_severe: true,
        title: "Arbitrary Code Execution",
        kodemScore: 22260,
        scenario_id: 5
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a malicious HTML template to the application.",
            technique: "T1190",
            riskId: "CVE-2023-39319",
            package_name: "stdlib",
            prerequisites:
              "The application uses the html/template package to render user-provided HTML.",
            metadata: {
              kodemScore: 636,
              riskId: "CVE-2023-39319",
              severity: "medium",
              isRuntime: true,
              issueId: "b29dddf26c81d03bb92b6055520ee014",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "The malicious template is rendered, causing improper escaping and script execution.",
            technique: "T1059.007",
            riskId: "CVE-2023-39319",
            package_name: "stdlib",
            prerequisites: "Initial Access step completed.",
            metadata: {
              kodemScore: 636,
              riskId: "CVE-2023-39319",
              severity: "medium",
              isRuntime: true,
              issueId: "b29dddf26c81d03bb92b6055520ee014",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Exploiting the XSS vulnerability to execute administrative actions.",
            technique: "T1068",
            prerequisites: "Execution step completed.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Using the //line directive to bypass restrictions and execute arbitrary code.",
            technique: "T1222",
            riskId: "CVE-2023-39323",
            package_name: "stdlib",
            prerequisites: "Privilege Escalation step completed.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2023-39323",
              severity: "high",
              isRuntime: true,
              issueId: "0d72a06a01aa77486c8cca95d8df91aa",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Extracting sensitive information from the application using the executed code.",
            technique: "T1003",
            prerequisites: "Defense Evasion step completed.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Exfiltrating the extracted credentials to an external server.",
            technique: "T1020",
            prerequisites: "Credential Access step completed.",
            metadata: null
          }
        ],
        details:
          "Attacker exploits XSS and code execution to extract credentials.",
        is_severe: true,
        kodemScore: 20140,
        scenario_id: 8,
        title: "Credential Extraction"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains access to the system via a misconfigured OpenSSL API function.",
            technique: "Exploit Public-Facing Application",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites:
              "OpenSSL API function SSL_select_next_proto is called with a 0 length list of supported client protocols.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uses the line directive to bypass restrictions and execute arbitrary code.",
            technique: "Command and Scripting Interpreter",
            riskId: "CVE-2023-39323",
            package_name: "stdlib",
            prerequisites:
              "Initial access to the system and ability to manipulate source code files.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2023-39323",
              severity: "high",
              isRuntime: true,
              issueId: "0d72a06a01aa77486c8cca95d8df91aa",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker exploits buffer overread to access sensitive memory contents.",
            technique: "Credentials from Password Stores",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites:
              "Initial access to the system and ability to call SSL_select_next_proto with a 0 length list.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data obtained from memory overread.",
            technique: "Exfiltration Over Alternative Protocol",
            prerequisites: "Access to sensitive memory contents.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker causes unexpected application behavior or crash by exploiting buffer overread.",
            technique: "Service Stop",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites:
              "Initial access to the system and ability to call SSL_select_next_proto with a 0 length list.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker gains access, executes code, accesses credentials, exfiltrates data, and causes a crash.",
        is_severe: false,
        kodemScore: 2335,
        scenario_id: 1,
        title: "Code Execution & Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains access by exploiting command injection in OpenSSH via crafted username or hostname.",
            technique: "T1078",
            riskId: "CVE-2023-51385",
            package_name: "openssh-client",
            prerequisites: "OpenSSH client is installed and accessible.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2023-51385",
              severity: "medium",
              isRuntime: false,
              issueId: "e27b5b6f261713eba12cedb8962b56ff",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes arbitrary commands on the system using the injected shell metacharacters.",
            technique: "T1059",
            riskId: "CVE-2023-51385",
            package_name: "openssh-client",
            prerequisites:
              "Initial access gained through OpenSSH command injection.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2023-51385",
              severity: "medium",
              isRuntime: false,
              issueId: "e27b5b6f261713eba12cedb8962b56ff",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker modifies source code to include malicious //line directives to bypass restrictions.",
            technique: "T1546",
            riskId: "CVE-2023-39323",
            package_name: "stdlib",
            prerequisites: "Access to source code repository.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2023-39323",
              severity: "high",
              isRuntime: true,
              issueId: "0d72a06a01aa77486c8cca95d8df91aa",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker compiles the modified code, executing arbitrary code with elevated privileges.",
            technique: "T1068",
            riskId: "CVE-2023-39323",
            package_name: "stdlib",
            prerequisites:
              "Modified source code with malicious //line directives.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2023-39323",
              severity: "high",
              isRuntime: true,
              issueId: "0d72a06a01aa77486c8cca95d8df91aa",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details: "Attacker cleans up logs and traces to avoid detection.",
            technique: "T1070",
            prerequisites: "Elevated privileges and access to log files.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker exfiltrates sensitive data from the compromised system.",
            technique: "T1020",
            prerequisites: "Elevated privileges and access to sensitive data.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, and exfiltrates data.",
        is_severe: false,
        kodemScore: 2272,
        scenario_id: 7,
        title: "Privilege Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains access to the system via a vulnerable SUID binary.",
            technique: "T1078",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites: "Presence of a vulnerable SUID binary.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uses the SUID binary to execute a descendant process with dumpable flag set to 1.",
            technique: "T1059",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites: "Initial access via vulnerable SUID binary.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Descendant process crashes, core dump stored in root-owned directory with uid:gid permissions.",
            technique: "T1068",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites:
              "Execution of descendant process with dumpable flag set to 1.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker modifies core dump to include malicious code, ensuring persistence.",
            technique: "T1547",
            prerequisites: "Core dump stored in root-owned directory.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses line directives to bypass restrictions and execute arbitrary code during build.",
            technique: "T1221",
            riskId: "CVE-2023-39323",
            package_name: "stdlib",
            prerequisites: "Access to source code files and build environment.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2023-39323",
              severity: "high",
              isRuntime: true,
              issueId: "0d72a06a01aa77486c8cca95d8df91aa",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Impact",
            details:
              "Arbitrary code execution achieved, attacker gains control over the system.",
            technique: "T1486",
            prerequisites: "Successful execution of arbitrary code.",
            metadata: null
          }
        ],
        details:
          "Attacker gains control over the system via privilege escalation and arbitrary code execution.",
        is_severe: false,
        kodemScore: 2005,
        scenario_id: 4,
        title: "System Takeover"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a specially crafted email to bypass domain verification.",
            technique: "T1078.003",
            riskId: "CVE-2023-27043",
            package_name: "libpython3.9-minimal",
            prerequisites: "None",
            metadata: {
              kodemScore: 331,
              riskId: "CVE-2023-27043",
              severity: "medium",
              isRuntime: false,
              issueId: "fc9ac3669b54fe4c155d1b60ff3c6cd6",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uses the bypassed email verification to gain access to the system.",
            technique: "T1203",
            prerequisites: "Initial Access",
            metadata: null
          },
          {
            title: "Persistence",
            details:
              "Attacker uploads a malicious Go file with line directives to bypass restrictions.",
            technique: "T1505.003",
            riskId: "CVE-2023-39323",
            package_name: "stdlib",
            prerequisites: "Execution",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2023-39323",
              severity: "high",
              isRuntime: true,
              issueId: "0d72a06a01aa77486c8cca95d8df91aa",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker compiles the malicious Go file to execute arbitrary code.",
            technique: "T1068",
            prerequisites: "Persistence",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker causes a denial of service by uploading a crafted SVG file.",
            technique: "T1499.004",
            riskId: "CVE-2023-1289",
            package_name: "imagemagick",
            prerequisites: "Privilege Escalation",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-1289",
              severity: "medium",
              isRuntime: false,
              issueId: "672c56e24db98fc6dffef742b1fbbfec",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details: "Attacker gains access, escalates privileges, and causes DoS.",
        is_severe: false,
        kodemScore: 1415,
        scenario_id: 2,
        title: "Privilege DoS"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains access to the system using a compromised SSH key.",
            technique: "T1078",
            package_name: "openssh-client",
            prerequisites: "Attacker has a compromised SSH key.",
            metadata: null
          },
          {
            title: "Execution",
            details:
              "Attacker uses CVE-2023-39323 to inject malicious linker flags during compilation.",
            technique: "T1059",
            riskId: "CVE-2023-39323",
            package_name: "stdlib",
            prerequisites: "Initial access to the system.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2023-39323",
              severity: "high",
              isRuntime: true,
              issueId: "0d72a06a01aa77486c8cca95d8df91aa",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker exploits CVE-2023-4641 to retrieve passwords from memory.",
            technique: "T1003",
            riskId: "CVE-2023-4641",
            package_name: "passwd",
            prerequisites: "Access to the system and ability to read memory.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-4641",
              severity: "medium",
              isRuntime: false,
              issueId: "8efbf06750f04a3f42de8a00430df5d3",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker installs a backdoor to maintain access to the system.",
            technique: "T1547",
            package_name: "openssh-client",
            prerequisites: "Credential access to the system.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "T1070",
            package_name: "login",
            prerequisites: "Persistence on the system.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details: "Attacker exfiltrates sensitive data from the system.",
            technique: "T1041",
            package_name: "openssh-client",
            prerequisites: "Persistence on the system.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes code, retrieves credentials, and exfiltrates data.",
        is_severe: false,
        kodemScore: 1084,
        scenario_id: 6,
        title: "Code Execution & Exfiltration"
      }
    ]
  },
  "5c3e45715121d1a1746e7ed6dbab4f7f": {
    riskId: "CVE-2024-27289",
    package_name: "github.com/jackc/pgx/v4",
    issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted SQL query to exploit SQL injection vulnerability.",
            technique: "T1190",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "The application uses the non-default simple protocol and user-controlled parameters.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker injects malicious JavaScript into the application via the SQL injection.",
            technique: "T1059.007",
            prerequisites:
              "Successful exploitation of CVE-2024-27289 to gain initial access.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker leverages improper handling of script contexts to avoid detection.",
            technique: "T1140",
            riskId: "CVE-2023-39319",
            package_name: "stdlib",
            prerequisites: "Injected JavaScript is in a <script> context.",
            metadata: {
              kodemScore: 636,
              riskId: "CVE-2023-39319",
              severity: "medium",
              isRuntime: true,
              issueId: "b29dddf26c81d03bb92b6055520ee014",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data by sending it to an external server.",
            technique: "T1041",
            prerequisites:
              "Malicious JavaScript is executed in the victim's browser.",
            metadata: null
          }
        ],
        details: "Attacker exploits SQL injection and XSS to exfiltrate data.",
        is_severe: true,
        kodemScore: 13780,
        scenario_id: 3,
        title: "Data Exfiltration Exploit"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted SQL query to exploit SQL injection vulnerability.",
            technique: "T1190",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "The application uses the non-default simple protocol and has user-controlled placeholders.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes arbitrary SQL commands to gain control over the database.",
            technique: "T1059.003",
            prerequisites:
              "Successful exploitation of SQL injection vulnerability.",
            metadata: null
          },
          {
            title: "Credential Access",
            details:
              "Attacker retrieves sensitive information from the database, including user credentials.",
            technique: "T1212",
            prerequisites: "Control over the database through SQL injection.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "T1070.004",
            prerequisites: "Access to the database and sufficient privileges.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data, including user credentials, to a remote server.",
            technique: "T1041",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites: "Sensitive data retrieved from the database.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Impact",
            details:
              "Attacker uses exfiltrated credentials to access other systems or sell data.",
            technique: "T1486",
            prerequisites: "Exfiltrated sensitive data.",
            metadata: null
          }
        ],
        details:
          "Attacker exploits SQL injection to gain DB control, exfiltrates data.",
        is_severe: true,
        kodemScore: 12730,
        scenario_id: 1,
        title: "SQL Injection Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains initial access by exploiting SQL injection vulnerability in pgx package.",
            technique: "T1190",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "The application uses the non-default simple protocol and user-controlled input is used in SQL queries.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits the dumpable flag setting flaw to escalate privileges using a SUID binary.",
            technique: "T1068",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites:
              "Attacker has access to a SUID binary that sets real UID equal to effective UID, and real GID equal to effective GID.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Dumping",
            details:
              "Attacker accesses core dumps in root-owned directories to extract sensitive information.",
            technique: "T1003",
            prerequisites:
              "Privilege escalation to access core dumps in root-owned directories.",
            metadata: null
          },
          {
            title: "Exfiltration Over Alternative Protocol",
            details:
              "Attacker exfiltrates sensitive information using an alternative protocol to avoid detection.",
            technique: "T1048",
            prerequisites: "Access to sensitive information from core dumps.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, dumps credentials, and exfiltrates data.",
        is_severe: true,
        kodemScore: 11630,
        scenario_id: 5,
        title: "Credential Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted request to exploit SQL injection vulnerability in pgx.",
            technique: "T1190",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites: "Assume attacker has network access to the service.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker retrieves sensitive information including XSRF-TOKEN from cookies via Axios vulnerability.",
            technique: "T1552",
            riskId: "CVE-2023-45857",
            package_name: "axios",
            prerequisites:
              "Initial access achieved and ability to make HTTP requests.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2023-45857",
              severity: "medium",
              isRuntime: false,
              issueId: "4323c34c2a3bd739cd2731201d1d5a77",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "javascript",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uses the retrieved XSRF-TOKEN to perform authenticated actions on behalf of the user.",
            technique: "T1059",
            prerequisites: "Possession of valid XSRF-TOKEN.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data from the compromised service.",
            technique: "T1041",
            prerequisites: "Authenticated access to the service.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker modifies or deletes data within the service, causing disruption.",
            technique: "T1485",
            prerequisites: "Authenticated access to the service.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, retrieves sensitive tokens, and performs malicious actions.",
        is_severe: true,
        kodemScore: 11360,
        scenario_id: 7,
        title: "Token Compromise"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits SQL injection vulnerability to gain initial access to the system.",
            technique: "T1190",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "The application uses the non-default simple protocol and user-controlled inputs.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker retrieves password from memory due to improper buffer cleaning.",
            technique: "T1003",
            riskId: "CVE-2023-4641",
            package_name: "passwd",
            prerequisites:
              "Attacker has access to the system memory after initial access.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-4641",
              severity: "medium",
              isRuntime: false,
              issueId: "8efbf06750f04a3f42de8a00430df5d3",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker uses retrieved credentials to create a persistent backdoor.",
            technique: "T1078",
            prerequisites:
              "Attacker has valid credentials from the previous step.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Attacker disables security logging to avoid detection.",
            technique: "T1562",
            prerequisites:
              "Attacker has administrative privileges from the previous step.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data from the compromised system.",
            technique: "T1041",
            prerequisites:
              "Attacker has access to sensitive data after gaining persistence.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, retrieves credentials, creates backdoor, evades detection, and exfiltrates data.",
        is_severe: true,
        kodemScore: 10840,
        scenario_id: 4,
        title: "Backdoor Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains access by exploiting command injection in OpenSSH client via crafted username.",
            technique: "T1078",
            riskId: "CVE-2023-51385",
            package_name: "openssh-client",
            prerequisites:
              "Attacker has access to the system where OpenSSH client is used.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2023-51385",
              severity: "medium",
              isRuntime: false,
              issueId: "e27b5b6f261713eba12cedb8962b56ff",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes arbitrary commands on the system using the injected command.",
            technique: "T1059",
            riskId: "CVE-2023-51385",
            package_name: "openssh-client",
            prerequisites:
              "Initial access gained through OpenSSH client command injection.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2023-51385",
              severity: "medium",
              isRuntime: false,
              issueId: "e27b5b6f261713eba12cedb8962b56ff",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details: "Attacker creates a new user account to maintain access.",
            technique: "T1136",
            prerequisites: "Attacker has command execution capabilities.",
            metadata: null
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits SQL injection in pgx to escalate privileges.",
            technique: "T1055",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "Attacker has access to the application using pgx with simple protocol.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details: "Attacker dumps database credentials using SQL injection.",
            technique: "T1003",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "Privilege escalation achieved through SQL injection.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Impact",
            details: "Attacker exfiltrates sensitive data from the database.",
            technique: "T1485",
            prerequisites:
              "Database credentials obtained and access to the database.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, and exfiltrates sensitive data.",
        is_severe: false,
        kodemScore: 2272,
        scenario_id: 6,
        title: "Privilege Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a specially crafted email to bypass domain verification.",
            technique: "T1078",
            riskId: "CVE-2023-27043",
            package_name: "python3.9",
            prerequisites:
              "The email module of Python is used for domain verification.",
            metadata: {
              kodemScore: 331,
              riskId: "CVE-2023-27043",
              severity: "medium",
              isRuntime: false,
              issueId: "3b1101195928e4bfdd2a17e9d981bec2",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker exploits SQL injection vulnerability to execute arbitrary SQL commands.",
            technique: "T1190",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "Attacker has access to the system and can send SQL queries.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker creates a new user with administrative privileges in the database.",
            technique: "T1078.003",
            prerequisites: "Attacker can execute arbitrary SQL commands.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Attacker deletes logs to cover tracks.",
            technique: "T1070.004",
            prerequisites:
              "Attacker has administrative privileges in the database.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details: "Attacker exfiltrates sensitive data from the database.",
            technique: "T1041",
            prerequisites:
              "Attacker has administrative privileges in the database.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes SQL injection, and exfiltrates data.",
        is_severe: false,
        kodemScore: 1073,
        scenario_id: 2,
        title: "SQL Data Exfiltration"
      }
    ]
  },
  be899e2c8e1d72bb0196417de114e1ab: {
    riskId: "CVE-2022-23539",
    package_name: "jsonwebtoken",
    issueId: "be899e2c8e1d72bb0196417de114e1ab",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a specially crafted JWT to the vulnerable API endpoint.",
            technique: "T1078",
            riskId: "CVE-2022-23539",
            package_name: "jsonwebtoken",
            prerequisites:
              "The API endpoint uses a vulnerable version of jsonwebtoken.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2022-23539",
              severity: "high",
              isRuntime: true,
              issueId: "be899e2c8e1d72bb0196417de114e1ab",
              fixability: "FIXABILITY_FULLY_FIXABLE",
              language: "javascript",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker gains access to the system by exploiting the JWT verification flaw.",
            technique: "T1203",
            riskId: "CVE-2022-23539",
            package_name: "jsonwebtoken",
            prerequisites: "Initial access achieved by sending a crafted JWT.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2022-23539",
              severity: "high",
              isRuntime: true,
              issueId: "be899e2c8e1d72bb0196417de114e1ab",
              fixability: "FIXABILITY_FULLY_FIXABLE",
              language: "javascript",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker calls SSL_select_next_proto with an empty client protocols buffer to read memory.",
            technique: "T1552",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites:
              "Attacker has access to the system and can call OpenSSL API functions.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data read from memory to an external server.",
            technique: "T1041",
            prerequisites:
              "Sensitive data obtained from memory using OpenSSL API.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker uses the exfiltrated data to impersonate users or escalate privileges.",
            technique: "T1496",
            prerequisites: "Sensitive data exfiltrated to an external server.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, reads memory, exfiltrates data, and escalates privileges.",
        is_severe: true,
        kodemScore: 20150,
        scenario_id: 2,
        title: "Memory Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted request to the vulnerable service to obtain the XSRF-TOKEN.",
            technique: "T1078",
            riskId: "CVE-2023-45857",
            package_name: "axios",
            prerequisites:
              "Service is using Axios 1.5.1 and stores XSRF-TOKEN in cookies.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2023-45857",
              severity: "medium",
              isRuntime: false,
              issueId: "4323c34c2a3bd739cd2731201d1d5a77",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "javascript",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uses the obtained XSRF-TOKEN to craft a malicious request.",
            technique: "T1203",
            prerequisites:
              "Attacker has obtained the XSRF-TOKEN from the initial access step.",
            metadata: null
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker leverages the XSRF-TOKEN to perform actions with elevated privileges.",
            technique: "T1068",
            prerequisites:
              "Attacker has crafted a malicious request using the XSRF-TOKEN.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses the insecure key type to bypass signature verification.",
            technique: "T1070",
            riskId: "CVE-2022-23539",
            package_name: "jsonwebtoken",
            prerequisites:
              "Attacker has crafted a malicious request and needs to bypass signature verification.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2022-23539",
              severity: "high",
              isRuntime: true,
              issueId: "be899e2c8e1d72bb0196417de114e1ab",
              fixability: "FIXABILITY_FULLY_FIXABLE",
              language: "javascript",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker extracts sensitive information from the service using the crafted request.",
            technique: "T1003",
            prerequisites: "Attacker has bypassed signature verification.",
            metadata: null
          },
          {
            title: "Impact",
            details: "Attacker exfiltrates sensitive data from the service.",
            technique: "T1485",
            prerequisites:
              "Attacker has extracted sensitive information from the service.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, and exfiltrates sensitive data.",
        is_severe: false,
        kodemScore: 1136,
        scenario_id: 7,
        title: "Privilege Exfiltration"
      }
    ]
  },
  e7fb97aa243e1723ea3122a4695db82c: {
    riskId: "CVE-2023-29403",
    package_name: "stdlib",
    issueId: "e7fb97aa243e1723ea3122a4695db82c",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a malicious HTML template to the web application.",
            technique: "T1190",
            riskId: "CVE-2023-39319",
            package_name: "stdlib",
            prerequisites:
              "The web application uses the html/template package for rendering user inputs.",
            metadata: {
              kodemScore: 636,
              riskId: "CVE-2023-39319",
              severity: "medium",
              isRuntime: true,
              issueId: "b29dddf26c81d03bb92b6055520ee014",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "The malicious template is rendered, causing improper escaping and execution of injected script.",
            technique: "T1059.007",
            riskId: "CVE-2023-39319",
            package_name: "stdlib",
            prerequisites:
              "Initial access step where the malicious template is accepted and rendered by the application.",
            metadata: {
              kodemScore: 636,
              riskId: "CVE-2023-39319",
              severity: "medium",
              isRuntime: true,
              issueId: "b29dddf26c81d03bb92b6055520ee014",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Exploiting setuid/setgid binary to gain elevated privileges.",
            technique: "T1068",
            riskId: "CVE-2023-29403",
            package_name: "stdlib",
            prerequisites:
              "Execution of the injected script from the previous step.",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2023-29403",
              severity: "high",
              isRuntime: true,
              issueId: "e7fb97aa243e1723ea3122a4695db82c",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Dumping memory state to extract sensitive information like credentials.",
            technique: "T1003",
            prerequisites: "Elevated privileges from the previous step.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Exfiltrating the extracted credentials to an external server.",
            technique: "T1041",
            prerequisites:
              "Access to sensitive information from the previous step.",
            metadata: null
          }
        ],
        details:
          "Attacker gains initial access via XSS, escalates privileges, and exfiltrates credentials.",
        is_severe: true,
        kodemScore: 19980,
        scenario_id: 8,
        title: "XSS Credential Theft"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits SQL injection vulnerability in pgx to gain unauthorized access.",
            technique: "T1190",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "The application must use the non-default simple protocol and have user-controlled inputs.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes arbitrary SQL commands to manipulate the database.",
            technique: "T1059.003",
            prerequisites: "Initial access gained through SQL injection.",
            metadata: null
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker leverages CVE-2023-29403 to escalate privileges by exploiting setuid/setgid binary behavior.",
            technique: "T1068",
            riskId: "CVE-2023-29403",
            package_name: "stdlib",
            prerequisites:
              "Attacker has access to the system and can execute binaries.",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2023-29403",
              severity: "high",
              isRuntime: true,
              issueId: "e7fb97aa243e1723ea3122a4695db82c",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker dumps memory state to extract sensitive information like credentials.",
            technique: "T1003",
            prerequisites:
              "Privilege escalation achieved, almediuming access to memory state.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker modifies or deletes critical data in the database.",
            technique: "T1485",
            prerequisites: "Execution of arbitrary SQL commands.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, and manipulates database.",
        is_severe: true,
        kodemScore: 14680,
        scenario_id: 4,
        title: "Privilege Manipulation"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains initial access by exploiting CVE-2022-29526 to incorrectly report file accessibility.",
            technique: "Exploit Public-Facing Application",
            riskId: "CVE-2022-29526",
            package_name: "stdlib",
            prerequisites:
              "Public-facing application with vulnerable stdlib package.",
            metadata: {
              kodemScore: 594,
              riskId: "CVE-2022-29526",
              severity: "medium",
              isRuntime: true,
              issueId: "5a8301ddaae68cb553396d3eab08fd97",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits CVE-2023-29403 to gain elevated privileges by manipulating setuid/setgid bits.",
            technique: "Exploitation for Privilege Escalation",
            riskId: "CVE-2023-29403",
            package_name: "stdlib",
            prerequisites:
              "Initial access gained and ability to execute binaries.",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2023-29403",
              severity: "high",
              isRuntime: true,
              issueId: "e7fb97aa243e1723ea3122a4695db82c",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Dumping",
            details:
              "Attacker dumps memory state to extract sensitive information like credentials.",
            technique: "Credential Dumping",
            prerequisites: "Elevated privileges and access to memory state.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates the dumped credentials to an external server.",
            technique: "Exfiltration Over Command and Control Channel",
            prerequisites: "Dumped credentials and network access.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, dumps credentials, and exfiltrates them.",
        is_severe: true,
        kodemScore: 13200,
        scenario_id: 7,
        title: "Credential Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a specially crafted email to bypass domain verification.",
            technique: "T1078.003",
            riskId: "CVE-2023-27043",
            package_name: "libpython3.9-stdlib",
            prerequisites: "None",
            metadata: {
              kodemScore: 331,
              riskId: "CVE-2023-27043",
              severity: "medium",
              isRuntime: false,
              issueId: "56e1632f57c4438d3f5ac6081b1b6668",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes a setuid/setgid binary with closed standard I/O file descriptors.",
            technique: "T1059.004",
            riskId: "CVE-2023-29403",
            package_name: "stdlib",
            prerequisites: "Initial access achieved",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2023-29403",
              severity: "high",
              isRuntime: true,
              issueId: "e7fb97aa243e1723ea3122a4695db82c",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker opens files with elevated privileges due to setuid/setgid binary behavior.",
            technique: "T1068",
            riskId: "CVE-2023-29403",
            package_name: "stdlib",
            prerequisites: "Execution of setuid/setgid binary",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2023-29403",
              severity: "high",
              isRuntime: true,
              issueId: "e7fb97aa243e1723ea3122a4695db82c",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses a URL with blank characters to bypass blocklisting methods.",
            technique: "T1070.004",
            riskId: "CVE-2023-24329",
            package_name: "libpython3.9-stdlib",
            prerequisites: "Privilege escalation achieved",
            metadata: {
              kodemScore: 447,
              riskId: "CVE-2023-24329",
              severity: "high",
              isRuntime: false,
              issueId: "fab91484a28ca6b3cc8a2482736a67d1",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details: "Attacker reads sensitive files with elevated privileges.",
            technique: "T1003",
            prerequisites: "Defense evasion achieved",
            metadata: null
          }
        ],
        details:
          "Attacker gains initial access, escalates privileges, and reads sensitive files.",
        is_severe: false,
        kodemScore: 2230,
        scenario_id: 5,
        title: "Privilege Access Breach"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits CVE-2021-36368 to gain unauthorized access via OpenSSH client.",
            technique: "T1078",
            riskId: "CVE-2021-36368",
            package_name: "openssh-client",
            prerequisites: "Attacker has network access to the target system.",
            metadata: {
              kodemScore: 247,
              riskId: "CVE-2021-36368",
              severity: "low",
              isRuntime: false,
              issueId: "0c37e77ac2ba5368c7639017aa4a395b",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker leverages CVE-2023-29403 to escalate privileges by exploiting setuid/setgid binary behavior.",
            technique: "T1068",
            riskId: "CVE-2023-29403",
            package_name: "stdlib",
            prerequisites: "Initial access gained via CVE-2021-36368.",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2023-29403",
              severity: "high",
              isRuntime: true,
              issueId: "e7fb97aa243e1723ea3122a4695db82c",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker exploits CVE-2023-4641 to retrieve passwords from memory buffer in shadow-utils.",
            technique: "T1003",
            riskId: "CVE-2023-4641",
            package_name: "passwd",
            prerequisites: "Privileges escalated via CVE-2023-29403.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-4641",
              severity: "medium",
              isRuntime: false,
              issueId: "8efbf06750f04a3f42de8a00430df5d3",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker installs a backdoor to maintain access to the compromised system.",
            technique: "T1053",
            prerequisites: "Credentials accessed via CVE-2023-4641.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker clears logs to cover tracks and avoid detection.",
            technique: "T1070",
            prerequisites: "Persistence established.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, retrieves credentials, and maintains access.",
        is_severe: false,
        kodemScore: 1315,
        scenario_id: 3,
        title: "Privilege Persistence"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits CVE-2023-34152 in ImageMagick to execute remote code.",
            technique: "Exploit Public-Facing Application",
            riskId: "CVE-2023-34152",
            package_name: "imagemagick",
            prerequisites:
              "Public-facing ImageMagick service with --enable-pipes configured.",
            metadata: {
              kodemScore: 568,
              riskId: "CVE-2023-34152",
              severity: "critical",
              isRuntime: false,
              issueId: "3b8156b664855394d558be3522abc2dd",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker leverages CVE-2023-29403 to gain elevated privileges by exploiting setuid/setgid binary behavior.",
            technique: "Exploitation for Privilege Escalation",
            riskId: "CVE-2023-29403",
            package_name: "stdlib",
            prerequisites:
              "Remote code execution on the system (previous step).",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2023-29403",
              severity: "high",
              isRuntime: true,
              issueId: "e7fb97aa243e1723ea3122a4695db82c",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Dumping",
            details:
              "Attacker dumps memory state to extract sensitive information like credentials.",
            technique: "Credential Dumping",
            prerequisites: "Elevated privileges (previous step).",
            metadata: null
          },
          {
            title: "Command and Control",
            details:
              "Attacker establishes a persistent backdoor for remote control.",
            technique: "Remote Access Software",
            prerequisites:
              "Elevated privileges and access to credentials (previous steps).",
            metadata: null
          },
          {
            title: "Data Exfiltration",
            details:
              "Attacker exfiltrates sensitive data from the compromised system.",
            technique: "Exfiltration Over Command and Control Channel",
            prerequisites:
              "Established command and control channel (previous step).",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, dumps credentials, and exfiltrates data.",
        is_severe: false,
        kodemScore: 1294,
        scenario_id: 6,
        title: "Credential Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains initial access by exploiting CVE-2024-5535 in OpenSSL.",
            technique: "T1078",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites: "OpenSSL is used in the target system.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits CVE-2023-29403 to gain elevated privileges by manipulating setuid/setgid bits.",
            technique: "T1068",
            riskId: "CVE-2023-29403",
            package_name: "stdlib",
            prerequisites:
              "Initial access gained; attacker has access to a vulnerable binary.",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2023-29403",
              severity: "high",
              isRuntime: true,
              issueId: "e7fb97aa243e1723ea3122a4695db82c",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Dumping",
            details:
              "Attacker dumps memory state to extract sensitive information using elevated privileges.",
            technique: "T1003",
            prerequisites: "Elevated privileges obtained.",
            metadata: null
          },
          {
            title: "Exfiltration Over Alternative Protocol",
            details:
              "Attacker exfiltrates sensitive data using an alternative protocol to avoid detection.",
            technique: "T1048",
            prerequisites: "Sensitive information obtained from memory dump.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, dumps credentials, and exfiltrates data.",
        is_severe: false,
        kodemScore: 1257,
        scenario_id: 1,
        title: "Credential Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains initial access by exploiting a vulnerable SUID binary.",
            technique: "T1078",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites: "Presence of a vulnerable SUID binary.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits CVE-2023-29403 to gain elevated privileges by manipulating file descriptors.",
            technique: "T1068",
            riskId: "CVE-2023-29403",
            package_name: "stdlib",
            prerequisites: "Initial access with a vulnerable SUID binary.",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2023-29403",
              severity: "high",
              isRuntime: true,
              issueId: "e7fb97aa243e1723ea3122a4695db82c",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Dumping",
            details:
              "Attacker dumps memory state to extract sensitive information like credentials.",
            technique: "T1003",
            prerequisites: "Elevated privileges from previous step.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates the dumped credentials to an external server.",
            technique: "T1041",
            prerequisites: "Dumped credentials from previous step.",
            metadata: null
          }
        ],
        details:
          "Attacker gains initial access, escalates privileges, dumps credentials, and exfiltrates them.",
        is_severe: false,
        kodemScore: 1147,
        scenario_id: 2,
        title: "Credential Exfiltration"
      }
    ]
  },
  "4e2bb724941911e3023818ee683350ae": {
    riskId: "CVE-2022-30580",
    package_name: "stdlib",
    issueId: "4e2bb724941911e3023818ee683350ae",
    scenarios: [
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a malicious Go module to the target system.",
            technique: "T1190",
            riskId: "CVE-2023-29404",
            package_name: "stdlib",
            prerequisites:
              "Target system must use cgo and build untrusted code.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-29404",
              severity: "critical",
              isRuntime: true,
              issueId: "8c5628d382b46f3515b812594f39808e",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Malicious Go module executes arbitrary code during the build process.",
            technique: "T1059",
            riskId: "CVE-2023-29404",
            package_name: "stdlib",
            prerequisites:
              "Initial access achieved by sending a malicious Go module.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-29404",
              severity: "critical",
              isRuntime: true,
              issueId: "8c5628d382b46f3515b812594f39808e",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker places a binary named '..com' or '..exe' in the working directory.",
            technique: "T1547",
            prerequisites:
              "Arbitrary code execution achieved during the build process.",
            metadata: null
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits code injection vulnerability to execute the placed binary.",
            technique: "T1068",
            riskId: "CVE-2022-30580",
            package_name: "stdlib",
            prerequisites:
              "Binary named '..com' or '..exe' placed in the working directory.",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2022-30580",
              severity: "high",
              isRuntime: true,
              issueId: "4e2bb724941911e3023818ee683350ae",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details:
              "Attacker uses the GODEBUG flag to re-enable previous behavior and avoid detection.",
            technique: "T1562",
            riskId: "CVE-2023-24538",
            package_name: "stdlib",
            prerequisites:
              "Privilege escalation achieved by executing the placed binary.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-24538",
              severity: "critical",
              isRuntime: true,
              issueId: "f6870bb6e9a916744bdd805d87501180",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Impact",
            details:
              "Attacker injects arbitrary JavaScript code into Go templates.",
            technique: "T1059.007",
            riskId: "CVE-2023-24538",
            package_name: "stdlib",
            prerequisites:
              "Defense evasion achieved by using the GODEBUG flag.",
            metadata: {
              kodemScore: 831,
              riskId: "CVE-2023-24538",
              severity: "critical",
              isRuntime: true,
              issueId: "f6870bb6e9a916744bdd805d87501180",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker achieves code execution, privilege escalation, and injects JavaScript code.",
        is_severe: true,
        kodemScore: 40500,
        scenario_id: 8,
        title: "Code Execution & Injection"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a specially crafted request to exploit code injection vulnerability.",
            technique: "T1190",
            riskId: "CVE-2022-30580",
            package_name: "stdlib",
            prerequisites: "Service is running and accessible to the attacker.",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2022-30580",
              severity: "high",
              isRuntime: true,
              issueId: "4e2bb724941911e3023818ee683350ae",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Injected code runs a malicious binary named '..exe' in the working directory.",
            technique: "T1059",
            riskId: "CVE-2022-30580",
            package_name: "stdlib",
            prerequisites: "Initial access achieved through code injection.",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2022-30580",
              severity: "high",
              isRuntime: true,
              issueId: "4e2bb724941911e3023818ee683350ae",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Exploit OpenSSL vulnerability to leak up to 255 bytes of memory.",
            technique: "T1552",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites: "Malicious code execution in the environment.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Exfiltration",
            details:
              "Exfiltrate sensitive data obtained from memory leak to an external server.",
            technique: "T1041",
            prerequisites: "Sensitive data obtained from memory leak.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker disrupts service by causing a crash using the OpenSSL vulnerability.",
            technique: "T1499",
            riskId: "CVE-2024-5535",
            package_name: "openssl",
            prerequisites: "Sensitive data exfiltrated.",
            metadata: {
              kodemScore: 531,
              riskId: "CVE-2024-5535",
              severity: "critical",
              isRuntime: false,
              issueId: "af4f8211770c9ed5184876d9fdebb931",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker gains access, leaks memory, exfiltrates data, and disrupts service.",
        is_severe: true,
        kodemScore: 25140,
        scenario_id: 2,
        title: "Memory Leak Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a crafted SQL query exploiting SQL injection vulnerability in pgx package.",
            technique: "T1190",
            riskId: "CVE-2024-27289",
            package_name: "github.com/jackc/pgx/v4",
            prerequisites:
              "Service uses the non-default simple protocol and user-controlled input is used in SQL queries.",
            metadata: {
              kodemScore: 742,
              riskId: "CVE-2024-27289",
              severity: "high",
              isRuntime: true,
              issueId: "5c3e45715121d1a1746e7ed6dbab4f7f",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker exploits code injection vulnerability to execute arbitrary binaries in the working directory.",
            technique: "T1059",
            riskId: "CVE-2022-30580",
            package_name: "stdlib",
            prerequisites:
              "Attacker has initial access and can execute commands via SQL injection.",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2022-30580",
              severity: "high",
              isRuntime: true,
              issueId: "4e2bb724941911e3023818ee683350ae",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker installs a backdoor to maintain access to the compromised system.",
            technique: "T1547",
            prerequisites: "Attacker has command execution capabilities.",
            metadata: null
          },
          {
            title: "Credential Access",
            details:
              "Attacker extracts sensitive information, such as database credentials, from the compromised system.",
            technique: "T1003",
            prerequisites: "Attacker has persistent access to the system.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates the stolen credentials and other sensitive data to an external server.",
            technique: "T1041",
            prerequisites: "Attacker has access to sensitive information.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes code, installs backdoor, steals credentials, and exfiltrates data.",
        is_severe: true,
        kodemScore: 14680,
        scenario_id: 4,
        title: "Backdoor Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains access by exploiting CVE-2023-51385 in OpenSSH client.",
            technique: "T1078",
            riskId: "CVE-2023-51385",
            package_name: "openssh-client",
            prerequisites: "Attacker has network access to the target system.",
            metadata: {
              kodemScore: 394,
              riskId: "CVE-2023-51385",
              severity: "medium",
              isRuntime: false,
              issueId: "e27b5b6f261713eba12cedb8962b56ff",
              fixability: "FIXABILITY_HAS_FIX",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uses CVE-2022-30580 to execute arbitrary binaries in the working directory.",
            technique: "T1059",
            riskId: "CVE-2022-30580",
            package_name: "stdlib",
            prerequisites: "Initial access gained through CVE-2023-51385.",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2022-30580",
              severity: "high",
              isRuntime: true,
              issueId: "4e2bb724941911e3023818ee683350ae",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker creates a malicious binary in the working directory to maintain access.",
            technique: "T1547",
            prerequisites:
              "Execution of arbitrary binaries using CVE-2022-30580.",
            metadata: null
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits CVE-2023-34152 in ImageMagick to gain criticaler privileges.",
            technique: "T1068",
            riskId: "CVE-2023-34152",
            package_name: "imagemagick",
            prerequisites: "Persistence established with a malicious binary.",
            metadata: {
              kodemScore: 568,
              riskId: "CVE-2023-34152",
              severity: "critical",
              isRuntime: false,
              issueId: "3b8156b664855394d558be3522abc2dd",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "T1070",
            prerequisites:
              "Privilege escalation achieved through CVE-2023-34152.",
            metadata: null
          },
          {
            title: "Impact",
            details:
              "Attacker exfiltrates sensitive data from the compromised system.",
            technique: "T1020",
            prerequisites: "Defense evasion by clearing logs.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes code, escalates privileges, and exfiltrates data.",
        is_severe: false,
        kodemScore: 1688,
        scenario_id: 1,
        title: "Privilege Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a malicious file to the application using untrusted input.",
            technique: "T1190",
            riskId: "CVE-2015-20107",
            package_name: "libpython3.9-minimal",
            prerequisites:
              "Application calls mailcap.findmatch with untrusted input.",
            metadata: {
              kodemScore: 452,
              riskId: "CVE-2015-20107",
              severity: "high",
              isRuntime: false,
              issueId: "29173b25bf4cac746a7c5854214c191c",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "The malicious file is processed, leading to shell command injection.",
            technique: "T1059.004",
            riskId: "CVE-2015-20107",
            package_name: "libpython3.9-minimal",
            prerequisites: "Initial access with malicious file.",
            metadata: {
              kodemScore: 452,
              riskId: "CVE-2015-20107",
              severity: "high",
              isRuntime: false,
              issueId: "29173b25bf4cac746a7c5854214c191c",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Exploit code injection vulnerability to execute binaries in the working directory.",
            technique: "T1068",
            riskId: "CVE-2022-30580",
            package_name: "stdlib",
            prerequisites: "Shell command injection executed.",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2022-30580",
              severity: "high",
              isRuntime: true,
              issueId: "4e2bb724941911e3023818ee683350ae",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Install a backdoor to maintain access to the compromised system.",
            technique: "T1547.001",
            prerequisites: "Privilege escalation achieved.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Clear logs to remove traces of the attack.",
            technique: "T1070.001",
            prerequisites: "Backdoor installed.",
            metadata: null
          },
          {
            title: "Impact",
            details: "Exfiltrate sensitive data from the compromised system.",
            technique: "T1002",
            prerequisites: "Logs cleared.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, escalates privileges, installs backdoor, and exfiltrates data.",
        is_severe: false,
        kodemScore: 1630,
        scenario_id: 3,
        title: "Backdoor Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker sends a specially crafted email to bypass domain verification.",
            technique: "T1078.003",
            riskId: "CVE-2023-27043",
            package_name: "libpython3.9-stdlib",
            prerequisites: "None",
            metadata: {
              kodemScore: 331,
              riskId: "CVE-2023-27043",
              severity: "medium",
              isRuntime: false,
              issueId: "56e1632f57c4438d3f5ac6081b1b6668",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker exploits code injection vulnerability to execute arbitrary binaries.",
            technique: "T1059.001",
            riskId: "CVE-2022-30580",
            package_name: "stdlib",
            prerequisites: "Initial access through CVE-2023-27043",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2022-30580",
              severity: "high",
              isRuntime: true,
              issueId: "4e2bb724941911e3023818ee683350ae",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker creates a malicious binary in the working directory for future execution.",
            technique: "T1547.001",
            prerequisites:
              "Execution of arbitrary binaries through CVE-2022-30580",
            metadata: null
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker exploits improper input validation to gain criticaler privileges.",
            technique: "T1068",
            riskId: "CVE-2020-12363",
            package_name: "linux-libc-dev",
            prerequisites:
              "Persistence with a malicious binary in the working directory",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2020-12363",
              severity: "medium",
              isRuntime: false,
              issueId: "d56f76fcf8d634f9e963535c7dff678c",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          }
        ],
        details:
          "Attacker gains access, executes code, persists, and escalates privileges.",
        is_severe: false,
        kodemScore: 1399,
        scenario_id: 6,
        title: "Code Execution Persistence"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker exploits OpenSSH vulnerability to determine valid user accounts.",
            technique: "Valid Accounts",
            riskId: "CVE-2007-2243",
            package_name: "openssh-client",
            prerequisites:
              "OpenSSH service is running and ChallengeResponseAuthentication is enabled.",
            metadata: {
              kodemScore: 316,
              riskId: "CVE-2007-2243",
              severity: "medium",
              isRuntime: false,
              issueId: "2403433bed84e82fd786574b776de42d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker uses code injection vulnerability to execute arbitrary binaries in the working directory.",
            technique: "Command and Scripting Interpreter",
            riskId: "CVE-2022-30580",
            package_name: "stdlib",
            prerequisites:
              "Attacker has valid user account and access to the working directory.",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2022-30580",
              severity: "high",
              isRuntime: true,
              issueId: "4e2bb724941911e3023818ee683350ae",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Credential Access",
            details:
              "Attacker retrieves password from memory buffer due to improper cleanup.",
            technique: "Credentials from Password Stores",
            riskId: "CVE-2023-4641",
            package_name: "passwd",
            prerequisites:
              "Attacker has access to the system and can trigger password change process.",
            metadata: {
              kodemScore: 342,
              riskId: "CVE-2023-4641",
              severity: "medium",
              isRuntime: false,
              issueId: "8efbf06750f04a3f42de8a00430df5d3",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Persistence",
            details:
              "Attacker installs a backdoor to maintain access to the compromised system.",
            technique: "Implant Internal Image",
            prerequisites:
              "Attacker has administrative privileges on the system.",
            metadata: null
          },
          {
            title: "Exfiltration",
            details:
              "Attacker exfiltrates sensitive data from the compromised system.",
            technique: "Exfiltration Over C2 Channel",
            prerequisites:
              "Attacker has established a C2 channel and access to sensitive data.",
            metadata: null
          }
        ],
        details:
          "Attacker gains access, executes code, retrieves credentials, installs backdoor, and exfiltrates data.",
        is_severe: false,
        kodemScore: 1384,
        scenario_id: 5,
        title: "Code Execution & Exfiltration"
      },
      {
        steps: [
          {
            title: "Initial Access",
            details:
              "Attacker gains initial access by exploiting a vulnerable SUID binary to set dumpable flag.",
            technique: "Exploit Public-Facing Application",
            riskId: "CVE-2021-3864",
            package_name: "linux-libc-dev",
            prerequisites: "Presence of a vulnerable SUID binary.",
            metadata: {
              kodemScore: 421,
              riskId: "CVE-2021-3864",
              severity: "high",
              isRuntime: false,
              issueId: "3ff3789aa22e755a6b51c71376b29b7d",
              fixability: "FIXABILITY_NOT_FIXABLE",
              language: "deb",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Execution",
            details:
              "Attacker executes a crafted binary in the working directory to exploit code injection.",
            technique: "Command and Scripting Interpreter",
            riskId: "CVE-2022-30580",
            package_name: "stdlib",
            prerequisites: "Initial access gained and dumpable flag set.",
            metadata: {
              kodemScore: 726,
              riskId: "CVE-2022-30580",
              severity: "high",
              isRuntime: true,
              issueId: "4e2bb724941911e3023818ee683350ae",
              fixability: "FIXABILITY_HAS_FIX",
              language: "go",
              type: "Vulnerable_Package",
              exploitMaturity: "UNDETERMINED"
            }
          },
          {
            title: "Privilege Escalation",
            details:
              "Attacker uses core dump in root-owned directory to escalate privileges.",
            technique: "Exploitation for Privilege Escalation",
            prerequisites: "Core dump created in root-owned directory.",
            metadata: null
          },
          {
            title: "Defense Evasion",
            details: "Attacker clears logs to avoid detection.",
            technique: "Indicator Removal on Host",
            prerequisites: "Elevated privileges obtained.",
            metadata: null
          },
          {
            title: "Credential Access",
            details: "Attacker extracts sensitive information from core dumps.",
            technique: "Credentials from Password Stores",
            prerequisites: "Access to core dumps in root-owned directory.",
            metadata: null
          }
        ],
        details:
          "Attacker gains initial access, escalates privileges, and extracts sensitive information.",
        is_severe: false,
        kodemScore: 1147,
        scenario_id: 7,
        title: "Privilege Extraction"
      }
    ]
  }
} as unknown as Record<string, { scenarios: AttackChain[] }>
