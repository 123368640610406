import { createContext, Dispatch, useContext, useReducer } from "react"

import { getInitialState, reducer } from "./useSelection.reducer"

import type { Action, Item, State } from "./useSelection.types"

export * as actions from "./useSelection.actions"

export const SelectionContext = createContext<[any, Dispatch<Action>]>([
  getInitialState(),
  () => {}
])

export const useSelection = <I extends Item>() => {
  const value = useContext<[State<I>, Dispatch<Action>]>(SelectionContext)

  return value
}

export const useSelectionState = () => {
  const value = useReducer(reducer, getInitialState())

  return value
}
