import { ExportButton } from "@/molecules/ExportButton"
import type { DatePickerExportContainerProps } from "."
import { Filter } from "../FilterNew/Filter"
import styles from "./DatePickerExportContainer.module.scss"

export const DatePickerExportContainer = ({
  exportType,
  isLoading
}: DatePickerExportContainerProps) => {
  return (
    <div className={styles.container}>
      <Filter.DatePicker isLoading={isLoading} />
      <ExportButton isLoading={isLoading} exportType={exportType} />
    </div>
  )
}
