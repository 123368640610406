import cn from "classnames"
import { useEffect, useRef, useState } from "react"

import { Button } from "@/atoms"
import { useToggle } from "@/hooks/useToggle"
import { useTranslation } from "@/hooks/useTranslation"

import styles from "./LineClamp.module.scss"

import type { LineClampComponent } from "."

export const LineClamp: LineClampComponent = (props) => {
  const { children, clamp } = props
  const [attr, setAttr] = useState({ lines: 0, lineHeight: 0 })
  const { value: collapsed, toggle } = useToggle(true)
  const { t } = useTranslation()

  useEffect(() => {
    if (!refContainer.current) return

    const el = refContainer.current
    const offsetHeight = el.offsetHeight
    const lineHeight =
      props.itemHeight || parseFloat(window.getComputedStyle(el).lineHeight)

    if (!offsetHeight || !lineHeight) return

    const lines = Math.round(offsetHeight / lineHeight)

    setAttr({ lines, lineHeight })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refContainer = useRef<HTMLDivElement>(null)
  const { lines, lineHeight } = attr
  const needTruncate = lines > clamp
  const { classes = {} } = props
  const className = cn(
    styles.container,
    lines > 0 && styles.text,
    needTruncate && styles.truncate,
    classes?.container
  )

  const heightDisplayAllText = "auto"
  const firstRender = !lines && !lineHeight
  const maxHeight =
    firstRender || !needTruncate
      ? heightDisplayAllText
      : `${lines * lineHeight}px`

  const heightInTruncateState = clamp * lineHeight
  const useHeightInTruncateState = collapsed && !firstRender && needTruncate
  const height = useHeightInTruncateState
    ? `${heightInTruncateState}px`
    : maxHeight

  const textInButton = t(collapsed ? "general.showMore" : "general.showLess")

  return (
    <>
      <div style={{ height }} className={className} ref={refContainer}>
        {children}
      </div>
      {needTruncate && (
        <Button
          size="small"
          className={styles.button}
          type="link"
          onClick={toggle}
        >
          {textInButton}
        </Button>
      )}
    </>
  )
}
