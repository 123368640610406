import type { Package } from "."

export const packageHasUltimateFix = (packageData: Package) =>
  packageData?.ultimateFix

export const packageHasFix = (packageData: Package) =>
  packageHasUltimateFix(packageData)

export const getCurrentVersion = (packageData: Package) => packageData.version

export const getUltimateFixVersion = (packageData: Package) =>
  packageData.ultimateFix

export const getSuggestedFixVersion = (packageData: Package) => ({
  currentVersion: getCurrentVersion(packageData),
  suggestedVersion: getUltimateFixVersion(packageData)
})

export const splitCommand = (command?: string) => {
  if (!command) return {}

  const trimmedCommand = command.trim()
  const indexOfFirst = trimmedCommand.indexOf(" ")
  const instruction = trimmedCommand.substring(0, indexOfFirst)
  const commandArgument = trimmedCommand.substring(indexOfFirst + 1)

  if (!instruction || !commandArgument) return {}

  return { instruction, commandArgument }
}
