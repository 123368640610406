import type {
  EnvironmentResponse,
  SnapshotResponse
} from "@/api/useInventory.types"

export const snapshotInventoryPropsSelector = (response: SnapshotResponse) => {
  const snapshotData = response?.data || {}

  return snapshotData || {}
}

export const getAllEnvironmentOption = () => ({
  value: "all",
  name: "",
  label: "All Environments"
})

export const environmentInventoryPropsSelector = (
  response: EnvironmentResponse
) => {
  const environmentData = response?.data || []

  return {
    options: [
      getAllEnvironmentOption(),
      ...environmentData.map(({ id, data, displayName }) => ({
        value: id,
        label: displayName,
        name: data.name
      }))
    ]
  }
}
