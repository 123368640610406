import { AbstractTextHandler } from "./text-handler"

import type { PropsTextHandler, Token } from "./text-handler.types"

export class KeyTextHandler extends AbstractTextHandler {
  public handle(token: Token, props: PropsTextHandler): string {
    const { addString, StringService } = props

    if (
      this.needAddValueToToken(props) &&
      !Array.isArray(addString) &&
      !StringService.isCurrentCaretPosInValue()
    ) {
      const { currentTextLength } = props
      const [, , textInCaretPos] = StringService.getDataByCaretPos()
      const isPreviousSpace = this.getPreviousToken(props)?.isEmpty
      const isNextSpace = this.getNextToken(props)?.isEmpty
      const prefix = isPreviousSpace ? "" : "\u00A0"
      const suffix = isNextSpace ? "" : "\u00A0"

      if (StringService.isEmptyString(textInCaretPos)) {
        const result = `${prefix}${addString}:${suffix}`

        StringService.setCaretPos(currentTextLength + result.length - 1)

        return result
      }

      const result = `${prefix}${addString}:`

      StringService.setCaretPos(currentTextLength + result.length)

      return result
    }

    return super.handle(token, props)
  }
}
