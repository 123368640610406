import { Avatar, Tooltip } from "@/atoms"
import { getInitials } from "@/domain/user"

import styles from "./Avatars.module.scss"

import type { AvatarsProps } from "."

export const Avatars = ({ list = [], maxCount = 1 }: AvatarsProps) => (
  <Avatar.Group className={styles.container} max={{ count: maxCount }}>
    {list.map((item: any, index: number) => (
      <Tooltip
        destroyTooltipOnHide
        key={`${item.name}_${index}`}
        title={item.name}
        className={styles.tooltip}
      >
        <Avatar>{getInitials(item.name)}</Avatar>
      </Tooltip>
    ))}
  </Avatar.Group>
)
