import { RESOURCES_NAMES } from "@/const/resource.constants"

export const getQueriesSBOM = (params: Record<string, unknown> = {}) => {
  const paramsRuntimeSBOM = { isPackageRuntime: "YES", ...params }
  const paramsAllSBOM = params

  return [
    { url: RESOURCES_NAMES.INVENTORIES.PACKAGE, params: paramsAllSBOM },
    {
      url: RESOURCES_NAMES.INVENTORIES.PACKAGE,
      params: paramsRuntimeSBOM
    }
  ]
}
