import { SourceControlIcon } from "@/icons"

import { IconTextCell } from "@/molecules/IconTextCell"
import type { RepositoryNameCellComponent } from "./RepositoryNameCell.types"

export const RepositoryNameCell: RepositoryNameCellComponent = (
  value,
  { sourceControl }
) => {
  return (
    <IconTextCell
      icon={<SourceControlIcon sourceControl={sourceControl} />}
      text={value}
    />
  )
}
