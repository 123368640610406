import { Text } from "@/atoms"
import { List } from "@/pages/DiscoveryPage/components/molecules/List"

import { splitCommand } from "@/domain/package"
import { ImportCommandCard } from "@/molecules/ImportCommandCard"
import { HistoryCommandsListProps } from "./HistoryCommandsList.types"

import styles from "./HistoryCommandsList.module.scss"

export const HistoryCommandsList: React.FC<HistoryCommandsListProps> = (
  props
) => {
  const { historyEntries, title } = props

  if (!historyEntries?.length) {
    return null
  }

  return (
    <>
      {title && <Text className={styles.title}>{title}</Text>}
      <List
        classes={{ list: styles.commandsWrapper, button: styles.buttonWrapper }}
        limit={3}
        items={historyEntries}
        render={(command, i) => {
          const { instruction, commandArgument } = splitCommand(
            command.createdBy
          )
          if (!instruction || !commandArgument) return null
          return (
            <ImportCommandCard
              key={`${instruction}_${i}`}
              isImportedFromBaseImage={false}
              layerIndex={command.layerIndex}
              instruction={instruction}
              commandArgument={commandArgument}
              createdBy={command.createdBy}
              dockerCommand={null}
              dockerfileMatch={false}
              dockerfilePath={null}
            />
          )
        }}
      />
    </>
  )
}
