import cn from "classnames"

import { FixabilityIcon } from "@/icons"
import { IconTag } from "@/molecules/IconTag"

import styles from "./FixabilityCell.module.scss"

import type { Fixability } from "@/api/useIssues.types"
import type { TFunction } from "@/hooks/useTranslation"

export const FixabilityCell = (t: TFunction) => (fixability: Fixability) => (
  <IconTag
    iconSize="sm"
    classes={{
      tag: cn(
        styles.common,
        styles[fixability === "has_fix" ? "hasFix" : fixability]
      )
    }}
    icon={<FixabilityIcon fixability={fixability} />}
    text={t(`general.fixability.${fixability}`)}
  />
)
