import { Button, Divider } from "@/atoms"
import { DATA_OPENED_IN_DRAWER } from "@/const/table-constants"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { ExpandedRowTable } from "@/molecules/ExpandedRowTable"
import { PACKAGE_DRAWER_ID } from "@/organisms/Drawers/const"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { useProjectsByPackage } from "@/pages/DiscoveryPage/application/sbom-inventory/useSbomInventory"
import { getColumnsExpandedRow } from "./getColumnsExpandedRow"

export const ExpandedRowSbomTab = ({
  expanded,
  packageId,
  params,
  onCollapsed
}: any) => {
  const response = useProjectsByPackage(
    { needle: `/${packageId}/projects`, ...params },
    { enabled: !!expanded }
  )
  const { onSorterChange, ...orderByProps } = useOrderBy()
  const { t } = useTranslation()
  const convertors = useNumberConvertor()
  const { idsInDrawers, onRowClick } = useDrawer(PACKAGE_DRAWER_ID, {
    filters: []
  })
  const columnProps = { t, convertors, orderByProps, onRowClick, inline: true }
  const columns = getColumnsExpandedRow(columnProps)
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    response || {}
  const pages = data?.pages || []

  useTooltip(pages)

  return (
    <ExpandedRowTable
      initialMinHeight
      loading={isLoading}
      columns={columns}
      dataSource={pages}
      onRow={(record) => {
        return {
          [DATA_OPENED_IN_DRAWER]: !!idsInDrawers?.[record.id],
          onClick: () => onRowClick(record)
        }
      }}
      footer={() => (
        <>
          <Button
            disabled={!hasNextPage}
            loading={isFetchingNextPage}
            size="small"
            type="link"
            onClick={() => fetchNextPage()}
          >
            Show more
          </Button>
          <Divider type="vertical" />
          <Button size="small" type="link" onClick={onCollapsed}>
            Collapse all
          </Button>
        </>
      )}
    />
  )
}
