import cn from "classnames"

import { Title } from "@/atoms"
import { InformationPopover } from "@/molecules/InformationPopover"

import styles from "./Heading.module.scss"

import { HeadingProps, LevelTitleAntd } from "."

export const Heading: React.FC<HeadingProps> = (props) => {
  const { title, level, popover, icon, suffix, classes = {} } = props
  const additionalLevel = level && level > 5
  const currentLevel = additionalLevel ? 5 : (level as LevelTitleAntd)

  return (
    <div className={styles.container}>
      <Title
        className={cn(
          styles.title,
          {
            [styles.level6]: level === 6,
            [styles.icon]: !!icon
          },
          classes.title
        )}
        level={currentLevel}
      >
        {!!icon && icon}
        {title}
        {suffix}
      </Title>
      {popover && (
        <InformationPopover.Popover
          classNamePopover={popover.className}
          placement={popover.placement}
          content={
            <InformationPopover.CommonTemplate title={popover.title}>
              {popover.content}
            </InformationPopover.CommonTemplate>
          }
        />
      )}
    </div>
  )
}
