import configJSON from "./config.json"

export const ENV = configJSON.ENVIRONMENT || process.env.NODE_ENV
export const RELEASE = configJSON.KOSMOS_UI_CONFIG_APP_RELEASE

export const isDev = ENV === "development"

// Firebase ENV variables
export const FIREBASE_API_KEY = configJSON.KOSMOS_UI_CONFIG_FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN =
  configJSON.KOSMOS_UI_CONFIG_FIREBASE_AUTH_DOMAIN

// Loggers ENV variables
export const SENTRY_DSN = configJSON.KOSMOS_UI_CONFIG_SENTRY_DSN || ""
export const MIXPANEL_TOKEN = configJSON.KOSMOS_UI_CONFIG_MIXPANEL_TOKEN || ""
export const MIXPANEL_PROXY_URL =
  configJSON.KOSMOS_UI_CONFIG_MIXPANEL_PROXY_URL || ""
export const INTERCOM_APP_ID = (window as any).APP_ID_INTERCOM || ""

// E2E ENV variables
export const isE2E = ENV === "e2e"

//recaptcha env variables
export const RECAPTCHA_SITE_KEY =
  configJSON.KOSMOS_UI_CONFIG_RECAPTCHA_SITE_KEY || ""
