import type { Services } from "@/interfaces/services"

export interface Transport {
  level: Services.LoggerAdapter.LevelKeys
  log(info: Services.LoggerAdapter.Info): void
  format?: (
    info: Services.LoggerAdapter.Info
  ) => Services.LoggerAdapter.Info | false
  identify?: (data: Services.LoggerAdapter.IdentificationData) => void
  reset?: () => void
  setDefaultMetaAttributes?: (attributes: Record<string, string>) => void
}

abstract class AbstractTransport implements Transport {
  protected transportLevel: Services.LoggerAdapter.LevelKeys

  constructor({ level }: { level: Services.LoggerAdapter.LevelKeys }) {
    this.transportLevel = level
  }

  get level() {
    return this.transportLevel
  }

  public abstract log(info: Services.LoggerAdapter.Info): void
}

export { AbstractTransport }
