import { useState } from "react"
import { Navigate } from "react-router-dom"

import { STEPS_MFA } from "@/const/provider-auth.constants"
import { ConfirmCodeMultiFactorForm } from "../../molecules/ConfirmCodeMultiFactorForm"
import { PhoneNumberMultiFactorForm } from "../../molecules/PhoneNumberMultiFactorForm"
import { SuccessMultiFactorForm } from "../../molecules/SuccessMultiFactorForm"
import { VerificationMethodMultiFactorForm } from "../../molecules/VerificationMethodMultiFactorForm"

export const MultiFactorSetUp = () => {
  const [values, setValues] = useState({ step: 0 })
  const prevStep = () =>
    setValues((currentValues) => ({ ...currentValues, step: values.step - 1 }))
  const nextStep = () =>
    setValues((currentValues) => ({ ...currentValues, step: values.step + 1 }))
  const { step } = values
  const props = { values, onOk: nextStep, onCancel: prevStep }

  if (step === STEPS_MFA.CHOOSE_MFA_METHOD) {
    return <VerificationMethodMultiFactorForm {...props} />
  }

  if (step === STEPS_MFA.INPUT_PHONE_NUMBER) {
    return (
      <PhoneNumberMultiFactorForm
        values={values}
        onCancel={props.onCancel}
        onOk={(nextValues) => {
          setValues({ ...values, ...nextValues })
          props.onOk()
        }}
      />
    )
  }

  if (step === STEPS_MFA.CONFIRM_CODE) {
    return <ConfirmCodeMultiFactorForm {...props} />
  }

  if (step === STEPS_MFA.SUCCESS) {
    return <SuccessMultiFactorForm {...props} />
  }

  return <Navigate to="/" />
}
