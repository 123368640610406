import cn from "classnames"

import { Tag } from "@/atoms"

import styles from "./IconTag.module.scss"

import type { IconTagProps } from "."

export const IconTag = ({
  icon,
  text,
  iconSize = "xl",
  classes = {}
}: IconTagProps) => {
  const className = cn(
    styles.tag,
    { [styles.sm]: iconSize === "sm" },
    classes.tag
  )
  return (
    <Tag className={className}>
      {icon}
      {text}
    </Tag>
  )
}
