import cn from "classnames"

import {
  useCreateLabel,
  useGetColorsLabels,
  useGetLabels,
  useUpdateLabel
} from "@/api/useLabels"
import { Button, Form, Input, Modal, Text, message } from "@/atoms"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { getColorVariable } from "@/domain/label"
import { useCustomFunctionsForm } from "@/hooks/useCustomFunctionsForm"
import { useQueryClient } from "@/hooks/useHttp"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import { FooterModal } from "@/molecules/FooterModal"
import { MAX_LABEL_LENGTH } from "../../const"
import { ModalTemplate } from "../ModalTemplate"

import styles from "./CreateModal.module.scss"

import type { Label } from "@/domain/label"
import type { CreateModalComponent } from "."

export const CreateModal: CreateModalComponent = (props) => {
  const { open, onCreate, onCancel, label } = props
  const [form] = Form.useForm()
  const selectedColor = Form.useWatch("color", form)
  const { response, isLoading } = useGetColorsLabels()
  const colors = response?.data || []
  const getInitialState = () => ({
    color:
      colors[Math.floor(Math.random() * (colors.length - 1) + 1) - 1] ||
      colors[0],
    ...label
  })
  const createContext = useCreateLabel()
  const updateContext = useUpdateLabel()
  const { response: labelsRes, isRefetching: labelsIsRefetching } =
    useGetLabels()
  const queryClient = useQueryClient()
  const isUpdate = !!label?.id
  const { t: tForm } = useTranslation("translation", {
    keyPrefix: "settings.labels.modal.form"
  })
  const okText = tForm(isUpdate ? "update" : "create")
  const loading =
    createContext.isPending || updateContext.isPending || labelsIsRefetching
  const nameInput = "name"
  const { isDirty, onFieldsChange } = useCustomFunctionsForm({
    ref: form.getFieldInstance(nameInput),
    visible: open,
    isDirty: !!label?.name && !isUpdate
  })
  const [messageApi, contextHolder] = message.useMessage()
  const htmlType = "submit" as const
  const disabled = !isDirty
  const formId = "LabelsForm"
  const okBtnProps = { htmlType, disabled, loading, form: formId }
  const { t } = useTranslation()
  const { logger, EVENTS } = useLogger()

  const rules = [
    { required: true, message: t("error.requiredField") },
    {
      validator: (_: unknown, value: string) => {
        const data = labelsRes?.data || []
        const labelIndex = data.findIndex((label) => label.name === value)
        const exist = labelIndex !== undefined && labelIndex !== -1

        if (exist && isUpdate && label.name === data[labelIndex].name)
          return Promise.resolve()

        if (exist) return Promise.reject(tForm("error.labelExist"))

        return Promise.resolve()
      }
    }
  ]

  return (
    <>
      <Modal
        destroyOnClose
        open={open}
        cancelText={t("general.cancel")}
        zIndex={2000}
        onCancel={onCancel}
        okText={okText}
        okButtonProps={okBtnProps}
        footer={FooterModal}
      >
        <ModalTemplate
          title={tForm(`title.${isUpdate ? "update" : "create"}`)}
          isLoading={isLoading}
          classes={{ container: styles.content }}
        >
          <Form
            clearOnDestroy
            id={formId}
            autoComplete="off"
            form={form}
            initialValues={getInitialState()}
            layout="vertical"
            preserve={false}
            onFieldsChange={onFieldsChange}
            onFinish={async (values) => {
              try {
                let newLabel
                const payload = { label: values }
                const { component, placement } = props
                const newName = values?.name
                const meta = { component, placement, newName }

                if (isUpdate) {
                  await updateContext.mutateAsync({
                    needle: `/${label.id}`,
                    data: payload
                  })

                  logger.info(EVENTS.ANALYTIC_EVENTS.LABELS.UPDATE, {
                    labelId: label.id,
                    oldName: label.name,
                    ...meta
                  })
                } else {
                  const result = (await createContext.mutateAsync(payload)) as {
                    data?: Label
                  }

                  logger.info(EVENTS.ANALYTIC_EVENTS.LABELS.CREATE, meta)

                  newLabel = result?.data
                }

                await queryClient.invalidateQueries({
                  queryKey: [RESOURCES_NAMES.LABELS.LIST]
                })

                queryClient.invalidateQueries({
                  queryKey: [RESOURCES_NAMES.TRIAGE.FILTERS]
                })

                const text = isUpdate ? "message.updated" : "message.created"

                messageApi.success(tForm(text, { name: payload.label.name }))

                if (onCreate && newLabel) return onCreate(newLabel)

                onCancel()
              } catch (error: any) {
                messageApi.error(error?.data?.detail || t("error.serverError"))
              }
            }}
          >
            <Form.Item
              label={tForm("name.label")}
              name={nameInput}
              rules={rules}
              required={false}
            >
              <Input
                showCount
                placeholder={tForm("name.placeholder")}
                maxLength={MAX_LABEL_LENGTH}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {tForm("description.label")}{" "}
                  <Text type="secondary">({t("general.optional")})</Text>
                </span>
              }
              name="description"
            >
              <Input placeholder={tForm("description.placeholder")} />
            </Form.Item>
            <Form.Item label={tForm("colors")} name="color">
              <span className={styles.colorFieldItem}>
                {colors.map((color) => (
                  <Button
                    key={color}
                    style={{ background: getColorVariable(color) }}
                    className={cn(
                      styles.colorTag,
                      selectedColor === color && styles.selected
                    )}
                    onClick={() => {
                      form.setFieldsValue({ color })
                      onFieldsChange()
                    }}
                  />
                ))}
                <div className={styles.colorWrap}></div>
              </span>
            </Form.Item>
          </Form>
        </ModalTemplate>
      </Modal>
      {contextHolder}
    </>
  )
}
