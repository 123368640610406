import { Dropdown } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import { MenuDropdown } from "../MenuDropdown"

import styles from "./DropdownSmartSearch.module.scss"

export const DropdownSmartSearch = ({
  open,
  onClick,
  Input,
  r,
  id,
  services
}: {
  open?: boolean
  data: any
  onClick: (e: any) => void
  Input: React.ReactNode
  id: string
  services: any
  r: any
}) => {
  const { t } = useTranslation()
  const { logger } = useLogger()
  const itemProps = { id, t, logger, services, onClick }

  return (
    <Dropdown
      destroyPopupOnHide
      open={open}
      rootClassName={styles.container}
      trigger={["click"]}
      getPopupContainer={() => r.current}
      dropdownRender={() => <MenuDropdown {...itemProps} />}
    >
      {Input}
    </Dropdown>
  )
}
