import type { State } from "@/pages/TriagePage/application/useSelection.types"
import type { Issue } from "."

export const isDismissed = (issue: Issue) => issue.status === "dismissed"

export const isOpen = (issue: Issue) => issue.status === "open"

export const isIssueForReopen = (issues: Issue[]) => issues.some(isDismissed)

export const isIssueForDismissed = (issues: Issue[]) => issues.some(isOpen)

export const getInitialState = () => ({
  issuesCount: 0,
  issuesTotal: 0,
  reOpen: false,
  dismiss: false,
  projectsCount: 0
})

export const getProjectIds = (selectedIssues: State["items"]) =>
  Object.keys(selectedIssues)

export const getStateFromSelectedIssues = (selectedState: State<Issue>) => {
  try {
    const selectedIssues = selectedState.items
    const projectIds = getProjectIds(selectedIssues)

    return projectIds.reduce((acc, projectId) => {
      const projectState = selectedIssues[projectId] || {}

      if (projectState.updating) return acc

      const {
        selectedNestedIds = [],
        nestedItems = [],
        metadata = {}
      } = projectState
      let { issuesCount, issuesTotal, reOpen, dismiss, projectsCount } = acc

      const selectedNestedItems = nestedItems.filter(({ id }) =>
        selectedNestedIds.includes(id)
      )

      if (selectedNestedIds.length) {
        issuesCount += selectedNestedIds.length
        issuesTotal += metadata?.issuesTotal || 0
        projectsCount += 1
      }

      if (!acc.reOpen) {
        reOpen = isIssueForReopen(selectedNestedItems)
      }

      if (!acc.dismiss) {
        dismiss = isIssueForDismissed(selectedNestedItems)
      }

      return {
        issuesCount,
        issuesTotal,
        reOpen,
        dismiss,
        projectsCount
      }
    }, getInitialState())
  } catch (err) {
    return null
  }
}

export const filterIssuesByProject = (
  selectedIssues: State<Issue>["items"],
  predicate: (issue: Issue) => boolean
) =>
  getProjectIds(selectedIssues).reduce(
    (acc, projectId) => {
      const { nestedItems = [], selectedNestedIds = [] } =
        selectedIssues[projectId] || {}
      const selectedNestedItems = nestedItems.filter(({ id }) =>
        selectedNestedIds.includes(id)
      )

      const issues = selectedNestedItems.filter(predicate)

      if (!issues?.length) return acc

      acc[projectId] = issues

      return acc
    },
    {} as Record<string, Issue[]>
  )

export const getIssuesForDismissByProject = (
  selectedIssues: State<Issue>["items"]
) => filterIssuesByProject(selectedIssues, isOpen)

export const getIssuesForReopenByProject = (
  selectedIssues: State<Issue>["items"]
) => filterIssuesByProject(selectedIssues, isDismissed)
