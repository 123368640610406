import { CellTable, type SortOrder } from "@/atoms"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { HeaderCell } from "@/molecules/HeaderCell"
import { ImpactCell } from "@/molecules/ImpactCell"
import { IssuesSummaryCell } from "@/molecules/IssuesSummaryCell"
import { LanguageIconCell } from "@/molecules/LanguageIconCell"
import { MoreTableCell } from "@/molecules/MoreTableCell"
import { ResourcesCell } from "@/molecules/Resources"
import { InsightsSbomCell } from "../../molecules/InsightsSbomCell"
import { PackageTooltip } from "./PackageTooltip"

import type { ColumnsProps } from "."

export const getColumnsTable = (props: ColumnsProps) => {
  const { orderByProps, placement, onRowClick, previewMode } = props
  const isDrawer = placement === "drawer"
  const projectRender = isDrawer
    ? ResourcesCell({ inline: true, ...props })
    : CellTable
  const projectFieldName = isDrawer ? "resources" : "projects"

  const columns = [
    {
      title: isDrawer
        ? "Package"
        : HeaderCell({ title: "Package", Content: PackageTooltip }),
      render: LanguageIconCell,
      ...getDefaultColumnParams("name", orderByProps)
    },
    {
      title: "Version",
      width: 100,
      render: CellTable,
      ...getDefaultColumnParams("version", orderByProps)
    },
    {
      title: "License",
      width: 120,
      render: MoreTableCell,
      ...getDefaultColumnParams("license", orderByProps)
    },
    {
      title: "Vulnerabilities",
      width: 220,
      render: IssuesSummaryCell(props, { limit: 3 }),
      ...getDefaultColumnParams("issuesSummary", orderByProps),
      defaultSortOrder: "descend" as SortOrder,
      sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
    },
    {
      title: "Insights",
      width: 120,
      render: InsightsSbomCell(props),
      ...getDefaultColumnParams("insights")
    },
    {
      title: "Projects",
      width: 150,
      render: projectRender,
      ...getDefaultColumnParams(projectFieldName, orderByProps, {
        customSorterName: "projectsCount"
      })
    },
    {
      title: "Apps",
      width: 70,
      render: ImpactCell({ onRowClick }),
      className: "center-align-tab-template-component",
      ...getDefaultColumnParams("applicationsCount")
    },
    {
      title: "Last Seen",
      width: 130,
      render: CellTable,
      ...getDefaultColumnParams("lastSeenDate")
    }
  ]

  if (!previewMode) return columns

  return columns.filter(({ key }) => {
    if (key === "lastSeenDate") return false
    if (key === "license") return false

    return true
  })
}
