import type { ShowMoreProps } from "."
import { ComponentLabel } from "./components/ComponentLabel"
import { ComponentShowMore } from "./components/ComponentShowMore"
import { CountLabelShowMore } from "./components/CountLabelShowMore"
import { PopoverShowMore } from "./components/PopoverShowMore"
import { TextLabelShowMore } from "./components/TextLabelShowMore"
import { TooltipShowMore } from "./components/TooltipShowMore"

import styles from "./ShowMore.module.scss"

export const ShowMore = ({ children, label, id }: ShowMoreProps) => {
  const tooltipId = `${id || Date.now()}_templateId-showMore`

  return (
    <>
      <span data-template-id-show-more={tooltipId}>{label}</span>
      <div id={tooltipId} className={styles.template}>
        {children}
      </div>
    </>
  )
}

ShowMore.Label = {
  Count: CountLabelShowMore,
  Text: TextLabelShowMore,
  Component: ComponentLabel
}

ShowMore.Tooltip = TooltipShowMore
ShowMore.Popover = PopoverShowMore
ShowMore.Component = ComponentShowMore
