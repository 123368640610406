import { Button, Link, Text, Title } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import { ConnectPlug, ReadOutlined } from "@/icons"

import { isUserAdmin } from "@/domain/user"
import { useUser } from "@/hooks/useUser"
import { InformationCard } from "@/molecules/InformationCard"
import { HowToUseIntegration } from "../HowToUseIntegration"
import styles from "./ItemIntegration.module.scss"

export const ItemIntegration = ({ name, connectPage }: any) => {
  const { logger, EVENTS } = useLogger()
  const { submitButton, sections = [] } = connectPage || {}
  const [firstSection, secondSection, howToUse] = sections
  const {
    label: labelSubmitButton,
    description: descriptionSubmitButton,
    link: linkSubmitButton,
    note: noteSubmitButton,
    connect: connectSubmitButton,
    disabled: disabledSubmitButton
  } = submitButton || {}

  const { user } = useUser()
  const isAdmin = isUserAdmin(user)
  const showConnectButton = isAdmin && connectSubmitButton

  return (
    <>
      {firstSection && (
        <div className={styles.firstSection}>
          <Title level={5} className={styles.title}>
            {firstSection.title}
          </Title>
          <Text>{firstSection.description}</Text>
        </div>
      )}
      {secondSection && (
        <div>
          <Title level={5} className={styles.title}>
            {secondSection.title}
          </Title>
          <InformationCard
            infoMessage={secondSection.noteTitle || "Prerequisites"}
          >
            <ul className={styles.listPrerequisites}>
              {secondSection.prerequisites.map((item: any, index: number) => {
                if (typeof item === "string") {
                  return (
                    <li key={index}>
                      <Text>{secondSection.prerequisites[index]}</Text>
                    </li>
                  )
                }

                return Object.keys(item).map((key: any) => {
                  return (
                    <li key={key}>
                      <Text>{key}</Text>
                      <ul>
                        {item[key].map((item: any) => (
                          <li key={item}>
                            <Text>{item}</Text>
                          </li>
                        ))}
                      </ul>
                    </li>
                  )
                })
              })}
            </ul>
          </InformationCard>
        </div>
      )}
      <div className={styles.submitButtonSection}>
        {descriptionSubmitButton && (
          <Title level={5}>{descriptionSubmitButton}</Title>
        )}
        {noteSubmitButton && (
          <Text className={styles.note} type="secondary">
            {noteSubmitButton}
          </Text>
        )}
        {linkSubmitButton && !showConnectButton && (
          <Button
            disabled={disabledSubmitButton}
            icon={<ReadOutlined />}
            type="primary"
            href={linkSubmitButton}
            target="_blank"
            onClick={() => {
              logger.info(
                EVENTS.ANALYTIC_EVENTS.INTEGRATIONS.OPENED_INSTRUCTION,
                {
                  name
                }
              )
            }}
          >
            {labelSubmitButton}
          </Button>
        )}
        {showConnectButton && (
          <Link to={connectSubmitButton}>
            <Button
              icon={<ConnectPlug />}
              type="primary"
              onClick={() => {
                logger.info(
                  EVENTS.ANALYTIC_EVENTS.INTEGRATIONS.OPENED_CONNECT,
                  {
                    name
                  }
                )
              }}
            >
              {labelSubmitButton}
            </Button>
          </Link>
        )}
      </div>
      {howToUse && howToUse.integrationSample !== "TBD" && (
        <HowToUseIntegration name={name} {...howToUse} />
      )}
    </>
  )
}
