import type { Project } from "@/domain/issue"
import { JiraSoftwareLogo } from "@/icons"
import style from "./TriageActionCell.module.scss"

export const TriageActionCell = (
  _: string,
  record: Project
): React.ReactNode => {
  //currently we only count jira tickets as actions
  return record.hasAction ? (
    <JiraSoftwareLogo className={style.iconSize} />
  ) : null
}
