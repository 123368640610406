import { useNavigate } from "react-router-dom"

import { Button, Form, Input, message, Spin, Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { AlignLeftOutlined, FilterOutlined, PlusOutlined } from "@/icons"
import { useFiltersScopes } from "@/pages/SettingsPage/application/scopes/useScopes"
import { SectionFormTemplate } from "../../templates/SectionFormTemplate"
import { CardFormList } from "../CardFormList"

import styles from "./ScopeForm.module.scss"

import { isUserAdmin } from "@/domain/user"
import { useUser } from "@/hooks/useUser"
import { InformationAlert } from "@/molecules/InformationAlert/InformationAlert"
import type { ScopeFormProps } from "."

export const ScopeForm = (props: ScopeFormProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { t: tForm } = useTranslation("translation", {
    keyPrefix: "settings.scopes.form"
  })
  const [messageApi, messageContextHolder] = message.useMessage()
  const { response: filters, isLoading: filtersIsLoading } = useFiltersScopes()
  const { data: options = { resources: [], fields: {} } } = filters || {}
  const { scopeIsLoading } = props
  const { user } = useUser()
  const isNotAdmin = !isUserAdmin(user)

  if (scopeIsLoading) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    )
  }

  const rules = [{ required: true, message: t("error.requiredField") }]
  const conditionInitialValues = { filters: [{}], includeExclude: "Include" }
  const { disabledForm, form, currentScope, actions, isDuplicate } = props
  let initialValues = currentScope || {
    name: "",
    conditions: [conditionInitialValues]
  }
  initialValues = isDuplicate
    ? {
        ...initialValues,
        name: `${t("general.duplicate")} ${initialValues.name || ""}`
      }
    : initialValues
  const isNewScope = isDuplicate || !currentScope?.id

  const isAllResources = currentScope?.isAllResources || false

  return (
    <Form
      disabled={filtersIsLoading || disabledForm}
      className={styles.form}
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={async (scope) => {
        const commonActions = () => {
          actions.setPreviewState((prevState: object) => ({
            ...prevState,
            dirty: false
          }))

          const action = isNewScope ? "created" : "updated"

          messageApi.success(
            `${scope.name} ${action} ${t("general.successfully")}.`
          )
        }
        if (isAllResources) {
          return
        }
        try {
          if (isNewScope) {
            const id = await actions.createScope(scope)

            commonActions()
            navigate(`/settings/scopes/${id}`, { replace: true })

            return
          }

          await actions.updateScope(currentScope.id, scope)

          commonActions()
        } catch (error: any) {
          let errorMessage =
            error?.data?.detail || "Error, please try again later"

          messageApi.error(errorMessage)
        }
      }}
      onFieldsChange={() => {
        actions.setPreviewState((prevState: object) => ({
          ...prevState,
          dirty: true
        }))
      }}
    >
      {isAllResources && (
        <InformationAlert
          message={tForm("allResourcesInfoMessage")}
          className={styles.informationAlert}
        />
      )}
      <SectionFormTemplate
        id="details"
        title={t("general.details")}
        Icon={AlignLeftOutlined}
      >
        <Form.Item
          required={false}
          name="name"
          label={tForm("label.name")}
          rules={rules}
        >
          <Input
            disabled={isAllResources || isNotAdmin}
            placeholder={tForm("placeholder.name")}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label={
            isAllResources
              ? tForm("label.description")
              : tForm("label.descriptionOptional")
          }
        >
          <Input
            disabled={isAllResources || isNotAdmin}
            placeholder={tForm("placeholder.description")}
          />
        </Form.Item>
        {!isNewScope && (
          <div id="scope-id" className={styles.scopeIdContainer}>
            <div className={styles.scopeId}>
              <Text>ID</Text>
            </div>
            <Input disabled={true} value={currentScope?.id} />
          </div>
        )}
      </SectionFormTemplate>
      <SectionFormTemplate
        id="resources"
        title={tForm("label.resources")}
        Icon={FilterOutlined}
      >
        <div className={styles.cardWrap}>
          {isAllResources && (
            <CardFormList
              name={0}
              key={0}
              form={form}
              options={options}
              remove={() => {}}
              cardsLength={1}
              isAllResources={isAllResources}
            />
          )}
          <Form.List name="conditions">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }, index) => (
                  <CardFormList
                    key={key}
                    name={name}
                    form={form}
                    remove={() => remove(index)}
                    options={options}
                    cardsLength={fields.length}
                    isAllResources={isAllResources}
                  />
                ))}
                {!isAllResources && (
                  <Form.Item className={styles.button}>
                    <Button
                      block
                      type="dashed"
                      disabled={isNotAdmin}
                      className="action-button-list-form-settings"
                      onClick={() => add(conditionInitialValues)}
                      icon={<PlusOutlined />}
                    >
                      {t("general.and")}
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        </div>
      </SectionFormTemplate>
      {messageContextHolder}
    </Form>
  )
}
