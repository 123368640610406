import { PROVIDER_AUTH_TYPES } from "@/const/provider-auth.constants"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useCreate, useHttp } from "@/hooks/useHttp"

import type { Config } from "@/hooks/useHttp"

export type Provider = {
  id: string
  name: string
  type: (typeof PROVIDER_AUTH_TYPES)[keyof typeof PROVIDER_AUTH_TYPES]
}

export type TenantResponse = {
  id: string
  firebaseTenantId: string
  identityProviders: Provider[]
  isMultiFactorAuthEnabled: boolean
}

export const useGetTenant = (slug?: string) => {
  const context = useHttp<KosmosApi.Response<TenantResponse, null>>(
    `${RESOURCES_NAMES.AUTHENTICATION.TENANT}`,
    { slug },
    { enabled: !!slug, staleTime: Infinity }
  )

  return context
}

export const useAcceptInvite = <T = unknown, S = T>() => {
  const context = useCreate<T, S>(
    `${RESOURCES_NAMES.AUTHENTICATION.ACCEPT_INVITE}`
  )

  return context
}

export const useForgotPassword = <T = unknown, S = T>() => {
  const context = useCreate<T, S>(
    `${RESOURCES_NAMES.AUTHENTICATION.FORGOT_PASSWORD}`
  )

  return context
}

export const useDocumentationToken = <
  T = KosmosApi.Response<{ token: string }>,
  S = T
>(
  config: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.AUTHENTICATION.DOCS}`,
    undefined,
    config
  )

  return context
}
