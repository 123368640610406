import cn from "classnames"

import { MenuOutlined } from "@/icons"

import styles from "./PoliceNameCell.module.scss"

import type { PoliceNameCellProps } from "."

export const PoliceNameCell: React.FC<PoliceNameCellProps> = ({
  children,
  disabled,
  setActivatorNodeRef,
  ...listeners
}) => {
  const { key, props } = children || {}
  const { render, record } = props || {}
  const renderChild = !!key && !!render && !!record

  return (
    <div className={styles.container}>
      <MenuOutlined
        ref={setActivatorNodeRef}
        className={cn(styles.icon, disabled && styles.disabled)}
        {...listeners}
      />
      {renderChild && render(record[key])}
    </div>
  )
}
