import { useRef } from "react"

import { SEVERITIES_FILTER_OPTIONS } from "@/const/options.constants"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { TEASERS } from "@/const/teaser.constants"
import { useInitialRender } from "@/hooks/useInitialRender"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { DatePickerExportContainer } from "@/organisms/DatePickerExportContainer"
import { IMAGE_DRAWER_ID } from "@/organisms/Drawers/const"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { Filter } from "@/organisms/FilterNew/Filter"
import { InputSmartSearchLoading, SmartSearch } from "@/organisms/SmartSearch"
import { useImageInventory } from "@/pages/DiscoveryPage/application/image-inventory/useImageInventory"
import { TabTemplate } from "@/templates/TabTemplate"
import { getColumnsTable } from "./getColumnsTable"
import { getFilters, getItemsFilter } from "./getItemsFilter"

import { isDataAvailable } from "@/helpers/context.helper"
import type { ImagesTabProps } from "."

export const ImagesTab: React.FC<ImagesTabProps> = (props) => {
  const { useActiveKeyTabsInventory } = props
  const { onSorterChange, ...orderByProps } = useOrderBy()
  const { t } = useTranslation()
  const filters = getFilters(t)
  const filterItems = useRef([...getItemsFilter(t), ...filters]).current
  const convertors = useNumberConvertor()
  const { activeKey, config = {} } = useActiveKeyTabsInventory()
  const previewMode = !!config.headers
  const { idsInDrawers, onRowClick } = useDrawer(IMAGE_DRAWER_ID, {
    filters: ["severity", "riskId", "runtime"]
  })
  const columns = getColumnsTable({
    sorter: orderByProps,
    t,
    convertors,
    previewMode,
    onRowClick
  })
  const TAB_KEYS = TAB_KEYS_BY_PAGE.INVENTORY

  const context = useInitialRender(
    useImageInventory(filterItems, {
      enabled: activeKey === TAB_KEYS[2],
      ...config
    })
  )
  const { response, isLoading } = context
  const { data, metadata } = response || {}
  const dataReadinessFF = useFeatureFlags("dataReadiness")
  const filterDisabled = !!dataReadinessFF
  const disableExport = !isDataAvailable(context) || filterDisabled
  useTooltip(data)

  return (
    <TabTemplate
      resizable
      dataSource={data}
      metadata={metadata}
      columns={columns}
      loading={isLoading}
      locale={{
        teasers: {
          dataReadiness: true,
          kortexInstalled: TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(t)
        }
      }}
      SearchComponent={
        !previewMode && (
          <Filter.Provider keys={Object.keys(filters)}>
            <Filter.Template
              isLoading={filterDisabled}
              aside={
                <DatePickerExportContainer
                  isLoading={disableExport}
                  exportType="Image"
                />
              }
              search={
                filterDisabled ? (
                  <InputSmartSearchLoading />
                ) : (
                  <SmartSearch
                    id="smart-search-images-inventory"
                    filters={filters}
                  />
                )
              }
            >
              <Filter.Insights isLoading={filterDisabled} filters={filters} />
              <Filter.Checkbox
                isLoading={isLoading}
                order="none"
                keyFilter="severity"
                items={SEVERITIES_FILTER_OPTIONS}
                placeHolderDefaultValue="Severity"
              />
            </Filter.Template>
          </Filter.Provider>
        )
      }
      onChange={(_pagination, _filters, sorter) => {
        onSorterChange(sorter)
      }}
      idsInDrawers={idsInDrawers}
      onRowClick={onRowClick}
    />
  )
}
