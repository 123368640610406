import { useDeleteLabel, useGetLabels } from "@/api/useLabels"
import { message } from "@/atoms"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useQueryClient } from "@/hooks/useHttp"
import { useLogger } from "@/hooks/useLogger"
import { useQueryString } from "@/hooks/useQueryString"
import { useTranslation } from "@/hooks/useTranslation"
import { getIssueRequestFilter } from "@/organisms/LabelModal/applications/useLabelsModal"
import { getLabelsSelector } from "./useLabels.selector"

import type { Label } from "@/domain/label"
import type { Config } from "@/hooks/useHttp"

export const useLabels = (
  config?: Config<ReturnType<typeof getLabelsSelector>>
) => {
  const params = useQueryString()
  const context = useGetLabels(params, {
    select: getLabelsSelector,
    ...config
  })
  const { mutateAsync, isPending } = useDeleteLabel()
  const queryClient = useQueryClient()
  const { logger, EVENTS } = useLogger()
  const { t } = useTranslation()

  const onDelete = async (record: Label) => {
    try {
      const { id, name } = record

      await mutateAsync(id)
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [RESOURCES_NAMES.LABELS.LIST]
        }),
        queryClient.invalidateQueries({
          queryKey: [RESOURCES_NAMES.TRIAGE.FILTERS]
        })
      ])

      logger.info(EVENTS.ANALYTIC_EVENTS.LABELS.DELETE, { id, name })

      queryClient.removeQueries(getIssueRequestFilter())
    } catch (err: any) {
      logger.error(err, { tags: { custom_error: "DELETE_LABEL_ERROR" } })

      const isDetailsString = err?.data?.detail === "string"
      const errorMessage = isDetailsString
        ? err.data.detail
        : t("error.serverError")

      message.error(errorMessage)
    }
  }

  const { response, isLoading } = context
  const { data = [] } = response || {}

  return [
    data,
    { context, onDelete, isLoading: isLoading || isPending }
  ] as const
}
