import cn from "classnames"
import { Fragment } from "react"

import { SeverityLetter } from "@/atoms"

import styles from "./IssuesSummaryFC.module.scss"

import type { NumberConvertor } from "@/hooks/useNumberConvertor"
import { IssuesSummary } from "../IssuesSummaryCell/IssuesSummaryCell.types"

export const IssuesSummaryFC: React.FC<{
  issues: IssuesSummary
  convertors: NumberConvertor
  noFixWidth?: boolean
  Tooltip?: React.FC
}> = (props) => {
  const { issues, convertors, noFixWidth, Tooltip } = props

  return (
    <span className={styles.issueSummaryContainer}>
      {issues.map(([severity, value]) => {
        return (
          <Fragment key={severity}>
            <SeverityLetter
              classes={{ container: styles.tag }}
              severity={severity}
              Wrapper={Tooltip}
            />
            <span className={cn(styles.count, !noFixWidth && styles.fixWidth)}>
              {convertors.abbreviateNumber(value)}
            </span>
          </Fragment>
        )
      })}
    </span>
  )
}
