import { Button, Link } from "@/atoms"
import type { LinkButtonProps } from "."

export const LinkButton = (props: LinkButtonProps) => {
  const { to, children, ...buttonProps } = props
  return (
    <Link to={to}>
      <Button {...buttonProps}>{children}</Button>
    </Link>
  )
}
