import type { Label } from "."

const COLORS = {
  purple: "golden-purple",
  blue: "daybreak-blue",
  red: "dust-red",
  orange: "volcano",
  green: "polar-green",
  magenta: "magenta"
} as Record<string, string>

export const getLabelColor = (label: Label) => label.color

export const getLabelById = (id: string, labels: Label[]) =>
  labels.find((label) => id === label.id)

export const getBgColorVariable = (color: Label["color"]) =>
  `var(--${COLORS[color]}-1-color)`

export const getColorVariable = (color: Label["color"]) =>
  `var(--${COLORS[color]}-3-color)`

export const getTextColorVariable = (color: Label["color"]) =>
  `var(--${COLORS[color]}-6-color)`

export const getLabelStyle = (color: Label["color"]) => ({
  color: getTextColorVariable(color),
  backgroundColor: getBgColorVariable(color),
  borderColor: getColorVariable(color)
})
