import dayjs from "dayjs"

import type { FiltersResponse, ScopesResponse } from "@/api/useScopes.types"

export const getScopesData = (response: ScopesResponse) => {
  const { data = [], metadata } = response || {}

  return {
    metadata,
    data: data.map(({ createdAt, ...scope }) => ({
      key: scope.id,
      createdAt: dayjs(new Date(Number(`${createdAt}000`))).format(
        "YYYY-MM-DD HH:mm"
      ),
      ...scope
    }))
  }
}

export const getOptions = (response: FiltersResponse) => {
  return {
    data: (response?.data?.resources || []).reduce(
      (acc, { name, fields }) => {
        acc.fields[name] = fields.map((field) => ({
          label: field,
          value: field
        }))

        acc.resources.push({
          label: name,
          value: name
        })

        return acc
      },
      {
        resources: [] as { label: string; value: string }[],
        fields: {} as Record<string, { label: string; value: string }[]>
      }
    )
  }
}
