import { Radio, Text } from "@/atoms"

import styles from "./RadioLabel.module.scss"

import type { RadioLabelProps } from "."

export const RadioLabel: React.FC<RadioLabelProps> = ({
  label,
  description,
  checked,
  onChange,
  disabled
}) => (
  <div className={styles.container}>
    <Radio checked={checked} onChange={onChange} disabled={disabled}>
      {label}
    </Radio>
    {description && (
      <Text type="secondary" className={styles.text}>
        {description}
      </Text>
    )}
  </div>
)
