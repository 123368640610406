import { Steps, Title } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { InformationAlert } from "@/molecules/InformationAlert"

import styles from "./DeployTab.module.scss"

import { DeployTabComponent } from "."

export const DeployTab: DeployTabComponent = (props) => {
  const { t } = useTranslation()
  const { children, steps, message, title } = props

  return (
    <div>
      <Title className={styles.title} level={5}>
        {`${title || t("deployments.instructions")}:`}
      </Title>
      <Steps
        size="small"
        className={styles.steps}
        direction="vertical"
        items={steps}
      />
      <div className={styles.main}>{children}</div>
      {!!message && (
        <InformationAlert className={styles.infoAlert} message={message} />
      )}
    </div>
  )
}
