import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { Children, cloneElement } from "react"

import { PoliceNameCell } from "@/pages/SettingsPage/components/molecules/PoliceNameCell"

export const Row = ({ children, ...props }: any) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  })
  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {})
  }

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "name") {
          return cloneElement(child as React.ReactElement, {
            children: (
              <PoliceNameCell
                disabled={attributes["aria-disabled"]}
                setActivatorNodeRef={setActivatorNodeRef}
                {...listeners}
              >
                {child}
              </PoliceNameCell>
            )
          })
        }
        return child
      })}
    </tr>
  )
}
