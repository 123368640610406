import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import { Breadcrumb, Drawer, Form, Link } from "@/atoms"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { useTranslation } from "@/hooks/useTranslation"
import { TitleDrawer } from "@/molecules/TitleDrawer"
import { useCustomMaskClass } from "@/organisms/Drawers/hooks/useDrawer"
import { HeaderPage } from "@/organisms/HeaderPage"

import { HeaderInventoryPage } from "@/pages/DiscoveryPage/components/organisms/HeaderInventoryPage"
import {
  useCreateScope,
  useScope,
  useUpdateScope
} from "@/pages/SettingsPage/application/scopes/useScopes"
import {
  ActiveKeyTabsPreviewContext,
  getQueryKeyHashFn,
  useActiveKeyTabsPreview
} from "@/pages/SettingsPage/hooks/useActiveKeyTabsPreview"
import { PageWithBreadcrumbTemplate } from "@/templates/PageWithBreadcrumbTemplate"
import { AsideBreadcrumbPage } from "../../molecules/AsideBreadcrumbPage"
import { FooterScopePage } from "../../organisms/FooterScopePage"
import { ScopeForm } from "../../organisms/ScopeForm"

import styles from "./ScopePage.module.scss"

export const ScopePage = () => {
  const { t } = useTranslation()
  const [previewState, setPreviewState] = useState({
    activeKey: TAB_KEYS_BY_PAGE.INVENTORY[0],
    dirty: false,
    open: false
  })
  const { id, duplicateId } = useParams()
  const needle = duplicateId || id

  const {
    createScope,
    isLoading: createScopeIsLoading,
    data
  } = useCreateScope()
  const { response, isLoading, refetch } = useScope(
    { needle },
    !!data ? { initialData: data } : undefined
  )
  const { updateScope, isLoading: updateScopeIsLoading } =
    useUpdateScope(refetch)

  const [form] = Form.useForm()
  const currentScope = response?.data[0]
  const currentScopeId = currentScope?.id || ""
  const loading = createScopeIsLoading || updateScopeIsLoading
  const [, maskClassName] = useCustomMaskClass()

  const contextValue = useMemo(() => {
    return {
      activeKey: previewState.activeKey,
      setActiveKey: (key: string) =>
        setPreviewState((prevState) => ({ ...prevState, activeKey: key })),
      config: {
        queryKeyHashFn: getQueryKeyHashFn(currentScopeId),
        headers: {
          "X-Scope-Id": currentScopeId
        }
      }
    }
  }, [currentScopeId, previewState.activeKey])

  return (
    <PageWithBreadcrumbTemplate
      header={
        <HeaderPage
          title={
            <Breadcrumb
              items={[
                {
                  title: <Link to="/settings">{t("navigation.settings")}</Link>
                },
                {
                  title: (
                    <Link to="/settings/scopes">
                      {t("settings.scopes.title")}
                    </Link>
                  )
                },
                {
                  title: isLoading
                    ? ""
                    : currentScope?.name || t("settings.scopes.createScope")
                }
              ]}
            />
          }
        />
      }
      aside={
        <AsideBreadcrumbPage
          items={[
            { key: "details", title: t("general.details") },
            {
              key: "resources",
              title: t("settings.scopes.form.label.resources")
            }
          ]}
        />
      }
      footer={
        <FooterScopePage
          submitting={loading}
          form={form}
          currentScopeId={currentScopeId}
          statePreview={previewState}
          setPreviewState={setPreviewState}
        />
      }
    >
      <ScopeForm
        dirty={previewState.dirty}
        currentScope={currentScope}
        isDuplicate={!!duplicateId}
        disabledForm={loading}
        scopeIsLoading={isLoading}
        form={form}
        actions={{
          createScope,
          updateScope,
          setPreviewState
        }}
      />
      <Drawer
        destroyOnClose
        open={previewState.open}
        width={1100}
        maskClassName={maskClassName}
        title={
          <TitleDrawer
            title={`${t("general.preview")} ${currentScope?.name || ""}`}
          />
        }
        onClose={() =>
          setPreviewState((prevState) => ({ ...prevState, open: false }))
        }
      >
        <div className={styles.previewContainer}>
          {currentScopeId && (
            <ActiveKeyTabsPreviewContext.Provider value={contextValue}>
              <HeaderInventoryPage
                useActiveKeyTabsInventory={useActiveKeyTabsPreview}
              />
            </ActiveKeyTabsPreviewContext.Provider>
          )}
        </div>
      </Drawer>
    </PageWithBreadcrumbTemplate>
  )
}
