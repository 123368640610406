import { ShowMore } from "@/molecules/ShowMore"

import type { MoreTableCellComponent } from "."

export const MoreTableCell: MoreTableCellComponent = ({
  count,
  label: labelText,
  id,
  ...props
}) => {
  const text = count === undefined
  const copyable = props.copyable || !!props.copyableTitle
  const LabelComponent = text ? ShowMore.Label.Text : ShowMore.Label.Count
  const label = (
    <LabelComponent copyable={copyable} count={count || 0}>
      {labelText}
    </LabelComponent>
  )

  if (copyable) {
    return <ShowMore.Popover {...props}>{label}</ShowMore.Popover>
  }

  return (
    <ShowMore id={id} label={label}>
      <ShowMore.Tooltip {...props} />
    </ShowMore>
  )
}
