import { useInitializerForOrderBy } from "@/hooks/useInitializerForOrderBy"
import { HeaderPage } from "@/organisms/HeaderPage"
import { PageTemplate } from "@/templates/PageTemplate"
import { DeploymentsTab } from "../../organisms/DeploymensTab"

export const DeploymentsPage = () => {
  const isReady = useInitializerForOrderBy("kortexVersion", true)

  if (!isReady.current) {
    return null
  }
  return (
    <PageTemplate
      full
      header={<HeaderPage titleTranslationKey="Deployments" />}
    >
      <DeploymentsTab />
    </PageTemplate>
  )
}
