import { Area, Text } from "@/atoms"
import dayjs from "dayjs"
import { memo, useMemo } from "react"
import type { HighRiskPackagesGraphProps } from "."
import { getDefaultLabelFormatterForXAxis } from "../../../const/graph"
import StatisticInk from "../StatisticInk/StatisticInk"
import styles from "./HighRiskPackagesGraph.module.scss"

const HighRiskPackagesGraph = (props: HighRiskPackagesGraphProps) => {
  const { response } = props
  const { data = [] } = response || {}
  const config = useMemo(() => {
    const documentStyle = getComputedStyle(document.documentElement)
    const daybreakBlueColor = documentStyle.getPropertyValue(
      "--daybreak-blue-5-color"
    )
    const neutralColor = documentStyle.getPropertyValue("--neutral-1-color")

    return {
      data: [...data].reverse().map(({ timestamp, counts }) => ({
        timestamp,
        value: counts.highRisk + counts.criticalRisk,
        fromNow: dayjs(new Date(timestamp * 1000)).fromNow()
      })),
      xField: "timestamp",
      yField: "value",
      line: {
        style: {
          stroke: daybreakBlueColor
        }
      },
      xAxis: {
        ...getDefaultLabelFormatterForXAxis(),
        range: [0, 1]
      },
      height: 168,
      areaStyle: () => {
        return {
          fill: `l(270) 0:${neutralColor} 1:${daybreakBlueColor}`
        }
      }
    }
  }, [data])

  return (
    <Area
      tooltip={{
        position: "right",
        domStyles: { "g2-tooltip": { padding: "6px 0px" } },
        customContent: (_, data) => {
          const { value, fromNow } = data[0]?.data || {}

          return (
            <div className={styles.contentTooltip}>
              <StatisticInk
                color="var(--high-vulnerabilities)"
                title="High Risk Packages"
                value={value}
              />
              <Text type="secondary">{fromNow}</Text>
            </div>
          )
        }
      }}
      {...config}
    />
  )
}

export default memo(
  HighRiskPackagesGraph,
  (prevProps, nextProps) =>
    prevProps.response?.data === nextProps.response?.data
)
