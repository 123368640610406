import { ReactComponent as ConnectEnvDataSvg } from "@/assets/icons/connect-env.svg"
import { ReactComponent as InjectingDataSvg } from "@/assets/icons/injecting-data.svg"
import { Link } from "@/atoms"
import { isUserAdmin } from "@/domain/user"
import { useTranslation } from "@/hooks/useTranslation"
import { useUser } from "@/hooks/useUser"
import { PlaceholderTemplate } from "@/templates/PlaceholderTemplate"

import type { DataReadinessPlaceholderProps } from "."

export const DataReadinessPlaceholder: React.FC<
  DataReadinessPlaceholderProps
> = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "collectingData" })
  const { type, onlyIcon, title, link, classes = {} } = props
  const setupRequired = type !== "digesting"
  const Icon = setupRequired ? ConnectEnvDataSvg : InjectingDataSvg
  const templateCommonProps = {
    classes,
    icon: <Icon />
  }
  const { user } = useUser()

  if (onlyIcon) return <PlaceholderTemplate {...templateCommonProps} />

  const titleText = typeof title === "string" ? title : title?.[type] || t(type)
  const showLink = !!link && isUserAdmin(user)

  return (
    <PlaceholderTemplate
      title={titleText}
      text={showLink && <Link to={link.url}>{link.text}</Link>}
      {...templateCommonProps}
    />
  )
}
