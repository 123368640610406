import { isDev, RELEASE } from "@/config"
import { useEffect } from "react"
import { useLogger } from "./useLogger"

export const useCheckApplicationUpdates = () => {
  const { logger, EVENTS } = useLogger()

  useEffect(() => {
    if (isDev) {
      return
    }

    const fn = async () => {
      try {
        const res = await fetch("/version")
        const text = await res.text()
        const lastVersion = text.trim()
        const releaseVersion = RELEASE.trim().split("-")[0]
        const versionsExists = lastVersion && releaseVersion

        if (versionsExists && releaseVersion !== lastVersion) {
          window.location.reload()
        }
      } catch (err) {
        const custom_event = EVENTS.ERROR_EVENTS.APPLICATION.CHECK_VERSION
        const error = err instanceof Error ? err : new Error(custom_event)

        logger.error(error, { tag: { custom_event } })
      }
    }

    const EVENT = "visibilitychange"
    const handleVisibilityChange = function () {
      if (document.visibilityState === "visible") {
        fn()
      }
    }

    document.addEventListener(EVENT, handleVisibilityChange)

    return () => {
      document.removeEventListener(EVENT, handleVisibilityChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
