import { UserItem } from "@/molecules/UserItem"

import styles from "./UserCell.module.scss"

import type { UserCellComponent } from "."

export const UserCell: UserCellComponent = (email, record) => {
  const { initials, firstName, lastName, colorNumber } = record
  return (
    <UserItem
      email={email}
      initials={initials}
      description={`${firstName} ${lastName}`}
      colorNumber={colorNumber}
      classes={{ description: styles.description }}
    />
  )
}
