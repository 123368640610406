import { Steps } from "@/atoms"
import { LoadingOutlined } from "@/icons"

import type { PhoneStepsMultiFactorFormProps } from "."

export const PhoneStepsMultiFactorForm = ({
  currentStep,
  loading
}: PhoneStepsMultiFactorFormProps) => {
  if ((currentStep ?? null) === null) return null

  const currentIndex = currentStep - 1
  const steps = ["Insert", "Verify", "Finish"].map((title, index) => ({
    title,
    icon: loading && index === currentIndex ? <LoadingOutlined /> : undefined
  }))
  const status = currentStep === steps.length ? "finish" : "process"

  return (
    <Steps size="small" current={currentIndex} status={status} items={steps} />
  )
}
