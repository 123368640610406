import { Paragraph } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { KodemLogo } from "@/icons"
import { IntegrationStatusButtonsContainer } from "../../molecules/IntegrationStatusButtonsContainer"
import { IntegrationStatusIcon } from "../../molecules/IntegrationStatusIcon"
import { LogoIntegration } from "../../molecules/LogoIntegration"

import styles from "./IntegrationStatus.module.scss"

import type { StatusComponentProps } from "."

export const StatusComponent = ({ vendor, status }: StatusComponentProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "integrations.callback"
  })
  const showDescription = vendor === "github" || vendor === "bitbucket"

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <LogoIntegration onlyLogo name={vendor} className={styles.vendorIcon} />
        <div className={styles.dotContainer}>
          <div className={styles.dot} />
          <div className={styles.dot} />
          <IntegrationStatusIcon integrationStatus={status} />
          <div className={styles.dot} />
          <div className={styles.dot} />
        </div>
        <KodemLogo className={styles.logoIcon} />
      </div>
      <div className={styles.textContainer}>
        <Paragraph className={styles.title} type="secondary">
          {t(`${status}.text`, { name: t(`vendors.${vendor}`) })}
        </Paragraph>
        {showDescription && (
          <Paragraph className={styles.description} type="secondary">
            {t("description")}
          </Paragraph>
        )}
      </div>
      <IntegrationStatusButtonsContainer
        integrationStatus={status}
        vendor={vendor}
      />
    </div>
  )
}
