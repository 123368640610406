import { Item, List } from "@/pages/DiscoveryPage/components/molecules/List"

import styles from "./SourceList.module.scss"

import { useTranslation } from "@/hooks/useTranslation"
import type { ComputedFixes } from "."

export const ComputedFixesItem = ({
  computedFixes,
  noLimits
}: ComputedFixes) => {
  const { t } = useTranslation()
  return (
    <List
      classes={{
        list: computedFixes.length > 1 ? styles.itemList : styles.oneItemList,
        button: styles.button
      }}
      items={computedFixes}
      limit={noLimits ? undefined : 2}
      render={({ name, version, remove, reinstall }) => (
        <>
          <Item
            title={name}
            value={
              remove
                ? `(${t(
                    "inventory.codeRepositoriesTab.drawer.issue.nestedRow.remove"
                  )})`
                : reinstall
                  ? `${version} (${t("general.reinstall")})`
                  : version
            }
          />
        </>
      )}
    />
  )
}
