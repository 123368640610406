import { ScoreExplanationProps } from "@/api/useTriage.types"
import { TFunction } from "@/hooks/useTranslation"
import { InformationPopover } from "@/molecules/InformationPopover"
import { ScoreExplanation, TitleScoreCard } from "@/molecules/ScoreExplanation"

import styles from "./KodemScoreCell.module.scss"

const KodemScoreCellComponent = (
  score: number,
  popoverComponent: React.ReactNode,
  title: string | React.ReactNode
) => (
  <InformationPopover.Popover
    placement="top"
    content={
      <InformationPopover.CommonTemplate title={title}>
        {popoverComponent}
      </InformationPopover.CommonTemplate>
    }
  >
    <span className={styles.score}>{score}</span>
  </InformationPopover.Popover>
)

export const KodemScoreIssueCell =
  (t: TFunction) => (score: number, props: ScoreExplanationProps) =>
    KodemScoreCellComponent(
      props.kodemScore,
      <ScoreExplanation record={props} bordered={false} />,
      <TitleScoreCard t={t} score={score} />
    )
