import {
  useDeleteIntegration as useDeleteIntegrationApi,
  usePatchIntegration
} from "@/api/useIntegrations"
import { useLogger } from "@/hooks/useLogger"

import { IntegrationConfiguration } from "@/api/useIntegrations.types"

export const useUpdateIntegration = (vendor: string) => {
  const updater = usePatchIntegration({ vendor })
  const { logger, EVENTS } = useLogger()

  const updateIntegration = async (data: IntegrationConfiguration) => {
    try {
      return await updater.mutateAsync({
        data
      })
    } catch (error) {
      logger.error(error as Error, {
        tags: { custom_error: EVENTS.ERROR_EVENTS.INTEGRATIONS.ERROR }
      })
      return Promise.reject(error)
    }
  }

  return { updateIntegration, isLoading: updater.isPending }
}

export const useDeleteIntegration = (vendor: string) => {
  const remover = useDeleteIntegrationApi({ vendor })
  const { logger, EVENTS } = useLogger()
  const deleteIntegration = async (accountId: string) => {
    try {
      await remover.mutateAsync(accountId)

      return Promise.resolve()
    } catch (error) {
      logger.error(error as Error, {
        tags: { custom_error: EVENTS.ERROR_EVENTS.INTEGRATIONS.ERROR }
      })
      return Promise.reject(error)
    }
  }

  return { deleteIntegration, isLoading: remover.isPending }
}
