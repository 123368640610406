import { CellTableWithCopyableTooltip } from "@/molecules/CellTableWithCopyableTooltip"

import type { CommitHashCellComponent } from "."

import { ScanStatusIcon } from "../../../../../molecules/ScanStatusTag"
import styles from "./CommitHashCell.module.scss"

export const CommitHashCellWithStatus: CommitHashCellComponent = (
  value,
  record
) => {
  return (
    <div className={styles.commitContainer}>
      <CellTableWithCopyableTooltip
        value={value}
        copyableText={record.commitHash}
      />
      {record.scanInfo.status !== "Done" && (
        <span data-tooltip-content={record.scanInfo.message || undefined}>
          <ScanStatusIcon status={record.scanInfo.status} />
        </span>
      )}
    </div>
  )
}
