import { Text } from "@/atoms"

import { TFunction } from "@/hooks/useTranslation"
import { ArrowUp } from "@/icons"
import { Link } from "react-router-dom"
import styles from "./KortexVersionCell.module.scss"

export const KortexVersionCell =
  (t: TFunction) => (kortexVersion: string, items: any) => {
    return (
      <div className={styles.container}>
        <Text className={styles.versionText}>{kortexVersion}</Text>
        {items.updateAvailable && (
          <div className={styles.divider}>
            <ArrowUp className={styles.icon} />
            <Link
              to="/deployments/new-deployment"
              state={{ deployEnvFieldValue: items?.environmentName }}
            >
              <Text className={styles.upgrade}>{t("deployments.upgrade")}</Text>
            </Link>
          </div>
        )}
      </div>
    )
  }
