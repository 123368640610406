import { Alert, Descriptions, Skeleton, Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { InfoCircleOutlined } from "@/icons"
import { CodeRepoScanStatusTag } from "@/molecules/ScanStatusTag"
import { useBranchEntity } from "@/organisms/Drawers/applications/inventory/useInventory"
import { PackageManagersList } from "../PackageManagersList"

import styles from "./OverviewTabCodeRepositoryDrawer.module.scss"

import type { DescriptionsProps } from "@/atoms"
import { InformationAlert } from "@/molecules/InformationAlert/InformationAlert"
import type { OverviewTabCodeRepositoryDrawerComponent } from "."

export const OverviewTabCodeRepositoryDrawer: OverviewTabCodeRepositoryDrawerComponent =
  (props) => {
    const [context, { isPackageManagerEstimated }] = useBranchEntity(
      props.record?.id
    )
    const { response, isLoading } = context
    const { data } = response || {}
    const { t } = useTranslation()
    const record = data?.[0] || ({ scanInfo: {} } as any)

    const infoMessage = isPackageManagerEstimated
      ? t("inventory.codeRepositoriesTab.drawer.estimatedPackageManagerMessage")
      : undefined

    const LoadingComponent = isLoading && <Skeleton.Input active block />
    const items: DescriptionsProps["items"] = [
      {
        label: (
          <span className={styles.label}>
            {t("inventory.codeRepositoriesTab.drawer.overview.defaultBranch")}
          </span>
        ),
        children: LoadingComponent || record.branchName
      },
      {
        label: (
          <span className={styles.label}>
            {t("inventory.codeRepositoriesTab.drawer.overview.packageManagers")}
          </span>
        ),
        children: LoadingComponent || (
          <PackageManagersList packageManagers={record.packageManagers} />
        )
      },
      {
        label: (
          <span className={styles.label}>
            {t("inventory.codeRepositoriesTab.drawer.overview.lastCommit")}
          </span>
        ),
        children: LoadingComponent ? (
          LoadingComponent
        ) : record.commitHash ? (
          <Text copyable={{ text: record.commitHash }}>
            {record.commitHashShort}
          </Text>
        ) : null
      },
      {
        label: (
          <span className={styles.label}>
            {t("inventory.codeRepositoriesTab.drawer.overview.lastSeen")}
          </span>
        ),
        children: LoadingComponent || record.lastSeen
      },
      {
        label: (
          <span className={styles.label}>
            {t("inventory.codeRepositoriesTab.drawer.overview.lastCveScan")}
          </span>
        ),
        children: LoadingComponent || record.lastCveScan
      },
      {
        label: (
          <span className={styles.label}>
            {t("inventory.codeRepositoriesTab.drawer.overview.status")}
          </span>
        ),
        children: LoadingComponent || (
          <CodeRepoScanStatusTag status={record.scanInfo.status} />
        )
      }
    ]

    return (
      <div className={styles.container}>
        {infoMessage && <InformationAlert message={infoMessage} />}
        {!!record.scanInfo.message && (
          <Alert
            showIcon
            icon={<InfoCircleOutlined />}
            type={record.scanInfo.status === "Incomplete" ? "warning" : "info"}
            message={record.scanInfo.message}
          />
        )}
        <Descriptions
          colon={false}
          size="small"
          title={
            <span className={styles["section-title"]}>
              {t("inventory.codeRepositoriesTab.drawer.overview.general")}
            </span>
          }
          layout="vertical"
          items={items}
        />
      </div>
    )
  }
