import { CellTable } from "@/atoms"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { LinkCVECell } from "../LinkCVECell"
import { SeverityCell } from "../SeverityCell"

import type { TFunction } from "@/hooks/useTranslation"

export const getColumnsTable = (t: TFunction) => {
  return [
    {
      title: "Vulnerability ID",
      width: 150,
      render: LinkCVECell,
      ...getDefaultColumnParams("id")
    },
    {
      title: "CWE",
      width: 150,
      render: CellTable,
      ...getDefaultColumnParams("cweDescription")
    },
    {
      title: "Severity",
      width: 150,
      render: SeverityCell(t),
      ...getDefaultColumnParams("severity")
    },
    {
      title: "Fix",
      width: 150,
      classNames: "fix-version-column-table",
      render: CellTable,
      ...getDefaultColumnParams("fix")
    }
  ]
}
