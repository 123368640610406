import { TFunction } from "@/hooks/useTranslation"

export const SEVERITIES_FILTER_OPTIONS = [
  { label: "Critical", value: "Critical" },
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
  { label: "Negligible", value: "Negligible" },
  { label: "Unknown", value: "Unknown" }
]

export const EXPLOITABILITY_FILTER_OPTIONS = [
  {
    displayLabel: "Exploit POC",
    label: '"Exploit POC"',
    value: "POC"
  },
  {
    displayLabel: "In The Wild",
    label: '"In The Wild"',
    value: "IN_THE_WILD"
  }
]

export const getFixabilityOptions = (t: TFunction) => [
  { label: "Fully", value: "full", displayLabel: t("general.fixability.full") },
  {
    label: "Partially",
    value: "partial",
    displayLabel: t("general.fixability.partial")
  },
  {
    label: "Has Fix",
    value: "has_fix",
    displayLabel: t("general.fixability.has_fix")
  },
  { label: t("general.fixability.not_fixable"), value: "not_fixable" },
  { label: t("general.fixability.unknown"), value: "unknown" }
]
