import { sliceConcat } from "@/helpers/string.helpers"
import { CellTableWithCopyableTooltip } from "@/molecules/CellTableWithCopyableTooltip"

export const CopyableKeyIdCell = (id: string) => {
  return (
    <CellTableWithCopyableTooltip
      value={sliceConcat(id, 10)}
      copyableText={id}
    />
  )
}
