import { Text } from "@/atoms"
import { SettingOutlined } from "@/icons"

import type { TitleItemProps } from "."

export const TitleItem = ({
  title,
  className,
  Icon = SettingOutlined
}: TitleItemProps) => (
  <li className={className} tabIndex={0}>
    <Icon />
    <Text className="capitalize-text-ks">{title}</Text>
  </li>
)
