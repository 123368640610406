import cn from "classnames"
import { Fragment } from "react"

import { Card, Link } from "@/atoms"
import { BulletedList } from "@/molecules/BulletedList"
import { ButtonModal } from "@/molecules/ButtonModal"
import { CodeHighlight } from "@/molecules/CodeHighlight"
import { FixabilityCell } from "@/molecules/FixabilityCell"
import { Heading } from "@/molecules/Heading"
import { LineClamp } from "@/molecules/LineClamp"
import IssueDetailsSkeleton from "@/organisms/Drawers/components/IssuesTab/IssueExpandableCell/IssueDetailsSkeleton"

import styles from "./FixesLocationCard.module.scss"

import type { FixesLocationCardComponent } from "."

export const FixesLocationCard: FixesLocationCardComponent = (props) => {
  const { t, record } = props
  const fixDescription = record?.computedFixes?.[0].fix

  if (!record) return <IssueDetailsSkeleton />

  const codeExamplesExist = record.computedFixes?.[0]?.codeExamples?.length

  return (
    <Card className={cn("embedded-card-ks", styles.container)}>
      <Card
        className="embedded-card-ks inner"
        bordered={false}
        title={
          <div className={styles.titleContainer}>
            <Heading level={6} title={t("general.fixes")} />
            {FixabilityCell(t)(record.fixability)}
          </div>
        }
      >
        <LineClamp clamp={3}>
          {fixDescription ? (
            <span>{fixDescription}</span>
          ) : (
            <span>
              {`${t(
                "issueTypes.components.fixLocationCard.noFixesDescription"
              )}.`}
            </span>
          )}
        </LineClamp>
      </Card>
      <Card
        className="embedded-card-ks inner"
        bordered={false}
        title={
          <Heading
            level={6}
            title={t("issueTypes.components.fixLocationCard.title")}
            popover={{
              content: t("issueTypes.components.fixLocationCard.contentPopover")
            }}
          />
        }
      >
        <div className={cn(codeExamplesExist && styles.fixLocationContainer)}>
          <div>
            <LineClamp clamp={3} itemHeight={24}>
              <BulletedList>
                {record.introducedThrough?.map(({ url, displayText }) => (
                  <Link href={url} target="_blank" type="link">
                    {displayText}
                  </Link>
                ))}
              </BulletedList>
            </LineClamp>
          </div>
          {codeExamplesExist && (
            <ButtonModal.FixExample>
              {record.computedFixes[0].codeExamples.map(
                (
                  {
                    code,
                    description
                  }: {
                    code: string
                    description: string
                  },
                  index: number
                ) => (
                  <Fragment key={index}>
                    <div className={styles.codeExampleDescription}>
                      {description}
                    </div>
                    <CodeHighlight code={code} language={record.language} />
                  </Fragment>
                )
              )}
            </ButtonModal.FixExample>
          )}
        </div>
      </Card>
    </Card>
  )
}
