import dayjs from "dayjs"

export const getDefaultLabelFormatterForXAxis = () => {
  return {
    tickCount: 4,
    label: {
      formatter: (timestampSeconds: string) => {
        return dayjs(new Date(Number(timestampSeconds) * 1000)).format("MMM DD")
      }
    }
  }
}
