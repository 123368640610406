import cn from "classnames"

import { Text } from "@/atoms"

import styles from "./Descriptions.module.scss"

import type { ItemDescriptions } from "."

export const Item: React.FC<ItemDescriptions> = (props) => {
  const { label, children, copyable, copyableText, onCopy, w } = props
  const copyableConfig = copyable
    ? {
        onCopy,
        text: copyableText || children
      }
    : undefined

  const labelClassName = cn({
    [styles.w500]: w === 500,
    [styles.sm]: true
  })
  const valueClassName = cn({
    [styles.mono]: true,
    [styles.sm]: true
  })

  return (
    <span className={cn(styles.containerItem)}>
      <Text className={labelClassName}>{label}</Text>
      {children && (
        <Text className={valueClassName} copyable={copyableConfig}>
          {children}
        </Text>
      )}
    </span>
  )
}
