import { ANALYTIC_EVENTS } from "@/const/event.constants"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import type { ExportItems, ExportType } from "."

const ExcludeParams = true
const BlackListParams = ["pageNumber", "pageSize"]
const ExportCsvLabel = "Export CSV"

export const getExportItems = (
  exportType: ExportType,
  filters: string = ""
): ExportItems => {
  switch (exportType) {
    case "Branch":
      return [
        {
          key: "1",
          label: ExportCsvLabel,
          url: `${RESOURCES_NAMES.INVENTORIES.BRANCH_EXPORT}`,
          queryStringParams: BlackListParams,
          excludeParams: ExcludeParams,
          analytic: {
            event: ANALYTIC_EVENTS.INVENTORY.CODE_REPOSITORIES_TAB.EXPORT,
            type: "csv"
          }
        }
      ]
    case "Application":
      return [
        {
          key: "1",
          label: ExportCsvLabel,
          url: `${RESOURCES_NAMES.INVENTORIES.APPLICATION_EXPORT}`,
          queryStringParams: BlackListParams,
          excludeParams: ExcludeParams,
          analytic: {
            event: ANALYTIC_EVENTS.INVENTORY.APPLICATIONS.EXPORT,
            type: "csv"
          }
        }
      ]
    case "Image":
      return [
        {
          key: "1",
          label: ExportCsvLabel,
          url: `${RESOURCES_NAMES.INVENTORIES.IMAGES_EXPORT}`,
          queryStringParams: BlackListParams,
          excludeParams: ExcludeParams,
          analytic: {
            event: ANALYTIC_EVENTS.INVENTORY.IMAGES.EXPORT,
            type: "csv"
          }
        }
      ]
    case "BaseImage":
      return [
        {
          key: "1",
          label: ExportCsvLabel,
          url: `${RESOURCES_NAMES.INVENTORIES.CONTAINER_BASE_IMAGE_EXPORT}`,
          queryStringParams: BlackListParams,
          excludeParams: ExcludeParams,
          analytic: {
            event: ANALYTIC_EVENTS.INVENTORY.BASE_IMAGES.EXPORT,
            type: "csv"
          }
        }
      ]
    case "Sbom":
      return [
        {
          key: "1",
          label: ExportCsvLabel,
          url: `${RESOURCES_NAMES.INVENTORIES.PACKAGE_EXPORT}`,
          queryStringParams: BlackListParams,
          excludeParams: ExcludeParams,
          analytic: {
            event: ANALYTIC_EVENTS.SBOM.EXPORT,
            type: "csv"
          }
        }
      ]
    case "SbomDrawer":
      return [
        {
          key: "1",
          label: ExportCsvLabel,
          url: `${RESOURCES_NAMES.INVENTORIES.PACKAGE_EXPORT}`,
          params: { filters },
          queryStringParams: BlackListParams,
          excludeParams: ExcludeParams,
          analytic: {
            event: ANALYTIC_EVENTS.INVENTORY.DRAWERS.SBOM_EXPORT,
            type: "csv"
          }
        }
      ]
    case "Triage":
      return [
        {
          key: "1",
          label: ExportCsvLabel,
          url: `${RESOURCES_NAMES.TRIAGE.EXPORT}`,
          queryStringParams: BlackListParams,
          excludeParams: ExcludeParams,
          analytic: {
            event: ANALYTIC_EVENTS.TRIAGE.EXPORT,
            type: "csv"
          }
        }
      ]
    case "IssuesDrawers":
      return [
        {
          key: "1",
          label: ExportCsvLabel,
          url: `${RESOURCES_NAMES.TRIAGE.EXPORT}`,
          params: { filters },
          queryStringParams: BlackListParams,
          excludeParams: ExcludeParams,
          analytic: {
            event: ANALYTIC_EVENTS.INVENTORY.DRAWERS.ISSUES_EXPORT,
            type: "csv"
          }
        }
      ]
    default:
      return []
  }
}
