import { useEffect, useRef } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

export const useInitializerForOrderBy = (
  orderByProperty: string,
  ascending: boolean,
  initialParams: Record<string, string> = {}
) => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const navigation = useNavigate()
  const isReady = useRef(!!searchParams.get("orderBy"))

  useEffect(() => {
    if (!isReady.current) {
      searchParams.set("orderBy", orderByProperty)
      searchParams.set("ascending", ascending.toString())

      Object.entries(initialParams).forEach(([key, value]) => {
        searchParams.set(key, value)
      })

      isReady.current = true

      navigation(`${location.pathname}?${searchParams.toString()}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isReady
}
