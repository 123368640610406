import cn from "classnames"

import { Text } from "@/atoms"

import styles from "./LabelExpandedRow.module.scss"

import { LabelExpandedRowProps } from "."

export const LabelExpandedRow: React.FC<LabelExpandedRowProps> = ({
  label,
  value,
  className,
  classes = {},
  ...valueProps
}) => {
  return (
    <div className={cn(styles.container, className)}>
      <Text className={cn(styles.text, classes.text)} type={"secondary"}>
        {label}
      </Text>
      {typeof value === "string" ? (
        <Text
          className={cn(styles.text, styles.value, classes.value)}
          {...valueProps}
        >
          {value}
        </Text>
      ) : (
        value
      )}
    </div>
  )
}
