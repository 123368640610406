import { InkTag, Text } from "@/atoms"
import cn from "classnames"
import styles from "./StatusCell.module.scss"

export const StatusCell = (status: string) => {
  return (
    <div className={cn(styles.container)}>
      <InkTag color={`var(--status-${status.toLowerCase()})`} dot />
      <Text>{status}</Text>
    </div>
  )
}
