import cn from "classnames"

import { TEASERS } from "@/const/teaser.constants"
import { useTeasers } from "@/hooks/useTeasers"
import { useTranslation } from "@/hooks/useTranslation"
import { Funnel } from "@/organisms/Funnel"
import { useFunnelTriage } from "."
import { getColorsByIndex } from "./helpers"

import styles from "./FunnelTriagePage.module.scss"

import type { FunnelTriagePageComponent } from "."

export const FunnelTriagePage: FunnelTriagePageComponent = (props) => {
  const { getData = useFunnelTriage } = props
  const [[context, { isLoading }], cards] = getData()
  const { t } = useTranslation()
  const { size, classes = {} } = props
  const teaser = TEASERS.SCM_INSTALLED.codeRepoNotConnected(t)
  const [showTeaser] = useTeasers({
    teasers: {
      dataReadiness: true,
      scmInstalled: teaser,
      kortexInstalled: teaser
    }
  })

  return (
    <div className={cn(styles.container, classes.container)}>
      {!showTeaser && (
        <Funnel
          loading={isLoading}
          size={size}
          numbers={context.map((query) => query.data?.data || 0)}
          cards={cards}
          getColorsByIndex={getColorsByIndex}
        />
      )}
    </div>
  )
}
