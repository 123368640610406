import { Navigate, Route, Routes } from "react-router-dom"
import { DeploymentsPage } from "./components/pages/DeploymentsPage"
import { NewDeploymentPage } from "./components/pages/NewDeploymentPage"

export const DeploymentRouts = () => {
  return (
    <Routes>
      <Route path="/" element={<DeploymentsPage />} />
      <Route path="/new-deployment" element={<NewDeploymentPage />} />
      <Route path="/*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}
