import type { Option } from "../MultiSelectWithDisabledOptions.types"

export const isOption = (obj: string | Option): obj is Option => {
  return typeof obj === "object" && "value" in obj
}

export const filterEnabledValues = (
  selectedValues: (string | Option)[],
  options: Option[] | undefined
) => {
  const disabledOptions = options?.filter((option) => option.disabled) || []
  return selectedValues.filter(
    (value: string | Option) =>
      !disabledOptions.some(
        (option) => option.label === (isOption(value) ? value.value : value)
      )
  )
}
