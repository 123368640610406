import cn from "classnames"

import { CellTable } from "@/atoms"

import styles from "./IconTextCell.module.scss"

import type { IconTextCellProps } from "."

export const IconTextCell = ({
  icon,
  text,
  description,
  noTooltip,
  classes = {}
}: IconTextCellProps) => {
  const cellTableProps = { noTooltip }

  return (
    <div className={cn(styles.container, classes.container)}>
      <span className={styles.icon}>{icon}</span>
      {description ? (
        <span className={styles.textContainer}>
          {CellTable(text, cellTableProps)}
          <span className={styles.description}>{description}</span>
        </span>
      ) : (
        CellTable(text, cellTableProps)
      )}
    </div>
  )
}
