import { useEffect, useRef, useState } from "react"
import type { ScoreShieldProps } from "."
import PathScoreShield from "./PathScoreShield"
import styles from "./ScoreShield.module.scss"

export const ScoreShield = ({ color, score }: ScoreShieldProps) => {
  const pathRef = useRef<SVGPathElement>(null)
  const [variables, setVariables] = useState({})

  useEffect(() => {
    if (pathRef.current) {
      const pathLength = pathRef.current.getTotalLength() || 0

      setVariables({
        "--score-shield-path-length": pathLength,
        "--score-shield-animate-dash": pathLength - pathLength * (score / 100)
      })
    }
  }, [score])

  return (
    <svg
      style={variables}
      className={styles.svgScoreShield}
      viewBox="0 0 74 96"
      fill="none"
    >
      <PathScoreShield pathRef={pathRef} className={styles.baseSvg} />
      <PathScoreShield
        fillOpacity="0"
        stroke={color}
        className={styles.progressSvg}
      />
      <text
        textAnchor="middle"
        className={styles.textScoreShield}
        x="50%"
        y="50%"
        stroke={color}
        fill={color}
      >
        {Math.round(score)}
      </text>
    </svg>
  )
}
