import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"
import { InsightsSbomCell } from "@/pages/DiscoveryPage/components/molecules/InsightsSbomCell"
import { EMPTY_VALUE, OverviewCardTemplate } from "../OverviewCardTemplate"

export const InsightsCard = (props: any) => {
  const { t } = useTranslation()
  const { Label } = OverviewDescriptions
  const internetFacingFF = useFeatureFlags("internetFacingIntegration")
  const attr = { t, internetFacingFF }
  const tag = true
  const { data } = props

  if (!data) return null

  const { insights } = data
  const {
    isDirect,
    isIndirect,
    runtime,
    internetFacing,
    ingress,
    fromBaseImage
  } = insights
  const { exploitMaturity } = insights
  const cell = InsightsSbomCell(attr)

  const items = [
    {
      label: <Label>Introduced Through</Label>,
      children:
        !isDirect && !isIndirect && !fromBaseImage
          ? EMPTY_VALUE
          : cell({ isDirect, isIndirect, fromBaseImage, tag })
    },
    {
      label: <Label>Usage</Label>,
      children: !runtime ? EMPTY_VALUE : cell({ runtime, tag })
    },
    {
      label: <Label>Exploit Maturity</Label>,
      children:
        exploitMaturity === "UNDETERMINED"
          ? EMPTY_VALUE
          : cell({
              "exploitability.POC": exploitMaturity === "POC",
              "exploitability.IN_THE_WILD": exploitMaturity === "IN_THE_WILD",
              tag
            })
    },
    {
      label: <Label>Exposure</Label>,
      children:
        !internetFacing && !ingress
          ? EMPTY_VALUE
          : cell({ internetFacing, ingress, tag })
    }
  ]

  return <OverviewCardTemplate title="Insights" items={items} />
}
