import type { Severity } from "@/domain/vulnerability"

export type StatusAlert = "open" | "resolved" | "ignored"

export interface Alert {
  id: string
  description: string
  moreInfo: string
  status: StatusAlert
  cveId: string
  kodemScore: number | undefined
  packageName: string
  packageVersion: string
  service: string
  readMoreUrl: string
  isFixable: boolean
  fixTitle: string
  fixSteps: string[]
  jiraIssue: string
  jiraUrl: string
  severity: Severity
  lastSeen: string
  icon?: string
}

export const STATUSES: Record<Uppercase<StatusAlert>, StatusAlert> = {
  OPEN: "open",
  RESOLVED: "resolved",
  IGNORED: "ignored"
}

export const getLocation = (
  entity?: Pick<Alert, "service" | "packageName" | "packageVersion">
) => {
  if (!entity) return "N/A"

  const { service, packageName, packageVersion } = entity

  return `${service} > ${packageName} (${packageVersion})`
}
