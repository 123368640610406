import { useCallback, useEffect, useRef, useState } from "react"

import type { InputRef } from "@/atoms"

interface Props {
  ref?: HTMLInputElement | HTMLTextAreaElement
  visible?: boolean
  isDirty?: boolean
}

export const useCustomFunctionsForm = (props: Props = {}) => {
  const wasFocused = useRef(false)
  const { ref, visible } = props
  const [isDirty, setIsDirty] = useState(!!props.isDirty)
  const focusRef = useCallback((node: InputRef | null) => {
    if (node !== null && !wasFocused.current) {
      node.focus()
      wasFocused.current = true
    }
  }, [])

  useEffect(() => {
    if (!ref || !visible) return

    ref.focus?.()
    wasFocused.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, ref])

  useEffect(() => {
    const needReset = wasFocused.current || isDirty

    if (!visible && needReset) {
      wasFocused.current = false
      setIsDirty(false)
    }

    if (visible && !isDirty && props.isDirty) {
      setIsDirty(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFieldsChange = () => {
    if (!isDirty) setIsDirty(true)
  }

  return { isDirty, focusRef, onFieldsChange }
}
