import styles from "./InfoHeader.module.scss"

import type { TFunction } from "@/hooks/useTranslation"

export const ProjectsTooltipContent = (t: TFunction) => () => (
  <div>
    {t("triage.infoTooltip.title")}
    <ol className={styles.infoList}>
      {[1, 2, 3, 4].map((value) => (
        <li>{t(`triage.infoTooltip.li${value}`)}</li>
      ))}
    </ol>
  </div>
)
