import { Link, type ItemType } from "@/atoms"

import styles from "./MenuHeader.module.scss"

export const getNavMenu = (currentKey: string) => {
  const PREFIX_URL = "/settings"

  const items = [
    { label: "Organization" },
    { label: "Authentication Method", url: "/auth" },
    { label: "Users", url: "/users" },
    { label: "Preferences" },
    { label: "Scopes", url: "/scopes" },
    { label: "Labels", url: "/labels" },
    { label: "Code Scan (SAST)", url: "/codeScanSast" },
    { label: "Keys" },
    { label: "API Keys", url: "/apikeys" },
    { label: "Deployment Keys", url: "/deploymentkeys" },
    { label: "Service Keys", url: "/servicekeys" }
  ]

  let isActive = false
  const itemsWithClassName = items.map((item, index) => {
    if (item.url) {
      const to = `${PREFIX_URL}${item.url}`
      const isMatch = currentKey === to
      const className = isMatch ? styles.active : ""

      if (isMatch) isActive = true

      return {
        className,
        key: `${item.url}`,
        label: <Link to={to}>{item.label}</Link>
      }
    }

    return { key: `${index}`, label: item.label, disabled: true }
  }) as ItemType[]

  return { isActive, items: itemsWithClassName }
}
