import { useEffect, useRef } from "react"

import { Form, Modal, Text, TextArea, Title } from "@/atoms"
import { usePrevious } from "@/hooks/usePrevious"
import { useTranslation } from "@/hooks/useTranslation"
import { InformationPopover } from "@/molecules/InformationPopover"

import styles from "./DismissModal.module.scss"

import type { DismissModalComponent } from "./DismissModal.types"

export const DismissModal: DismissModalComponent = (props) => {
  const { count, open, onOk, onCancel } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const reasonMaxLength = 250
  const refTextArea = useRef<HTMLTextAreaElement>(null)
  const prevOpen = usePrevious(open)

  useEffect(() => {
    if (open) setTimeout(() => refTextArea.current?.focus(), 100)
    if (!open && prevOpen) form.resetFields()
  }, [open, prevOpen, form])

  return (
    <Modal
      open={open}
      okText={t("general.dismiss")}
      onOk={() => {
        onOk({ reason: form.getFieldValue("reason") })
      }}
      onCancel={onCancel}
      footer={(originNode) => <div className={styles.footer}>{originNode}</div>}
    >
      <Title className={styles.title} level={4}>
        {t("triage.dismiss_modal.title", { count })}
        <InformationPopover.Popover
          iconSize="large"
          classNamePopover={styles.popover}
          classNameIcon={styles.iconPopover}
          content={
            <InformationPopover.CommonTemplate>
              Dismissed Issues are excluded from the project summary but can be
              viewed using the status filter.
            </InformationPopover.CommonTemplate>
          }
        />
      </Title>
      <Text>
        Dismissed Issues are automatically reopened if a new risk, fix or
        correlation between Code and Image Repositories is detected.
      </Text>
      <Form form={form} name="dismiss-issue">
        <Text className={styles.labelReason}>
          Add your dismiss reason (Recommended)
        </Text>
        <Form.Item name="reason" className={styles.formItemReason}>
          <TextArea
            showCount
            autoSize={{ minRows: 3, maxRows: 6 }}
            ref={refTextArea}
            maxLength={reasonMaxLength}
            placeholder="It is recommended to provide a reason for dismissal for better tracking and accountability."
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
