import { Text } from "@/atoms"
import { DeploymentType, DeploymentTypeType } from "@/icons"

import styles from "./DeploymentTypeCell.module.scss"

export const DeploymentTypeCell = (deploymentType: string) => {
  return (
    <div className={styles.container}>
      <DeploymentType
        className={styles.icon}
        deploymentType={deploymentType as DeploymentTypeType}
      />
      <Text className={styles.textSection} ellipsis>
        {deploymentType}
      </Text>
    </div>
  )
}
