import { Divider, Space } from "@/atoms"
import { IssueTypeIcon } from "@/icons"
import { DismissedTag } from "@/molecules/DismissedTag"
import { LabelTag } from "@/molecules/LabelTag"

import styles from "./IssueDetails.module.scss"

import type { IssueDetailsComponent } from "."

export const IssueDetails: IssueDetailsComponent = (props) => {
  const {
    severity,
    title,
    description,
    status,
    issueType,
    t,
    issueId,
    onAddLabel,
    dismissReason,
    dismissedAt = 0,
    dismissedBy = "",
    labels = []
  } = props

  return (
    <div className={styles.container}>
      {issueType && (
        <IssueTypeIcon
          popover
          size="small"
          t={t}
          type={issueType}
          severity={severity}
        />
      )}
      <div className={styles.textWrapper}>
        <span className={styles.title}>{title}</span>
        <Space
          className={styles.space}
          split={<Divider type="vertical" className={styles.divider} />}
        >
          {!!description && (
            <span className={styles.description}>{description}</span>
          )}
          {status === "dismissed" && (
            <DismissedTag
              classes={{ tag: styles.dismissedTag }}
              issueId={issueId}
              email={dismissedBy}
              timestamp={dismissedAt}
              reason={dismissReason}
            />
          )}
          {!!labels?.length && (
            <LabelTag
              type="tag"
              labels={labels}
              issueId={issueId}
              onAddLabel={onAddLabel}
            />
          )}
        </Space>
      </div>
    </div>
  )
}
