import cn from "classnames"

import styles from "./PageTemplate.module.scss"

import type { PageTemplateProps } from "."

export const PageTemplate = ({
  full,
  header,
  children,
  classes = {}
}: PageTemplateProps) => (
  <div className={styles.container}>
    {header && <div className={styles.header}>{header}</div>}
    <div className={cn(styles.main, full && styles.full, classes.main)}>
      {children}
    </div>
  </div>
)
