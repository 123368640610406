import { useState } from "react"
import { useSearchParams } from "react-router-dom"

import type { ColumnType, SortOrder } from "antd/es/table/interface"

export type FIELD = React.Key
export type SORT_ORDER = SortOrder

interface Sorter {
  field?: FIELD | readonly FIELD[]
  order?: SORT_ORDER
  column?: ColumnType<any> & { customSorterName?: string }
}

export type ORDER_BY_PROPS = ReturnType<typeof useOrderBy>

export const useOrderBy = ({
  onChange,
  initialState = {}
}: {
  onChange?: ({
    orderBy,
    ascending
  }: {
    orderBy?: string
    ascending?: string
  }) => void
  initialState?: Record<string, string>
} = {}) => {
  const ORDER_FIELD_NAME = "ascending"
  const ORDER_BY_FIELD_NAME = "orderBy"
  const ORDER_VALUES: Record<Exclude<SORT_ORDER, null>, "true" | "false"> = {
    ascend: "true",
    descend: "false"
  }
  const hook = onChange ? useState : useSearchParams
  const [searchParams, setSearchParams] = hook(
    new URLSearchParams(initialState)
  )
  const orderBy = searchParams.get(ORDER_BY_FIELD_NAME)
  const ascending: SORT_ORDER =
    searchParams.get(ORDER_FIELD_NAME) === ORDER_VALUES.ascend
      ? "ascend"
      : "descend"

  const onSorterChange = (sorter: Sorter | Sorter[]) => {
    const { field, order, column } = Array.isArray(sorter) ? sorter[0] : sorter
    const sorterFieldName = column?.customSorterName || field

    if (!sorterFieldName) {
      return
    }

    if (!order) {
      searchParams.delete(ORDER_BY_FIELD_NAME)
      searchParams.delete(ORDER_FIELD_NAME)
    } else {
      searchParams.set(
        ORDER_BY_FIELD_NAME,
        `${Array.isArray(sorterFieldName) ? sorterFieldName[0] : sorterFieldName}`
      )
      searchParams.set(
        ORDER_FIELD_NAME,
        !order || order === "ascend"
          ? ORDER_VALUES.ascend
          : ORDER_VALUES.descend
      )
    }

    searchParams.set("pageNumber", "0")
    setSearchParams(searchParams)

    if (onChange) {
      const orderBy = searchParams.get(ORDER_BY_FIELD_NAME)
      const nextData = orderBy
        ? {
            orderBy,
            ascending: searchParams.get(ORDER_FIELD_NAME) || ""
          }
        : {}

      onChange(nextData)
    }
  }

  return { orderBy, ascending, onSorterChange }
}
