import { useState } from "react"

import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { isDataAvailable } from "@/helpers/context.helper"
import { scrollToElement } from "@/helpers/table.helpers"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useQueryString } from "@/hooks/useQueryString"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { ExpandIconTable } from "@/molecules/ExpandIconTable"
import { TotalPagination } from "@/molecules/TotalPagination"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import {
  UsePackageReferencesCount,
  useSbomInventory
} from "@/pages/DiscoveryPage/application/sbom-inventory/useSbomInventory"
import { TabTemplate } from "@/templates/TabTemplate"
import type { SbomTabComponent } from "."
import { ExpandedRowSbomTab } from "../ExpandedRowSbomTab"
import { SbomFilter } from "../SbomFilter"
import { getColumnsTable } from "./getColumnsTable"
import { getDefaultFilters } from "./getItemsFilter"

export const SbomTab: SbomTabComponent = ({ useActiveKeyTabsInventory }) => {
  const { onSorterChange, ...orderByProps } = useOrderBy()
  const { t } = useTranslation()
  const convertors = useNumberConvertor()
  const { onRowClick } = useDrawer("")
  const { activeKey, config = {} } = useActiveKeyTabsInventory()
  const previewMode = !!config.headers
  const columns = getColumnsTable({
    t,
    convertors,
    orderByProps,
    previewMode,
    onRowClick
  })
  const filterItems = getDefaultFilters(t)
  const TAB_KEYS = TAB_KEYS_BY_PAGE.INVENTORY
  const params = useQueryString(["orderBy", "ascending", ...filterItems])
  const [context] = useSbomInventory(params, {
    enabled: activeKey === TAB_KEYS[4],
    disableCountRequest: true
  })
  const { response, isLoading } = context
  const { data, metadata } = response || {}
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
  const countContext = UsePackageReferencesCount(params)
  const {
    isLoading: isCountLoading,
    response: countResponse = { data: undefined }
  } = countContext

  useTooltip(data)

  return (
    <TabTemplate
      columns={columns}
      dataSource={data}
      metadata={metadata}
      loading={isLoading}
      expandable={
        !previewMode
          ? {
              expandedRowKeys,
              expandIcon: ExpandIconTable,
              expandRowByClick: true,
              rowExpandable: ({ expandable }) => !!expandable,
              expandedRowRender: ({ key }, _index, _, expanded) => (
                <ExpandedRowSbomTab
                  packageId={key}
                  expanded={expanded}
                  params={params}
                  onCollapsed={() => {
                    scrollToElement(key, _index)

                    setExpandedRowKeys(
                      expandedRowKeys.filter((value) => value !== key)
                    )
                  }}
                />
              ),
              onExpand: (expanded, record) => {
                if (expanded)
                  setExpandedRowKeys([record.key, ...expandedRowKeys])
                else
                  setExpandedRowKeys(
                    expandedRowKeys.filter((value) => value !== record.key)
                  )
              }
            }
          : undefined
      }
      totalRender={({ loading, total }) => (
        <TotalPagination
          totals={[
            {
              translationKey: "general.package",
              value: total
            },
            {
              translationKey: "general.result",
              value: countResponse.data
            }
          ]}
          isLoading={loading || isCountLoading}
        />
      )}
      SearchComponent={
        !previewMode && (
          <SbomFilter
            items={filterItems}
            isLoading={isLoading || isCountLoading}
            previewMode={previewMode}
            exportType="Sbom"
            isDataAvailable={isDataAvailable(context)}
            packagesReferencesCount={countResponse.data}
          />
        )
      }
      onChange={(_pagination, _filters, sorter) => {
        onSorterChange(sorter)
      }}
    />
  )
}
