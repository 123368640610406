import { useState } from "react"

import { Button, Empty, Text } from "@/atoms"
import useServices from "@/hooks/useServices"
import { useTranslation } from "@/hooks/useTranslation"
import { EmptyLabels, TagOutlined } from "@/icons"
import { Heading } from "@/molecules/Heading"
import { CreateModal } from "@/organisms/LabelModal"
import { useLabels } from "@/pages/SettingsPage/application/labels/useLabels"
import { VerticalTabTemplate } from "@/pages/SettingsPage/components/templates/VerticalTabTemplate"
import { TabTemplate } from "@/templates/TabTemplate"
import { Filter } from "../Filter"
import { getColumnsTable } from "./getColumns"

import styles from "./LabelsTab.module.scss"

import type { Label } from "@/domain/label"

export const LabelsTab = () => {
  const { t } = useTranslation()
  const [data, { isLoading, onDelete }] = useLabels({ staleTime: 0, gcTime: 0 })
  const [label, setLabel] = useState<Partial<Label> | undefined>(undefined)
  const onEdit = (record: Label) => setLabel(record)
  const actions = { onEdit, onDelete }
  const onCreate = () => setLabel({})
  const { http } = useServices()

  return (
    <VerticalTabTemplate
      title={
        <Heading
          level={4}
          title={t("settings.labels.title")}
          popover={{
            content: t("settings.labels.tab.tooltip")
          }}
        />
      }
      button={
        <Button icon={<TagOutlined />} type="primary" onClick={onCreate}>
          {t("settings.labels.create")}
        </Button>
      }
    >
      <TabTemplate
        loading={isLoading}
        columns={getColumnsTable({ t, http, actions })}
        dataSource={data}
        SearchComponent={<Filter />}
        locale={{
          emptyText: (
            <Empty
              className={styles.empty}
              image={null}
              description={
                <div className={styles.descriptionEmpty}>
                  <EmptyLabels className={styles.iconEmpty} />
                  <Text type="secondary" className={styles.textEmpty}>
                    {t("settings.labels.tab.empty.title")}
                  </Text>
                  <Button type="link" onClick={onCreate}>
                    {t("settings.labels.tab.empty.action")}
                  </Button>
                </div>
              }
            />
          )
        }}
      />
      <CreateModal
        open={!!label}
        label={label}
        onCancel={() => setLabel(undefined)}
        component="settings"
        placement="button"
      />
    </VerticalTabTemplate>
  )
}
