import { buildQueryStringFromRecord } from "@/helpers/filters.helper"
import { splitPackageIdFromDrawerRecord } from "@/organisms/Drawers/applications/inventory/useInventory.selectors"
import { IssuesTab } from "@/organisms/Drawers/components/IssuesTab"
import { geDefaultFiltersPackageDrawer } from "@/organisms/Drawers/hooks/useParamsFilter"

export const IssueTabPackageDrawer = (props: any) => {
  const values = splitPackageIdFromDrawerRecord(props)
  const defaultParams = geDefaultFiltersPackageDrawer(values)
  return (
    <IssuesTab
      getInitialParamsFilter={() => ({})}
      defaultParams={defaultParams}
      exportQueryParams={(params: Record<string, any>) =>
        buildQueryStringFromRecord({ ...defaultParams, ...params })
      }
      {...props}
    />
  )
}
