import cn from "classnames"
import { useEffect, useState } from "react"

import { Button } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { EditOutlined } from "@/icons"
import { InformationPopover } from "@/molecules/InformationPopover"
import { MAX_LABELS_ON_SCREEN } from "@/organisms/LabelModal/const"
import { ClosableTag } from "./ClosableTag"

import styles from "./LabelTag.module.scss"

import type { PopoverLabelTagComponent } from "./LabelTag.types"

export const PopoverLabelTag: PopoverLabelTagComponent = (props) => {
  const { labels, Label, issueId, placement, onAddLabel } = props
  const [open, setOpen] = useState<boolean | undefined>(undefined)
  const { t } = useTranslation()

  useEffect(() => {
    if (open === false) setOpen(undefined)
  }, [open])

  return (
    <InformationPopover.Popover
      open={open}
      placement={placement}
      content={
        <InformationPopover.CommonTemplate
          title={
            <span className={styles.title}>
              <span>{t("settings.labels.title")}</span>
              {onAddLabel && (
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => {
                    onAddLabel({ issueId })
                    setOpen(false)
                  }}
                />
              )}
            </span>
          }
          classes={{
            content: cn(
              styles.contentTooltipTag,
              labels.length > MAX_LABELS_ON_SCREEN &&
                styles.scrollContentTooltipTag
            ),
            title: styles.titleTooltipTag
          }}
        >
          {labels.map((label) => (
            <ClosableTag key={label.id} issueId={issueId} {...label} />
          ))}
        </InformationPopover.CommonTemplate>
      }
    >
      {Label}
    </InformationPopover.Popover>
  )
}
