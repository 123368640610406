import type { Services } from "@/interfaces/services"

export const getPageNameFromPath = (
  pathname: string,
  logger: Services.ILoggerAdapter
) => {
  try {
    if (!pathname) return null

    if (pathname === "/") return "DASHBOARD_PAGE"

    return `${pathname
      .split("/")
      .filter(Boolean)
      .reverse()
      .join("_")}_page`.toUpperCase()
  } catch (err) {
    logger.error(err as Error, { custom_error: "getPageNameFromPath" })

    return null
  }
}
