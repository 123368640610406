import { Language } from "@/icons"

import styles from "./IssueDetails.module.scss"

import type {
  ExposedSecretFinding,
  VulnerableCodeFinding
} from "@/domain/issue"

export const DescriptionVulnerableCodeIssue = ({
  language,
  filePath,
  LanguageComponent
}: Pick<
  VulnerableCodeFinding | ExposedSecretFinding,
  "filePath" | "language"
> & {
  LanguageComponent?: React.ReactNode
}) => {
  const str = filePath.split("/")
  const fileName = str.splice(-1)
  const path = str.join("/")

  return (
    <div className={styles.descriptionVulnerableCodeIssue}>
      on
      {!LanguageComponent && (
        <Language secondary withBg={false} language={language} />
      )}
      {LanguageComponent && LanguageComponent}
      <div className={styles.pathContainer}>
        <span className={styles.filePath}>{path}</span>
        <span>{`/${fileName}`}</span>
      </div>
    </div>
  )
}
