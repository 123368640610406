import type { Services } from "@/interfaces/services"
import type { Transport } from "./transports/Transport"

class Logger implements Services.ILoggerAdapter {
  protected transports: Transport[]

  constructor({ transports }: { transports: Transport[] }) {
    this.transports = transports
  }

  public identify(data: Services.LoggerAdapter.IdentificationData) {
    this.transports.forEach((transport) => {
      if (transport.identify) {
        transport.identify(data)
      }
    })
  }

  public reset() {
    this.transports.forEach((transport) => {
      if (transport.reset) {
        transport.reset()
      }
    })
  }

  public log(info: Services.LoggerAdapter.Info) {
    this.transports.forEach((transport) => {
      if (transport.level === info.level) {
        transport.log(info)
      }
    })
  }

  public error: Services.LoggerAdapter.LogByLevel = (message, meta) => {
    this.log({ level: "error", message, meta })
  }

  public warn: Services.LoggerAdapter.LogByLevel = (message, meta) => {
    this.log({ level: "warn", message, meta })
  }

  public info: Services.LoggerAdapter.LogByLevel = (message, meta) => {
    this.log({ level: "info", message, meta })
  }

  public getTransportByLevel<T = Transport>(
    level: Services.LoggerAdapter.LevelKeys
  ) {
    return this.transports.find((transport) => transport.level === level) as
      | T
      | undefined
  }
}

export { Logger }
