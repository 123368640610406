import { useEffect } from "react"

import type { Actions } from "./useTabs"

export const getCountParams = () => ({ pageSize: 1, pageNumber: 0 })

export const useCounts = (
  tabKey: string,
  countsContext: any,
  props: {
    actions?: Actions
  }
) => {
  const total = countsContext?.response?.metadata?.total

  useEffect(() => {
    if (total === undefined) return

    props?.actions?.onChangeCountByKey?.(tabKey, total)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total])
}
