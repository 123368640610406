import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { ResourcesCell } from "@/molecules/Resources"
import { getColumnsTable } from "../SbomTab/getColumnsTable"

import type { ColumnsProps } from "../SbomTab"

export const getColumnsExpandedRow = (props: ColumnsProps) =>
  getColumnsTable(props).map(({ title, ...column }) => {
    if (column.dataIndex === "projects") {
      return {
        title: null,
        width: (column as { width?: number }).width,
        render: ResourcesCell(props),
        ...getDefaultColumnParams("resources")
      }
    }

    return {
      title: null,
      ...column
    }
  })
