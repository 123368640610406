import { useEffect } from "react"
import tippy from "tippy.js"

export const useTooltip = () => {
  useEffect(() => {
    const tooltip = tippy("[data-tooltip-error]", {
      content: (reference: any) => {
        return reference.getAttribute("data-tooltip-error")
      },
      maxWidth: "none",
      theme: "light",
      popperOptions: {
        strategy: "fixed"
      }
    })

    return () => {
      tooltip.forEach((tippyInstance) => tippyInstance.destroy())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [{}])
}
