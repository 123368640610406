import cn from "classnames"
import { Fragment } from "react"

import { Col, Row } from "@/atoms"

import styles from "./TemplateFilter.module.scss"

import type { TemplateFilterProps } from "."

export const TemplateFilter = ({
  children,
  aside,
  search,
  inline,
  title
}: TemplateFilterProps) => {
  const searchComponentExist = !!search && !!children
  const Wrap = inline ? "div" : Fragment
  const wrapProps = inline ? { className: styles.title } : undefined
  const showAside = !inline && !!aside
  const showChildrenContainer = inline || !!children || showAside

  return (
    <Wrap {...wrapProps}>
      {title && <span>{title}</span>}
      <Row
        className={cn(
          styles.container,
          !searchComponentExist && styles.onlyChildren
        )}
        data-filter-type={searchComponentExist ? 2 : 1}
      >
        {showChildrenContainer && (
          <Col className={styles.container} span={24}>
            {children}
            {inline && (
              <div className={cn(styles.search, styles.inline)}>{search}</div>
            )}
            {showAside && <div className={styles.aside}>{aside}</div>}
          </Col>
        )}
        {!inline && (
          <Col className={styles.search} span={24}>
            {search}
          </Col>
        )}
      </Row>
    </Wrap>
  )
}
