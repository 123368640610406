import { useEffect, useState } from "react"

export const useInitialRender = (context: {
  response: any
  isLoading: boolean
}) => {
  const [initialRender, setInitialRender] = useState(true)

  useEffect(() => {
    setInitialRender(false)
  }, [])

  if (!initialRender) return context

  const { response, isLoading, ...rest } = context

  return { response: undefined, isLoading: true, ...rest }
}
