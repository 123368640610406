import type { ExploitMaturity, InsightsIssue } from "@/domain/issue"
import type * as ResourceDomain from "@/domain/resource"
import type {
  CWE,
  IssuesSummary,
  Severity,
  Vulnerability
} from "@/domain/vulnerability"
import type { Params } from "@/hooks/useHttp"

export interface Snapshot {
  new: number
  total: number
}

export type SnapshotType =
  | "application"
  | "cluster"
  | "containerImage"
  | "packages"

export interface SnapshotsByType {
  application: Snapshot
  cluster: Snapshot
  containerImage: Snapshot
  packages: Snapshot
  branch: Snapshot
  containerBaseImage: Snapshot
}

export type SnapshotResponse = KosmosApi.Response<SnapshotsByType>
export interface Environment {
  id: string
  displayName: string
  data: { name: string }
}

export type EnvironmentResponse = KosmosApi.Response<Environment[]>

export interface Inventory<D> {
  id: string
  displayName: string
  type: string
  data: D
}

type ImageAccessPermission = "public" | "private"

export interface InsightsInventory {
  imageAccessPermission?: ImageAccessPermission
}

export type Vulnerabilities = Record<string, Vulnerability>
export interface DataApplication {
  applicationId: string
  environment: string // "production" | "staging" | "development"
  insights: InsightsInventory
  name: string
  namespace: string
  operatingSystems: string[]
  resourceIds: string[]
  startedAt: number
  issuesSummary?: IssuesSummary
}

export interface Application extends Inventory<DataApplication> {}

export interface CodeRepositoryData {
  repositoryId: string
  name: string
}

export interface DataImage {
  digest?: string
  firstSeen: string
  insights: InsightsInventory
  os: string
  registry: string
  repository: string
  fullNameName: string
  imageId: string
  tag: string
  applications: Pick<
    DataApplication,
    "name" | "environment" | "namespace" | "resourceIds" | "applicationId"
  >[]
  codeRepositories?: string[] // This is a legacy field, use codeRepositoriesData instead
  codeRepositoriesData?: CodeRepositoryData[]
  issuesSummary?: IssuesSummary
}

export interface Image extends Inventory<DataImage> {}

export interface DataContainerBaseImage {
  imagesNames: string[]
  digest: string
  firstSeen: string
  insights: InsightsInventory
  applications: Pick<
    DataApplication,
    "name" | "environment" | "namespace" | "resourceIds" | "applicationId"
  >[]
  issuesSummary?: IssuesSummary
  minorUpdate: string
  majorUpdate: string
}

export interface ContainerBaseImage extends Inventory<DataContainerBaseImage> {}

export interface DataContainerBaseImageDetails {
  minorUpdateVulnerabilities?: BaseImageUpdateVulnerability[]
  majorUpdateVulnerabilities?: BaseImageUpdateVulnerability[]
}

export interface Contributors {
  name: string
}

export type BranchScanStatus = "Done" | "Processing" | "Incomplete" | "Info"
export type BranchScanInfo = {
  status: BranchScanStatus
  message?: string
}

export interface DataBranch {
  branchName: string
  contributors: Contributors[]
  insights: InsightsInventory
  lastSeen: number
  lastCveScan: number | null
  repositoryName: string
  sourceControl: SourceControl.Vendor
  packagesAtRisk: number
  issuesSummary?: IssuesSummary
  relatedImages: { id: string; name: string; imageId: string }[]
  commitHash: string
  scanInfo: BranchScanInfo
  packageManagers: PackageManager[]
  estimatedPackageManagers: PackageManager[]
}

export interface Branch extends Inventory<DataBranch> {}

export interface ApplicationParamsList {
  pageNumber: string
  repositoryName?: string
  query?: string
  needle?: string
}
export interface ApplicationParamsRecord {
  needle: string
}

export type ApplicationParams = ApplicationParamsList | ApplicationParamsRecord

export type ApplicationResponse = KosmosApi.Response<Application[]>

export type ImageResponse = KosmosApi.Response<Image[]>

export type BranchResponse = KosmosApi.Response<Branch[]>

export type ContainerBaseImageResponse = KosmosApi.Response<
  ContainerBaseImage[]
>

export type ContainerBaseImageDetailsResponse =
  KosmosApi.Response<DataContainerBaseImageDetails>

type InventoryEntity =
  | "application"
  | "branch"
  | "cluster"
  | "containerImage"
  | "environment"
  | "containerBaseImage"
  | "packages"
  | "vm"

type ArchivedCountEntity = "archived"

export type CountsResponse = KosmosApi.Response<Record<InventoryEntity, number>>
export type ArchivedCountsResponse = KosmosApi.Response<
  Record<ArchivedCountEntity, number>
>

export type RepoPackageVulnerability = {
  cve: string
  severity: Severity
  cvss: number
  fixVersions: string[]
}

export type BaseImageUpdateVulnerability = {
  id: string
  severity: Severity
}

export type CodeRepoLanguage = Language

export const codeRepoLanguages = [
  "deb",
  "dotnet",
  "go",
  "java",
  "javascript",
  "php",
  "python",
  "rpm",
  "ruby",
  "rust",
  "scala",
  "alpine",
  "typescript",
  "c",
  "c#",
  "c++",
  "dart",
  "swift"
] as const

interface CodeRepoInsights {
  easyFix: boolean
  runtime: boolean
}

export type CodeRepoPackageRes = {
  id: string
  name: string
  version: string
  vulnerabilities: RepoPackageVulnerability[]
  fixVersion: string
  insights: CodeRepoInsights
  language: CodeRepoLanguage
}

export type CodeRepoPackagesResponse = KosmosApi.ResponseWithCodeRepoScanStatus<
  CodeRepoPackageRes[]
>

export interface CodeRepoPackagesParams {
  pageNumber?: number
  pageSize?: number
  filters?: string
  ascending?: boolean
  orderBy?: "name"
  view?: "flat" | "issue"
}

export type InventoryCodeRepoPackagesParams = Params<CodeRepoPackagesParams>

export interface BaseImageDetailsParams {}

export interface TransitiveFix {
  directPackage: CodeRepoPackageRes
  indirectPackages: CodeRepoPackageRes[]
}

export type TransitiveFixesResponse = KosmosApi.Response<TransitiveFix[]>

export type EntityTypes = "branch" | "image"
export interface EntityByTypeAndId {
  type: EntityTypes
  id: string
}

export interface Coordinate {
  line: number
  column: number
  offset: number
}
export interface Coordinates {
  start: Coordinate
  end: Coordinate
}

export interface CodeExample {
  description: string
  code: string
}

export interface IntroducedThroughLink {
  url: string
  displayText: string
}

export interface SastIssue {
  cwe: CWE
  cweLink: string
  riskId: string
  impact: string
  score: number
  fixComment: string
  description: string
  codeExamples: CodeExample[]
  languages: CodeRepoLanguage[]
  owasp: string
  file: string
  filePath: string
  discoveredAt: number
  coordinates: Coordinates[]
  relatedApplications: Application[]
  introducedThroughLinks: IntroducedThroughLink[]
  insights: InsightsIssue
}

export type SastIssuesResponse = KosmosApi.Response<SastIssue[]>

export interface InsightsPackage {
  exploitMaturity: ExploitMaturity
}
export interface Package {
  insights: InsightsPackage
}

export interface PackageItem {
  id: string
  data: Package
  type: "package"
}

export type PackageResponse = KosmosApi.Response<PackageItem[]>

export interface PackageVulnerability {
  id: string
  cwe: string
  cweDescription: string
  severity: Severity
  fixVersions: string[]
}

export type PackageVulnerabilitiesResponse = KosmosApi.Response<
  PackageVulnerability[]
>

interface Resource {
  name: string
  imageRepository: string
  type: ResourceDomain.Type
}

interface BaseImageDetails {
  baseImageDigest: string
  baseImageLayerIndex: number
  baseImageNames: string[]
}

interface PackageDescribe {
  name: string
  language: Language
  introducedThroughResources: {
    resource: Resource
    introducedThrough: {
      baseImageDetails: BaseImageDetails
      packageLocations: string[]
    }[]
    sourceId: string
  }[]
  resources: []
  lastSeen: number
  version: string
  vulnerabilitiesSummary: Record<Severity, number>
  insights: {
    fromBaseImage: boolean
  }
}

export type PackageDescribeResponse = KosmosApi.Response<{
  data: PackageDescribe
  displayName: string
}>

export interface PackageCommonProps extends Params {
  id?: string
  projectId?: string
}

export interface VM {
  id: string
  displayName: string
  type: "vm"
  data: {
    instanceId: string
    imageId: string
    instanceType: string
    environmentName: string
    os: string
    region: string
    autoScalingGroups: string[]
    insights: {}
    lastSeen: number
    issuesSummary: IssuesSummary
  }
}

export type VMResponse = KosmosApi.Response<VM[]>
