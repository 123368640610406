import { IssuesTab } from "@/organisms/Drawers/components/IssuesTab"
import {
  getDefaultFiltersInventoryIssuesDrawer,
  getDefaultParamsOpenSourceIssue
} from "@/organisms/Drawers/hooks/useParamsFilter"

import { buildQueryStringFromRecord } from "@/helpers/filters.helper"

import type { IssueTabVirtualMachineDrawerComponent } from "."

export const IssueTabVirtualMachineDrawer: IssueTabVirtualMachineDrawerComponent =
  (props) => (
    <IssuesTab
      getInitialParamsFilter={getDefaultFiltersInventoryIssuesDrawer}
      defaultParams={getDefaultParamsOpenSourceIssue()}
      exportQueryParams={(params: Record<string, any>) =>
        buildQueryStringFromRecord({ resourceId: props.record?.id, ...params })
      }
      {...props}
    />
  )
