import { useEffect, type RefObject } from "react"

export const useResizeObserver = <E extends Element>(
  ref: RefObject<E>,
  cb: (entities: ResizeObserverEntry[]) => void
) => {
  useEffect(() => {
    if (!ref?.current || !cb) return

    const observer = new ResizeObserver(cb)

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])
}
