import { Button } from "@/atoms"
import { useMutation } from "@/hooks/useHttp"
import useServices from "@/hooks/useServices"
import { DownloadOutlined } from "@/icons"
import { downloadFileFromJSON, downloadFileFromUrl } from "@/services/download"

const STREAM = false

export const ExportInventoryItem = ({
  itemType,
  itemId,
  filename = "sbom-report.json"
}: {
  filename?: string
  itemType: string
  itemId: string
}) => {
  const { http } = useServices()
  const $export = useMutation({
    mutationFn: () => {
      return http.post<{ downloadUrl: string } | any>(
        `/v1/inventory/${itemType}/${btoa(itemId)}/export`,
        { outputFormat: "SPDX_JSON", stream: STREAM }
      )
    },
    onSuccess: async (data) => {
      if (STREAM) {
        downloadFileFromJSON({ filename, data })
      } else {
        downloadFileFromUrl({ url: data.downloadUrl, filename })
      }
    }
  })
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation()
        $export.mutate()
      }}
      size="small"
      loading={$export.isPending}
      icon={<DownloadOutlined />}
      data-tooltip-content="Download SBOM in SPDX format"
    />
  )
}
