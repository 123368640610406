import { DatePicker } from "@/atoms"
import { useFilters } from "@/hooks/useFilters"
import cn from "classnames"
import dayjs from "dayjs"
import { useRef } from "react"
import styles from "./DatePicker.module.scss"

import type { DatePickerFilterProps } from "."

export const DatePickerFilter = ({
  placeholder,
  isLoading
}: DatePickerFilterProps) => {
  const { searchParams, setSearchParams } = useFilters()
  const defaultValue = useRef(
    (() => {
      const daysAgo = Number(searchParams.get("daysAgo"))

      if (!daysAgo) return undefined

      return dayjs().subtract(daysAgo, "day")
    })()
  ).current
  const presets = [
    { label: "Last 24 hours", value: dayjs().add(-1, "d") },
    { label: "Last 7 days", value: dayjs().add(-7, "d") },
    { label: "Last 30 days", value: dayjs().add(-30, "d") },
    { label: "Last 90 days", value: dayjs().add(-90, "d") }
  ]

  return (
    <DatePicker
      className={cn(isLoading && styles.disabled)}
      disabled={isLoading}
      defaultValue={defaultValue}
      placeholder={placeholder}
      presets={presets}
      onChange={(_, dateString) => {
        setSearchParams((prev) => {
          const params = new URLSearchParams(prev)
          const date = Array.isArray(dateString) ? dateString[0] : dateString
          const daysAgo = dayjs().diff(dayjs(date), "day")

          if (!dateString || !daysAgo) {
            params.delete("daysAgo")
          } else {
            params.set("daysAgo", `${daysAgo}`)
          }

          params.set("pageNumber", "0")

          setSearchParams(params)

          return params
        })
      }}
      disabledDate={(current) =>
        current > dayjs().endOf("day") || current < dayjs().subtract(10, "year")
      }
    />
  )
}
