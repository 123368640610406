import { Skeleton } from "@/atoms"
import { useRef } from "react"
import { IntegrationCardTemplate } from "../../templates/IntegrationCardTemplate"

export const ListIntegrationsSkeleton = () => {
  const listSkeleton = useRef(Array(10).fill(0)).current

  return (
    <>
      {listSkeleton.map((_, index) => (
        <IntegrationCardTemplate
          key={index}
          header={
            <Skeleton.Node active>
              <div />
            </Skeleton.Node>
          }
          title={<Skeleton active paragraph={{ rows: 2 }} />}
          tags={<Skeleton active paragraph={{ rows: 0 }} />}
        />
      ))}
    </>
  )
}
