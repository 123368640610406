import cn from "classnames"

import { Button } from "@/atoms"

import styles from "./InlineInputButton.module.scss"

import type { ButtonProps } from "@/atoms"

export const InlineInputButton = (props: ButtonProps) => (
  <Button
    className={cn(styles.container, props.disabled && styles.disabled)}
    type="text"
    size="small"
    {...props}
  />
)
