import { Tooltip } from "@/atoms"

import type { TFunction } from "@/hooks/useTranslation"

export const ScopeCell =
  (t: TFunction) =>
  (
    specificScopes: { label: string; name: string; value: string | string[] }[]
  ) => {
    const content = specificScopes.map(
      ({ label, name, value }, index: number) => {
        const shouldAddComma = index > 0
        const title = label || name
        const titleString = `${shouldAddComma ? ", " : ""}${title}: `
        const shouldJoinValues = Array.isArray(value)
        const valueString = shouldJoinValues ? value.join(", ") : value

        return (
          <span key={name}>
            {titleString}
            <b>{valueString}</b>
          </span>
        )
      }
    )

    return (
      <Tooltip placement="topLeft" title={content}>
        {content.length ? content : t("settings.policies.form.allRepo")}
      </Tooltip>
    )
  }
