import { useRef, useState } from "react"

import type { Key, State, TabsKeys } from "./useTabs.types"

export type Actions = ReturnType<typeof useTabs>[1]

export const getInitialCounts = (tabs: TabsKeys) =>
  tabs.reduce(
    (acc, key) => {
      acc[key] = 0

      return acc
    },
    {} as Record<Key, number>
  )

export const getInitialState = (tabs: TabsKeys, forceRender = false) => ({
  forceRender,
  counts: getInitialCounts(tabs)
})

export const useTabs = (tabs: TabsKeys, attr: any) => {
  const { drawer, ...rest } = attr
  const { activeTabFieldName, searchParams } = drawer
  const [state, setState] = useState<State>(getInitialState(tabs))
  const activeTab = searchParams.get(activeTabFieldName)
  const lastActiveTab = useRef(activeTab) // lastActiveTab is used to keep track of the last active tab to prevent switching to the first tab during the closing animation

  const onChangeActiveKey = (activeTab: Key) => {
    const { setSearchParams } = drawer

    lastActiveTab.current = activeTab
    searchParams.set(activeTabFieldName, activeTab)
    setSearchParams(searchParams, { replace: true })
  }

  const onChangeCountByKey = (name: Key, count: number) => {
    setState((prev) => ({ ...prev, counts: { ...prev.counts, [name]: count } }))
  }

  const forceRender = () => setState((prev) => ({ ...prev, forceRender: true }))

  const activeTabValue = activeTab || lastActiveTab.current || tabs[0]

  return [
    { activeTab: activeTabValue, ...state },
    { forceRender, onChangeActiveKey, onChangeCountByKey, ...rest }
  ] as const
}
