import { Route, Routes } from "react-router-dom"

import { message } from "@/atoms"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { ConnectIntegrationPage } from "./components/pages/ConnectIntegrationPage"
import { GitlabIntegrationPage } from "./components/pages/GitLabIntegrationPage"
import { IntegrationPage } from "./components/pages/IntegrationPage"
import { ItemIntegrationPage } from "./components/pages/ItemIntegrationPage"
import { ListIntegrationsPage } from "./components/pages/ListIntegrationsPage"
import { UnauthorizedIntegrationCallbackPage } from "./components/pages/UnauthorizedIntegrationCallbackPage"

import type { IntegrationsPageProps } from "."

export const IntegrationsPage = (props: IntegrationsPageProps) => {
  const { authorizedUser } = props
  const { INTEGRATIONS } = NAMES_ROUTES
  const [messageApi, messageContextHolder] = message.useMessage()

  return (
    <>
      {messageContextHolder}
      <Routes>
        {!authorizedUser && (
          <>
            <Route path="/" element={<UnauthorizedIntegrationCallbackPage />} />
          </>
        )}
        {authorizedUser && (
          <>
            <Route path="/" element={<ListIntegrationsPage />} />
            <Route
              path={`/${INTEGRATIONS.CALLBACK}`}
              element={<IntegrationPage />}
            />
            <Route
              path="/:nameIntegration"
              element={<ItemIntegrationPage messageApi={messageApi} />}
            />
            <Route
              path="/gitlab/connect"
              element={<GitlabIntegrationPage messageApi={messageApi} />}
            />
            <Route
              path="/:nameIntegration/connect"
              element={<ConnectIntegrationPage messageApi={messageApi} />}
            />
          </>
        )}
      </Routes>
    </>
  )
}
