import { useEffect } from "react"
import { useLocation } from "react-router-dom"

import { EVENTS } from "@/const/event.constants"
import { getPageNameFromPath } from "@/helpers/logger.helper"
import useServices from "@/hooks/useServices"
import { usePrevious } from "./usePrevious"

export const useLogger = () => {
  const { logger } = useServices()

  return { logger, EVENTS }
}

export const useLoggerOnNavigation = () => {
  const { pathname, search } = useLocation()
  const prevPathname = usePrevious(pathname)
  const { logger, EVENTS } = useLogger()

  useEffect(() => {
    if (prevPathname !== pathname) {
      logger.info(EVENTS.ANALYTIC_EVENTS.NAVIGATION, {
        "url.from": prevPathname,
        "url.to": pathname
      })

      const transportsByLevel = logger.getTransportByLevel("info")
      const currentPage = getPageNameFromPath(pathname, logger)

      if (currentPage) {
        transportsByLevel?.setDefaultMetaAttributes?.({
          current_page: currentPage
        })
      }

      logger.info(EVENTS.ANALYTIC_EVENTS.PAGE_OPENED)
    }

    logger.info(EVENTS.ANALYTIC_EVENTS.pageView, {
      url: `${pathname}${search}`
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, prevPathname, search])
}
