import { Divider, Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { ApartmentOutlined } from "@/icons"
import { Heading } from "@/molecules/Heading"
import { InformationPopover } from "@/molecules/InformationPopover"
import { CodeIssueExpandableCell } from "./CodeIssueExpandableCell"
import { FindingsMetadata } from "./components/FindingsMetadata"
import { BaseImageFixes } from "./components/Fixes/BaseImageFixes/BaseImageFixes"
import { PackageUpdates } from "./components/Fixes/PackagesUpdates/PackageUpdates"
import IntroduceThroughModal from "./components/IntroduceThrough/IntroduceThroughModal"
import { RuntimeEvidence } from "./components/RuntimeEvidence/RuntimeEvidence"

import styles from "./IssueExpandableCell.module.scss"

import { isCodeIssueType } from "@/domain/issue"
import type { IssueExpandableCellPropsView } from "./IssueExpandableCell.types"

export const IssueExpandableCellView = (
  props: IssueExpandableCellPropsView
) => {
  const {
    metadata,
    issueType,
    fixability,
    fixVersions,
    codeRepoFindings = [],
    drawer,
    noLimits,
    baseImageFindings = [],
    imageFindings = [],
    vmFindings = [],
    insightIssue,
    copyable
  } = props

  const imagesFindingsWithFixes = imageFindings?.filter(
    (f: any) => f.computedFixes?.length > 0
  )

  const showPackagesUpdates =
    codeRepoFindings.length > 0 || imagesFindingsWithFixes.length > 0

  const { t } = useTranslation()
  const tKey = "inventory.images.drawer.issue."

  if (isCodeIssueType(issueType)) {
    return <CodeIssueExpandableCell t={t} {...props} />
  }

  const showBaseImage = baseImageFindings.length > 0
  const showRemediationGuide = showPackagesUpdates || showBaseImage

  return (
    <div
      className={styles.fixcontainer}
      data-element-issue-expandable-cell-view="fix-container"
    >
      {!drawer && (
        <div className="container-items-issue-expanded-cell-component-issue-tab">
          <FindingsMetadata {...metadata} t={t} tKey={tKey} />
        </div>
      )}
      {!drawer && (
        <RuntimeEvidence
          record={{
            issueType,
            riskInsights: insightIssue,
            language: metadata.language
          }}
        />
      )}
      <div className="container-items-issue-expanded-cell-component-issue-tab">
        {!drawer && (
          <div className={styles.titleSection}>
            <Heading level={6} title={t(`general.fixes`)} />
          </div>
        )}
        <div className={styles.section}>
          <div>
            <Text className={styles.subtitleSection}>
              {`${t(`${tKey}fixesVersions`)}:`}
            </Text>
            <InformationPopover.Popover
              content={
                <InformationPopover.CommonTemplate>
                  {t(`${tKey}fixesInPackageVersionsPopover`)}
                </InformationPopover.CommonTemplate>
              }
            />
          </div>
          <div>
            {fixVersions &&
            fixVersions.length &&
            issueType !== "Malicious_Package" ? (
              <Text className={styles.value}>
                {`${metadata.packageName} ${fixVersions.join(", ")}`}
              </Text>
            ) : (
              <Text className={styles.valueNull}>-</Text>
            )}
          </div>
        </div>
        {showRemediationGuide && (
          <>
            <Divider className={styles.divider} />
            {copyable && <br />}
            <Text className={styles.remediationGuide} type="secondary">
              {t(`${tKey}remediationGuide`).toUpperCase()}
            </Text>
            {copyable && (
              <>
                <br />
                <br />
              </>
            )}
          </>
        )}
        {showPackagesUpdates ? (
          <PackageUpdates
            copyable={copyable}
            noLimits={noLimits}
            codeRepoFindings={codeRepoFindings}
            imageFindings={imagesFindingsWithFixes}
            fixability={fixability}
            introducedThrough={
              codeRepoFindings.length > 0 && (
                <IntroduceThroughModal
                  t={t}
                  tKey={tKey}
                  imageFindings={[]}
                  baseImageFindings={[]}
                  vmFindings={[]}
                  codeRepoFindings={codeRepoFindings}
                  title={`${t(
                    `${tKey}introducedThroughByResourcePackageHierarchy`
                  )}`}
                  description={`${t(
                    `${tKey}introducedThroughDescriptionPackageHierarchy`
                  )}`}
                  buttonText={`${t(`${tKey}packageHierarchy`)}`}
                  icon={<ApartmentOutlined />}
                  defaultExpandedRowKeys={codeRepoFindings?.map(
                    (codeRepoFinding) => codeRepoFinding.resource.id
                  )}
                />
              )
            }
            t={t}
            tKey={tKey}
          />
        ) : null}
        {showBaseImage && (
          <BaseImageFixes
            baseImageFindings={baseImageFindings}
            t={t}
            tKey={tKey}
            introducedThrough={
              !noLimits && (
                <IntroduceThroughModal
                  t={t}
                  tKey={tKey}
                  imageFindings={[]}
                  vmFindings={[]}
                  baseImageFindings={baseImageFindings}
                  codeRepoFindings={[]}
                  title={`${t(`${tKey}introducedThroughByResourceBaseImage`)}`}
                  description={`${t(`${tKey}introducedThroughDescription`)}`}
                  buttonText={`${t(`${tKey}originalBaseImage`)}`}
                  icon={<ApartmentOutlined />}
                  defaultExpandedRowKeys={[baseImageFindings[0].resource.id]}
                />
              )
            }
            fixability={fixability}
          />
        )}
      </div>
      <div
        className="container-items-issue-expanded-cell-component-issue-tab"
        data-element-issue-expandable-cell-view="introduce-through"
      >
        {!drawer && (
          <div className={styles.titleSection}>
            <Heading level={6} title={t("general.introducedThrough")} />
          </div>
        )}
        {!copyable && (
          <IntroduceThroughModal
            drawer={drawer}
            t={t}
            tKey={tKey}
            imageFindings={imageFindings}
            baseImageFindings={baseImageFindings}
            codeRepoFindings={codeRepoFindings}
            vmFindings={vmFindings}
            title={t(`${tKey}introducedThroughByResource`)}
            description={t(`${tKey}introducedThroughDescription`)}
            buttonText={t(`${tKey}allIntroducedThrought`)}
            defaultExpandedRowKeys={
              codeRepoFindings.length > 0
                ? [codeRepoFindings[0].resource.id]
                : baseImageFindings.length > 0
                  ? [baseImageFindings[0].resource.id]
                  : imageFindings.length > 0
                    ? [imageFindings[0].resource.id]
                    : []
            }
          />
        )}
      </div>
    </div>
  )
}
