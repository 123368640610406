import { TFunction } from "@/hooks/useTranslation"
import { Filters } from "@/organisms/SmartSearch/types/options.types"

export const getFilters = (t: TFunction): Filters => [
  {
    key: "environments",
    label: t("filters.environments"),
    type: "multiple",
    order: "none",
    options: []
  },
  {
    key: "statuses",
    label: t("filters.status"),
    type: "multiple",
    order: "none",
    options: []
  },
  {
    key: "kortexVersions",
    label: t("filters.kortexVersion"),
    type: "multiple",
    order: "none",
    options: []
  }
]
