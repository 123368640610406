import { REDIRECT_URL_PARAM_AUTH } from "@/const/provider-auth.constants"
import { useLocation, useNavigate } from "react-router-dom"

export const useSlugSignInRedirect = () => {
  const navigate = useNavigate()
  const location = useLocation()
  return (slug: string) => {
    const query = location.search ? `${location.search}` : ""
    const pathname =
      location.pathname !== "/signin"
        ? `/${slug}/signin?${REDIRECT_URL_PARAM_AUTH}=${encodeURIComponent(
            `${location.pathname}${query}`
          )}`
        : ""
    navigate(pathname)
  }
}
