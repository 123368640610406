import cn from "classnames"

import { Spin } from "@/atoms"
import { Heading } from "@/molecules/Heading"

import styles from "./ModalTemplate.module.scss"

import { ModalTemplateComponent } from "."

export const ModalTemplate: ModalTemplateComponent = (props) => {
  const { title, children, isLoading, classes = {} } = props

  return (
    <>
      <Heading level={4} title={title} />
      <div className={cn(styles.content, classes.container)}>
        {!isLoading && children}
      </div>
      {isLoading && (
        <div className={styles.spin}>
          <Spin />
        </div>
      )}
    </>
  )
}
