export const getDefaultValues = (initialValues?: { environment?: string }) => {
  const { environment = "${ENVIRONMENT}" } = initialValues || {}

  return {
    key: Date.now().toString(),
    workDir: "${WORKDIR}",
    pathKortexTGZ: "${KORTEX_TGZ_PATH}",
    deploymentKey: "${DEPLOYMENT_KEY}",
    environment
  }
}

export const getDeployCommand = (
  values: ReturnType<typeof getDefaultValues>,
  defaultValues: ReturnType<typeof getDefaultValues>
) => {
  const firstLine = `cd ${getTextForValue(values.workDir, defaultValues.workDir)} \\`
  const secondLine = `&& tar --no-overwrite-dir -xvf ${getTextForValue(values.pathKortexTGZ, defaultValues.pathKortexTGZ)} \\`
  const thirdLine = `&& sudo ./install_kortex.sh ${getTextForValue(values.deploymentKey, defaultValues.deploymentKey)} ${getTextForValue(values.environment, defaultValues.environment)} \\`
  const fourthLine = `&& sudo systemctl start komon koltan \\`
  const fiveLine = `&& rm ${getTextForValue(values.pathKortexTGZ, defaultValues.pathKortexTGZ)}`

  return `${firstLine}\n${secondLine}\n${thirdLine}\n${fourthLine}\n${fiveLine}`
}

export const getTextForValue = (value: string, defaultValue: string) =>
  value === defaultValue ? `[${value}]` : value
