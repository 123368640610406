import { Card } from "@/atoms"
import { Heading } from "@/molecules/Heading"
import { LineClamp } from "@/molecules/LineClamp"
import IssueDetailsSkeleton from "@/organisms/Drawers/components/IssuesTab/IssueExpandableCell/IssueDetailsSkeleton"

import type { DescriptionIssueCardComponent } from "."

export const DescriptionIssueCard: DescriptionIssueCardComponent = (props) => {
  const { t, record } = props

  if (!record) return <IssueDetailsSkeleton />

  return (
    <Card
      className="embedded-card-ks"
      bordered={false}
      title={<Heading level={6} title={t("general.description")} />}
    >
      <LineClamp clamp={3}>
        <span>{record.description}</span>
      </LineClamp>
    </Card>
  )
}
