import { useSearchParams } from "react-router-dom"

import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useParallelQueries } from "@/hooks/useHttp"
import { useTranslation } from "@/hooks/useTranslation"
import { getCards } from "./helpers"

import type { GetCardProps } from "."

export const useFunnelTriage = (props?: GetCardProps) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const cards = getCards(t, searchParams, props)
  const context = useParallelQueries<{ data: number }>(
    cards.map(({ params }) => ({
      url: RESOURCES_NAMES.TRIAGE.ISSUES_COUNT,
      params
    }))
  )

  return [context, cards] as const
}
