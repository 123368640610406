import { Form, Input } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { Enterprise } from "@/icons"
import type { SlugInputFormItemProps } from "."

export const SlugInputFormItem = (props: SlugInputFormItemProps) => {
  const { t } = useTranslation()
  const rules = [{ required: true, message: t("error.requiredField") }]
  const { name, size } = props
  return (
    <Form.Item name={name} rules={rules}>
      <Input
        size={size}
        placeholder={t("auth.organizationSlug") || ""}
        placeholderAlign="center"
        prefix={<Enterprise />}
        data-testid="input_slug_slugForm"
      />
    </Form.Item>
  )
}
