import { Button, Modal } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { CheckCircleOutlined } from "@/icons"

import type { ActionButtonsFooterComponent } from "."

export const ActionButtonsFooter: ActionButtonsFooterComponent = (props) => {
  const { onOk, onCancel, okButtonProps, cancelButtonProps } = props
  const { t } = useTranslation()
  const [modal, contextHolder] = Modal.useModal()
  const commonProps = {
    width: 400,
    okText: t("general.approve"),
    cancelText: t("general.backToEdit")
  }
  const getModalProps = (value: boolean | object) =>
    typeof value === "boolean" ? {} : value

  return (
    <>
      <Button
        disabled={props.cancelDisabled}
        onClick={() => {
          const {
            cancelConfirmModalContent,
            cancelConfirmModal = !!cancelConfirmModalContent
          } = props

          if (!cancelConfirmModal) return onCancel()

          const content =
            cancelConfirmModalContent ||
            "Are you sure you want to cancel the changes?"

          modal.confirm({
            content,
            onOk: onCancel,
            ...commonProps,
            ...getModalProps(cancelConfirmModal)
          })
        }}
        {...cancelButtonProps}
      >
        {t("general.cancel")}
      </Button>
      <Button
        disabled={props.okDisabled}
        loading={props.okLoading}
        icon={<CheckCircleOutlined />}
        type="primary"
        onClick={() => {
          const {
            okConfirmModalContent,
            okConfirmModal = !!okConfirmModalContent
          } = props

          if (!okConfirmModal) return onOk()

          const content =
            okConfirmModalContent || t("general.defaultSafeGuardOnApplyAction")

          modal.confirm({
            content,
            ...commonProps,
            ...getModalProps(okConfirmModal),
            onOk: onOk
          })
        }}
        {...okButtonProps}
      >
        {t("general.apply")}
      </Button>
      {contextHolder}
    </>
  )
}
