import cn from "classnames"

import { Badge, Tag } from "@/atoms"
import { WrapperLabelPrimary } from "./WrapperLabelPrimary"

import styles from "./LabelPrimaryNavigation.module.scss"

import type { LabelPrimaryNavigationProps } from "."

export const LabelPrimaryNavigation = ({
  label,
  asideText,
  count,
  subMenu,
  tooltip,
  classes = {}
}: LabelPrimaryNavigationProps) => {
  return (
    <WrapperLabelPrimary title={tooltip}>
      <div className={styles.container}>
        <span className={styles.label}>{label}</span>
        {!!asideText && (
          <Tag className={cn(styles.tag, classes.tag)}>{asideText}</Tag>
        )}
        {!!count && (
          <Badge
            className={cn(styles.count, subMenu && styles.subMenuCount)}
            count={count}
          />
        )}
      </div>
    </WrapperLabelPrimary>
  )
}
