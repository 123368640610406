import { Radio } from "@/atoms"
import { MONTH_IN_SECONDS, YEAR_IN_SECONDS } from "@/const/default-values"

import type { ExpiryRadioButtonGroupProps, FormatExpiry } from "."

const formatExpiry: FormatExpiry = (t, count, tKey) => {
  return `${count} ${t(tKey, { count })}`
}

export const ExpiryRadioGroupButton = (props: ExpiryRadioButtonGroupProps) => {
  const {
    t,
    expiresIn,
    onChange: handleExpiresInChange,
    disabled: inputBlocked
  } = props

  return (
    <Radio.Group
      value={expiresIn}
      onChange={handleExpiresInChange}
      disabled={inputBlocked}
    >
      {[
        {
          key: `${3 * MONTH_IN_SECONDS}`,
          text: formatExpiry(t, 3, "general.month"),
          expiryInSeconds: 3 * MONTH_IN_SECONDS
        },
        {
          key: `${1 * YEAR_IN_SECONDS}`,
          text: formatExpiry(t, 1, "general.year"),
          expiryInSeconds: 1 * YEAR_IN_SECONDS
        },
        {
          key: `${3 * YEAR_IN_SECONDS}`,
          text: formatExpiry(t, 3, "general.year"),
          expiryInSeconds: 3 * YEAR_IN_SECONDS
        },
        {
          key: "0",
          text: t("general.never"),
          expiryInSeconds: 0
        }
      ].map(({ expiryInSeconds, text }) => (
        <Radio.Button value={expiryInSeconds}>{text}</Radio.Button>
      ))}
    </Radio.Group>
  )
}
