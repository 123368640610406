import { useEffect, useMemo, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"

export const useSearchQueryProxy = ({
  initialState,
  useQueryString,
  onChangeCb
}: {
  initialState: Record<string, any>
  useQueryString: boolean
  onChangeCb?: (params: any) => void
}) => {
  const hook = useRef<any>(useQueryString ? useSearchParams : useState).current
  const [state, setState] = hook(() => initialState)

  useEffect(() => {
    if (state === initialState) return
    onChangeCb?.(state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return useMemo(() => {
    if (useQueryString) {
      return [state, setState]
    }

    return [
      new URLSearchParams(state),
      (cb: any) => {
        const nextState: any = {}

        if (typeof cb === "function") {
          cb(state).forEach((v: any, k: any) => {
            nextState[k] = v
          })
        }

        setState(nextState)
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setState])
}
