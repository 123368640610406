import type { EcrConfiguration } from "@/api/useIntegrations.types"
import { FormInstance } from "@/atoms"
import { useEffect, useState } from "react"
import { IntegrationDetailsSkeleton } from "../../IntegrationDetailsSkeleton"

import { usePreInstallECR } from "@/api/useIntegrations"
import type { Dispatch } from "react"
import { EcrRegistriesList } from "./EcrRegistriesList"

type Props = {
  isDebug?: boolean
  // installation is null when the component is loading
  configuration: EcrConfiguration | undefined
  onApply: (configuration: EcrConfiguration) => void
  onRemove: (accountId: string) => void
  form: FormInstance
  setFormDirty: Dispatch<boolean>
}

export const EcrConfigurationForm: React.FC<Props> = ({
  configuration = null,
  form,
  onApply,
  onRemove,
  setFormDirty
}) => {
  const [currentConfigurations, setCurrentConfigurations] =
    useState<EcrConfiguration | null>(configuration)

  const { response: preInstallResponse } = usePreInstallECR("ecr")

  useEffect(() => {
    setCurrentConfigurations(configuration)
  }, [configuration])

  if (currentConfigurations === null) {
    return <IntegrationDetailsSkeleton />
  }

  const { accountRegistries = [] } = configuration || ({} as EcrConfiguration)
  return (
    <EcrRegistriesList
      accountRegistries={accountRegistries}
      regionOptions={preInstallResponse?.regionOptions || []}
      form={form}
      onApply={onApply}
      onRemove={onRemove}
      setFormDirty={setFormDirty}
    />
  )
}
