import { Button, Dropdown } from "@/atoms"
import { MoreOutlined } from "@/icons"

import styles from "./ActionsDropdown.module.scss"

import type { ActionsDropdownComponent } from "."

export const ActionsDropdown: ActionsDropdownComponent = (props) => (
  <Dropdown rootClassName={styles.rootClassName} {...props}>
    <Button size="small" type="text" icon={<MoreOutlined />} />
  </Dropdown>
)
