import { SLUG_LS, X_SCOPE_ID } from "@/const/local-storage.constants"
import { RESOURCES_NAMES } from "@/const/resource.constants"

import type { User } from "@/domain/user/user"
import type { Services } from "@/interfaces/services"

type Settings = RequestInit & {
  params?: Record<string, string>
}

export type InitialParamsHttpAdapter = {
  auth: Services.IAuthAdapter
  logger: Services.ILoggerAdapter
  apiUrl?: string
  exchangeTokenUrl?: string
  localStorage?: Storage
}

type ResponseToken = {
  data: {
    features: Features.Response
    role: User["role"]
  }
}

type OnReceiveFeatureFlags = (
  flags: Features.Response,
  role: User["role"]
) => void

type PromiseWithResolve = {
  resolve?: () => void
  reject?: () => void
  promise: Promise<unknown>
}

export class HttpAdapter implements Services.IHttpAdapter {
  static COOKIE_EXPIRED_MS = 1000 * 60 * 15

  static defaultInstance: Services.IHttpAdapter

  protected apiUrl

  protected auth: Services.IAuthAdapter

  protected logger: Services.ILoggerAdapter

  protected localStorage: Storage

  protected exchangeTokenUrl: string

  protected queue: null | Promise<unknown> = null

  protected waitingScopeIdPromise: null | PromiseWithResolve = null

  protected authWhiteList = [
    RESOURCES_NAMES.AUTHENTICATION.AUTH,
    RESOURCES_NAMES.TENANTS.SIGNUP,
    RESOURCES_NAMES.INTEGRATIONS.NOTIFY_CALLBACK
  ]

  protected scopeIdWhiteList = [
    RESOURCES_NAMES.SETTINGS.SCOPES,
    RESOURCES_NAMES.ONBOARDING.STATUS,
    RESOURCES_NAMES.ONBOARDING.DISMISS_ONBOARDING,
    RESOURCES_NAMES.ONBOARDING.DISMISS_WELCOME,
    ...this.authWhiteList
  ]

  protected headers: Record<string, string> = {
    Accept: "application/json",
    "Content-Type": "application/json"
  }

  protected isAuthCookie: boolean = false

  protected featureFlags: null | Features.Response = null

  protected role: undefined | User["role"]

  protected tokenExchangeTimestamp: null | number = null

  protected onReceiveFeatureFlags: OnReceiveFeatureFlags = () => {}

  protected signOut: () => void

  protected createHeadersSnapshot = () => {
    if (this.headers[X_SCOPE_ID])
      this.localStorage.setItem(X_SCOPE_ID, this.headers[X_SCOPE_ID])
    else this.localStorage.removeItem(X_SCOPE_ID)
  }

  protected restoreHeadersSnapshot = () => {
    const scopeId = this.localStorage.getItem(X_SCOPE_ID)

    if (!!scopeId) this.headers[X_SCOPE_ID] = scopeId
  }

  constructor(params: InitialParamsHttpAdapter) {
    const {
      apiUrl,
      exchangeTokenUrl,
      auth,
      logger,
      localStorage = window.localStorage
    } = params || {}

    this.apiUrl = apiUrl || ""
    this.exchangeTokenUrl = exchangeTokenUrl || ""
    this.auth = auth
    this.localStorage = localStorage
    this.logger = logger
    this.restoreHeadersSnapshot()
    this.signOut = async () => {
      await this.auth.signOut()
      window.location.reload()
    }
  }

  get isAuth() {
    return this.isAuthCookie
  }

  set isAuth(value) {
    this.isAuthCookie = value
  }

  private checkStatus(response: Response) {
    try {
      if (response.ok) {
        return response
      }

      if (response.status === 401) {
        this.onAuthorizeError()
      }

      return Promise.reject(response)
    } catch (err) {
      return Promise.reject(response)
    }
  }

  private parseEndpoint(
    endpoint: string,
    params: Record<string, string | string[]> = {}
  ) {
    const url = this.apiUrl + endpoint
    const searchParams = new URLSearchParams()

    Object.keys(params).forEach((key) => {
      const value = params[key]

      if (value === undefined || value === null) {
        return
      }

      if (Array.isArray(value)) {
        value.forEach((value) => {
          searchParams.append(key, value)
        })

        return
      }

      searchParams.append(key, value)
    })
    const querystring = searchParams.toString()

    return `${url}${querystring && "?"}${querystring}`
  }

  private parseSettings({
    body,
    method = "get",
    headers = {},
    ...otherSettings
  }: Settings) {
    const settings = {
      body: body ? JSON.stringify(body) : undefined,
      method,
      headers: { ...this.headers, ...headers },
      ...otherSettings
    }

    return settings
  }

  private onAuthorizeError() {
    return this.signOut()
  }

  private getPromiseWithResolver() {
    let resolve,
      reject,
      promise = new Promise((a, b) => {
        resolve = a
        reject = b
      })

    return { resolve, reject, promise }
  }

  private checkInWhiteList(whiteList: string[], endpoint: string) {
    return whiteList.some((value) => endpoint.includes(value))
  }

  private needRefreshCookie(endpoint: string) {
    const noRefreshCookie = this.checkInWhiteList(this.authWhiteList, endpoint)
    const isCookieExpired =
      this.tokenExchangeTimestamp &&
      Date.now() - this.tokenExchangeTimestamp > HttpAdapter.COOKIE_EXPIRED_MS

    if (noRefreshCookie) {
      return false
    }

    return !this.isAuth || isCookieExpired
  }

  private refreshCookie(endpoint: string, settings: Settings) {
    if (!this.queue) {
      this.queue = this.exchangeToken()
        .catch((err) => {
          this.onAuthorizeError()

          return Promise.reject(err)
        })
        .finally(() => {
          this.queue = null
        })
    }

    return this.queue.then(() => this.request(endpoint, settings))
  }

  private needScopeId(endpoint: string) {
    return (
      !this.headers[X_SCOPE_ID] &&
      !this.checkInWhiteList(this.scopeIdWhiteList, endpoint)
    )
  }

  private waitingScopeId() {
    if (!this.waitingScopeIdPromise)
      this.waitingScopeIdPromise = this.getPromiseWithResolver()

    return this.waitingScopeIdPromise.promise
  }

  private request(endpoint: string, settings: Settings): Promise<any> {
    if (this.needRefreshCookie(endpoint)) {
      return this.refreshCookie(endpoint, settings)
    }

    if (this.needScopeId(endpoint)) {
      return this.waitingScopeId()
    }

    const { params, ...options } = settings

    return fetch(
      this.parseEndpoint(endpoint, params),
      this.parseSettings(options)
    )
      .then((res) => this.checkStatus(res))
      .then(async (res) => {
        try {
          if (res.status === 204) {
            return
          }

          const json = await res.json()

          return json
        } catch (e) {
          return {}
        }
      })
      .catch(async (err) => {
        try {
          err.data = await err.json()

          return Promise.reject(err)
        } catch {
          return Promise.reject(err)
        }
      })
  }

  public getCurrentScopeId() {
    return this.headers[X_SCOPE_ID]
  }

  public removeHeader(name: string) {
    delete this.headers[name]
  }

  public reset() {
    this.removeHeader(X_SCOPE_ID)
    this.removeHeader("Authorization")
    this.localStorage.removeItem(X_SCOPE_ID)
  }

  public setCurrentScope(scopeId: string | null) {
    const fieldName = "X-Scope-Id"

    if (!scopeId) this.removeHeader(fieldName)
    else this.headers[fieldName] = scopeId

    this.createHeadersSnapshot()
    this.waitingScopeIdPromise?.resolve?.()
    this.waitingScopeIdPromise = null
  }

  public setFeatureFlags(onReceiveFeatureFlags: OnReceiveFeatureFlags) {
    this.onReceiveFeatureFlags = onReceiveFeatureFlags

    if (this.featureFlags) {
      this.onReceiveFeatureFlags(this.featureFlags, this.role)
    }
  }

  public setSignOut(signOut: () => void) {
    this.signOut = signOut
  }

  public async exchangeToken() {
    try {
      if (this.auth) {
        const firebaseToken = await this.auth.getToken()
        const slug = this.localStorage.getItem(SLUG_LS)

        if (!firebaseToken || !slug) {
          return Promise.reject()
        }

        const { data } = await this.post<ResponseToken>(this.exchangeTokenUrl, {
          firebaseToken,
          slug
        })

        const { features, role } = data
        this.isAuth = true
        this.tokenExchangeTimestamp = Date.now()
        this.featureFlags = features || {}
        this.role = role
        this.onReceiveFeatureFlags(this.featureFlags, role)
      }
    } catch (err: any) {
      this.logger?.error(err, {
        status: err?.status,
        custom_event: "exchangeToken"
      })

      return Promise.reject()
    }
  }

  public get<T>(endpoint: string, settings: Settings): Promise<T> {
    return this.request(endpoint, { method: "get", ...settings })
  }

  public post<T>(
    endpoint: string,
    body: any,
    settings: Settings = {}
  ): Promise<T> {
    return this.request(endpoint, { method: "post", body, ...settings })
  }

  public put<T>(
    endpoint: string,
    body: any,
    settings: Settings = {}
  ): Promise<T> {
    return this.request(endpoint, { method: "put", body, ...settings })
  }

  public delete<T>(endpoint: string, settings: Settings = {}): Promise<T> {
    return this.request(endpoint, { method: "delete", ...settings })
  }

  public patch<T>(
    endpoint: string,
    body: any,
    settings: Settings = {}
  ): Promise<T> {
    return this.request(endpoint, { method: "PATCH", body, ...settings })
  }
}
