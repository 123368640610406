import { Form, Input } from "@/atoms"
import { useServices } from "@/hooks/useServices"
import { MobileOutlined } from "@/icons"
import { useMultiFactorForm } from "@/pages/SignInPage/hooks/useMultiFactorForm"
import { MultiFactorFormTemplate } from "../../templates/MultiFactorFormTemplate"
import { PhoneStepsMultiFactorForm } from "../PhoneStepsMultiFactorForm"

import type { ActionMultiFactorFormTemplateProps } from "../../templates/MultiFactorFormTemplate"

export const PhoneNumberMultiFactorForm = ({
  values,
  onOk,
  onCancel
}: ActionMultiFactorFormTemplateProps) => {
  const phoneNumberFieldName = "phoneNumber"
  const { step } = values
  const { auth } = useServices()
  const {
    inputRef,
    value,
    t,
    form,
    okDisabled,
    loading,
    setLoading,
    onKeyDown,
    onSubmit
  } = useMultiFactorForm(phoneNumberFieldName)

  return (
    <MultiFactorFormTemplate
      title={t("auth.mfa.phone.title")}
      description={t("auth.mfa.phone.description")}
      okText={t("auth.mfa.phone.okAction")}
      cancelText={t("general.back")}
      steps={<PhoneStepsMultiFactorForm loading={loading} currentStep={step} />}
      onOk={onSubmit}
      onCancel={onCancel}
      okDisabled={okDisabled}
    >
      <Form
        initialValues={values}
        form={form}
        onFinish={async () => {
          const defaultErrorMessage = t("auth.mfa.errors.default")
          const validPhoneNumber = value[0] !== "+" ? `+${value}` : value

          try {
            setLoading(true)
            const currentVerificationId =
              await auth.sendCodePhoneNumberEnrolling(validPhoneNumber)

            if (!currentVerificationId) {
              throw new Error(defaultErrorMessage)
            }

            onOk({
              verificationId: currentVerificationId,
              phoneNumber: validPhoneNumber
            })
          } catch (err) {
            const message = err instanceof Error ? err.message : ""

            if (message.includes("invalid-phone-number")) {
              return form.setFields([
                {
                  name: phoneNumberFieldName,
                  errors: [t("auth.mfa.errors.invalid-phone-number")]
                }
              ])
            }

            if (message.includes("too-many-requests")) {
              return form.setFields([
                {
                  name: phoneNumberFieldName,
                  errors: [t("auth.mfa.errors.too-many-requests")]
                }
              ])
            }

            form.setFields([
              { name: phoneNumberFieldName, errors: [defaultErrorMessage] }
            ])
          } finally {
            setLoading(false)
          }
        }}
      >
        <Form.Item
          name={phoneNumberFieldName}
          rules={[
            {
              required: true,
              message: t("error.requiredField") || ""
            },
            {
              message: t("auth.mfa.errors.valid-phone-number"),
              validator: (_, value) => {
                const pattern =
                  /^[+]?[0-9]{3}?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

                if (pattern.test(value)) {
                  return Promise.resolve()
                } else {
                  return Promise.reject(t("auth.mfa.errors.valid-phone-number"))
                }
              }
            }
          ]}
        >
          <Input
            ref={inputRef}
            size="large"
            placeholder={t("auth.mfa.phone.placeholder")}
            prefix={<MobileOutlined />}
            data-testid="phoneNumberMultiFactorFormInput"
            onKeyDown={onKeyDown}
          />
        </Form.Item>
      </Form>
    </MultiFactorFormTemplate>
  )
}
