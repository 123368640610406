import cn from "classnames"
import { Fragment } from "react"

import { Text } from "@/atoms"

import styles from "./ContentShowMore.module.scss"

import type { ActionOpenDrawer } from "@/hooks/useLinkDrawerTooltip"
import type { ContentShowMoreProps } from "."

export const ItemContentShowMore = (
  item: ContentShowMoreProps["items"][number],
  {
    copyable,
    width,
    noBullets,
    index,
    openDrawer
  }: Pick<ContentShowMoreProps, "copyable"> & {
    width: number | string
    index?: number
    noBullets?: boolean
    openDrawer?: ActionOpenDrawer
  }
) => {
  const innerWidth = typeof width === "string" ? width : width - 20
  const Wrap = copyable ? Fragment : "li"
  const isString = typeof item === "string"
  const isArray = Array.isArray(item)
  const name = isString ? item : item.name
  const description = isString || isArray ? undefined : item.description
  const externalLink = isString || isArray ? undefined : item.externalLink
  const linkToDrawer = !isString && !!openDrawer && item.drawerEntityId
  const Tag = externalLink ? "a" : "span"
  const linkProps = externalLink
    ? { href: externalLink, target: "_blank", rel: "noopener noreferrer" }
    : {}

  return (
    <Wrap key={`${name}_${index}`}>
      <div
        className={cn(
          styles.section,
          copyable && styles.sectionVirtualList,
          !noBullets && styles.bullets
        )}
      >
        <Text
          className={cn(styles.text, linkToDrawer && styles.link)}
          copyable={copyable ? { text: name } : copyable}
          style={{ maxWidth: width }}
          onClick={() => {
            if (linkToDrawer && item.drawerEntityId && item.drawerId) {
              openDrawer(item.drawerId, item.drawerEntityId)
            }
          }}
        >
          {!isArray && (
            <Tag
              style={{ maxWidth: innerWidth }}
              className={styles.textWrapVirtualList}
              {...linkProps}
            >
              {name}
            </Tag>
          )}
          {isArray &&
            item.map(({ label, value }, i) => (
              <span key={i} className={styles.wrapArray}>
                <span className={styles.labelArray}>{label}</span>
                <span>{value}</span>
              </span>
            ))}
        </Text>
        {description && (
          <Text type="secondary" className={styles.description}>
            <span
              style={{ maxWidth: innerWidth }}
              className={styles.textWrapVirtualList}
            >
              {description}
            </span>
          </Text>
        )}
      </div>
    </Wrap>
  )
}
