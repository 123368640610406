import { Card, Link } from "@/atoms"
import cn from "classnames"
import type { LinkCardProps } from "."
import styles from "./LinkCard.module.scss"

export const LinkCard = (props: LinkCardProps) => {
  const { children, className, selected, to } = props
  const cardClassName = cn(
    styles.containerLinkCard,
    !to && styles.disabledLinkCard,
    selected && styles.selectedLinkCard,
    className
  )

  return (
    <Link to={to} className={cn(!to && styles.disabledLink)}>
      <Card className={cardClassName}>{children}</Card>
    </Link>
  )
}
