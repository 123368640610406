import { useState } from "react"

import {
  useDeleteScope as useDeleteScopeApi,
  useGetFiltersScopes,
  useGetScopes,
  usePostScope,
  usePutScope
} from "@/api/useScopes"
import { useQueryClient } from "@/hooks/useHttp"
import { useLogger } from "@/hooks/useLogger"
import { useUser } from "@/hooks/useUser"
import { getOptions, getScopesData } from "./useScopes.selector"

import type { ScopesResponse } from "@/api/useScopes.types"
import type { Params, QueryClient } from "@/hooks/useHttp.types"
import type { MessageInstance } from "antd/es/message/interface"

export const useFiltersScopes = () => {
  const context = useGetFiltersScopes({}, { select: getOptions })

  return context
}

export const useScopes = (params = {}) => {
  const context = useGetScopes(params, { select: getScopesData })

  return context
}

export const useScope = (
  params: { needle: string | undefined } | undefined,
  data: any
) => {
  const context = useGetScopes(params, {
    select: getScopesData,
    enabled: !!params?.needle && params.needle !== "new",
    staleTime: 0,
    gcTime: 0,
    initialData: data
  })

  return context
}

export const removeQueries = (queryClient: QueryClient, scopeId: string) => {
  try {
    const hashPrefix = `_${scopeId}_`

    queryClient
      .getQueryCache()
      .findAll(undefined)
      .forEach((query) => {
        if (query.queryHash.includes(hashPrefix)) {
          queryClient.removeQueries({ queryKey: query.queryKey })
        }
      })

    return null
  } catch (error) {
    return error
  }
}

export const useUpdateScope = (refetchScope: () => void) => {
  const updater = usePutScope()
  const queryClient = useQueryClient()
  const { logger, EVENTS } = useLogger()
  const [isLoading, setIsLoading] = useState(false)

  const updateScope = async (scopeId: string, values: Record<string, any>) => {
    try {
      setIsLoading(true)

      await updater.mutateAsync({
        needle: `/${scopeId}`,
        data: { scope: values }
      })
      await refetchScope()
    } catch (error) {
      logger.error(error as Error, {
        tags: { custom_error: EVENTS.ERROR_EVENTS.SCOPES.ERROR_UPDATE_SCOPE }
      })

      return Promise.reject(error)
    } finally {
      setIsLoading(false)

      const err = removeQueries(queryClient, scopeId)

      if (err) {
        logger.error(err as Error, {
          tags: { custom_error: EVENTS.ERROR_EVENTS.SCOPES.ERROR_UPDATE_SCOPE }
        })
      }
    }
  }

  return { updateScope, isLoading }
}

export const useCreateScope = () => {
  const creator = usePostScope()
  const { logger, EVENTS } = useLogger()

  const createScope = async (values: Record<string, any>) => {
    try {
      const res: any = await creator.mutateAsync({ scope: values })

      return res.data[0].id
    } catch (error) {
      logger.error(error as Error, {
        tags: { custom_error: EVENTS.ERROR_EVENTS.SCOPES.ERROR_CREATE_SCOPE }
      })

      return Promise.reject(error)
    }
  }

  return {
    createScope: createScope,
    data: creator?.data,
    isLoading: creator.isPending
  }
}

export const useDeleteScope = (params: Params, messageApi: MessageInstance) => {
  const { user, setCurrentScope } = useUser()
  const { logger, EVENTS } = useLogger()
  const remover = useDeleteScopeApi(
    params,
    (oldData: ScopesResponse, newData: string) => {
      try {
        return {
          ...oldData,
          data: oldData.data.filter(({ id }) => id !== newData)
        }
      } catch (error: any) {
        return oldData
      }
    }
  )

  const deleteScope = async (scopeId: string) => {
    try {
      await remover.mutateAsync(scopeId)

      if (scopeId === user.currentScopeId) {
        //TODO: should decide if apply default scope or apply selection mode
        setCurrentScope(null)
      }
    } catch (error: any) {
      logger.error(error, {
        tags: { custom_error: EVENTS.ERROR_EVENTS.SCOPES.ERROR_DELETE_SCOPE }
      })
      const message = error?.data?.detail || error?.message || "Error"

      messageApi.error(message)
    }
  }

  return { deleteScope, isLoading: remover.isPending }
}
