import { RESOURCES_NAMES } from "@/const/resource.constants"
import {
  Config,
  Params,
  useCreate,
  useDelete,
  useHttp,
  usePatch
} from "@/hooks/useHttp"
import {
  getIntegrationDetailsData,
  getIntegrationsListData,
  getPreInstallOptionsECR
} from "./useIntegrations.selector"

import { useNavigate } from "react-router"
import type {
  IntegrationDetailsResponse,
  IntegrationsListResponse
} from "./useIntegrations.types"

export const useGetIntegrationsList = <
  T extends IntegrationsListResponse = IntegrationsListResponse,
  S = ReturnType<typeof getIntegrationsListData>
>(
  params: Params = {},
  config: Config<T, S> = {}
) => {
  const context = useHttp<T, S>(RESOURCES_NAMES.INTEGRATIONS.LIST, params, {
    ...config,
    select: (response: IntegrationsListResponse) =>
      getIntegrationsListData(response) as any
  })

  return context
}

export const useGetIntegrationDetails = <
  T extends IntegrationDetailsResponse = IntegrationDetailsResponse,
  S = ReturnType<typeof getIntegrationDetailsData>
>(
  name: string,
  params: Params = {},
  config: Config<T, S> = {}
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INTEGRATIONS.LIST}/${name}`,
    params,
    {
      ...config,
      select: (response: IntegrationDetailsResponse) =>
        getIntegrationDetailsData(response) as any
    }
  )

  return context
}

export const usePatchIntegration = (params: Params<{ vendor: string }>) => {
  const updater = usePatch(
    `${RESOURCES_NAMES.INTEGRATIONS.LIST}/${params.vendor}`
  )

  return updater
}

export const useDeleteIntegration = <T, S = T>(
  params: Params<{ vendor: string }>
) => {
  const navigation = useNavigate()
  const remover = useDelete<T, S>(
    `${RESOURCES_NAMES.INTEGRATIONS.LIST}/${params.vendor}`,
    params,
    undefined,
    (client) => ({
      onSuccess() {
        client.invalidateQueries({
          queryKey: [`${RESOURCES_NAMES.INTEGRATIONS.LIST}/${params.vendor}`]
        })
        client.invalidateQueries({
          queryKey: [
            `${RESOURCES_NAMES.INTEGRATIONS.LIST}/${params.vendor}/${params.vendor}`
          ]
        })

        if (params.vendor === "gitlab") return

        navigation(`/integrations/${params.vendor}`)
      }
    })
  )

  return remover
}

export const useGetCallbackIntegrations = (
  vendor: string,
  params: Params<Record<string, string>>,
  config: Config
) => {
  const context = useHttp(
    `${RESOURCES_NAMES.INTEGRATIONS.CALLBACK}/${vendor}`,
    params,
    config
  )

  return context
}

export type InstallStatus = {
  status: string
  installedRegistriesUrls: string[]
}

export type InstallRegistryResponse = {
  data: InstallStatus
}

export const useInstallIntegration = (params: Params<{ vendor: string }>) => {
  const updater = useCreate(
    `${RESOURCES_NAMES.INTEGRATIONS.LIST}/${params.vendor}`,
    undefined,
    undefined,
    (client) => ({
      onSuccess() {
        client.invalidateQueries({
          queryKey: [`${RESOURCES_NAMES.INTEGRATIONS.LIST}/${params.vendor}`]
        })
      }
    })
  )

  return updater
}

export const useNotifyAdminsOnCallbackIntegrations = <T = unknown, S = T>() => {
  const context = useCreate<T, S>(
    `${RESOURCES_NAMES.INTEGRATIONS.NOTIFY_CALLBACK}`
  )

  return context
}

export const usePreInstallECR = (vendor?: string) => {
  const context = useHttp(
    RESOURCES_NAMES.INTEGRATIONS.ECR.PRE_INSTALL,
    undefined,
    {
      enabled: vendor === "ecr",
      select: getPreInstallOptionsECR
    }
  )

  return context
}
