import { useGcpTokenValidation } from "@/api/useTenantSignup"
import { Text } from "@/atoms"
import { useStatus } from "@/hooks/useStatus"
import { useTranslation } from "@/hooks/useTranslation"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { TenantSignupForm } from "./components/organisms/TenantSignupForm"

import styles from "./TenantSignupPage.module.scss"

export const TenantSignupPage = () => {
  const [searchParams] = useSearchParams()
  const gcpToken = searchParams.get("x-gcp-marketplace-token")
  const entitlementId = searchParams.get("entitlement-id")
  const { mutateAsync: tokenMutateAsync } = useGcpTokenValidation()
  const { t: tModal } = useTranslation("translation", {
    keyPrefix: "auth.tenantSignup"
  })
  const {
    status: gcpTokenValidationStatus,
    setStatus: setGcpTokenValidationStatus
  } = useStatus("PENDING")
  const [isTenantCreated, setIsTenantCreated] = useState(false)

  useEffect(() => {
    if (!gcpToken || !entitlementId)
      return setGcpTokenValidationStatus(
        !gcpToken && !entitlementId ? "UNSPECIFIED" : "ERROR"
      )
    const validateToken = async () => {
      try {
        await tokenMutateAsync({
          gcp_token: gcpToken
        })
        setGcpTokenValidationStatus("SUCCESS")
      } catch (error: any) {
        setGcpTokenValidationStatus("ERROR")
      }
    }
    validateToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isTenantCreated) {
    const translationKey =
      gcpTokenValidationStatus === "SUCCESS"
        ? "successMessageWithToken"
        : "successMessage"
    return <Text className={styles.text}>{tModal(translationKey)}</Text>
  }

  return (
    <>
      {gcpTokenValidationStatus === "ERROR" && (
        <Text className={styles.text}>{tModal("invalidOperation")}</Text>
      )}
      {gcpTokenValidationStatus === "PENDING" && (
        <Text className={styles.text}>{tModal("validatingRequest")}</Text>
      )}
      {(gcpTokenValidationStatus === "SUCCESS" ||
        gcpTokenValidationStatus === "UNSPECIFIED") && (
        <TenantSignupForm
          entitlementId={entitlementId}
          setIsTenantCreated={setIsTenantCreated}
        />
      )}
    </>
  )
}
