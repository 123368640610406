import { Select } from "@/atoms"
import { getColorVariable, getLabelById } from "@/domain/label"
import { TagFilled, TagMore } from "@/icons"
import { ClosableTag } from "./ClosableTag"
import { PopoverLabelTag } from "./PopoverLabelTag"

import styles from "./LabelTag.module.scss"

import type { LabelTagComponent } from "."

export const LabelTag: LabelTagComponent = (props) => {
  const { type } = props

  if (type === "tag") {
    const { labels, issueId, onAddLabel } = props
    const count = labels.length
    const maxCount = 3
    const firstLabels = labels.slice(0, maxCount)
    const lastIndexInFirstLabels = firstLabels.length - 1
    const Label = (
      <span className={styles.tag}>
        {firstLabels.map(({ id, color }, index) => {
          const Icon = index === lastIndexInFirstLabels ? TagFilled : TagMore

          return <Icon key={id} style={{ color: getColorVariable(color) }} />
        })}
        {count > maxCount && (
          <span className={styles.countHiddenTag}>+{count - maxCount}</span>
        )}
      </span>
    )

    return (
      <PopoverLabelTag
        placement="top"
        labels={labels}
        Label={Label}
        issueId={issueId}
        onAddLabel={onAddLabel}
      />
    )
  }

  if (type === "line") {
    const { labels, issueId } = props
    const value = labels.map(({ id }) => id)

    return (
      <Select
        disabled
        open={false}
        suffixIcon={null}
        className={styles.line}
        value={value}
        options={labels}
        mode="tags"
        maxTagCount="responsive"
        tagRender={(tagProps) => {
          if (tagProps.isMaxTag && typeof tagProps.label === "string") {
            const label = tagProps.label.replace("...", "")
            const number = Number(label.replace("+", "").trim())
            const content = labels.slice(0 - number)

            return (
              <PopoverLabelTag
                labels={content}
                Label={<span>{label}</span>}
                issueId={issueId}
              />
            )
          }

          const label = getLabelById(tagProps.value, labels)

          if (!label) return <span />

          return <ClosableTag {...label} issueId={issueId} />
        }}
      />
    )
  }

  const { name, color } = props

  return (
    <span className={styles.box}>
      <TagFilled style={{ color: getColorVariable(color) }} />
      <span>{name}</span>
    </span>
  )
}
