import cn from "classnames"

import { Text, Tooltip } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { CheckCircleFilled } from "@/icons"

import styles from "./DeployTypeButton.module.scss"

import type { DeployTypeButtonProps } from "."

export const DeployTypeButton = ({
  name,
  Icon,
  disabled
}: DeployTypeButtonProps) => {
  const { t } = useTranslation()

  return (
    <Tooltip title={disabled ? t("general.comingSoon") : ""}>
      <div className={cn(styles.container, disabled && styles.disabled)}>
        <CheckCircleFilled className={styles.check} />
        <Icon className={styles.icon} />
        <Text className={styles.text}>{name}</Text>
      </div>
    </Tooltip>
  )
}
