import { Button } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"

import styles from "./ButtonsFilter.module.scss"

import type { ButtonsFilterProps } from "."

export const ButtonsFilter = ({
  clearDisabled,
  okDisabled,
  onOk,
  onClear
}: ButtonsFilterProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "filter" })

  return (
    <div className={styles.container}>
      <Button
        disabled={clearDisabled}
        size="small"
        type="text"
        onClick={onClear}
      >
        {t("clear")}
      </Button>
      <Button disabled={okDisabled} size="small" type="primary" onClick={onOk}>
        {t("apply")}
      </Button>
    </div>
  )
}
