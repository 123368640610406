import { ICONS_INSIGHTS } from "@/const/insights-constants"
import { InsightsCell } from "@/molecules/InsightsCell"

import type { TFunction } from "@/hooks/useTranslation"
import type { InsightsValues } from "."

export const InsightsTriageCell =
  (t: TFunction, internetFacingFF?: boolean) =>
  ({ tag, id, ...values }: InsightsValues) => {
    const items = [
      ICONS_INSIGHTS.runtime,
      ICONS_INSIGHTS.exploitability.IN_THE_WILD,
      ICONS_INSIGHTS.ingress,
      ICONS_INSIGHTS.exploitability.POC
    ]

    if (!!internetFacingFF) {
      items.splice(1, 0, ICONS_INSIGHTS.internetFacing)
    }

    return (
      <InsightsCell
        hide
        id={id}
        items={items}
        values={values}
        t={t}
        tag={tag}
      />
    )
  }
