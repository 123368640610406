import { Text } from "@/atoms"
import cn from "classnames"

import styles from "./DescriptionItem.module.scss"

import type { DescriptionItemProps } from "."

export const DescriptionItem = ({
  children,
  className,
  description,
  disabled,
  handler,
  tabIndex = 1
}: DescriptionItemProps) => {
  return (
    <li
      className={cn(styles.container, className)}
      tabIndex={disabled ? 0 : tabIndex}
      onClick={handler}
      onKeyDown={handler}
    >
      <Text>{children}</Text>
      {description && (
        <Text type="secondary" className={styles.description}>
          {description}
        </Text>
      )}
    </li>
  )
}
