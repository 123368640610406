import { DatePicker, Form, Input, InputNumber, Select, TextArea } from "@/atoms"
import {
  isDateField,
  isMultiplePredefinedOptionsField,
  isNumberField,
  isSingleFetchableOptionsField,
  isSinglePredefinedOptionsField,
  isTextAreaField,
  isTextField
} from "@/helpers/jira.helpers"
import { useTranslation } from "@/hooks/useTranslation"
import { AutoCompleteField } from "@/molecules/JiraIssueField/AutoCompleteField"
import { toSelectionOption } from "@/molecules/JiraIssueField/helpers"
import React from "react"
import styles from "./JiraIssueField.module.scss"

const JiraDynamicField = ({
  field,
  className,
  label,
  projectId
}: {
  field: Jira.IssueTypeField
  className?: string
  label?: React.ReactNode
  projectId: string
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "alerts.jiraIssueModal"
  })

  // The `renderField` can be overwritten on each `if`
  // which means that the order of the `if`s matters
  // It's by purpose because of some rare cases
  let renderField = null
  if (isSinglePredefinedOptionsField(field)) {
    renderField = (
      <Select options={field.allowedValues.map(toSelectionOption)} />
    )
  }
  if (isSingleFetchableOptionsField(field)) {
    renderField = <AutoCompleteField field={field} projectId={projectId} />
  }

  if (isMultiplePredefinedOptionsField(field)) {
    renderField = (
      <Select
        mode={"multiple"}
        options={field.allowedValues.map(toSelectionOption)}
      />
    )
  }

  if (isTextAreaField(field)) {
    renderField = <TextArea />
  }

  if (isTextField(field)) {
    renderField = <Input />
  }

  if (isNumberField(field)) {
    renderField = <InputNumber className={styles.inputNumber} />
  }

  if (isDateField(field)) {
    renderField = <DatePicker />
  }

  if (!renderField) return null

  return (
    <Form.Item
      key={field.key}
      className={className}
      label={label}
      rules={[
        {
          required: field.required,
          message: t("error.requiredField") || ""
        }
      ]}
      name={field.key}
    >
      {renderField}
    </Form.Item>
  )
}

export default JiraDynamicField
