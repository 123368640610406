import { CellTable, SortOrder } from "@/atoms"
import { ISSUES_LIMIT } from "@/const/table-constants"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { NumberConvertor } from "@/hooks/useNumberConvertor"
import { ORDER_BY_PROPS } from "@/hooks/useOrderBy"
import { TFunction } from "@/hooks/useTranslation"
import { IssuesSummaryCell } from "@/molecules/IssuesSummaryCell"
import { ContributorsCell } from "../../../../../molecules/ContributorsCell"
import { CommitHashCellWithStatus } from "../../molecules/CommitHashCell"
import { MoreTableCellDrawer } from "../../molecules/MoreTableCellDrawer"
import { RepositoryNameCell } from "../../molecules/RepositoryNameCell"

import type { RowClick } from "@/hooks/useLinkDrawerTooltip"

export const getPreviewColumnsTable = (
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">
) => [
  {
    title: "Repository Name",
    className: "displayName-branch-tab-template-component",
    showTitle: true,
    width: "30%",
    render: RepositoryNameCell,
    ...getDefaultColumnParams("repositoryName", sorter),
    sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
  },
  {
    title: "Branch",
    width: 70,
    render: CellTable,
    ...getDefaultColumnParams("branchName")
  }
]

export const getColumnsTable = ({
  sorter,
  t,
  convertors,
  previewMode,
  onRowClick
}: {
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">
  t: TFunction
  convertors: NumberConvertor
  onRowClick: RowClick
  previewMode?: boolean
}) => {
  if (previewMode) {
    return getPreviewColumnsTable(sorter)
  }

  return [
    ...getPreviewColumnsTable(sorter),
    {
      title: "Commit",
      width: 90,
      render: CommitHashCellWithStatus,
      ...getDefaultColumnParams("commitHashShort")
    },
    {
      title: "Issues Summary",
      width: 220,
      render: IssuesSummaryCell({ t, convertors }, ISSUES_LIMIT),
      ...getDefaultColumnParams("severity", sorter),
      defaultSortOrder: "descend" as SortOrder,
      sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
    },
    {
      title: "Packages at Risk",
      width: 125,
      className: "center-align-tab-template-component",
      ...getDefaultColumnParams("packagesAtRisk")
    },

    {
      title: "Contributors",
      width: 100,
      className: "contributors-branch-tab-template-component",
      render: ContributorsCell,
      ...getDefaultColumnParams("contributors")
    },
    {
      title: "Related Images",
      width: "30%",
      render: MoreTableCellDrawer(onRowClick),
      ...getDefaultColumnParams("relatedImages")
    },
    {
      title: "Last Seen",
      width: 155,
      ...getDefaultColumnParams("lastSeen")
    }
  ]
}
