import type { Services } from "@/interfaces/services"

export const copyHtmlToClipboard = (
  htmlContent: string,
  logger?: Services.ILoggerAdapter
) => {
  try {
    const tempElement = document.createElement("div")

    tempElement.innerHTML = htmlContent
    document.body.appendChild(tempElement)

    const range = document.createRange()

    range.selectNode(tempElement)

    const selection = window.getSelection()

    if (!selection) return

    selection.removeAllRanges()
    selection.addRange(range)
    document.execCommand("copy")
    selection.removeAllRanges()
    document.body.removeChild(tempElement)
  } catch (err) {
    logger?.error(err as Error)
  }
}
