import { Badge, Card, Paragraph, Space, Text } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import cn from "classnames"

import styles from "./ImportCommandCard.module.scss"

import type { HistoryImagePackageData } from "@/domain/package"
import { splitCommand } from "@/domain/package"

export const CodeSnippet = ({
  comment,
  instruction,
  commandArguments,
  copyText
}: {
  instruction: string
  comment: string | null
  commandArguments: string
  copyText: string
}) => {
  const { EVENTS, logger } = useLogger()

  return (
    <>
      <div className={styles.commentContainer}>
        {!!comment && <p className={styles.comment}>{comment}</p>}
        <Paragraph
          className={styles.paragraphCopyable}
          copyable={{
            text: copyText,
            onCopy: () => {
              logger.info(EVENTS.ANALYTIC_EVENTS.SBOM.COPYABLE.COMMAND, {
                value: copyText
              })
            }
          }}
        ></Paragraph>
      </div>
      <Paragraph className={styles.historyCommand} ellipsis={{ rows: 3 }}>
        <Text className={styles.instruction}>{instruction}</Text>
        {commandArguments}
      </Paragraph>
    </>
  )
}

export const ImportCommandCard = ({
  createdBy,
  isImportedFromBaseImage,
  commandArgument,
  instruction,
  layerIndex,
  dockerfileMatch = false,
  dockerfilePath,
  dockerCommand
}: HistoryImagePackageData) => {
  if (!createdBy) {
    return null
  }

  const Component = isImportedFromBaseImage ? Badge.Ribbon : Space
  const { instruction: dockerInstruction, commandArgument: dockerArguments } =
    splitCommand(dockerCommand ?? undefined)
  const [dockerfile, dockerfileLine] = (dockerfilePath ?? "").split("@")

  return (
    <Component text="Base image" placement="start" className={styles.container}>
      <Card
        className={cn(
          styles.card,
          isImportedFromBaseImage && styles.isImportedFromBaseImage
        )}
      >
        {dockerfileMatch && (
          <div>
            <CodeSnippet
              instruction={dockerInstruction as string}
              commandArguments={dockerArguments as string}
              copyText={dockerCommand as string}
              comment={`# ${dockerfile} command line: ${dockerfileLine}`}
            />
          </div>
        )}
        <div>
          <CodeSnippet
            instruction={instruction}
            commandArguments={commandArgument}
            copyText={createdBy}
            comment={`# History layer number: ${layerIndex}`}
          />
        </div>
      </Card>
    </Component>
  )
}
