import { useGetInventoryImage } from "@/api/useInventory"
import { useQueryString } from "@/hooks/useQueryString"
import { getImageTabData } from "./useImageInventory.selector"

type Config = { enabled: boolean }

export const useImageInventory = (
  paramKeys?: { key: string }[],
  config?: Config
) => {
  const params = useQueryString(paramKeys)
  const paramsOrder = useQueryString(["orderBy", "ascending"])
  const context = useGetInventoryImage(
    { ...params, ...paramsOrder },
    {
      select: getImageTabData,
      ...(config || {})
    }
  )

  return context
}
