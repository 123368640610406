import { CellTable } from "@/atoms"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { FixabilityCell } from "@/molecules/FixabilityCell"
import { ImpactCell } from "@/molecules/ImpactCell"
import { IssueTitleCell } from "@/molecules/IssueTitleCell"
import { KodemScoreIssueCell } from "@/molecules/KodemScoreCell"
import { TriageIssueActionCell } from "@/molecules/TriageActionCell"
import { InsightsTriageCell } from "../../molecules/InsightsTriageCell"
import { IntroduceThroughInsightsICell as IntroduceThroughInsightsCell } from "../../molecules/IntroduceThroughInsightsICell"

import type { RowClick } from "@/hooks/useLinkDrawerTooltip"
import type { TFunction } from "@/hooks/useTranslation"

interface Props {
  t: TFunction
  internetFacingFF: boolean
  onRowClick: RowClick
  setRecord: (record: unknown) => void
}

export const getColumnsExpandedRow = ({
  t,
  internetFacingFF,
  onRowClick,
  setRecord
}: Props) => [
  {
    title: null,
    render: IssueTitleCell(t, setRecord),
    ...getDefaultColumnParams("cve")
  },
  {
    width: 80,
    className: "center-align-tab-template-component",
    render: KodemScoreIssueCell(t),
    ...getDefaultColumnParams("kodemScore")
  },
  {
    title: null,
    render: InsightsTriageCell(t, internetFacingFF),
    width: 149,
    ...getDefaultColumnParams("riskInsights")
  },
  {
    title: null,
    render: FixabilityCell(t),
    width: 145,
    ...getDefaultColumnParams("fixability")
  },
  {
    title: null,
    render: IntroduceThroughInsightsCell({ t }),
    width: 100,
    ...getDefaultColumnParams("introducedThroughInsights")
  },
  {
    title: null,
    render: ImpactCell({ onRowClick }),
    width: 70,
    className: "center-align-tab-template-component",
    ...getDefaultColumnParams("applicationsCount")
  },
  {
    title: null,
    render: TriageIssueActionCell(t),
    width: 84,
    className: "center-align-tab-template-component",
    ...getDefaultColumnParams("tickets")
  },
  {
    title: null,
    width: 125,
    render: CellTable,
    ...getDefaultColumnParams("discoveredDaysAgo")
  }
]
