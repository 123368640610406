import {
  getSeverityFilter,
  InventoryFilters
} from "@/pages/DiscoveryPage/utils/filters.utils"

import type { TFunction } from "@/hooks/useTranslation"

export const getFilters = (t: TFunction) => {
  return [
    getSeverityFilter(),
    InventoryFilters.environment(t),
    {
      key: "os",
      label: t("filters.os"),
      type: "input",
      helper: [
        {
          label: t("inventory.generalFilters.multipleValues", {
            label: "os"
          }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "operating systems"
          })
        }
      ]
    },
    {
      key: "instanceType",
      label: "instance-type",
      type: "input",
      helper: [
        {
          label: t("inventory.generalFilters.multipleValues", {
            label: "instance-type"
          }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "instance types"
          })
        }
      ]
    },
    {
      key: "autoScalingGroups",
      label: "auto-scaling-groups",
      type: "input",
      helper: [
        {
          label: t("inventory.generalFilters.multipleValues", {
            label: "auto-scaling-groups"
          }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "auto scaling groups"
          })
        }
      ]
    },
    {
      key: "imageId",
      label: "image-id",
      type: "input",
      helper: [
        {
          label: t("inventory.generalFilters.multipleValues", {
            label: "image-id"
          }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "image ids"
          })
        }
      ]
    },
    {
      key: "instanceId",
      label: "instance-id",
      type: "input",
      helper: [
        {
          label: t("inventory.generalFilters.multipleValues", {
            label: "instance-id"
          }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "instance ids"
          })
        }
      ]
    }
  ]
}
