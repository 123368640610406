import { buildQueryStringFromRecord } from "@/helpers/filters.helper"
import { useTranslation } from "@/hooks/useTranslation"
import { InformationAlert } from "@/molecules/InformationAlert/InformationAlert"
import { useBranchEntity } from "@/organisms/Drawers/applications/inventory/useInventory"
import { IssuesTab } from "@/organisms/Drawers/components/IssuesTab"
import {
  getDefaultFiltersInventoryIssuesDrawer,
  getDefaultParamsOpenSourceIssue
} from "@/organisms/Drawers/hooks/useParamsFilter"

import type { IssuesTabImageDrawerComponent } from "."

export const IssuesTabCodeRepoDrawer: IssuesTabImageDrawerComponent = (
  props
) => {
  const { t } = useTranslation()
  const [, { isPackageManagerEstimated }] = useBranchEntity(props.record?.id)

  const headerComponent = isPackageManagerEstimated && (
    <InformationAlert
      message={t(
        "inventory.codeRepositoriesTab.drawer.estimatedPackageManagerMessage"
      )}
    />
  )

  return (
    <IssuesTab
      includeApplicationFilter
      getInitialParamsFilter={getDefaultFiltersInventoryIssuesDrawer}
      headerComponent={headerComponent}
      defaultParams={getDefaultParamsOpenSourceIssue()}
      exportQueryParams={(params: Record<string, any>) =>
        buildQueryStringFromRecord({ resourceId: props.record?.id, ...params })
      }
      {...props}
    />
  )
}
