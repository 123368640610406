import { lazy, Suspense } from "react"

import { Card, Skeleton } from "@/atoms"

import type { AttackChainsComponent } from "."

const AttackSimulationCard = lazy(
  () => import("./components/AttackSimulationCard")
)

const AttackChains: AttackChainsComponent = (props) => (
  <Suspense
    fallback={
      <Card>
        <Skeleton active />
      </Card>
    }
  >
    <AttackSimulationCard {...props} />
  </Suspense>
)

export default AttackChains
