import { Button } from "@/atoms"
import { CloseOutlined } from "@/icons"

import styles from "./RemoveButtonListForm.module.scss"

import type { ButtonProps } from "@/atoms"

export const RemoveButtonListForm = (
  props: Pick<ButtonProps, "disabled" | "onClick">
) => (
  <Button
    block
    type="text"
    className={styles.button}
    icon={<CloseOutlined />}
    {...props}
  />
)
