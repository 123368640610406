import React, { useState } from "react"
import { useDebounce } from "use-debounce"

import { useGetJiraFieldOptions } from "@/api/useJira"
import { AutoComplete, Select, Spin } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { removeBTags } from "@/molecules/JiraIssueField/helpers"
import { JiraIssueFieldProps } from "@/molecules/JiraIssueField/JiraIssueField.types"

import styles from "./JiraIssueField.module.scss"

export const AutoCompleteField = ({
  field,
  onChange,
  projectId
}: React.ComponentProps<typeof AutoComplete> & JiraIssueFieldProps) => {
  const emptyValue = { label: "", id: "" }
  const [value, setValue] = useState<{ label: string; id: any }>(emptyValue)
  const [searchValue, setSearchValue] = useState("")
  const [debouncedSearchValue] = useDebounce(searchValue, 1000)
  const { t } = useTranslation("translation", {
    keyPrefix: "alerts.jiraIssueModal"
  })
  const onClear = () => {
    setValue(emptyValue)
    setSearchValue("")
    onChange?.(undefined, {})
  }
  const { response, isLoading } = useGetJiraFieldOptions(
    {
      q: debouncedSearchValue,
      fieldKey: field.key,
      fieldName: field.name,
      projectId: projectId
    },
    { gcTime: 0, staleTime: 0 }
  )

  const options = response?.data || []

  return (
    <Select
      showSearch
      value={value.label || searchValue}
      onSearch={setSearchValue}
      allowClear
      onClear={onClear}
      notFoundContent={
        isLoading ? (
          <Spin size="small" className={styles.optionsLoader} />
        ) : (
          t("noResults")
        )
      }
      filterOption={false}
    >
      {options.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          <div
            onClick={() => {
              const normalizedOption = {
                label: removeBTags(option.label),
                id: option.value
              }
              setValue(normalizedOption)
              onChange?.(normalizedOption.id, normalizedOption)
            }}
            dangerouslySetInnerHTML={{ __html: option.label }}
          />
        </Select.Option>
      ))}
    </Select>
  )
}
