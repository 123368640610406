import type { DownloadParams, JsonData, UrlData } from "."

export const downloadFileFromUrl = (
  values: UrlData,
  params?: DownloadParams
) => {
  try {
    const { url, filename } = values
    const oldOnBeforeUnloadValue = window.onbeforeunload
    const link = document.createElement("a")

    link.setAttribute("download", filename)
    link.setAttribute("href", url)
    window.onbeforeunload = null
    link.click()
    window.onbeforeunload = oldOnBeforeUnloadValue
  } catch (error) {
    params?.logger?.error("Failed to download file from url", {
      url: values?.url,
      filename: values?.filename,
      error,
      reason: error instanceof Error ? error.message : "unknown"
    })
  }
}

export const downloadFileFromJSON = (
  values: JsonData,
  params?: DownloadParams
) => {
  try {
    const { data, filename } = values
    const fileToSave = encodeURI(
      "data:text/json;charset=utf-8," + JSON.stringify(data, null, 2)
    )

    downloadFileFromUrl({ filename, url: fileToSave })
  } catch (error) {
    params?.logger?.error("Failed to download JSON", {
      error,
      data: values?.data,
      url: values?.filename,
      reason: error instanceof Error ? error.message : "unknown"
    })
  }
}
