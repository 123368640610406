import { Suspense, lazy } from "react"

import { Flex, Spin } from "@/atoms"

import type { ComingSoonPageProps } from "./ComingSoonPage.types"

const DefaultPage = lazy(() => import("./components/pages/DefaultPage"))

export const ComingSoonPage = (props: ComingSoonPageProps) => (
  <Suspense
    fallback={
      <Flex flex={1} align="center" justify="center">
        <Spin />
      </Flex>
    }
  >
    <DefaultPage {...props} />
  </Suspense>
)
