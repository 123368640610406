import { CellTableWithCopyableTooltip } from "@/molecules/CellTableWithCopyableTooltip"

import type { ImageNameCellComponent } from "."

export const ImageNameCell: ImageNameCellComponent = (value, record) => (
  <CellTableWithCopyableTooltip
    value={value}
    copyableText={record.fullImageName}
  />
)
