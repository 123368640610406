import { AuthAdapter, InitialParamsAuthAdapter } from "@/services/auth-adapter"
import * as download from "@/services/download"
import { HttpAdapter, InitialParamsHttpAdapter } from "@/services/http-adapter"
import {
  InitialParamsIntercomAdapter,
  IntercomAdapter
} from "@/services/intercom-adapter"
import Logger from "@/services/logger-adapter"
import { createContext, useContext } from "react"

type DefaultInstanceConstructor = {
  (Service: typeof HttpAdapter, params: InitialParamsHttpAdapter): HttpAdapter
  (
    Service: typeof AuthAdapter,
    params: InitialParamsAuthAdapter
  ): InstanceType<typeof AuthAdapter>
  (
    Service: typeof IntercomAdapter,
    params: InitialParamsIntercomAdapter
  ): IntercomAdapter
  (
    Service: any,
    params:
      | InitialParamsHttpAdapter
      | InitialParamsAuthAdapter
      | InitialParamsIntercomAdapter
  ): HttpAdapter | InstanceType<typeof AuthAdapter> | IntercomAdapter
}

export const createDefaultAdapter: DefaultInstanceConstructor = (
  Service,
  params
) => {
  if (typeof Service !== "function") {
    throw new Error("Service must be a function")
  }

  if (!params) {
    throw new Error("Params must be defined")
  }

  if (!Service.defaultInstance) {
    Service.defaultInstance = new Service(params)
  }

  return Service.defaultInstance
}

export const ServicesContext = createContext<{
  auth: InstanceType<typeof AuthAdapter>
  download: typeof download
  http: HttpAdapter
  localStorage: Storage
  logger: Logger
  intercom: IntercomAdapter
}>(
  {} as {
    auth: InstanceType<typeof AuthAdapter>
    download: typeof download
    http: HttpAdapter
    localStorage: Storage
    logger: Logger
    intercom: IntercomAdapter
  }
)

export const useServices = () => {
  const services = useContext(ServicesContext)

  return services
}

export default useServices
