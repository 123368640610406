import cn from "classnames"

import { Text, Tooltip } from "@/atoms"
import { getLinkByVulnerability } from "@/domain/vulnerability"
import { preventEventBubbling } from "@/helpers/utils"

import styles from "./LinkRisk.module.scss"

import type { LinkRiskProps } from "."

export const LinkRisk: React.FC<LinkRiskProps> = ({
  cve,
  classes,
  ...props
}) => (
  <Tooltip
    title={
      <span
        className={styles.container}
        onClick={preventEventBubbling}
        {...props}
      >
        <a
          href={getLinkByVulnerability({ cve })}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          {cve}
        </a>
      </span>
    }
  >
    <Text className={cn(styles.container, classes?.text)} {...props}>
      {cve}
    </Text>
  </Tooltip>
)
