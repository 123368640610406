import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import {
  getActiveTabFieldName,
  getIdsDrawer,
  getOpenDrawersFromQueryString
} from "@/organisms/Drawers/hooks/useDrawer"
import { ApplicationDrawer } from "./components/ApplicationDrawer"
import { CodeRepositoryDrawer } from "./components/CodeRepositoryDrawer"
import { ImageDrawer } from "./components/ImageDrawer"
import { TriageDrawer } from "./components/TriageDrawer"
import { VirtualMachineDrawer } from "./components/VirtualMachineDrawer"
import * as IDS from "./const"

import styles from "./Drawers.module.scss"
import { PackageDrawer } from "./components/PackageDrawer"

export const getDrawerType = (type: string) => type[0]

export const isCodeRepositoryDrawer = (type: string) =>
  type === IDS.CODE_REPOSITORY_DRAWER_ID

export const isImageDrawer = (type: string) => type === IDS.IMAGE_DRAWER_ID

export const isApplicationDrawer = (type: string) =>
  type === IDS.APPLICATION_DRAWER_ID

export const isTriageDrawer = (type: string) => type === IDS.TRIAGE_DRAWER_ID

export const isPackageDrawer = (type: string) => type === IDS.PACKAGE_DRAWER_ID

export const isVirtualMachineDrawer = (type: string) =>
  type === IDS.VIRTUAL_MACHINE_DRAWER_ID

export const Drawers = () => {
  const [closing, setClosing] = useState<Record<string, boolean>>({})
  const [searchParams, setSearchParams] = useSearchParams()
  const drawers = getIdsDrawer(searchParams)
  const count = drawers.length
  const requestClose = (drawerId: string) => {
    setClosing({ ...closing, [drawerId]: true })
  }
  const drawerIdsFromQueryString = getOpenDrawersFromQueryString(searchParams)

  useEffect(() => {
    const index = Object.keys(closing).findIndex((id) => !!closing[id])

    if (index === -1) return

    setClosing((prevState) =>
      Object.keys(prevState).reduce(
        (acc, id) => {
          if (!searchParams.get(id)) delete acc[id]

          return acc
        },
        { ...prevState } as Record<string, boolean>
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerIdsFromQueryString])

  useEffect(() => {
    const result = drawers.filter((id) => {
      if (!!searchParams.get(id)) return true

      searchParams.delete(getActiveTabFieldName(id))
      return false
    })

    if (result.length === drawers.length) return

    if (!result.length) searchParams.delete(IDS.DRAWERS_PARAM_NAME)
    else searchParams.set(IDS.DRAWERS_PARAM_NAME, result.toString())

    setSearchParams(searchParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawers])

  let currentCount = count

  return (
    <>
      {drawers.map((id, index) => {
        const type = getDrawerType(id)
        const widthAdditive = (count - index) * 50
        const props = {
          id: `${id}`,
          open: !closing[id],
          requestCloseDrawer: requestClose,
          widthAdditive
        }

        if (isCodeRepositoryDrawer(type)) {
          return <CodeRepositoryDrawer key={id} {...props} />
        }

        if (isImageDrawer(type)) {
          return <ImageDrawer key={id} {...props} />
        }

        if (isApplicationDrawer(type)) {
          return <ApplicationDrawer key={id} {...props} />
        }

        if (isTriageDrawer(type)) {
          return <TriageDrawer key={id} {...props} />
        }

        if (isPackageDrawer(type)) {
          return <PackageDrawer key={id} {...props} />
        }

        if (isVirtualMachineDrawer(type)) {
          return <VirtualMachineDrawer key={id} {...props} />
        }

        currentCount -= 1

        return null
      })}
      {currentCount > 0 && <div className={styles.mask} />}
    </>
  )
}
