import { usePatchLabel } from "@/api/useLabels"
import { message } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"

import type { PayloadPatch } from "@/api/useLabels.types"
import type { Query, QueryClient } from "@/hooks/useHttp.types"

export const isIssueRequest = (query: Query) => {
  const { queryKey } = query
  const key = queryKey?.[0]

  if (typeof key !== "string") return false
  if (!key.includes("issues")) return false

  const params = queryKey?.[1] as Record<string, string | number>

  if (`${params?.pageSize}` === "1") return false

  return !key.includes("count") && !key.includes("findings")
}

export const getIssueRequestFilter = () => ({ predicate: isIssueRequest })

const getPatchOptions = (queryClient: QueryClient) => ({
  onSettled: () => queryClient.invalidateQueries(getIssueRequestFilter())
})

export const usePatchLabelOnEntities = () => {
  const context = usePatchLabel<PayloadPatch>(getPatchOptions)
  const { logger, EVENTS } = useLogger()
  const { t } = useTranslation()
  const patch = async (
    payload: PayloadPatch,
    { component, placement }: { component: string; placement: string }
  ) => {
    try {
      await context.mutateAsync(payload)

      logger.info(EVENTS.ANALYTIC_EVENTS.LABELS.PATCH, {
        component,
        placement,
        ...payload
      })
    } catch (err: any) {
      if (typeof err?.data?.detail === "string") message.error(err.data.detail)
      else message.error(t("error.serverError"))
    }
  }

  return [{ patch }, context] as const
}
