import { SeverityTag } from "@/molecules/SeverityTag"

import type { Severity } from "@/domain/vulnerability"
import type { TFunction } from "@/hooks/useTranslation"

export const SeverityCell =
  (t: TFunction) =>
  (severity: Severity, { cvss }: { cvss: number }) => (
    <SeverityTag t={t} severity={severity} cvss={cvss} />
  )
