import { Button, Form } from "@/atoms"
import { GithubOutlined, GoogleOutlined } from "@/icons"

import styles from "./ThirdPartyProvidersSignInForm.module.scss"

import type { ThirdPartyProvidersSignInFormProps } from "."

export const ThirdPartyProvidersSignInForm = ({
  signInWithGoogle,
  signInWithGithub
}: ThirdPartyProvidersSignInFormProps) => (
  <Form.Item className={styles.formItemThirdPartyProvidersSignInForm}>
    <Button
      block
      size="large"
      icon={<GoogleOutlined />}
      onClick={signInWithGoogle}
      data-testid="buttonGoogleSignIn"
    >
      Google
    </Button>
    <Button
      block
      size="large"
      icon={<GithubOutlined />}
      onClick={signInWithGithub}
      data-testid="buttonGithubSignIn"
    >
      Github
    </Button>
  </Form.Item>
)
