import { useGetJiraCreateIssueMetadata } from "@/api/useJira"
import { Skeleton, Text } from "@/atoms"
import { JiraFieldKey } from "@/const/jira.constants"
import { toDictionary } from "@/helpers/utils"
import JiraDynamicField from "@/molecules/JiraIssueField"
import { JiraIssueModalFieldsProps } from "@/organisms/JiraIssueModal/JiraIssueModal.types"

import "react-quill/dist/quill.snow.css"
import styles from "./JiraIssueModal.module.scss"

export const JiraIssueModalFields = ({
  projectId,
  issueTypeId
}: JiraIssueModalFieldsProps) => {
  const { response, isLoading } = useGetJiraCreateIssueMetadata({
    projectId: projectId
  })
  const { data } = response || {}
  const { projects = [] } = data || {}

  const selectedProject = projects.find(({ id }) => id === projectId)
  const issueTypesOptions = selectedProject?.issuetypes || []

  const fields: Jira.IssueTypeField[] =
    issueTypesOptions?.find(({ id }) => id === issueTypeId)?.fields || []

  const fieldsMetadata = toDictionary(fields, "key")

  const excludedKeys = {
    [JiraFieldKey.Summary]: true,
    [JiraFieldKey.Project]: true,
    [JiraFieldKey.IssueType]: true,
    [JiraFieldKey.Description]: true,
    [JiraFieldKey.Reporter]: true
  }

  const uiFields = fields.filter(
    (f) => !(f.key in excludedKeys && f.key in fieldsMetadata)
  )

  return (
    <>
      {isLoading && (
        <>
          <Skeleton.Input active block />
          <Skeleton.Input active block />
          <Skeleton.Input active block />
          <Skeleton.Input active block />
        </>
      )}
      {!isLoading &&
        uiFields.map((field: Jira.IssueTypeField) => (
          <JiraDynamicField
            key={field.key}
            className={styles.itemForm}
            label={<Text className={styles.label}>{field.name}</Text>}
            field={field}
            projectId={projectId}
          />
        ))}
    </>
  )
}
