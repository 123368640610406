import cn from "classnames"
import styles from "./FiltersTab.module.scss"

export const FiltersTab = ({
  showPlaceholder,
  scrollY,
  tabPosition,
  SearchComponent
}: {
  showPlaceholder?: Features.Placeholder
  scrollY?: boolean
  tabPosition?: "left"
  SearchComponent?: React.ReactNode
}) => (
  <div
    className={cn(
      styles.container,
      scrollY && styles.scrollY,
      showPlaceholder && styles.showPlaceholder,
      tabPosition === "left" && styles.tabPositionLeft
    )}
    data-component-name="filters-tab-component"
  >
    {SearchComponent}
  </div>
)
