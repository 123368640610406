import { Button, Form, Input, Select } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { getJobOptions } from "./helpers"

export const SelfForm = ({ onSubmit }: any) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const rules = [{ required: true }]

  return (
    <Form
      form={form}
      name="yorSelf"
      validateMessages={{ required: t("error.requiredField") }}
      onFinish={onSubmit}
    >
      <Form.Item required name="firstName" rules={rules}>
        <Input placeholder={t("general.firstName")} />
      </Form.Item>
      <Form.Item name="lastName" rules={rules}>
        <Input placeholder={t("general.lastName")} />
      </Form.Item>
      <Form.Item name="job" rules={rules}>
        <Select
          placeholder={t("general.jobDescription")}
          options={getJobOptions()}
        />
      </Form.Item>
      <Button block size="large" type="ks" htmlType="submit">
        {t("general.continue")}
      </Button>
    </Form>
  )
}
