import { useEffect, useRef } from "react"

import {
  LIST_MENU_DROPDOWN_SMART_SEARCH_ID,
  VIRTUAL_LIST_CLASS
} from "@/organisms/SmartSearch/const/classes.constants"

import type { KeyboardEvent } from "react"

export const useMenuDropdownSmartSearch = (props: any) => {
  const ref = useRef<HTMLUListElement>(null)
  const valueClickedLastTime = useRef<null | string>(null)

  const { services } = props
  const { StringService } = services

  const displayValues = StringService.isCurrentCaretPosInValue()

  if (!displayValues) {
    valueClickedLastTime.current = null
  }

  const createHandler =
    (getValue: () => unknown) => (event: MouseEvent | KeyboardEvent) => {
      const { onClick } = props

      if (!onClick) return

      const { type } = event as MouseEvent
      const { code } = event as KeyboardEvent

      if (type !== "click" && code !== "Space" && code !== "Enter") return

      event.stopPropagation()
      event.preventDefault()

      onClick({ key: getValue() })
    }

  const onFocus = () => {
    if (valueClickedLastTime.current) {
      ;(ref.current?.querySelector('li[tabindex="2"]') as HTMLElement)?.focus()
      valueClickedLastTime.current = null

      return
    }

    if (document.activeElement === ref.current) {
      ;(ref.current?.querySelector('li[tabindex="1"]') as HTMLElement)?.focus()

      return
    }
  }

  const onKeyDown = (event: KeyboardEvent<HTMLUListElement>) => {
    event.preventDefault()
    event.stopPropagation()

    let el = document.activeElement as null | HTMLElement
    let iterationLimit = 0

    do {
      if (event.key === "ArrowDown") {
        el = el?.nextSibling as HTMLElement

        if (el?.classList?.contains?.(VIRTUAL_LIST_CLASS)) {
          el = el.querySelector("li") as HTMLElement
        }
      } else if (event.key === "ArrowUp") {
        el = el?.previousSibling as HTMLElement

        if (!el) {
          const { id } = props

          const list = document
            .getElementById(id)
            ?.querySelector(`#${LIST_MENU_DROPDOWN_SMART_SEARCH_ID}`)
            ?.querySelector(`.${VIRTUAL_LIST_CLASS}`)
          const elementInVirtualList = list?.contains?.(document.activeElement)

          if (elementInVirtualList) el = list?.previousSibling as HTMLElement
        }

        if (!el) {
          ref.current?.scrollTo(0, 0)

          if (event.key === "ArrowUp") {
            const { id } = props

            document
              .getElementById(id)
              ?.querySelector<HTMLElement>(
                '[data-id-smart-search="input-smart-search"]'
              )
              ?.focus()
          }
        }
      }

      iterationLimit += 1
    } while (iterationLimit < 10 && el && el.getAttribute("tabIndex") !== "1")

    el?.focus()
  }

  useEffect(() => {
    if (ref.current && displayValues) {
      ref.current.scrollTo(0, 0)
    }
  }, [displayValues])

  return {
    ref,
    displayValues,
    valueClickedLastTime,
    createHandler,
    onFocus,
    onKeyDown
  }
}
