import { HeaderPage } from "@/organisms/HeaderPage"
import { SbomTab } from "@/pages/DiscoveryPage/components/organisms/SbomTab"
import { useActiveKeyTabsInventory } from "@/pages/DiscoveryPage/hooks/useActiveKeyTabsInventory"
import { PageTemplate } from "@/templates/PageTemplate"

export const SbomPage = () => (
  <PageTemplate
    full
    header={<HeaderPage titleTranslationKey="navigation.sbom" />}
  >
    <SbomTab useActiveKeyTabsInventory={useActiveKeyTabsInventory} />
  </PageTemplate>
)
