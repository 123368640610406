import cn from "classnames"
import { useState, useTransition } from "react"

import { Button, Link, Modal, Text } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import { useFeatureFlags } from "@/hooks/useUser"
import { ApartmentOutlined, ApiOutlined, CloudUploadOutlined } from "@/icons"
import { ExpandIconTable } from "@/molecules/ExpandIconTable"
import { Heading } from "@/molecules/Heading"
import { Item, List } from "@/molecules/List"
import {
  CODE_REPOSITORY_DRAWER_ID,
  IMAGE_DRAWER_ID,
  VIRTUAL_MACHINE_DRAWER_ID
} from "@/organisms/Drawers/const"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { TabTemplate } from "@/templates/TabTemplate"
import { getColumnsTable } from "./getColumnsTable"
import { ImageIntroduceThrough } from "./ImageIntroduceThrough"

import styles from "./IntroduceThroughModal.module.scss"

import type {
  ApplicativeImageFinding,
  BaseImageFinding,
  CodeRepoFinding,
  Resource,
  TypeResource,
  VmFinding
} from "@/domain/issue"
import type { TFunction } from "@/hooks/useTranslation"
import {
  SourceItem,
  SourceList
} from "@/organisms/Drawers/components/SourceList"
import { findingsToSources } from "@/organisms/Drawers/components/SourceList/utils"

export type IntroduceThroughModalProps = {
  codeRepoFindings: CodeRepoFinding[]
  imageFindings: ApplicativeImageFinding[]
  baseImageFindings: BaseImageFinding[]
  vmFindings: VmFinding[]
  buttonText: string
  title: string
  description: string
  icon?: React.ReactNode
  drawer?: boolean
  defaultExpandedRowKeys?: string[]
  isLoading?: boolean
  SearchComponent?: React.ReactNode
}

type DataSourceItem = {
  key: string
  resource: Resource
  introducedThrough: any
  expandable: boolean
  sources: SourceItem[]
}

const getDrawerId = (type: TypeResource) => {
  if (type === "CodeRepository") return CODE_REPOSITORY_DRAWER_ID
  if (type === "Vm") return VIRTUAL_MACHINE_DRAWER_ID

  return IMAGE_DRAWER_ID
}

function generateDataSource(
  codeRepoFindings: CodeRepoFinding[],
  baseImageFindings: BaseImageFinding[],
  imageFindings: ApplicativeImageFinding[],
  vmFindings: VmFinding[]
): DataSourceItem[] {
  const sources = findingsToSources(codeRepoFindings)
  const repo = codeRepoFindings?.[0]
  const imageSources = [...baseImageFindings, ...imageFindings].map(
    (value) => ({
      key: `${value.resource.id}`,
      expandable: true,
      resource: value?.resource,
      introducedThrough: value?.introducedThrough,
      sources: []
    })
  )
  const vmSources = (vmFindings || []).map((value, index) => {
    const {
      vmInstanceId,
      autoScaleGroup,
      packageLocations = []
    } = value?.introducedThrough?.[0] || {}

    return {
      key: `${vmInstanceId || index}`,
      expandable: !!sources.length,
      resource: {
        ...value?.resource,
        name: vmInstanceId,
        subTitle: autoScaleGroup
      },
      introducedThrough: value?.introducedThrough,
      sources: [],
      packageLocations
    }
  })
  if (repo) {
    // adding the repo source first
    // it would be the same source for all the repos
    return [
      {
        key: repo.resource.id,
        expandable: true,
        resource: repo?.resource,
        introducedThrough: [],
        sources
      },
      ...imageSources,
      ...vmSources
    ]
  }
  return [...imageSources, ...vmSources]
}

export const IntroduceThroughModal: React.FC<
  IntroduceThroughModalProps & { t: TFunction; tKey: string }
> = ({
  codeRepoFindings,
  baseImageFindings,
  imageFindings,
  vmFindings,
  buttonText,
  title,
  description,
  icon,
  t,
  tKey,
  drawer,
  isLoading,
  SearchComponent,
  defaultExpandedRowKeys = []
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { logger } = useLogger()

  const showModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }

  const { onRowClick } = useDrawer("")
  const [, setTransition] = useTransition()
  const columns = getColumnsTable({
    onRowClick: (params: { key: string }, type: TypeResource) => {
      try {
        onRowClick(params, getDrawerId(type))

        document
          .querySelectorAll(".popover-resource-cell")
          .forEach((element) => {
            if (!element.classList.contains("ant-popover-hidden")) {
              ;(element as HTMLElement).style.zIndex = "-1"
              element.classList.add("ant-popover-hidden")
            }
          })

        setTransition(() => {
          closeModal()
        })
      } catch (err) {
        logger.error(err as Error)
      }
    }
  })
  const dataSource = generateDataSource(
    codeRepoFindings,
    baseImageFindings,
    imageFindings,
    vmFindings
  )

  const Component = drawer ? "div" : Modal
  const kortexInstalledFF = useFeatureFlags("kortexInstalled")
  const scmInstalledFF = useFeatureFlags("scmInstalled")

  return (
    <>
      {!drawer && (
        <div>
          <Button
            type="link"
            onClick={() => showModal()}
            size="small"
            className={styles.button}
          >
            {icon && icon}
            {buttonText}
          </Button>
        </div>
      )}
      <Component
        closable={drawer ? undefined : true}
        open={isModalOpen}
        width={1200}
        footer={null}
        onCancel={closeModal}
        className={styles.modal}
      >
        <div className={styles.container}>
          {title && (
            <div className={cn(styles.title, drawer && styles.drawer)}>
              {!drawer && <ApartmentOutlined />}
              <Heading level={6} title={title} />
            </div>
          )}
          {description && (
            <Text className={styles.description}>{description}</Text>
          )}
          <div className={cn(!SearchComponent && styles.tableWrapper)}>
            <TabTemplate
              loading={!!isLoading}
              columns={columns}
              dataSource={dataSource}
              expandable={{
                defaultExpandedRowKeys: defaultExpandedRowKeys,
                expandIcon: ExpandIconTable,
                expandRowByClick: true,
                expandedRowRender: ({
                  resource,
                  introducedThrough,
                  packageLocations,
                  sources
                }) => {
                  if (resource.type === "Vm") {
                    return (
                      <div className={styles.introducedThroughSourcesList}>
                        <List
                          title="Package Locations"
                          items={packageLocations || []}
                          limit={2}
                          render={(path: string, index) => (
                            <Item
                              key={index}
                              title={<Text copyable>{path}</Text>}
                            />
                          )}
                        />
                      </div>
                    )
                  }

                  const hasImageIntroduceThrough =
                    resource.type === "Image" && introducedThrough.length > 0
                  return (
                    <div>
                      {hasImageIntroduceThrough && (
                        <ImageIntroduceThrough
                          introducedThrough={introducedThrough}
                          t={t}
                          tKey={tKey}
                        />
                      )}
                      {resource.type === "CodeRepository" && (
                        <div className={styles.introducedThroughSourcesList}>
                          <SourceList
                            type="introducedThrough"
                            sources={sources}
                          />
                        </div>
                      )}
                    </div>
                  )
                }
              }}
              classes={{ footer: styles.footer }}
              SearchComponent={SearchComponent}
            />
            {!isLoading && !kortexInstalledFF && (
              <Link to="/deployments">
                <Button
                  className={styles.deployButton}
                  type="link"
                  icon={<CloudUploadOutlined />}
                >
                  Deploy Kodem runtime sensor to view related images
                </Button>
              </Link>
            )}
            {!isLoading && !scmInstalledFF && (
              <Link to="/integrations">
                <Button
                  className={styles.deployButton}
                  type="link"
                  icon={<ApiOutlined />}
                >
                  Connect Kodem With SCM System To View Related Code
                  Repositories And Remediation Suggestions
                </Button>
              </Link>
            )}
          </div>
        </div>
      </Component>
    </>
  )
}
export default IntroduceThroughModal
