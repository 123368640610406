import { createContext, useContext } from "react"

import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"

export const getQueryKeyHashFn =
  (viewId: string = "") =>
  (keys: string[] = []) => {
    const [url = "", params = {}] = keys
    const search = new URLSearchParams(params)

    return `_${viewId}_${url}${search.toString()}`
  }

export const ActiveKeyTabsPreviewContext = createContext<{
  activeKey: (typeof TAB_KEYS_BY_PAGE.INVENTORY)[number]
  setActiveKey: (key: (typeof TAB_KEYS_BY_PAGE.INVENTORY)[number]) => void
  config: {
    queryKeyHashFn: (keys: string[]) => string
    headers?: Record<string, unknown>
  }
}>({
  activeKey: TAB_KEYS_BY_PAGE.INVENTORY[0],
  setActiveKey: () => {},
  config: {
    queryKeyHashFn: getQueryKeyHashFn()
  }
})

export const useActiveKeyTabsPreview = () => {
  const context = useContext(ActiveKeyTabsPreviewContext)

  return context
}
