import { Text } from "@/atoms"
import {
  DeployCommand,
  type CommonDeployCommandProps
} from "@/pages/DeploymentsPage/components/molecules/DeployCommand"
import { DeploymentKeyButton } from "@/pages/DeploymentsPage/components/molecules/DeploymentKeyButton"
import { InlineInput } from "@/pages/DeploymentsPage/components/molecules/InlineInput"
import { getDefaultValues, getDeployCommand } from "./helpers"

import styles from "./EC2DeployCommand.module.scss"

export const EC2DeployCommand = (props: CommonDeployCommandProps) => {
  return (
    <DeployCommand
      getDefaultValues={getDefaultValues}
      getDeployCommand={getDeployCommand}
      {...props}
    >
      {({ values, onChange, onError }) => {
        return (
          <>
            <Text className={styles.redText}>
              cd
              <InlineInput
                key={`${values.key}_workDir`}
                name="workDir"
                value={values.workDir}
                onChange={onChange}
              />
            </Text>
            <Text className={styles.stepText}>
              # Unpack the tgz file at your workdir
            </Text>
            <Text>
              <Text className={styles.redText}>tar</Text>
              {` --no-overwrite-dir -xvf `}
              <InlineInput
                key={`${values.key}_pathKortexTGZ`}
                name="pathKortexTGZ"
                value={values.pathKortexTGZ}
                onChange={onChange}
              />
            </Text>
            <Text className={styles.stepText}>
              # Install Kodem kortex sensors
            </Text>
            <Text>
              <Text className={styles.redText}>sudo</Text>
              {` ./install_kortex.sh `}
              <InlineInput
                key={`${values.key}_environment`}
                name="environment"
                value={values.environment}
                onChange={onChange}
              />
              &nbsp;
              <InlineInput
                key={`${values.key}_deploymentKey`}
                name="deploymentKey"
                value={values.deploymentKey}
                onChange={onChange}
                buttons={<DeploymentKeyButton onError={onError} />}
              />
            </Text>
            <Text>
              <Text className={styles.redText}>sudo</Text>
              {` systemctl start komon koltan `}
            </Text>
            <Text className={styles.stepText}># Clean</Text>
            <Text>
              <Text className={styles.redText}>rm</Text>
              <Text
                className={styles.valueText}
              >{` ${values.pathKortexTGZ}`}</Text>
            </Text>
          </>
        )
      }}
    </DeployCommand>
  )
}
