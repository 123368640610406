import cn from "classnames"

import { Button, Text, Title } from "@/atoms"

import styles from "./MultiFactorFormTemplate.module.scss"

import type { MultiFactorFormTemplateProps } from "."

export const MultiFactorFormTemplate = ({
  children,
  steps,
  title,
  description,
  okText,
  okDisabled,
  onOk,
  cancelText,
  onCancel,
  classes = {}
}: MultiFactorFormTemplateProps) => {
  return (
    <div className={styles.container}>
      {steps && <div className={styles.steps}>{steps}</div>}
      <div className={styles.body}>
        {title && (
          <Title level={3} className={cn(styles.title, classes.title)}>
            {title}
          </Title>
        )}
        {description && (
          <Text className={cn(styles.description, classes.description)}>
            {description}
          </Text>
        )}
        <div className={styles.wrap}>{children}</div>
      </div>
      <div className={cn(styles.footer, classes.footer)}>
        {cancelText && (
          <Button size="large" type="text" onClick={onCancel}>
            {cancelText}
          </Button>
        )}
        {okText && (
          <Button disabled={okDisabled} size="large" type="ks" onClick={onOk}>
            {okText}
          </Button>
        )}
      </div>
    </div>
  )
}
