import { InkTag, Statistic } from "@/atoms"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import StatisticCardTemplate from "../../templates/StatisticCardTemplate"
import styles from "./StatisticInk.module.scss"
import type { StatisticInkProps } from "./StatisticInk.types"

const StatisticInk = (props: StatisticInkProps) => {
  const { to, dot, onClick, color, ...statisticProps } = props
  const { abbreviateNumber } = useNumberConvertor()

  return (
    <StatisticCardTemplate
      className={styles.container}
      span={24}
      to={to}
      onClick={onClick}
    >
      <InkTag color={color} dot={dot} />
      <Statistic
        className={styles.statistic}
        formatter={abbreviateNumber}
        {...statisticProps}
      />
    </StatisticCardTemplate>
  )
}

export default StatisticInk
