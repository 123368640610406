import { CellTable } from "@/atoms"
import { getDefaultColumnParams } from "@/helpers/table.helpers"

import type { SortOrder } from "@/atoms"
import type { ORDER_BY_PROPS } from "@/hooks/useOrderBy"
import type { TFunction } from "@/hooks/useTranslation"
import { DeploymentTypeCell } from "../../molecules/DeploymentTypeCell"
import { KortexVersionCell } from "../../molecules/KortexVersionCell"
import { StatusCell } from "../../molecules/StatusCell"

export const getColumnsTable = (
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">,
  {
    t
  }: {
    t: TFunction
  }
) => [
  {
    title: "Environment Name",
    width: "25%",
    render: CellTable,
    ...getDefaultColumnParams("environmentName")
  },
  {
    title: "Kortex Version",
    width: "18%",
    sortDirections: ["ascend", "descend"] as SortOrder[],
    render: KortexVersionCell(t),
    ...getDefaultColumnParams("kortexVersion", sorter)
  },
  {
    title: "Deployment Type",
    width: "18%",
    render: DeploymentTypeCell,
    ...getDefaultColumnParams("deploymentType")
  },
  {
    title: "Status",
    width: "10%",
    sortDirections: ["descend", "ascend"] as SortOrder[],
    render: StatusCell,
    ...getDefaultColumnParams("status", sorter)
  },
  {
    title: "First Installation",
    width: "13%",
    sortDirections: ["descend", "ascend"] as SortOrder[],
    render: CellTable,
    ...getDefaultColumnParams("firstInstallationTime", sorter)
  },
  {
    title: "Last Upgrade",
    width: "11%",
    sortDirections: ["descend", "ascend"] as SortOrder[],
    render: CellTable,
    ...getDefaultColumnParams("lastUpgradeTime", sorter)
  },
  {
    title: "Last Seen",
    width: "11%",
    sortDirections: ["descend", "ascend"] as SortOrder[],
    render: CellTable,
    ...getDefaultColumnParams("lastHeartbeatTime", sorter)
  }
]
