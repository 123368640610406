import { Popover, Tag, Text } from "@/atoms"
import { EC2, Image, SourceControlIcon } from "@/icons"
import { Resource as ResourceComponent } from "@/pages/TriagePage/components/molecules/ResourceCell/Resource"

import styles from "./ResourceCell.module.scss"

import type { Resource, TypeResource } from "@/domain/issue"

export const ResourceCell =
  (onRowClick?: (record: { key: string }, type: TypeResource) => void) =>
  (resource: Resource) => {
    const { name, sourceControl, tag, baseImageDetailsExist, type, subTitle } =
      resource
    const nameWithTag = `${name}${tag ? `:${tag}` : ""}`
    const Wrapper = onRowClick ? Popover : "div"
    const Icon = type === "Vm" ? EC2 : Image
    const showSubtitle = !!subTitle
    const showTag = !!tag && !showSubtitle

    return (
      <div className={styles.container}>
        <span className={styles.icon}>
          {sourceControl ? (
            <SourceControlIcon sourceControl={sourceControl} />
          ) : (
            <Icon className={styles.vmIcon} />
          )}
        </span>

        <Wrapper
          destroyTooltipOnHide
          placement="topLeft"
          rootClassName="popover-resource-cell"
          className={styles.titleSection}
          content={
            !!onRowClick
              ? ((() => (
                  <Text
                    underline
                    className={styles.textPopover}
                    onClick={(e) => {
                      e.stopPropagation()
                      onRowClick?.({ key: resource.id }, type)
                    }}
                  >
                    <ResourceComponent
                      type={sourceControl ? "CodeRepository" : type}
                      name={nameWithTag}
                      sourceControl={sourceControl}
                    />
                  </Text>
                )) as any)
              : ""
          }
        >
          <span>{name}</span>
          {showTag && <span className={styles.subTitle}>{`Tag: ${tag}`}</span>}
          {showSubtitle && <span className={styles.subTitle}>{subTitle}</span>}
        </Wrapper>
        {!!baseImageDetailsExist && (
          <Tag className={styles.tag}>From Base Image</Tag>
        )}
      </div>
    )
  }
