import { HeaderPage } from "@/organisms/HeaderPage"
import { PageTemplate } from "@/templates/PageTemplate"
import { FunnelTriagePage } from "../../organisms/FunnelTriagePage"
import { SelectionProvider } from "../../organisms/SelectionProvider"
import { TriageTab } from "../../organisms/TriageTab"

import styles from "./IssuesPage.module.scss"

export const IssuesPage = () => (
  <PageTemplate
    full
    header={<HeaderPage titleTranslationKey="Triage" />}
    classes={{ main: styles.main }}
  >
    <FunnelTriagePage size="sm" />
    <SelectionProvider>
      <TriageTab />
    </SelectionProvider>
  </PageTemplate>
)
