import { useState } from "react"

import type {
  AttributesDismiss,
  UseDismissModalProps
} from "./useDismissModal.types"

export type * from "./useDismissModal.types"

export const useDismissModal = (props: UseDismissModalProps) => {
  const [open, setOpen] = useState(false)

  const onOk = (attr: AttributesDismiss) => {
    props.onOk(attr)
    setOpen(false)
  }
  const openModal = () => {
    setOpen(true)
  }
  const onCancel = () => setOpen(false)

  return { open, openModal, onOk, onCancel } as const
}
