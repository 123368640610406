import { Tooltip } from "@/atoms"
import { IssuesSummaryFC } from "../IssuesSummaryFC"

import styles from "./IssuesSummaryCell.module.scss"

import type { NumberConvertor } from "@/hooks/useNumberConvertor"
import type { TFunction } from "@/hooks/useTranslation"
import type { IssuesSummary } from "."

export const IssuesSummaryCell =
  (
    { convertors }: { t: TFunction; convertors: NumberConvertor },
    { limit }: { limit?: number } = {}
  ) =>
  (issues: IssuesSummary) => {
    if (!Array.isArray(issues)) return null

    let displayIssues: IssuesSummary = []
    let hiddenIssues: IssuesSummary = []

    const hiddenCount = issues.reduce((acc, issue, index) => {
      if (!!limit && limit < index + 1) {
        hiddenIssues.push(issue)
        acc += issue[1]
      } else displayIssues.push(issue)

      return acc
    }, 0)

    return (
      <span className={styles.issueSummaryContainer}>
        <IssuesSummaryFC issues={displayIssues} convertors={convertors} />
        {!!hiddenCount && (
          <Tooltip
            title={
              <IssuesSummaryFC
                issues={hiddenIssues}
                convertors={convertors}
                Tooltip={Tooltip}
              />
            }
          >
            <span
              className={styles.hiddenCount}
            >{`+${convertors.abbreviateNumber(hiddenCount)}`}</span>
          </Tooltip>
        )}
      </span>
    )
  }
