import { AbstractTransport } from "./Transport"

import type { Services } from "@/interfaces/services"

class ConsoleTransport extends AbstractTransport {
  protected instance: Console

  constructor(
    params: { level: Services.LoggerAdapter.LevelKeys },
    instance: Console = console
  ) {
    super(params)

    this.instance = instance
  }
  public log(info: Services.LoggerAdapter.Info) {
    this.instance.log(info.message, info.meta)
  }
}

export { ConsoleTransport }
