import { useLogger } from "@/hooks/useLogger"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { ExpandedRowTable } from "@/molecules/ExpandedRowTable"
import { ExpandIconTable } from "@/molecules/ExpandIconTable"
import { TabTemplate } from "@/templates/TabTemplate"
import { useDrawer } from "../../hooks/useDrawer"

import type { TabDrawerTemplateProps } from "."

export const TabDrawerTemplate = <D, R = any>(
  props: TabDrawerTemplateProps<D, R>
) => {
  const {
    getColumnsTable,
    dataContext,
    filtersContext,
    FilterComponent,
    headerComponent,
    setIssueRecord,
    expandable,
    onRow,
    forceRender
  } = props
  const { onSorterChange, ...orderByProps } = useOrderBy({
    onChange: filtersContext?.patchParams
  })
  const { t } = useTranslation()
  const internetFacingFF = useFeatureFlags("internetFacingIntegration")
  const { logger, EVENTS } = useLogger()
  const { response, isLoading } = dataContext as any
  const { data, metadata } = response || {}
  const { onRowClick } = useDrawer("")
  const convertors = useNumberConvertor()
  const paramsGetColumnsTable = {
    orderByProps,
    t,
    internetFacingFF,
    setIssueRecord,
    onRowClick,
    convertors,
    placement: "drawer"
  }

  useTooltip(data)

  const currentData = forceRender === false ? undefined : data

  return (
    <TabTemplate
      dataSource={currentData}
      metadata={metadata}
      loading={isLoading || forceRender === false}
      columns={getColumnsTable(paramsGetColumnsTable)}
      expandable={
        expandable === null
          ? undefined
          : {
              expandIcon: ExpandIconTable,
              expandRowByClick: true,
              rowExpandable: ({ expandable }) => !!expandable,
              expandedRowRender: (record, index, indent, expanded) => {
                if (props.expandedRowRender) {
                  return props.expandedRowRender(paramsGetColumnsTable)(
                    record,
                    index,
                    indent,
                    expanded
                  )
                }

                const { rowData } = record
                const { getColumnsExpandedRow } = props

                if (!getColumnsExpandedRow) {
                  return null
                }

                return (
                  <ExpandedRowTable
                    rowKey="key"
                    columns={getColumnsExpandedRow(paramsGetColumnsTable)}
                    dataSource={rowData}
                  />
                )
              }
            }
      }
      asideFooter={props.asideFooter}
      onRow={onRow}
      rowSelection={props.rowSelection}
      onChange={(_pagination, _filters, sorter: any) => {
        onSorterChange(sorter)

        const { field, order } = sorter

        logger.info(EVENTS.ANALYTIC_EVENTS.DRAWER.SORT, { field, order })
      }}
      onPaginationChange={(pageNumber) => {
        filtersContext.onPaginationChange(pageNumber)
        logger.info(EVENTS.ANALYTIC_EVENTS.DRAWER.PAGINATION, { pageNumber })
      }}
      SearchComponent={FilterComponent}
      headerComponent={headerComponent}
    />
  )
}
