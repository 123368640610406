import { createContext, useContext, useState } from "react"
import { useSearchParams } from "react-router-dom"

type FilterState = Record<string, any>

export const getFilterState = (values: FilterState = {}, dirty = false) => {
  const allowClear = Object.keys(values).some((key) => values[key])

  return {
    dirty,
    values,
    allowClear: allowClear
  }
}

export const useStateFilter = (initialState: FilterState) => {
  const [state, setState] = useState(getFilterState(initialState))

  const onChange = (value: FilterState) => {
    const nextState = { ...state.values, ...value }

    setState({
      ...state,
      ...getFilterState(nextState, true)
    })
  }

  return {
    filterState: state,
    reset: (values: FilterState, dirty = true) => {
      setState(getFilterState(values, dirty))
    },
    onFilterStateChange: onChange,
    onClear: () => {
      setState(getFilterState({}, true))
    },
    onOk: () => {
      setState((prevState) => ({ ...prevState, dirty: false }))
    }
  }
}

export type Value = undefined | string | (boolean | number | string)[]

export const FiltersContext = createContext<{
  filters: Record<string, Value>
  setFilters: (filters: Record<string, Value>) => void
  onChange: (values: { name: string; value: Value }) => void
  searchParams: ReturnType<typeof useSearchParams>[0]
  setSearchParams: ReturnType<typeof useSearchParams>[1]
  hash: number
  setHash: (number: number) => void
}>({
  filters: {},
  setFilters: () => {},
  onChange: () => {},
  searchParams: new URLSearchParams(),
  setSearchParams: () => new URLSearchParams(),
  hash: 0,
  setHash: () => {}
})

export const useFilters = () => {
  const context = useContext(FiltersContext)

  return context
}
