import { useServices } from "@/hooks/useServices"
import { useTranslation } from "@/hooks/useTranslation"
import { MultiFactorFormTemplate } from "../../templates/MultiFactorFormTemplate"
import { PhoneStepsMultiFactorForm } from "../PhoneStepsMultiFactorForm"

import styles from "./SuccessMultiFactorForm.module.scss"

import type { ActionMultiFactorFormTemplateProps } from "../../templates/MultiFactorFormTemplate"

export const SuccessMultiFactorForm = ({
  values
}: ActionMultiFactorFormTemplateProps) => {
  const { step } = values
  const { auth } = useServices()
  const { t } = useTranslation("translation", { keyPrefix: "auth.mfa.success" })

  return (
    <MultiFactorFormTemplate
      title={t("title")}
      description={t("description")}
      okText={t("okAction")}
      steps={<PhoneStepsMultiFactorForm currentStep={step} />}
      classes={{ description: styles.description }}
      onOk={() => {
        auth.skipMultiFactorSetUp()
      }}
    />
  )
}
