import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { usePackageVulnerabilities } from "@/organisms/Drawers/applications/inventory/useInventory"
import { useParamsFilter } from "@/organisms/Drawers/hooks/useParamsFilter"
import { TabTemplate } from "@/templates/TabTemplate"
import { SearchFilter } from "../SearchFilter"
import { getColumnsTable } from "./getColumnsTable"

export const VulnerabilitiesTab = ({ id, projectId, ...props }: any) => {
  const pageSize = 20
  const filtersContext = useParamsFilter({
    filterKey: "overview",
    defaultParams: { pageNumber: "0" },
    ...props
  })

  const params = { projectId, id, pageSize, ...filtersContext.params }
  const { response, isLoading } = usePackageVulnerabilities(params)
  const { data, metadata } = response || {}
  const { t } = useTranslation()

  useTooltip(data)

  return (
    <TabTemplate
      loading={isLoading}
      dataSource={data}
      metadata={metadata}
      pageSize={pageSize}
      columns={getColumnsTable(t)}
      SearchComponent={<SearchFilter {...filtersContext} />}
      onPaginationChange={(pageNumber) => {
        filtersContext.patchParams({ pageNumber })
      }}
    />
  )
}
