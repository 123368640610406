import { Card } from "@/atoms"
import { PageTemplate } from "@/templates/PageTemplate"
import { IntegrationStatus } from "../../organisms/IntegrationStatus"

import styles from "./IntegrationPage.module.scss"

export const IntegrationPage = () => (
  <PageTemplate classes={{ main: styles.mainPageTemplate }}>
    <Card className={styles.card}>
      <IntegrationStatus />
    </Card>
  </PageTemplate>
)
