import { HeaderPage } from "@/organisms/HeaderPage"
import { ImagesTab } from "@/pages/DiscoveryPage/components/organisms/ImagesTab"
import { useActiveKeyTabsInventory } from "@/pages/DiscoveryPage/hooks/useActiveKeyTabsInventory"
import { PageTemplate } from "@/templates/PageTemplate"

export const ImagesPage = () => (
  <PageTemplate
    full
    header={<HeaderPage titleTranslationKey="navigation.images" />}
  >
    <ImagesTab useActiveKeyTabsInventory={useActiveKeyTabsInventory} />
  </PageTemplate>
)
