import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

export const INDEX_DRAWER_PARAM_NAME = "idr"
export const STATE_DRAWER_PARAM_NAME = "sdr"
export const OPEN_VALUE = "open"

export const getLinkToDrawer = (params = "") => {
  const { origin, pathname } = window.location
  const additionalParams =
    !params || params.charAt(0) === "&" ? params : `&${params}`

  return `${origin}${pathname}?${STATE_DRAWER_PARAM_NAME}=${OPEN_VALUE}${additionalParams}`
}

export const getIndexFromQueryString = (searchParams: URLSearchParams) =>
  Number(searchParams.get(INDEX_DRAWER_PARAM_NAME) ?? -1)

export const getOpenStateFromQueryString = (searchParams: URLSearchParams) =>
  searchParams.get(STATE_DRAWER_PARAM_NAME) === OPEN_VALUE

export const queryStringContainsDrawerVariables = (
  searchParams: URLSearchParams
) =>
  getOpenStateFromQueryString(searchParams) ||
  getIndexFromQueryString(searchParams) !== -1

export const removeDrawerVariablesFromQueryString = (
  searchParams: URLSearchParams
) => {
  searchParams.delete(STATE_DRAWER_PARAM_NAME)
  searchParams.delete(INDEX_DRAWER_PARAM_NAME)

  return searchParams
}

export const getInitialStateFromQueryString = (
  searchParams: URLSearchParams
) => ({
  index: getIndexFromQueryString(searchParams),
  open: getOpenStateFromQueryString(searchParams)
})

export const useDrawerTabTemplate = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [state, setState] = useState(
    getInitialStateFromQueryString(searchParams)
  )

  useEffect(() => {
    if (queryStringContainsDrawerVariables(searchParams)) {
      setSearchParams(removeDrawerVariablesFromQueryString(searchParams))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [state, setState] as const
}
