import {
  useGetInventoryContainerBaseImage,
  useGetInventoryContainerBaseImageDetails
} from "@/api/useInventory"
import { useQueryString } from "@/hooks/useQueryString"
import { useTranslation } from "@/hooks/useTranslation"
import {
  getContainerBaseImagesTabData,
  getInventoryContainerBaseImageDetails
} from "./useContainerBaseImageInventory.selector"

export const useContainerBaseImageInventory = (
  paramKeys?: { key: string }[],
  config?: { enabled: boolean }
) => {
  const params = useQueryString(paramKeys)
  const paramsOrder = useQueryString(["orderBy", "ascending"])
  const context = useGetInventoryContainerBaseImage(
    { ...params, ...paramsOrder },
    {
      select: getContainerBaseImagesTabData,
      ...(config || {})
    }
  )

  return context
}

export const useInventoryContainerBaseImageDetails = (
  baseImageDigest: string | undefined,
  { minorUpdate, majorUpdate }: { minorUpdate?: string; majorUpdate?: string }
) => {
  const { t } = useTranslation()
  const { response, isLoading } = useGetInventoryContainerBaseImageDetails(
    baseImageDigest || "",
    {},
    {
      enabled: !!baseImageDigest,
      select: (response) =>
        getInventoryContainerBaseImageDetails(response, t, {
          minorUpdate,
          majorUpdate
        })
    }
  )

  return { response, isLoading }
}
