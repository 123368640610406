import cn from "classnames"
import { useEffect, useState } from "react"

import { Card } from "@/atoms"
import { copyHtmlToClipboard } from "@/helpers/clipboard.helper"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import { CopyButton } from "@/molecules/CopyButton"
import { FixabilityCell } from "@/molecules/FixabilityCell"
import { Heading } from "@/molecules/Heading"
import { IssueExpandableCell } from "@/organisms/Drawers/components/IssuesTab/IssueExpandableCell"

import { isCodeIssueType } from "@/domain/issue"
import styles from "./FixesCard.module.scss"

export const FixesCard = ({
  record,
  type
}: {
  record: any
  type?: "resources"
}) => {
  const { t } = useTranslation()
  const { logger } = useLogger()
  const [copyable, setCopyable] = useState(false)

  useEffect(() => {
    if (!copyable) return

    const node = document.getElementById("copyable-issue-expandable-cell")

    if (!node) return

    const el = node.querySelectorAll(
      '[data-element-issue-expandable-cell-view="fix-container"] > div:first-child'
    )

    let html = el[0]?.innerHTML || ""

    if (isCodeIssueType(issueType)) html += el[1]?.innerHTML || ""

    copyHtmlToClipboard(html, logger)

    setCopyable(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyable])

  if (!record) return null

  const { id, issueType, fixability } = record

  return (
    <Card
      className={cn(
        styles.container,
        type === "resources" ? styles.resources : styles.fixes
      )}
      title={
        type === "resources" ? undefined : (
          <div className={styles.titleRow}>
            <div className={styles.containerTitleRow}>
              <Heading level={6} title={t(`general.fixes`)} />
              {FixabilityCell(t)(fixability)}
            </div>
            <CopyButton
              size="small"
              type="secondary"
              onCopy={() => setCopyable(true)}
            >
              {t("general.copy")}
            </CopyButton>
          </div>
        )
      }
    >
      <span data-type-fixes-card={type}>
        <IssueExpandableCell
          drawer
          noLimits={type === "resources"}
          id={id}
          issueType={issueType}
          fixability={fixability}
          riskInsights={record.riskInsights}
        />
      </span>

      <span
        id="copyable-issue-expandable-cell"
        className={styles.copyableContainer}
      >
        <IssueExpandableCell
          drawer
          copyable
          noLimits={type === "resources"}
          id={id}
          issueType={issueType}
          fixability={fixability}
          riskInsights={record.riskInsights}
        />
      </span>
    </Card>
  )
}
// also in issue modal, for admin link for user text
