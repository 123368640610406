import { BranchScanInfo } from "@/api/useInventory.types"
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  SyncOutlined
} from "@/icons"
import styles from "./ScanStatusTag.module.scss"

export const ScanStatusIcon = ({
  status,
  colored = false
}: {
  status: BranchScanInfo["status"]
  colored?: boolean
}) => {
  switch (status) {
    case "Incomplete":
      return (
        <ExclamationCircleOutlined
          className={colored ? undefined : styles.warning}
        />
      )
    case "Processing":
      return <SyncOutlined className={colored ? undefined : styles.info} />
    case "Info":
      return (
        <InfoCircleOutlined className={colored ? undefined : styles.info} />
      )
    case "Done":
      return (
        <CheckCircleOutlined className={colored ? undefined : styles.success} />
      )
    default:
      return null
  }
}
