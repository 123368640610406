import { useEffect, useState } from "react"
import { Params } from "react-router-dom"

import { useGetTenant } from "@/api/useAuth"
import { Form, message as messageToast } from "@/atoms"
import { PROVIDER_AUTH_TYPES } from "@/const/provider-auth.constants"
import { useLogger } from "@/hooks/useLogger"
import { useServices } from "@/hooks/useServices"

import type { StateProviders } from "./authenticate.types"

export const shouldLogError = (
  error: undefined | string,
  excludeErrors: string[]
) => {
  if (typeof error !== "string") return true

  return !excludeErrors.some((excludeError) => error.includes(excludeError))
}

export const useAuthenticate = (
  params: Params,
  form: ReturnType<typeof Form.useForm>[number]
) => {
  const { slug } = params || {}
  const { auth } = useServices()
  const { logger, EVENTS } = useLogger()
  const { providers, isFetched, isLoading } = useProviders(slug)
  const setError = () => {
    form.setFields([
      {
        name: "email",
        errors: ["Email or password is incorrect"]
      },
      {
        name: "password",
        errors: [""]
      }
    ])
  }

  return {
    providers,
    isLoading: !isFetched || isLoading,
    signInWithSaml: async () => {
      try {
        const provider = providers?.[PROVIDER_AUTH_TYPES.SAML]

        if (!provider)
          return logger.error("No SAML Provider", {
            tags: {
              custom_error: EVENTS.ERROR_EVENTS.SIGN_IN,
              provider: "saml"
            }
          })

        const result = await auth.signInWithSaml(provider)

        if (result?.error)
          logger.error("SAML result with error", {
            tags: {
              custom_error: EVENTS.ERROR_EVENTS.SIGN_IN,
              provider: "saml"
            }
          })
      } catch (error: any) {
        const excludeErrors = [
          "auth/cancelled-popup-request",
          "auth/popup-closed-by-user"
        ]
        const message = error?.message

        if (shouldLogError(message, excludeErrors)) {
          messageToast.error(
            typeof message === "string" ? message : "Something went wrong"
          )
          logger.error(error as Error, {
            tags: {
              custom_error: EVENTS.ERROR_EVENTS.SIGN_IN,
              provider: "saml"
            }
          })
        }
      }
    },
    signInWithGoogle: async () => {
      await auth.signInWithGoogle()
    },
    signInWithGithub: async () => {
      await auth.signInWithGithub()
    },
    signInWithEmailPass: async (values: {
      email: string
      password: string
    }) => {
      try {
        const { email, password } = values || {}

        if (!email || !password) {
          return setError()
        }

        const result = await auth.signInWithEmailPass(email, password)

        if (result?.error) setError()
      } catch (error: any) {
        setError()

        const excludeErrors = ["auth/invalid-credential"]

        if (shouldLogError(error?.message, excludeErrors))
          logger.error(error as Error, {
            tags: {
              custom_error: EVENTS.ERROR_EVENTS.SIGN_IN,
              provider: "email"
            }
          })
      }
    }
  }
}

function useProviders(slug: string | undefined) {
  const { auth, localStorage } = useServices()
  const [providers, setProviders] = useState<null | StateProviders>()
  const { isLoading, isFetched, response } = useGetTenant(slug)
  const { data } = response || {}

  useEffect(() => {
    const { firebaseTenantId, identityProviders, isMultiFactorAuthEnabled } =
      data || {}
    const slugAndAuthServiceExists = slug && auth
    const tenantAndProvidersExistsForSlug =
      firebaseTenantId && identityProviders?.length

    if (slugAndAuthServiceExists && tenantAndProvidersExistsForSlug) {
      auth.setTenantId(firebaseTenantId)
      auth.setCompany(slug)
      auth.setMultiFactorAuth(!!isMultiFactorAuthEnabled)

      setProviders(
        identityProviders.reduce((acc, provider) => {
          acc[provider.type] = provider.id || provider.type

          return acc
        }, {} as StateProviders)
      )
    }
  }, [auth, slug, data, localStorage])

  return { providers, isLoading, isFetched }
}
