import { Button, Dropdown, Search } from "@/atoms"
import { useClickOutside } from "@/hooks/useClickOutside"
import { useToggle } from "@/hooks/useToggle"
import { DownOutlined } from "@/icons"
import cn from "classnames"
import { useMemo, useRef, useState } from "react"
import { ButtonsFilter } from "../ButtonsFilter"

import styles from "./DropdownFilter.module.scss"

export const DropdownFilter = ({
  children,
  disabled,
  clearDisabled,
  okDisabled,
  allowSearch,
  isPlaceholder,
  onOk,
  onClear,
  onOpenChange,
  menuItems: menuItemsProp
}: any) => {
  const ref = useRef(null)
  const { value: open, toggle, setValue } = useToggle()
  const [search, setSearch] = useState("")

  const menuItems = useMemo(() => {
    const copyMenuItems = [...menuItemsProp].filter(
      ({ key }: { key: string }) => key.indexOf(search) !== -1
    )

    if (allowSearch && menuItemsProp.length > 5) {
      if (copyMenuItems.length > 0) {
        copyMenuItems.unshift({
          type: "divider"
        })
      }

      copyMenuItems.unshift({
        className: styles.sectionControl,
        label: <Search className={styles.search} onSearch={setSearch} />,
        key: "search"
      })
    }

    if (copyMenuItems.length > 0) {
      copyMenuItems.push({
        type: "divider"
      })
    }

    copyMenuItems.push({
      className: styles.sectionControl,
      label: (
        <ButtonsFilter
          clearDisabled={clearDisabled}
          okDisabled={okDisabled}
          onOk={() => {
            toggle()

            onOk?.()
          }}
          onClear={onClear}
        />
      ),
      key: "apply"
    })

    return copyMenuItems
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItemsProp, search, toggle])

  useClickOutside(ref, () => {
    setValue(false)
  })

  return (
    <span ref={ref}>
      <Dropdown
        disabled={disabled}
        className={cn(styles.dropdown, disabled && styles.disabled)}
        menu={{
          items: menuItems
        }}
        open={open}
        getPopupContainer={() => {
          return ref.current || document.body
        }}
        onOpenChange={onOpenChange}
      >
        <Button type="text" onClick={toggle}>
          <div
            className={cn(
              styles.buttonContainer,
              isPlaceholder && styles.placeholder
            )}
          >
            {children}
            <DownOutlined className={styles.dropdownIcon} />
          </div>
        </Button>
      </Dropdown>
    </span>
  )
}
