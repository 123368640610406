import { Link, Text } from "@/atoms"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"
import { EMPTY_VALUE, OverviewCardTemplate } from "../OverviewCardTemplate"

export const SummaryCard = ({ data }: any) => {
  if (data === undefined) return null

  const { description, language, link, licenses } = data || {}

  const items = [
    {
      label: (
        <OverviewDescriptions.Label>Description</OverviewDescriptions.Label>
      ),
      children: <Text ellipsis>{description || EMPTY_VALUE}</Text>
    },
    {
      label: <OverviewDescriptions.Label>Link</OverviewDescriptions.Label>,
      children: link ? (
        <Link href={link} target="_blank" type="link">
          {link}
        </Link>
      ) : (
        EMPTY_VALUE
      )
    },
    {
      label: <OverviewDescriptions.Label>License</OverviewDescriptions.Label>,
      children: <Text ellipsis>{licenses.join(",") || EMPTY_VALUE}</Text>
    },
    {
      label: <OverviewDescriptions.Label>Language</OverviewDescriptions.Label>,
      children: <Text ellipsis>{language || EMPTY_VALUE}</Text>
    }
  ]

  return <OverviewCardTemplate title="Summary" items={items} />
}
