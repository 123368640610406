import { Alert } from "@/atoms"
import { CloseCircleOutlined } from "@/icons"

import styles from "./ErrorAlert.module.scss"

export const ErrorAlert = ({
  description
}: {
  description: string | null | undefined
}) => {
  if (!description) return null

  return (
    <Alert
      showIcon
      className={styles.container}
      type="error"
      icon={<CloseCircleOutlined />}
      message="Connection Failed"
      description={description}
    />
  )
}
