import cn from "classnames"

import styles from "./PlaceholderTemplate.module.scss"

import type { PlaceholderTemplateProps } from "."

export const PlaceholderTemplate: React.FC<PlaceholderTemplateProps> = ({
  icon,
  title,
  text,
  classes = {}
}) => (
  <div className={cn(styles.container, classes.container)}>
    {icon}
    {!!title && <div className={cn(styles.title, classes.title)}>{title}</div>}
    {text}
  </div>
)
