import { Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { EmptyLabels } from "@/icons"

import styles from "./EmptyState.module.scss"

import type { EmptyStateComponent } from "."

export const EmptyState: EmptyStateComponent = (props) => {
  const { isEmpty, isAdmin } = props
  const { t } = useTranslation("translation", {
    keyPrefix: "settings.labels.modal.empty"
  })
  const description = isAdmin ? "adminDescription" : "description"
  const title = isEmpty ? "title" : "noSearchResults"

  return (
    <div className={styles.container}>
      <EmptyLabels className={styles.labelsIcon} />
      <Text type="secondary">{t(title)}</Text>
      {isEmpty && <Text type="secondary">{t(description)}</Text>}
    </div>
  )
}
