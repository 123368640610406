import { Filter } from "@/organisms/FilterNew/Filter"
import { SmartSearch } from "@/organisms/SmartSearch/SmartSearch"

export const SearchFilter = (props: any) => {
  const keys = [{ key: "query", label: "query", type: "query" }]

  return (
    <Filter.Provider
      filters={keys}
      useQueryString={false}
      onChange={props.onChange}
    >
      <Filter.Template search={<SmartSearch onlySearch filters={keys} />} />
    </Filter.Provider>
  )
}
