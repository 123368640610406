export const getJobOptions = () =>
  [
    "Application Security",
    "DevOps",
    "DevSecOps",
    "CISO",
    "Cloud Security",
    "Security Architect",
    "Developer",
    "Architect",
    "Director of Security",
    "Head of Security",
    "Other"
  ].map((label) => ({ label, value: label }))
