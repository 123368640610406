import { NAMESPACE_DEFAULT_VALUE } from "@/const/default-values"
import { getIssuesCountMapOrderedSeverity } from "@/domain/vulnerability"
import {
  DEFAULT_LONG_FORMAT,
  getDateInUserLocale
} from "@/helpers/date.helpers"
import { getBooleanValuesFromInsights } from "@/helpers/insights.helper"
import { capitalizeFirstLetter } from "@/helpers/string.helpers"

import type { ApplicationResponse } from "@/api/useInventory.types"

export const getApplicationTabData = (response: ApplicationResponse) => {
  const { data, metadata } = response || {}

  return {
    metadata,
    data: data.map(({ data: dataRecord, id, displayName }, index) => {
      const { issuesSummary, insights } = dataRecord
      const operatingSystems = (dataRecord.operatingSystems || []).map(
        capitalizeFirstLetter
      )
      const issuesCounts = getIssuesCountMapOrderedSeverity(issuesSummary)
      return {
        key: id,
        displayName,
        ...dataRecord,
        namespace: dataRecord.namespace || NAMESPACE_DEFAULT_VALUE,
        operatingSystems: {
          id: `${id}_${index}`,
          items: operatingSystems,
          label: operatingSystems[0] || "",
          count: operatingSystems.length - 1
        },
        lastSeen: getDateInUserLocale(
          new Date(dataRecord.startedAt),
          DEFAULT_LONG_FORMAT
        ),
        severity: issuesCounts,
        insights: getBooleanValuesFromInsights(insights)
      }
    })
  }
}
