import { Button, Form } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"

import styles from "./SamlSignInForm.module.scss"

import type { SamlSignInFormProps } from "."

export const SamlSignInForm = ({ onClick }: SamlSignInFormProps) => {
  const { t } = useTranslation()

  return (
    <Form.Item className={styles.formItem}>
      <Button
        block
        size="large"
        onClick={onClick}
        data-testid="buttonSamlSignIn"
      >
        {t("auth.samlSignIn")}
      </Button>
    </Form.Item>
  )
}
