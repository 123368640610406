import { useRef } from "react"

import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { TEASERS } from "@/const/teaser.constants"
import { useInitialRender } from "@/hooks/useInitialRender"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { Filter } from "@/organisms/FilterNew/Filter"
import { InputSmartSearchLoading, SmartSearch } from "@/organisms/SmartSearch"
import { useContainerBaseImageInventory } from "@/pages/DiscoveryPage/application/container-base-image-inventory/useContainerBaseImageInventory"
import { TabTemplate } from "@/templates/TabTemplate"
import { getColumnsTable } from "./getColumnsTable"
import { getItemsFilter } from "./getItemsFilter"

import { isDataAvailable } from "@/helpers/context.helper"
import { ExportButton } from "@/molecules/ExportButton"
import type {
  ContainerBaseImagesTabProps,
  Record
} from "./ContainerBaseImagesTab.types"

export const ContainerBaseImagesTab: React.FC<ContainerBaseImagesTabProps> = (
  props
) => {
  const { t } = useTranslation()
  const { useActiveKeyTabsInventory } = props
  const { onSorterChange, ...orderByProps } = useOrderBy()
  const filterItems = useRef(getItemsFilter(t)).current
  const TAB_KEYS = TAB_KEYS_BY_PAGE.INVENTORY
  const { activeKey, config = {} } = useActiveKeyTabsInventory()
  const previewMode = !!config.headers
  const convertors = useNumberConvertor()
  const { onRowClick } = useDrawer("")
  const columns = getColumnsTable({
    sorter: orderByProps,
    t,
    convertors,
    previewMode,
    onRowClick
  })

  const context = useInitialRender(
    useContainerBaseImageInventory(filterItems, {
      enabled: activeKey === TAB_KEYS[3],
      ...config
    })
  )
  const { response, isLoading } = context
  const { data, metadata } = response || {}
  const keys = [{ key: "query", label: "query", type: "query" }]
  const dataReadinessFF = useFeatureFlags("dataReadiness")
  const filterDisabled = !!dataReadinessFF
  const disableExport = !isDataAvailable(context) || filterDisabled
  useTooltip(data)

  return (
    <TabTemplate<Record>
      resizable
      dataSource={data}
      metadata={metadata}
      columns={columns}
      loading={isLoading}
      locale={{
        teasers: {
          dataReadiness: true,
          kortexInstalled: TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(t)
        }
      }}
      SearchComponent={
        !previewMode && (
          <Filter.Provider keys={keys}>
            <Filter.Template
              isLoading={filterDisabled}
              aside={
                <ExportButton
                  isLoading={disableExport}
                  exportType="BaseImage"
                />
              }
            >
              {filterDisabled ? (
                <InputSmartSearchLoading />
              ) : (
                <SmartSearch
                  onlySearch
                  id="smart-search-container-base-tab-inventory"
                  filters={keys}
                />
              )}
            </Filter.Template>
          </Filter.Provider>
        )
      }
      onChange={(_pagination, _filters, sorter) => {
        onSorterChange(sorter)
      }}
      disableDrawer={previewMode}
    />
  )
}
