import { Button, Modal } from "@/atoms"
import { useModal } from "@/hooks/useModal"
import { IconText } from "@/molecules/IconText"
import { FixExampleButtonModal } from "./FixExampleButtonModal"

import styles from "./ButtonModal.module.scss"

import type { ButtonModalComponent } from "."

export const ButtonModal: ButtonModalComponent = (props) => {
  const { visible, show, hide } = useModal()
  const { children, Icon, label, buttonProps, modalProps } = props

  return (
    <>
      <Button type="link" onClick={show} {...buttonProps}>
        {Icon && <IconText Icon={Icon} text={label} />}
        {!Icon && <span>{label}</span>}
      </Button>
      <Modal
        open={visible}
        onCancel={hide}
        footer={null}
        className={styles.modal}
        {...modalProps}
      >
        {children}
      </Modal>
    </>
  )
}

ButtonModal.FixExample = FixExampleButtonModal
