import { AbstractTextHandler } from "./text-handler"

import type { PropsTextHandler, Token } from "./text-handler.types"

export class MultipleValueTextHandler extends AbstractTextHandler {
  public handle(token: Token, props: PropsTextHandler): string {
    const { addString } = props

    if (this.needAddValueToToken(props) && Array.isArray(addString)) {
      const { id, delimiter } = token
      const { currentTextLength, StringService } = props
      const result = `${id}${delimiter}${addString.join(",")},`

      StringService.setCaretPos(currentTextLength + result.length)

      return result
    }

    return super.handle(token, props)
  }
}
