import { Button } from "@/atoms"
import { useServices } from "@/hooks/useServices"
import { useTranslation } from "@/hooks/useTranslation"
import { MessageOutlined } from "@/icons"
import { MultiFactorFormTemplate } from "../../templates/MultiFactorFormTemplate"

import styles from "./VerificationMethodMultiFactorForm.module.scss"

import type { ActionMultiFactorFormTemplateProps } from "../../templates/MultiFactorFormTemplate"

export const VerificationMethodMultiFactorForm = ({
  onOk
}: ActionMultiFactorFormTemplateProps) => {
  const { auth } = useServices()
  const { t } = useTranslation()

  return (
    <MultiFactorFormTemplate
      title={t("auth.mfa.title")}
      description={`${t("auth.mfa.description")}:`}
      cancelText={t("general.skip")}
      classes={{ footer: styles.footer, title: styles.title }}
      onCancel={() => {
        auth.skipMultiFactorSetUp()
      }}
    >
      <Button
        className={styles.button}
        size="large"
        icon={<MessageOutlined />}
        onClick={onOk}
      >
        SMS
      </Button>
    </MultiFactorFormTemplate>
  )
}
