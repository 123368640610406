import type { TFunction } from "@/hooks/useTranslation"

type Property = string | undefined

export const isCustomProperty = (property: Property) =>
  property === "GitHub Custom Property"

export const isPropertyList = (property: Property) =>
  property === "GitHub Topic"

const keyPrefix = "settings.scopes.operators"

export const getAllOptions = (t: TFunction) => {
  return [
    { label: t(`${keyPrefix}.equalTo`), value: "Equals" },
    { label: t(`${keyPrefix}.startWith`), value: "Starts" },
    { label: t(`${keyPrefix}.endWith`), value: "Ends" },
    { label: t(`${keyPrefix}.contains`), value: "Contains" }
  ]
}

export const getCustomPropertyOptions = (t: TFunction) => {
  return [
    { label: t(`${keyPrefix}.equalTo`), value: "Equals" },
    { label: t(`${keyPrefix}.contains`), value: "Contains" }
  ]
}

export const getOperatorOptions = (t: TFunction, property: Property) => {
  if (isPropertyList(property)) {
    return getAllOptions(t).slice(0, 1)
  }
  if (isCustomProperty(property)) {
    return getCustomPropertyOptions(t)
  }

  return getAllOptions(t)
}
