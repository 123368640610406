import cn from "classnames"

import styles from "./TitleDrawer.module.scss"

import type { TitleDrawerProps } from "."

export const TitleDrawer = ({
  title,
  subTitle,
  aside,
  icon,
  loading,
  style,
  classes = {}
}: TitleDrawerProps) => {
  if (loading) {
    return <div className={cn(styles.loading)} />
  }

  const isLgSize = !!subTitle
  const classNameWithSubtitle = isLgSize && styles.withSubtitle
  const textSectionClassName = cn(styles.titleSection, classes.title)

  return (
    <div style={style} className={cn(styles.container, classNameWithSubtitle)}>
      {icon && (
        <span className={cn(styles.icon, classNameWithSubtitle)}>{icon}</span>
      )}
      <div className={textSectionClassName}>
        {title}
        {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
      </div>
      {aside && <div className={cn(styles.aside, classes.aside)}>{aside}</div>}
    </div>
  )
}
