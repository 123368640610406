import { Divider } from "@/atoms"
import {
  DEFAULT_LONG_FORMAT,
  getDateFromSeconds,
  getDateInUserLocale
} from "@/helpers/date.helpers"
import { useTranslation } from "@/hooks/useTranslation"
import { LabelTag } from "@/molecules/LabelTag"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"

import styles from "./MetaIssue.module.scss"

import type { Issue } from "@/domain/issue"

export const MetaIssue = ({ record }: { record: Issue }) => {
  const { t } = useTranslation()

  if (!record) return null

  const { discoveredAt } = record
  const emptyValue = " - "

  return (
    <div className={styles.container}>
      <div>
        <OverviewDescriptions.Label>{`${t(
          "general.type"
        )}:`}</OverviewDescriptions.Label>
        <OverviewDescriptions.Label>
          {t(`issueTypes.${record.issueType}`)}
        </OverviewDescriptions.Label>
      </div>
      <Divider type="vertical" />
      <div>
        <OverviewDescriptions.Label>{`${t(
          "general.discoveredAt"
        )}:`}</OverviewDescriptions.Label>
        <OverviewDescriptions.Label>
          {discoveredAt
            ? getDateInUserLocale(
                getDateFromSeconds(discoveredAt),
                DEFAULT_LONG_FORMAT
              )
            : emptyValue}
        </OverviewDescriptions.Label>
      </div>
      {!!record.labels?.length && (
        <>
          <Divider type="vertical" />
          <OverviewDescriptions.Label>{`${t(
            "settings.labels.title"
          )}:`}</OverviewDescriptions.Label>
          <div className={styles.labels}>
            <LabelTag labels={record.labels} type="line" issueId={record.id} />
          </div>
        </>
      )}
    </div>
  )
}
