import { useEffect, useState } from "react"

export const useAttributesObservable = (
  ref: React.MutableRefObject<HTMLElement | null>,
  attributes: string[],
  cb: (name: string) => any = (name: string) => ref.current?.getAttribute(name)
): [
  Record<(typeof attributes)[number], any>,
  { update: (name: string) => void }
] => {
  const [values, setValues] = useState(
    attributes.reduce(
      (acc, curr) => ({ ...acc, [curr]: cb(curr) }),
      {}
    ) as Record<string, any>
  )

  useEffect(() => {
    const callback = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        const { attributeName, oldValue } = mutation

        if (attributeName) {
          const nextValue = cb(attributeName)

          if (oldValue !== nextValue)
            setValues((prev) => ({ ...prev, [attributeName]: nextValue }))
        }
      }
    }

    const observer = new MutationObserver(callback)

    if (ref.current)
      observer.observe(ref.current, {
        attributeFilter: attributes,
        attributeOldValue: true
      })

    return () => {
      observer.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [
    values,
    {
      update: (name: string) =>
        setValues((prev) => ({ ...prev, [name]: cb(name) }))
    }
  ]
}
