import { Statistic, Text } from "@/atoms"
import { CaretDownOutlined, CaretUpOutlined } from "@/icons"

import styles from "./StatisticDifference.module.scss"

import type { StatisticDifferenceProps } from "."

export const StatisticDifference = (props: StatisticDifferenceProps) => {
  const {
    noTrend,
    isNeutral,
    isPositive,
    title,
    value,
    testId = "StatisticDifference"
  } = props

  let colorCssVariableName = isPositive
    ? "--exceptional-score"
    : "--unsatisfactory-score"

  if (isNeutral) colorCssVariableName = "--neutral-score"

  const PrefixComponent = isPositive ? CaretUpOutlined : CaretDownOutlined

  return (
    <Statistic
      className={styles.wrapStatisticDifference}
      title={
        <Text className={styles.title} data-testid={`${testId}-text`}>
          {title}
        </Text>
      }
      value={value}
      valueStyle={{
        color: `var(${colorCssVariableName})`,
        fontSize: "var(--md-font-size)"
      }}
      prefix={!noTrend && <PrefixComponent data-testid={`${testId}-prefix`} />}
    />
  )
}
