import { Select } from "@/atoms"
import { compareByDisabledAndLabel } from "@/helpers/compare.helper"
import type { MultiSelectProps, Option } from "."
import styles from "./MultiSelectWithDisabledOptions.module.scss"

export const MultiSelectWithDisabledOptions = (props: MultiSelectProps) => {
  const { options, ...rest } = props

  return (
    <Select<Option[], Option>
      className={styles.container}
      mode="multiple"
      maxTagCount={7}
      showSearch
      listHeight={200}
      rootClassName={styles.container}
      options={options}
      filterSort={compareByDisabledAndLabel}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      {...rest}
    />
  )
}
