import { useEffect, useState } from "react"

import { shallowEqualObjects } from "@/helpers/utils"
import { usePrevious } from "@/hooks/usePrevious"
import { ExportButton } from "@/molecules/ExportButton"
import { Filter } from "@/organisms/FilterNew/Filter"
import { KodemScoreFilter } from "@/organisms/FilterNew/KodemScoreFilter"
import { InputSmartSearchLoading, SmartSearch } from "@/organisms/SmartSearch"
import { useFiltersTriage } from "@/pages/TriagePage/application/useTriage"

import styles from "./FilterTriageTab.module.scss"

import type { Option } from "@/organisms/SmartSearch/types/options.types"
import type { FilterTriageTabProps } from "./FilterTriageTab.types"

export const FilterTriageTab = (props: FilterTriageTabProps) => {
  const [{ response }, isLoading] = useFiltersTriage()
  const [key, setKey] = useState<number>(Date.now())
  const { isDataAvailable } = props
  const disableExport = !isDataAvailable || isLoading

  const {
    issueType = [],
    issueStatus = [],
    allFilters = [],
    exploitMaturity = [],
    severity = [],
    fixability = [],
    label = []
  } = response || ({} as Record<string, Option[]>)

  const prevLabels = usePrevious(label)

  useEffect(() => {
    if (
      prevLabels?.length === label.length &&
      prevLabels.every((value, index) =>
        shallowEqualObjects(value, label[index])
      )
    ) {
      return
    }

    setKey(Date.now())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label])

  return (
    <Filter.Provider isLoading={isLoading}>
      <Filter.Template
        isLoading={isLoading}
        aside={<ExportButton isLoading={disableExport} exportType="Triage" />}
        search={
          isLoading ? (
            <InputSmartSearchLoading />
          ) : (
            <div className={styles.filteredOut}>
              <SmartSearch id="FilterTriage" key={key} filters={allFilters} />
            </div>
          )
        }
      >
        <Filter.Checkbox
          keyFilter="issueType"
          items={issueType}
          placeHolderDefaultValue="Issue Type"
          isLoading={isLoading}
        />
        <Filter.Checkbox
          keyFilter="issueStatus"
          items={issueStatus}
          placeHolderDefaultValue="Issue Status"
          isLoading={isLoading}
        />
        <Filter.Insights filters={allFilters} isLoading={isLoading} />
        <Filter.Checkbox
          isLoading={isLoading}
          keyFilter="exploitMaturity"
          items={exploitMaturity}
          placeHolderDefaultValue="Exploitability"
        />
        <KodemScoreFilter
          isLoading={isLoading}
          maxValue={1000}
          placeHolderDefaultValue="Score"
          prefix="Score ≥"
        />
        <Filter.Checkbox
          isLoading={isLoading}
          order="none"
          keyFilter="severity"
          items={severity}
          placeHolderDefaultValue="Severity"
        />
        <Filter.Checkbox
          isLoading={isLoading}
          order="none"
          keyFilter="fixability"
          items={fixability}
          placeHolderDefaultValue="Fixability"
        />
      </Filter.Template>
    </Filter.Provider>
  )
}
