import { Space, Spin } from "@/atoms"

import styles from "./Loader.module.scss"

export const Loader = () => (
  <div className={styles.wrapLoader}>
    <Space size="middle" className={styles.space}>
      <Spin size="large" />
    </Space>
  </div>
)
