import type { LabelsResponse } from "@/api/useLabels.types"

export const getLabelsSelector = (response: LabelsResponse) => {
  const { data = [], ...rest } = response

  return {
    data: data.map((label) => ({ key: label.id, ...label })),
    ...rest
  }
}
