import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import {
  decodeParams,
  encodeParams
} from "@/organisms/Drawers/applications/common/common"
import { DRAWERS_PARAM_NAME } from "../const"

import type { Values } from "@/organisms/Drawers/applications/common"

export const getOpenDrawersFromQueryString = (searchParams: URLSearchParams) =>
  searchParams.get(DRAWERS_PARAM_NAME)

export const getIdsDrawer = (searchParams: URLSearchParams) =>
  getOpenDrawersFromQueryString(searchParams)?.split(",") || []

export const getNextDrawerValue = (ids: string[], id: string) => {
  const drawerIds = ids.filter((value) => value[0] === id)

  if (!drawerIds.length) return `${id}`

  const number = drawerIds[drawerIds.length - 1].substring(1) || "0"

  return `${id}${parseInt(number) + 1}`
}

export const getActiveTabFieldName = (drawerId: string) => `${drawerId}_at`

export const useCustomMaskClass = () => {
  const [searchParams] = useSearchParams()
  const count = getIdsDrawer(searchParams).length

  return [count, count < 1 ? "mask-class-name-drawer-ks" : undefined] as const
}

export const useDrawer = (
  drawerId: string,
  setting: { filters?: string[] } = {}
) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const idsInDrawers = useMemo(() => {
    const ids = getIdsDrawer(searchParams)

    return ids.reduce(
      (acc, drawerId) => {
        const value = searchParams.get(drawerId)

        if (!value) return acc

        const id = decodeParams(value)?.record?.id

        if (!id) return acc

        acc[id] = drawerId

        return acc
      },
      {} as Record<string, string>
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOpenDrawersFromQueryString(searchParams)])

  const openByDrawerId = <V extends Values>(
    id: string,
    value: V,
    encodeFN: typeof encodeParams = encodeParams
  ) => {
    const ids = getIdsDrawer(searchParams)
    const nextId = getNextDrawerValue(ids, id)

    ids.push(nextId)

    if (setting.filters) {
      value.initialFilters = setting.filters
    }

    searchParams.set(DRAWERS_PARAM_NAME, ids.toString())
    searchParams.set(nextId, encodeFN(value))
    setSearchParams(searchParams)
  }

  const activeTabFieldName = getActiveTabFieldName(drawerId)

  const open = <V extends Values>(
    value: V,
    encodeFN: typeof encodeParams = encodeParams
  ) => {
    openByDrawerId(drawerId, value, encodeFN)
  }

  const close = () => {
    const nextD = getIdsDrawer(searchParams).filter(
      (value) => value !== drawerId
    )

    searchParams.delete(drawerId)
    searchParams.delete(activeTabFieldName)
    searchParams.delete(DRAWERS_PARAM_NAME)

    if (nextD.length) {
      searchParams.set(DRAWERS_PARAM_NAME, nextD.toString())
    }

    setSearchParams(searchParams)
  }

  const onRowClick = (record: { key: string }, id?: string) => {
    const value = { record: { id: record.key } }

    if (id) {
      const idsDrawer = getIdsDrawer(searchParams)
      const lastIndex = idsDrawer.length - 1
      const lastDrawerValue = searchParams.get(idsDrawer[lastIndex])

      if (!!lastDrawerValue) {
        const lastDrawerRecordId = decodeParams(lastDrawerValue)?.record?.id

        if (lastDrawerRecordId === `${record.key}`) {
          return
        }
      }

      return openByDrawerId(id, value)
    }

    open(value)
  }

  return {
    drawerId,
    activeTabFieldName,
    idsInDrawers,
    searchParams,
    setSearchParams,
    open,
    close,
    onRowClick
  }
}
