import { useEffect } from "react"

export const useClickOutsideSmartSearch = (ref: any, props: any) => {
  const { refInput, handlers } = props

  useEffect(() => {
    if (!ref?.current || !refInput?.current) {
      return
    }

    const fn = (event: MouseEvent) => {
      const elDiv = ref?.current
      const elInput = refInput?.current

      if (
        elDiv?.contains(event.target as Node) ||
        elInput?.contains(event.target as Node) ||
        (event?.target as any)?.getAttribute("data-smart-search")
      ) {
        return
      }

      if (document.activeElement?.id !== "input-smart-search") {
        handlers.onBlur()
      }
    }

    const EVENT = "click"

    window.addEventListener(EVENT, fn)

    return () => {
      window.removeEventListener(EVENT, fn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, refInput])
}
