import { SourceControlIcon } from "@/icons"
import { CopyLinkButton } from "@/molecules/CopyLinkButton"
import { TitleDrawer } from "@/molecules/TitleDrawer"
import { useBranchEntity } from "@/organisms/Drawers/applications/inventory/useInventory"
import { SkeletonTitle } from "@/organisms/Drawers/components/SkeletonTitle"
import { useLink } from "@/organisms/Drawers/hooks/useLink"

import type { EntityRecord } from "@/organisms/Drawers/applications/common"

export const TitleCodeRepositoryDrawer = (
  props: EntityRecord & { drawerId: string }
) => {
  const [context] = useBranchEntity(props?.record?.id)
  const { response, isLoading } = context || {}
  const { data } = response || {}
  const link = useLink(props.drawerId)

  if (isLoading || !data?.length) return <SkeletonTitle />

  const { sourceControl, repositoryName, branchName } = data[0]

  return (
    <TitleDrawer
      icon={<SourceControlIcon sourceControl={sourceControl} />}
      title={repositoryName}
      subTitle={`Branch: ${branchName || ""}`}
      aside={<CopyLinkButton link={link} />}
    />
  )
}
