import cn from "classnames"

import styles from "./DismissedTag.module.scss"

import type { RowContentComponent } from "."

export const RowContentDismissedTag: RowContentComponent = (props) => {
  const { label, children, textArea } = props

  return (
    <div
      className={cn(styles.rowContent, textArea && styles.textAreaRowContent)}
    >
      <span>{label}</span>
      <span>{children}</span>
    </div>
  )
}
