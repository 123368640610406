import type { TFunction } from "@/hooks/useTranslation"
import type { OptionFilter } from "@/organisms/SmartSearch/types/options.types"

export const getIssueTypeFilter = (t: TFunction): OptionFilter => ({
  key: "issueType",
  label: t("filters.issueType"),
  type: "multiple",
  helper: [
    {
      label: "issue-type:value1,value2",
      description: "Equals value1 OR value2"
    }
  ],
  options: []
})

export const getDismissReasonFilter = (t: TFunction): OptionFilter => ({
  key: "dismissReason",
  label: t("filters.dismissReason"),
  type: "input",
  helper: [
    {
      label: "Dismiss-Reason: value1",
      description:
        "Matches Dismiss Reason text as a substring (e.g. mitigated by)"
    }
  ]
})

export const buildQueryStringFromRecord = (
  params: Record<string, any>
): string => {
  const query = new URLSearchParams(params)

  return query.toString()
}
