import { Route, Routes } from "react-router-dom"

import { NAMES_ROUTES, PARAMS } from "@/const/routes.constants"
import { ScopePage } from "./components/pages/ScopePage"
import { SettingsPage } from "./components/pages/SettingsPage"

export const SettingsRouter = () => {
  const { SETTINGS } = NAMES_ROUTES
  const { SCOPES } = SETTINGS

  return (
    <Routes>
      <Route
        path={`/${SCOPES}/:${PARAMS.ID}/:${PARAMS.DUPLICATE_ID}?`}
        element={<ScopePage />}
      />
      <Route path="/:tab?/:needle?" element={<SettingsPage />} />
    </Routes>
  )
}
