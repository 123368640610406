import { Text, Title } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { useInvite } from "../../../applications/invite"
import { ExpiredMessage } from "../../molecules/ExpiredMessage"
import { Loader } from "../../molecules/Loader"
import { SelfForm } from "../SelfForm"

import styles from "./WelcomeCard.module.scss"

export const WelcomeCard = () => {
  const { t } = useTranslation()
  const [state, data, acceptInvite] = useInvite()

  if (state === "loading") {
    return <Loader />
  }

  if (state === "error") {
    return <ExpiredMessage />
  }

  const { email = "" } = data || {}

  return (
    <>
      <div className={styles.container}>
        <Title level={3} className={styles.title}>
          {`${t("general.welcomeGreat")}!`}
        </Title>
        <Text>{email}</Text>
        <Text>{t("auth.welcomeTitle")}</Text>
      </div>
      <SelfForm
        onSubmit={(value: any) => {
          const {
            firstName: first_name,
            lastName: last_name,
            job: job_description
          } = value

          acceptInvite({
            first_name,
            last_name,
            job_description,
            user_email: email
          })
        }}
      />
    </>
  )
}
