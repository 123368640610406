import { Col } from "@/atoms"
import { LinkCard } from "../../molecules/LinkCard"

import type { StatisticCardTemplateProps } from "."

const StatisticCardTemplate = (props: StatisticCardTemplateProps) => {
  const { children, footer, to, selected, className, ...rest } = props

  return (
    <Col {...rest}>
      <LinkCard to={to} selected={selected} className={className}>
        {children}
      </LinkCard>
      {!!footer && footer}
    </Col>
  )
}

export default StatisticCardTemplate
