import { useEffect, useState } from "react"

import { Spin } from "@/atoms"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useTranslation } from "@/hooks/useTranslation"

import styles from "./TotalPagination.module.scss"

import React from "react"
import type { TotalPaginationComponent } from "."

export const TotalPagination: TotalPaginationComponent = (props) => {
  const convertors = useNumberConvertor()
  const { t } = useTranslation()
  const { totals, isLoading } = props
  const [showSpin, setShowSpin] = useState(isLoading)

  useEffect(() => {
    setShowSpin(isLoading)
  }, [isLoading])

  if (showSpin)
    return <Spin className="ant-spin-default-style" size="small" spinning />

  return (
    <div className={styles.container}>
      {totals.map(
        (element, index) =>
          typeof element.value === "number" && (
            <React.Fragment key={element.translationKey}>
              {index > 0 && <span>|</span>}
              <span>
                {convertors.intlNumber(element.value)}
                <span className={styles.text}>
                  {t(`${element.translationKey}`, { count: element.value })}
                </span>
              </span>
            </React.Fragment>
          )
      )}
    </div>
  )
}
