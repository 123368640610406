import { FiltersContext } from "@/hooks/useFilters"
import { useMemo, useState } from "react"
import { CheckboxFilter } from "../CheckboxFilter"
import { DatePickerFilter } from "../DatePicker"
import { useSearchQueryProxy } from "../hooks/useSearchQueryProxy"
import { InsightsFilter } from "../InsightsFilter"
import { KodemScoreFilter } from "../KodemScoreFilter"
import { TemplateFilter } from "../TemplateFilter"

const Provider = ({
  initialState,
  children,
  onChange: onChangeCb,
  useQueryString = true
}: any) => {
  const [hash, setHash] = useState(0)
  const [filters, setFilters] = useState<Record<string, any>>({})
  const [searchParams, setSearchParams] = useSearchQueryProxy({
    initialState,
    useQueryString,
    onChangeCb
  })

  const value = useMemo(() => {
    return {
      hash,
      setHash,
      searchParams,
      setSearchParams,
      filters,
      setFilters,
      onChange: (data: { name: string; value: any }) => {
        const { name, value } = data

        if (name && value !== undefined) {
          setFilters((prev) => ({ ...prev, [name]: value }))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setSearchParams, hash])

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  )
}

export const Filter = {
  Provider,
  Checkbox: CheckboxFilter,
  Insights: InsightsFilter,
  KodemScore: KodemScoreFilter,
  DatePicker: DatePickerFilter,
  Template: TemplateFilter
}
