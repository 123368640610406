import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useCreate } from "@/hooks/useHttp"

export const useTenantSignup = <T = unknown, S = T>() => {
  const context = useCreate<T, S>(`${RESOURCES_NAMES.TENANTS.SIGNUP}`)

  return context
}

export const useGcpTokenValidation = <T = unknown, S = T>() => {
  const context = useCreate<T, S>(
    `${RESOURCES_NAMES.TENANTS.GCP_TOKEN_VALIDATION}`
  )

  return context
}
