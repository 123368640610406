import { Col, Row } from "@/atoms"
import { PageTemplate } from "@/templates/PageTemplate"
import { HeaderDashboardPage } from "./components/molecules/HeaderDashboardPage/HeaderDashboardPage"
import { FunnelDashboard } from "./components/organisms/FunnelDashboard"
import { InventorySnapshotWidget } from "./components/organisms/InventorySnapshotWidget"
import PackagesOverTime from "./components/organisms/PackagesOverTime"
import PackagesUseWidget from "./components/organisms/PackagesUseWidget"
import PostureScoreWidget from "./components/organisms/PostureScoreWidget"
import styles from "./DashboardPage.module.scss"
import useDashboard from "./hooks/useDashboard"

export const DashboardPage = () => {
  const {
    postureProps,
    inventorySnapshotProps,
    packagesInUseProps,
    funnelProps
  } = useDashboard()

  return (
    <PageTemplate header={<HeaderDashboardPage />}>
      <Row gutter={16} className={styles.firstSection}>
        <Col lg={13} sm={24}>
          <PostureScoreWidget {...postureProps} />
        </Col>
        <Col lg={11} sm={24}>
          <InventorySnapshotWidget {...inventorySnapshotProps} />
        </Col>
      </Row>
      <Row gutter={16} className={styles.secondSection}>
        <Col span={24}>
          <FunnelDashboard {...funnelProps} />
        </Col>
      </Row>
      <Row gutter={16} className={styles.thirdSection}>
        <Col xxl={7} xl={7} lg={7} sm={24}>
          <PackagesUseWidget {...packagesInUseProps} />
        </Col>
        <Col xxl={17} xl={17} lg={17} sm={24}>
          <PackagesOverTime {...postureProps} />
        </Col>
      </Row>
    </PageTemplate>
  )
}
