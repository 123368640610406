import { TEASERS } from "@/const/teaser.constants"
import { useTranslation } from "@/hooks/useTranslation"
import { PiePackagesUseWidget } from "../../atoms/PiePackagesUseWidget"
import StatisticInk from "../../molecules/StatisticInk/StatisticInk"
import CardDashboardTemplate from "../../templates/CardDashboardTemplate"

import styles from "./PackagesUseWidget.module.scss"

import type { PackagesUseWidgetProps } from "."

const PackagesUseWidget = (props: PackagesUseWidgetProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "dashboard.packagesUseWidget"
  })
  const { t: tTeaser } = useTranslation()
  const { response, isLoading, unavailable } = props
  const { inUseTotal, notInUseTotal } = response || {}
  const noData = inUseTotal === 0 && notInUseTotal === 0

  return (
    <CardDashboardTemplate
      loading={isLoading}
      unavailable={unavailable}
      noData={noData}
      title={t("title")}
      gutter={[8, 16]}
      className={{
        card: styles.card,
        row: !noData && !unavailable ? styles.row : undefined
      }}
      teasers={{
        dataReadiness: true,
        kortexInstalled:
          TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(tTeaser)
      }}
    >
      <div className={styles.pieContainer}>
        <PiePackagesUseWidget {...props} />
      </div>
      <div className={styles.legend}>
        <StatisticInk
          dot
          color="var(--daybreak-blue-5-color)"
          title={`${t("inUse")} - `}
          value={inUseTotal}
        />
        <StatisticInk
          dot
          color="var(--neutral-5-color)"
          title={`${t("notInUse")} - `}
          value={notInUseTotal}
        />
      </div>
    </CardDashboardTemplate>
  )
}

export default PackagesUseWidget
