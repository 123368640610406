import { TFunction, Trans } from "@/hooks/useTranslation"

export const TitleScoreCard = ({
  score,
  t
}: {
  score: number
  t: TFunction
}) => {
  return <Trans>{t("triage.drawer.scoreIs", { score })}</Trans>
}
