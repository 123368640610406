import { useTranslation } from "@/hooks/useTranslation"
import { useRef } from "react"

type Value = number | string

export type NumberConvertor = ReturnType<typeof useNumberConvertor>

/**
 * @name abbreviateNumber
 * @function
 * Abbreviates a given numerical value to make it more readable in a compact notation format.
 *
 * @param {number} value - The numerical value to abbreviate.
 *
 * @returns {string} The abbreviated value as a localized string in a compact notation format.
 *
 * @example
 * useAbbreviateNumber(1250000) // returns "1.3M"
 *
 * @remarks
 * This function relies on the `useTranslation` hook to format the numerical value as a localized string.
 * The output is in a compact notation format that abbreviates large numbers using "k" and "M" notations.
 * The `maximumFractionDigits` parameter is used to limit the number of decimal digits in the output.
 *
 * @typedef {Object} useNumberConvertor
 * @property {abbreviateNumber} abbreviateNumber - Abbreviates a given numerical value to make it more readable in a compact notation format.
 * @property {intlNumber} intlNumber - Formats a given numerical value as a localized string.
 */

export const useNumberConvertor = ({
  showPlaceholder
}: {
  showPlaceholder?: boolean
} = {}) => {
  const { t } = useTranslation()

  const convertors = useRef({
    t,
    intlNumber: (value: Value) =>
      t("{{val, number}}", { val: value.toString() }),
    abbreviateNumber: (value: Value) =>
      t("{{val, number}}", {
        notation: "compact",
        maximumFractionDigits: 1,
        val: value.toString()
      })
  }).current

  if (showPlaceholder) {
    return { ...convertors, abbreviateNumber: () => 0 }
  }

  return convertors
}
