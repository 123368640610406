import * as Config from "@/config"
import { addScript, isScriptExist, removeScript } from "@/helpers/script.helper"
import { useEffect, useState } from "react"
import { RecaptchaProps } from "./useRecaptcha.types"

export const useRecaptcha = (props: RecaptchaProps) => {
  const scriptId = "recaptcha"
  const containerId = "recaptcha-container"
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false)
  const getRecaptcha = () => (window as any).grecaptcha?.enterprise

  const reset = () => getRecaptcha()?.reset()
  const getToken = () => getRecaptcha()?.getResponse()
  const onLoad = () => {
    const siteKey = Config.RECAPTCHA_SITE_KEY
    const recaptcha = getRecaptcha()
    if (!recaptcha) {
      setIsRecaptchaLoaded(false)
      return
    }
    setIsRecaptchaLoaded(true)
    recaptcha.ready(() => {
      recaptcha.render(containerId, {
        sitekey: siteKey,
        size: props.size,
        action: props.userAction
      })
    })
  }

  useEffect(() => {
    if (isScriptExist(scriptId)) return onLoad()

    const options = {
      src: "https://www.google.com/recaptcha/enterprise.js",
      defer: true,
      async: true
    }

    addScript(scriptId, options, onLoad)

    return () => {
      try {
        removeScript(scriptId)
      } catch {
        throw new Error("Failed to remove recaptcha script")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return { reset, getToken, isRecaptchaLoaded }
}
