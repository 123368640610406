export const getDefaultValues = (initialValues?: { environment?: string }) => {
  const { environment = "environment" } = initialValues || {}

  return {
    namespace: "kodem",
    environment: environment,
    deploymentKey: "deployment_key",
    pullSecretName: "kortex-regcred",
    key: Date.now().toString()
  }
}

export const getDeployCommand = (
  values: ReturnType<typeof getDefaultValues>,
  defaultValues: ReturnType<typeof getDefaultValues>,
  version: string
) => {
  const firstLine = `helm upgrade --install kortex kortex-${version}.tgz \\`
  const secondLine = `--namespace ${values.namespace} \\`
  const thirdLine = `--set kortex.environment=${getTextForValue(
    values.environment,
    defaultValues.environment
  )},\\`
  const fourthLine = `kortex.deploymentKey=${getTextForValue(
    values.deploymentKey,
    defaultValues.deploymentKey
  )},\\`
  const fifthLine = `kortex.image.pullSecretName=${getTextForValue(
    values.pullSecretName,
    defaultValues.pullSecretName
  )} \\`
  const sixthLine = `--create-namespace`

  return `${firstLine}\n${secondLine}\n${thirdLine}\n${fourthLine}\n${fifthLine}\n${sixthLine}`
}

export const getTextForValue = (value: string, defaultValue: string) =>
  value === defaultValue ? `[${value}]` : value
