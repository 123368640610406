import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useCreate, useHttp } from "@/hooks/useHttp"

import type { Config } from "@/hooks/useHttp"
import type { OnboardingResponse } from "./useOnboarding.types"

export const useOnboardingStatus = <T = OnboardingResponse, S = T>(
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    RESOURCES_NAMES.ONBOARDING.STATUS,
    undefined,
    config
  )

  return context
}

export const useDismissOnboardingDismiss = <T = unknown, S = T>() => {
  const context = useCreate<T, S>(
    RESOURCES_NAMES.ONBOARDING.DISMISS_ONBOARDING,
    undefined
  )

  return context
}

export const useDismissWelcomeScreen = <T = unknown, S = T>() => {
  const context = useCreate<T, S>(
    RESOURCES_NAMES.ONBOARDING.DISMISS_WELCOME,
    undefined
  )

  return context
}
