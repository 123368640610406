import { EC2 } from "@/icons"
import { CopyLinkButton } from "@/molecules/CopyLinkButton"
import { TitleDrawer } from "@/molecules/TitleDrawer"
import { useVirtualMachineEntity } from "@/organisms/Drawers/applications/inventory/useInventory"
import { SkeletonTitle } from "@/organisms/Drawers/components/SkeletonTitle"
import { useLink } from "@/organisms/Drawers/hooks/useLink"

import styles from "./TitleVirtualMachineDrawer.module.scss"

import type { EntityRecord } from "@/organisms/Drawers/applications/common"

export const TitleVirtualMachineDrawer = (
  props: EntityRecord & { drawerId: string }
) => {
  const context = useVirtualMachineEntity(props.record?.id)
  const [{ response, isLoading }] = context || {}
  const { data } = response || {}
  const link = useLink(props.drawerId)

  if (isLoading || !data) return <SkeletonTitle />

  const { data: vm } = (data as any) || ({} as any)
  const { instanceId, autoScalingGroups } = vm || {}

  return (
    <TitleDrawer
      icon={<EC2 className={styles.icon} />}
      title={instanceId}
      subTitle={(autoScalingGroups || []).join(", ") || " "}
      aside={<CopyLinkButton link={link} />}
    />
  )
}
