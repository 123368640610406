import {
  GetUseMutationOptions,
  Updater,
  useCreate,
  useDelete,
  useHttp,
  useUpdate
} from "@/hooks/useHttp"

import { RESOURCES_NAMES } from "@/const/resource.constants"
import type { Config, Params } from "@/hooks/useHttp"
import type { PoliciesResponse, Policy } from "./usePolicies.types"

export const useGetPolicies = <T = PoliciesResponse, S = T>(
  params?: Params<{ needle?: string; pageSize?: number; query?: string }>,
  config?: Config<T, S>
) => {
  return useGetPoliciesHook(
    RESOURCES_NAMES.SETTINGS.POLICIES.LIST,
    params,
    config
  )
}

export const useGetCIPolicies = <T = PoliciesResponse, S = T>(
  params?: Params<{ needle?: string; pageSize?: number; query?: string }>,
  config?: Config<T, S>
) => {
  return useGetPoliciesHook(
    RESOURCES_NAMES.SETTINGS.CI_POLICIES.LIST,
    params,
    config
  )
}

const useGetPoliciesHook = <T = PoliciesResponse, S = T>(
  baseUrl: string,
  params?: Params<{ needle?: string; pageSize?: number; query?: string }>,
  config?: Config<T, S>
) => {
  const { needle, ...restParams } = params || {}
  const nextParam = Object.keys(restParams).length ? restParams : undefined
  const url = needle ? `${baseUrl}/${needle}` : baseUrl
  const context = useHttp<T, S>(url, nextParam, config)

  return context
}

export const useCreatePolicy = <T = PoliciesResponse, S = { policy: Policy }>(
  params?: Params,
  updater?: Updater<T, S>,
  config?: GetUseMutationOptions<T, S>
) => {
  const context = useCreate<T, S>(
    RESOURCES_NAMES.SETTINGS.POLICIES.LIST,
    params,
    updater,
    config
  )

  return context
}

export const useCreateCIPolicy = <T = PoliciesResponse, S = { policy: Policy }>(
  params?: Params,
  updater?: Updater<T, S>,
  config?: GetUseMutationOptions<T, S>
) => {
  const context = useCreate<T, S>(
    RESOURCES_NAMES.SETTINGS.CI_POLICIES.LIST,
    params,
    updater,
    config
  )

  return context
}

export const useUpdatePolicy = <
  T = PoliciesResponse & { policyType: string },
  S =
    | { needle: string; data: { policy: Pick<Policy, "id" | "enabled"> } }
    | { policies: Pick<Policy, "id" | "enabled">[] }
>(
  params?: Params,
  updater?: Updater<T, S>,
  config?: GetUseMutationOptions<T, S>
) => {
  const context = useUpdate<T, S>(
    RESOURCES_NAMES.SETTINGS.POLICIES.LIST,
    params,
    updater,
    config
  )

  return context
}

export const useUpdateCIPolicy = <
  T = PoliciesResponse,
  S =
    | { needle: string; data: { policy: Pick<Policy, "id" | "enabled"> } }
    | { policies: Pick<Policy, "id" | "enabled">[] }
>(
  params?: Params,
  updater?: Updater<T, S>,
  config?: GetUseMutationOptions<T, S>
) => {
  const context = useUpdate<T, S>(
    RESOURCES_NAMES.SETTINGS.CI_POLICIES.LIST,
    params,
    updater,
    config
  )

  return context
}

export const useDeletePolicy = <T, S>(
  params?: Params,
  updater?: Updater<T, S>,
  config?: GetUseMutationOptions<T, S>
) => {
  const context = useDelete(
    RESOURCES_NAMES.SETTINGS.POLICIES.LIST,
    params,
    updater,
    config
  )

  return context
}

export const useDeleteCIPolicy = <T, S>(
  params?: Params,
  updater?: Updater<T, S>,
  config?: GetUseMutationOptions<T, S>
) => {
  const context = useDelete(
    RESOURCES_NAMES.SETTINGS.CI_POLICIES.LIST,
    params,
    updater,
    config
  )

  return context
}

export const useConditionsOptions = <T, S>(
  params: Params,
  config?: Config<T, S>
) => {
  const context = useHttp(
    RESOURCES_NAMES.SETTINGS.POLICIES.CONDITIONS.OPTIONS,
    params,
    config
  )

  return context
}

export const useCIConditionsOptions = <T, S>(
  params: Params<{ policyType: string }>,
  config?: Config<T, S>
) => {
  const context = useHttp(
    RESOURCES_NAMES.SETTINGS.CI_POLICIES.CONDITIONS.OPTIONS,
    params,
    config
  )

  return context
}

export const useScopeOptions = <T, S>(config?: Config<T, S>) => {
  const context = useHttp(
    RESOURCES_NAMES.SETTINGS.POLICIES.SCOPE.OPTIONS,
    undefined,
    config
  )

  return context
}
