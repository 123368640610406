import { ScoreExplanationProps } from "@/api/useTriage.types"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"
import { getItems } from "./getItems"

export const ScoreExplanation = ({
  record,
  flex,
  bordered = true
}: {
  record: ScoreExplanationProps
  bordered?: boolean
  flex?: boolean
}) => {
  const { t } = useTranslation()
  const internetFacingFF = useFeatureFlags("internetFacingIntegration")

  return (
    <OverviewDescriptions
      flex={flex}
      bordered={bordered}
      column={1}
      layout="horizontal"
      items={getItems(record, { t, internetFacingFF })}
    />
  )
}
