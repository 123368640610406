import { AbstractTextHandler } from "./text-handler"

import type { PropsTextHandler, Token } from "./text-handler.types"

export class SingleValueTextHandler extends AbstractTextHandler {
  public handle(token: Token, props: PropsTextHandler): string {
    const { addString, currentTextLength, StringService } = props

    if (
      this.needAddValueToToken(props) &&
      !Array.isArray(addString) &&
      StringService.isCurrentCaretPosInValue()
    ) {
      const { id, delimiter } = token
      const isNextSpace = this.getNextToken(props)?.isEmpty
      const suffix = isNextSpace ? "" : "\u00A0"
      const result = `${id}${delimiter}${addString}${suffix}`
      let caretPos = currentTextLength + result.length

      if (isNextSpace) {
        caretPos += 1
      }

      StringService.setCaretPos(caretPos)

      return `${id}${delimiter}${addString}${suffix}`
    }

    return super.handle(token, props)
  }
}
