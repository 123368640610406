import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useHttp } from "@/hooks/useHttp"

import type { Config, Params } from "@/hooks/useHttp"
import type { PostureResponse } from "./usePosture.types"

export const useGetPosture = <T = PostureResponse, S = T>(
  params: Params = {},
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(RESOURCES_NAMES.POSTURE, params, config)

  return context
}
