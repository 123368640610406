import { matchPath, useLocation, useSearchParams } from "react-router-dom"

import { REDIRECT_URL_PARAM_AUTH } from "@/const/provider-auth.constants"

export const useKeyByParams = () => {
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()
  const redirectUrl = searchParams.get(REDIRECT_URL_PARAM_AUTH)
  const pathnameMatch = !!redirectUrl ? redirectUrl.split("?")[0] : pathname

  const { first = "" } =
    matchPath({ path: "/:first", end: false }, pathnameMatch)?.params || {}
  const { second = "" } =
    matchPath({ path: `${first}/:second`, end: false }, pathnameMatch)
      ?.params || {}

  return !first && !second ? "/" : `/${first}${second ? `/${second}` : ""}`
}
