import { useState } from "react"
import { Navigate, useParams } from "react-router-dom"

import { useForgotPassword } from "@/api/useAuth"
import { Alert, Button, Form, Input, Link, Text, Title } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import { UserOutlined } from "@/icons"

import styles from "./ForgotPasswordForm.module.scss"

export const ForgotPasswordForm = ({
  initialState = { success: false, error: false }
}) => {
  const params = useParams()
  const { logger } = useLogger()
  const [state, setState] = useState(initialState)
  const slug = params?.slug
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { mutateAsync, isPending: isLoading } = useForgotPassword()
  const onFinish = async (values: { email: string }) => {
    try {
      await mutateAsync({ user_email: values.email, slug: params.slug })
      setState({ success: true, error: false })
    } catch (err: any) {
      logger.error(err)
      setState({ success: false, error: true })
    }
  }

  if (!slug) {
    return <Navigate to="/sign" />
  }

  return (
    <Form className={styles.wrap} form={form} onFinish={onFinish}>
      <Title className={styles.title} level={4}>
        {t("auth.forgotPassword.title")}
      </Title>
      <Text className={styles.description}>
        {t("auth.forgotPassword.description")}
      </Text>
      <Form.Item
        name="email"
        rules={[
          { type: "email", message: t("error.emailInvalid") || "" },
          { required: true, message: t("error.emailRequired") || "" }
        ]}
        validateTrigger={["onBlur"]}
      >
        <Input
          placeholderAlign="center"
          placeholder={t("auth.forgotPassword.placeHolder")}
          prefix={<UserOutlined />}
          type="email"
          size="large"
        />
      </Form.Item>
      {state.success && (
        <span className={styles.successMessage}>
          <Text>{t("auth.forgotPassword.successMessage.1")}</Text>
          <Text>{t("auth.forgotPassword.successMessage.2")}</Text>
          <Text>{t("auth.forgotPassword.successMessage.3")}</Text>
        </span>
      )}
      {state.error && (
        <Alert
          className={styles.errorMessage}
          type="error"
          message={t("error.serverError")}
          banner
        />
      )}
      <Button
        block
        loading={isLoading}
        type="ks"
        htmlType="submit"
        size="large"
      >
        {t("general.send")}
      </Button>
      {!state.success && (
        <Text className={styles.backLink}>
          {`${t("auth.forgotPassword.goBack")} `}
          <Link to={`/${slug}/signin`}>{t("auth.signIn")}</Link>
        </Text>
      )}
    </Form>
  )
}
