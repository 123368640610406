import { Form, Input } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { BorderlessTableOutlined } from "@/icons"

import type { ConfirmCodeInputProps } from "."

export const ConfirmCodeInput = ({
  name,
  inputRef,
  onKeyDown
}: ConfirmCodeInputProps) => {
  const { t } = useTranslation()

  return (
    <Form.Item
      name={name}
      rules={[
        {
          required: true,
          message: t("error.requiredField") || ""
        },
        {
          message: t("auth.mfa.errors.inter-valid-code"),
          validator: (_, value) => {
            const pattern = /^[0-9]{6}$/

            if (pattern.test(value)) {
              return Promise.resolve()
            } else {
              return Promise.reject(t("auth.mfa.errors.inter-valid-code"))
            }
          }
        }
      ]}
    >
      <Input
        ref={inputRef}
        size="large"
        placeholder={t("auth.mfa.verify.placeholder")}
        prefix={<BorderlessTableOutlined />}
        data-testid="codeMultiFactorFormInput"
        onKeyDown={onKeyDown}
      />
    </Form.Item>
  )
}
