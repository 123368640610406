import cn from "classnames"
import { useState } from "react"

import { Button, Card, Text, Tooltip } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { CloudSync, SyncOutlined } from "@/icons"
import { CopyButton } from "@/molecules/CopyButton"

import styles from "./DeployCommand.module.scss"

import type { DeployCommandProps } from "."

export const DeployCommand = (props: DeployCommandProps) => {
  const { children, getDefaultValues, noError, classes = {} } = props
  const { t } = useTranslation()
  const [values, setValues] = useState(getDefaultValues(props))
  const [error, setError] = useState(!noError)

  const onChange = ({ name, value }: any) => {
    setValues({ ...values, [name]: value })
  }
  const onError = () => setError(true)

  return (
    <Card
      className={cn(styles.container, classes.container, error && styles.error)}
    >
      {!error && (
        <>
          <div className={styles.content}>
            {children({ values, onChange, onError })}
          </div>
          <div className={styles.buttons}>
            <Tooltip title={t("general.reset")}>
              <Button
                icon={<SyncOutlined />}
                size="small"
                onClick={() => {
                  setValues(getDefaultValues())
                }}
              />
            </Tooltip>
            <CopyButton
              type="primary"
              text={props.getDeployCommand(
                values,
                getDefaultValues(),
                props.version
              )}
            >
              {t("general.copy")}
            </CopyButton>
          </div>
        </>
      )}
      {error && (
        <>
          <CloudSync className={styles.icon} />
          <Text className={styles.text}>
            {t("deployments.error.loadError")}
          </Text>
        </>
      )}
    </Card>
  )
}
