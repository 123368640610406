import { Col, Divider, Flex, Row } from "@/atoms"
import {
  DEFAULT_LONG_FORMAT,
  getDateFromSeconds,
  getDateInUserLocale
} from "@/helpers/date.helpers"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { Heading } from "@/molecules/Heading"
import { IssuesSummaryFC } from "@/molecules/IssuesSummaryFC"
import { usePackageDescribe } from "@/organisms/Drawers/applications/inventory/useInventory"
import { splitPackageIdFromDrawerRecord } from "@/organisms/Drawers/applications/inventory/useInventory.selectors"
import { InsightsCard } from "../InsightsCard"
import { MetaRow } from "../MetaRow"
import { SummaryCard } from "../SummaryCard"
import { VulnerabilitiesTab } from "../VulnerabilitiesTab"

import type { TabComponentProps } from "@/organisms/Drawers/components/TabsDrawerTemplate"

export const OverviewTabPackageDrawer = (props: TabComponentProps) => {
  const values = splitPackageIdFromDrawerRecord(props)
  const { response, isLoading } = usePackageDescribe(values)
  const { data } = response || {}
  const convertors = useNumberConvertor()

  if (isLoading || !data) return null

  const { issues, lastSeen } = data
  const items = [
    { label: "Type:", children: "Open Source Package" },
    {
      label: "Last Seen:",
      children: getDateInUserLocale(
        getDateFromSeconds(lastSeen),
        DEFAULT_LONG_FORMAT
      )
    }
  ]

  return (
    <Flex vertical gap={18} className="body-tab-drawer-ks">
      <MetaRow items={items} />
      <Row gutter={20}>
        <Col span={12}>
          <SummaryCard data={data} />
        </Col>
        <Col span={12}>
          <InsightsCard data={data} />
        </Col>
      </Row>
      <Divider className="m-0-divider-ks" />
      <Flex vertical>
        <Flex gap={16}>
          <Heading level={6} title="Package Vulnerabilities" />
          {issues.length > 0 && (
            <IssuesSummaryFC
              noFixWidth
              convertors={convertors}
              issues={issues}
            />
          )}
        </Flex>
        <VulnerabilitiesTab {...values} {...props} />
      </Flex>
    </Flex>
  )
}
