import { HeaderPage } from "@/organisms/HeaderPage"
import { ContainerBaseImagesTab } from "@/pages/DiscoveryPage/components/organisms/ContainerBaseImagesTab"
import { useActiveKeyTabsInventory } from "@/pages/DiscoveryPage/hooks/useActiveKeyTabsInventory"
import { PageTemplate } from "@/templates/PageTemplate"

export const BaseImagesPage = () => (
  <PageTemplate
    full
    header={<HeaderPage titleTranslationKey="navigation.baseImages" />}
  >
    <ContainerBaseImagesTab
      useActiveKeyTabsInventory={useActiveKeyTabsInventory}
    />
  </PageTemplate>
)
