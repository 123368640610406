import { FilterService } from "../FilterService"

import { TokenHandler } from "./handlers/token/create.token-handler"
import { ErrorHandler } from "./handlers/token/error.token-handler"
import { TrailingCommaHandler } from "./handlers/token/trailing-comma.token-handler"

import { KeyTextHandler } from "./handlers/text/key.text-handler"
import { MultipleValueTextHandler } from "./handlers/text/multiple-value.text-handler"
import { SingleValueTextHandler } from "./handlers/text/single-value.text-handler"

import * as cursorHelpers from "./helpers/cursor.helpers"

import type { IndexInToken, RefDivElement, Token } from "."

export class StringService {
  protected TOKENS_DELIMITER = ","
  protected SPACE_REPLACEMENT = "@-&nbsp;-@"

  private refInput: React.RefObject<HTMLDivElement>
  private caretPos: number = 0
  private dataByCaretPos: [number, IndexInToken, string] = [-1, -1, ""] //first values is token // 0 - cursor in label, 1 - cursor in value
  private text: string
  private tokens: Token[] = []
  private FilterService: FilterService
  private refFilterCounter: [RefDivElement, string] = [null, ""]

  constructor(
    ref: React.RefObject<HTMLDivElement>,
    FilterService: FilterService,
    { initialText = "", caretPos = 0 } = {}
  ) {
    this.refInput = ref
    this.text = initialText
    this.caretPos = caretPos
    this.tokenizer(caretPos)
    this.FilterService = FilterService
  }

  public isEmptyString(str: undefined | string) {
    return !str || str.trim().length === 0
  }

  public getCursorPosition() {
    return cursorHelpers.getCursorPosition(this.refInput)
  }

  public setCursorPosition(caretPos: number = this.caretPos) {
    cursorHelpers.setCursorPosition(this.refInput, caretPos)

    return this
  }

  public getTokens() {
    return this.tokens
  }

  public getText() {
    return this.text
  }

  public setCursorAtTheEnd() {
    this.caretPos = this.text.length
    this.setCursorPosition()

    return this
  }

  public isCaretPosInToken({ startCaretPos, endCaretPos }: Token) {
    return this.caretPos > startCaretPos && this.caretPos <= endCaretPos
  }

  public getDataByCaretPos() {
    return this.dataByCaretPos
  }

  public getTextByCaretPos() {
    return this.dataByCaretPos[2]
  }

  public getCaretPos() {
    return this.caretPos
  }

  public setCaretPos(caretPos: number) {
    this.caretPos = caretPos
  }

  public setText(text: string) {
    this.text = text

    return this
  }

  public setTokens(tokens: Token[]) {
    this.tokens = tokens

    return this
  }

  public getIds() {
    return this.tokens.map((token) => token.id)
  }

  public getNotEmptyTokens() {
    return this.tokens.filter(({ str }) => !this.isEmptyString(str))
  }

  public updateCaretPosAndData(caretPos?: number) {
    this.caretPos = caretPos ?? this.getCursorPosition()
    this.updateDataByCaretPos()

    return this
  }

  public isValidStringsWithQuote(text = this.text) {
    return (text.match(/"/g) || []).length % 2 === 0
  }

  public tokenizer(caretPos?: number) {
    this.caretPos = caretPos ?? this.getCursorPosition()

    let offset = 0
    let text = this.text
    let lastIndex = text.lastIndexOf('"')
    let lastIndexExist = lastIndex !== -1
    const isValidStringsWithQuote = this.isValidStringsWithQuote()

    if (!isValidStringsWithQuote && lastIndexExist) {
      text = text.slice(0, lastIndex) + text.slice(lastIndex + 1)
    }

    text = text.replace(
      /\s(?=(?:(?:[^"]*"){2})*[^"]*"[^"]*$)/g,
      this.SPACE_REPLACEMENT
    )

    const errorHandler = new ErrorHandler()
    const tokenHandler = new TokenHandler()
    const trailingCommaHandler = new TrailingCommaHandler()

    tokenHandler.setNext(errorHandler).setNext(trailingCommaHandler)

    let i = 0

    this.tokens = text
      .split(/(\s+)/)
      .map((token) => {
        let nextToken = token.replace(/@-&nbsp;-@/g, " ")

        if (isValidStringsWithQuote || !lastIndexExist) {
          return nextToken
        }

        const nextTokenLength = nextToken.length

        i = i + nextTokenLength

        if (lastIndexExist && i >= lastIndex) {
          lastIndex = lastIndex - i + nextTokenLength

          nextToken =
            nextToken.slice(0, lastIndex) + '"' + nextToken.slice(lastIndex)

          lastIndexExist = false

          return nextToken
        }

        return nextToken
      })
      .filter((v) => !!v)
      .map((str, index) => {
        const token = tokenHandler.handle(
          { str },
          {
            offset,
            FilterService: this.FilterService,
            StringService: this
          }
        )

        offset += token.str.length

        if (this.isCaretPosInToken(token)) {
          this.updateDataByCaretPos(index, token)
        }

        return token
      })

    const isQueryToken = ({ id, delimiter, value, isEmpty }: Token) =>
      !isEmpty && id && (!delimiter || !value)
    const tokenContainsFreeSearch = this.tokens.filter(isQueryToken)

    if (tokenContainsFreeSearch.length > 1) {
      this.tokens = this.tokens.map((token) => {
        if (isQueryToken(token) && !token.error) {
          return {
            ...token,
            error: { message: "Cannot perform multiple free searches" }
          }
        }

        return token
      })
    }

    return this
  }

  isCaretPosInValue(token: Token) {
    const { id, startCaretPos } = token

    return this.caretPos > startCaretPos + id.length
  }

  public isCurrentCaretPosInValue() {
    return this.dataByCaretPos[1] === 1
  }

  public findTokenIndexByCaretPos() {
    return this.tokens.findIndex((token) => this.isCaretPosInToken(token))
  }

  public getTokenByCaretPos() {
    return this.tokens[this.findTokenIndexByCaretPos()]
  }

  public removeTokenByIndex(index: number) {
    this.tokens.splice(index, 1)

    return this
  }

  updateDataByCaretPos(index?: number, token?: Token) {
    const tokenIndex = index ?? this.findTokenIndexByCaretPos()

    if (tokenIndex === -1) {
      this.dataByCaretPos = [-1, -1, ""]

      return
    }

    const tokenByCurrentCaretPos = token || this.tokens[tokenIndex]
    const caretAfterDelimiter = this.isCaretPosInValue(tokenByCurrentCaretPos)
    const { id, value, str } = tokenByCurrentCaretPos
    const textByCaretPos = caretAfterDelimiter ? value : id

    this.dataByCaretPos = [
      tokenIndex,
      this.isCaretPosInValue(tokenByCurrentCaretPos) ? 1 : 0,
      this.isEmptyString(str) || !textByCaretPos ? "" : textByCaretPos
    ]
  }

  buildTextFromTokens(str?: string | string[], caretPos?: number) {
    const needAddStrToCurrentCaretPos = str !== undefined

    if (needAddStrToCurrentCaretPos) {
      this.updateCaretPosAndData(caretPos)
    }

    const [tokenIndex] = this.dataByCaretPos

    if (
      needAddStrToCurrentCaretPos &&
      tokenIndex === -1 &&
      !Array.isArray(str)
    ) {
      this.caretPos = str.length + 1

      return `${str}:`
    }

    const keyTextHandler = new KeyTextHandler()
    const singleValueTextHandler = new SingleValueTextHandler()
    const multipleValueTextHandler = new MultipleValueTextHandler()

    keyTextHandler
      .setNext(singleValueTextHandler)
      .setNext(multipleValueTextHandler)

    return this.tokens.reduce((acc, token, index) => {
      acc += keyTextHandler.handle(token, {
        addString: str,
        tokenInCaretPos: index === tokenIndex,
        currentTextLength: acc.length,
        StringService: this
      })

      return acc
    }, "")
  }

  buildHtmlFromTokens() {
    const [tokenIndex] = this.dataByCaretPos
    const [refElement, attributeName] = this.refFilterCounter
    const countNotEmptyTokens = this.FilterService.filters.filter(
      (filter) => !!filter.value
    ).length

    if (refElement) {
      if (countNotEmptyTokens !== 0)
        refElement.setAttribute(attributeName, countNotEmptyTokens.toString())
      if (countNotEmptyTokens === 0) refElement.setAttribute(attributeName, "0")
    }

    return (
      this.tokens
        .map((token, index: number) => {
          const { str, isEmpty } = token

          if (isEmpty) {
            return `<span>${str}</span>`
          }

          const { id, delimiter, value, error, inequality, label = id } = token
          const displayLabel = inequality
            ? `<span class="inequality-label-smart-search">!</span>${label.slice(
                1
              )}`
            : label

          return `<span class="label-smart-search${
            tokenIndex === index ? " edit-smart-search" : ""
          }${delimiter && tokenIndex === index ? " value-smart-search" : ""}${
            error ? " error-smart-search" : ""
          }"${
            error?.message && tokenIndex !== index
              ? ` data-tooltip-error="${error.message}"`
              : ""
          } data-smart-search="container" data-index-clear-filter-smart-search="${index}"><span data-smart-search="id">${displayLabel}${
            delimiter ?? ""
          }</span><span data-smart-search="value">${value ?? ""}</span></span>`
        })
        .join("") || "\u00A0"
    )
  }

  setRefFilterCounter(refRowContainer: RefDivElement, attributeName: string) {
    this.refFilterCounter = [refRowContainer, attributeName]
  }
}
