import type { TFunction } from "@/hooks/useTranslation"
import { Filters } from "@/organisms/SmartSearch/types/options.types"
import {
  getCVEFilter,
  getRuntimeFilter,
  getSeverityFilter,
  InventoryFilters
} from "@/pages/DiscoveryPage/utils/filters.utils"

export const getItemsFilter = (t: TFunction): Filters => [
  InventoryFilters.application(t),
  InventoryFilters.repositoryName(t),
  InventoryFilters.imageName(t),
  InventoryFilters.environment(t),
  InventoryFilters.namespace(t),
  InventoryFilters.packageManagers(t),
  getRuntimeFilter(),
  getSeverityFilter(),
  getCVEFilter(),
  {
    key: "commitHash",
    label: t("filters.commitHash"),
    type: "input",
    helper: [
      {
        label: t("inventory.generalFilters.multipleValues", {
          label: "commit-hash"
        }),
        description: t("inventory.generalFilters.multipleSubstring", {
          name: "commit hashes"
        })
      }
    ]
  },
  {
    key: "contributors",
    label: t("filters.contributors"),
    type: "input",
    helper: [
      {
        label: t("inventory.generalFilters.multipleValues", {
          label: "contributors"
        }),
        description: t("inventory.generalFilters.multipleSubstring", {
          name: "contributors"
        })
      }
    ]
  }
]
