import { TFunction } from "@/hooks/useTranslation"

export const getItemsFilter = (t: TFunction) => {
  return [
    {
      key: "daysAgo",
      label: t("filters.firstSeen"),
      type: "radio" as const,
      orientation: "vertical" as const,
      options: [
        {
          label: "Last 24 hours",
          value: "1",
          displayName: "First Seen (24 Hours)"
        },
        {
          label: "Last 7 days",
          value: "7",
          displayName: "First Seen (7 Days)"
        },
        {
          label: "Last 30 days",
          value: "30",
          displayName: "First Seen (30 Days)"
        },
        {
          label: "Last 90 days",
          value: "90",
          displayName: "First Seen (90 Days)"
        }
      ]
    }
  ]
}
